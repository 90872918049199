export const get = ({ endpoint, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({ endpoint: endpoint, payload: { method: 'GET' }, navigate })
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getById = ({ endpoint, id, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({ endpoint: endpoint + `/${id}`, payload: { method: 'GET' }, navigate })
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getOneToManyById = ({ endpointOwner, idOwner, endpointOwned, navigate }) => {
	const finalEndpoint = endpointOwner + `/${idOwner}/` + endpointOwned;

	return new Promise((resolve, reject) => {
		makeFetch({ endpoint: finalEndpoint, payload: { method: 'GET' }, navigate })
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const postApplicationJson = ({ endpoint, body, idUser, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({
			endpoint,
			payload: {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
				idUser,
			},
			navigate,
		})
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const postMultiPartFormData = ({ endpoint, body, idUser, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({
			endpoint,
			payload: {
				method: 'POST',
				body: body,
				idUser,
			},
			navigate,
		})
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const submitPost = ({ endpoint, body, contentType, idUser, navigate }) => {
	if (contentType == 'application/json') {
		return postApplicationJson({ endpoint, body, idUser, navigate });
	} else {
		const formData = new FormData();

		for (const [key, value] of Object.entries(body)) {
			if (Array.isArray(value)) {
				formData.append(key, JSON.stringify(value));
			} else {
				formData.append(key, value);
			}
		}

		return postMultiPartFormData({ endpoint, body: formData, idUser, navigate });
	}
};

export const patchApplicationJsonById = ({ endpoint, id, body, idUser, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({
			endpoint: endpoint + `/${id}`,
			payload: {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
				idUser,
			},
			navigate,
		})
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const patchMultiPartFormDataById = ({ endpoint, id, body, idUser, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({
			endpoint: endpoint + `/${id}`,
			payload: {
				method: 'PATCH',
				body: body,
				idUser,
			},
			navigate,
		})
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const submitPatchById = ({ endpoint, id, body, contentType, idUser, navigate }) => {
	if (contentType == 'application/json') {
		return patchApplicationJsonById({ endpoint, id, body, idUser, navigate });
	} else {
		const formData = new FormData();

		for (const [key, value] of Object.entries(body)) {
			if (Array.isArray(value)) {
				formData.append(key, JSON.stringify(value));
			} else {
				formData.append(key, value);
			}
		}
		return patchMultiPartFormDataById({ endpoint, id, body: formData, idUser, navigate });
	}
};

export const submitGeneric = ({ endpoint, data, id = undefined, contentType, requestType, idUser, navigate }) => {
	let requestFunction;

	if (requestType == 'PATCH') {
		requestFunction = submitPatchById({
			endpoint,
			id,
			body: data,
			contentType,
			idUser,
			navigate,
		});
	} else {
		requestFunction = submitPost({
			endpoint,
			body: data,
			contentType,
			idUser,
			navigate,
		});
	}

	return new Promise(async (resolve, reject) => {
		try {
			let response = await requestFunction;

			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};

export const deleteById = async ({ endpoint, id, idUser, navigate }) => {
	return new Promise((resolve, reject) => {
		makeFetch({ endpoint: endpoint + `/${id}`, payload: { method: 'DELETE', idUser }, navigate })
			.then((message) => {
				resolve(message);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const makeFetch = ({ endpoint, payload, navigate }) => {
	return new Promise(async (resolve, reject) => {
		payload.credentials = 'include';

		try {
			let r = await fetch(
				process.env.REACT_APP_API_DOMAIN + process.env.REACT_APP_API_BASE_PATH + '/' + endpoint,
				payload
			);

			r = await r.json();

			if (r.success == 0) {
				if (r.message == 'RefreshTokenRegenerated') {
					try {
						let message = await makeFetch({ endpoint, payload });

						resolve(message);
					} catch (error) {
						reject(error);
					}
				} else {
					if (r.message == 'NoTokenProvided') {
						alert('No token provided. Please login again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'NoGUIDProvided') {
						alert('No GUID found. Please login again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'UserIsNotActive') {
						alert('Your user is not active. Please contact the administrator.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'RefreshTokenExpired') {
						alert('Your session has expired. Please login again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'UserNotFound') {
						alert('We cannot find your user. Please try again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'TokensNotFound') {
						alert('We cannot find your tokens. Please login again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'SessionNotFound') {
						alert('We cannot find your session. Please login again.');
						if (navigate) navigate('/');
						return;
					}

					if (r.message == 'TokenIsDifferentFromExpected') {
						alert(
							'Your refresh token has changed unexpectedly. Your account has been restricted. Please contact the administrator.'
						);
						if (navigate) navigate('/');
						return;
					}

					reject(r.message);
				}
			} else {
				if (payload.method != 'GET') {
					await maybeMakeChangeLog({ endpoint, payload, response: r });
				}

				resolve(r.message);
			}
		} catch (error) {
			reject(error);
		}
	});
};

const maybeMakeChangeLog = async ({ endpoint, payload, response }) => {
	let body = {
		idUser: payload.idUser,
		endpoint,
		description: {
			method: payload.method,
		},
	};

	if (response.id) {
		body.description = {
			...body.description,
			id: response.id,
		};
	}

	body.description = JSON.stringify(body.description);

	await fetch(process.env.REACT_APP_API_DOMAIN + process.env.REACT_APP_API_BASE_PATH + '/' + 'changeLog', {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	});
};
