import { useState } from 'react';
import styled from 'styled-components';
import { useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputImage from '@/components/input-image.component';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonEditInfo, CancelSaveRow, ButtonAdd } from '@/components/buttons.component';
import PopupGeneric from '@/components/popup-generic.component';
import InputErrorMessage from '@/components/input-error-message.component';
import LoadingIcon from '@/components/loading-icon.component';
import PopupAddGroup from '@/components/popup-add-group.component';
import GroupMiniCard from '@/components/group-mini-card.component';
import SVG from '@/components/svg.component';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import {
	FlexCol,
	FlexRowAlignCenter,
	StyledHRFullWidth,
	StyledButtonGeneric,
	StyledSpan,
	StyledButtonNoStyle
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Hooks
import { useEditViewGenericProps } from '@/libs/hooks.lib';
import * as R from "ramda";

// Libs
import { get } from '@/libs/requests.lib';

// Assets
import { actions } from '@/assets/icons';

const StyledKeyCMStatus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-size: 0.8rem;
	font-weight: bold;
	color: white;
	padding: 5px 10px;
	background-color: ${(props) => {
		switch (props.status) {
			case 'found':
				return props.theme.colors.greens.midStrong;
			case 'not-found':
				return props.theme.colors.reds.midStrong;
		}
	}};
`;

const StyledUploadNewKeyCMButton = styled(StyledButtonGeneric)`
	padding: 5px 10px;
	background-color: ${(props) => props.theme.colors.blues.midStrong};
	color: white;
	font-size: 0.8rem;
	font-weight: bold;
	white-space: nowrap;
`;

const StyledKeyCMUploaderWrapper = styled(FlexCol)`
	align-items: center;
	gap: 10px;
	width: 400px;
	height: 300px;
	padding: 20px;
`;

const StyledKeyCMTextArea = styled.textarea`
	width: 100%;
	height: 200px;
	resize: none;
`;

export default function GamesEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = null,
	onSubmitError = null,
}) {
	const [isEditing, setIsEditing] = useState(requestType === 'POST');
	const [loading, setLoading] = useState(false);
	const [popupUploadKeyCMIsOpen, setPopupUploadKeyCMIsOpen] = useState(false);
	const [newKeyCMUploaded, setNewKeyCMUploaded] = useState(false);
	const [newKeyCMValue, setNewKeyCMValue] = useState('');
	const navigate = useNavigate();
	const [isPopupAddGroupOpen, setIsPopupAddGroupOpen] = useState(false);

	const { handleSubmit, isDirty, control, getValues, onSubmit, setValue, getData, errors, RequiredAsterisk } =
		useEditViewGenericProps({
			tableName: 'games',
			requestType,
			contentType: 'multipart/form-data',
			currentlySelectedId,
			onStartRequest: () => {
				setLoading(true);
			},
			onFinishRequest: () => {
				setLoading(false);
			},
			onSubmitSuccess,
			onSubmitError,
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'groups',
	});

	const [nonSelectedGroups, setNonSelectedGroups] = useState([]);

	const getGroups = async () => {
		try {
			const response = await get({ endpoint: 'groups', navigate });
			if (response) {
				const processedGroups = R.pipe(
					R.map((group) => {
						return R.dissoc('users', group);
					}),
					R.filter((group) => !R.find(R.propEq('idGroup', group.idGroup))(fields))
				)(response);
				setNonSelectedGroups(processedGroups);
			}
		} catch (error) {
			onSubmitError(error);
		}
	};

	return (
		<>
			{isPopupAddGroupOpen && (
				<PopupAddGroup
					nonSelectedGroups={nonSelectedGroups}
					onClose={() => {
						setIsPopupAddGroupOpen(false);
					}}
					onGroupSelected={(group) => {
						append(group);
						setIsPopupAddGroupOpen(false);
					}}
				/>
			)}
			{popupUploadKeyCMIsOpen && (
				<PopupGeneric>
					<StyledKeyCMUploaderWrapper>
						<StyledSpan>
							Upload a new Key CM for{' '}
							<StyledSpan style={{ fontWeight: 'bold' }}>{getValues('name')}</StyledSpan>
						</StyledSpan>
						<StyledKeyCMTextArea
							value={newKeyCMValue}
							onChange={(e) => {
								setNewKeyCMValue(e.target.value);
							}}
						/>
						<FlexRowAlignCenter
							style={{
								gap: '20px',
							}}>
							<StyledButtonGeneric
								onClick={() => {
									setPopupUploadKeyCMIsOpen(false);
									setNewKeyCMValue('');
								}}>
								<StyledSpan>Cancel</StyledSpan>
							</StyledButtonGeneric>
							<StyledButtonGeneric
								backgroundColor={theme.colors.greens.midStrong}
								onClick={() => {
									setValue('keyCM', newKeyCMValue);
									setPopupUploadKeyCMIsOpen(false);
									setNewKeyCMValue('');
									setNewKeyCMUploaded(true);
								}}>
								<StyledSpan>Upload</StyledSpan>
							</StyledButtonGeneric>
						</FlexRowAlignCenter>
					</StyledKeyCMUploaderWrapper>
				</PopupGeneric>
			)}
			<StyledPopupEditForm onSubmit={handleSubmit(onSubmit)}>
				<FlexCol>
					{loading && (
						<LoadingIcon
							style={{
								alignSelf: 'center',
								margin: '200px 0',
							}}
						/>
					)}
					{!loading && (
						<>
							<StyledPopupEditForm.Header>
								<RowCloseButton onClose={onClose} />
								<FlexRowAlignCenter
									style={{
										gap: '20px',
									}}>
									<InputImage
										accessor={'image'}
										control={control}
										getValues={getValues}
										disabled={!isEditing}
										onFileError={onSubmitError}
									/>
									<FlexCol
										style={{
											alignItems: 'flex-start',
											gap: '10px',
										}}>
										{!isEditing && (
											<StyledSpan
												style={{
													fontSize: '1.3rem',
													fontWeight: 'bold',
												}}>
												{getValues('name')}
											</StyledSpan>
										)}
										{isEditing && (
											<>
												<FlexRowAlignCenter>
													<InputText
														accessor={'name'}
														control={control}
														validation={{ required: true }}
													/>
													<RequiredAsterisk />
												</FlexRowAlignCenter>
												<InputErrorMessage
													formErrors={errors}
													accessor={'name'}
												/>
											</>
										)}
										{requestType != 'POST' && (
											<StyledSpan>{`ID: ${getValues('idGame')}`}</StyledSpan>
										)}
									</FlexCol>
								</FlexRowAlignCenter>
								<StyledHRFullWidth
									style={{
										marginTop: '20px',
										backgroundColor: theme.colors.grays.light,
									}}
								/>
							</StyledPopupEditForm.Header>
							<StyledPopupEditForm.Body>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>IOS BUNDLE NAME</StyledSpan>
									{!isEditing && <StyledSpan>{getValues('iosBundleName')}</StyledSpan>}
									{isEditing && (
										<InputText
											accessor={'iosBundleName'}
											control={control}
											style={{
												width: '100%',
											}}
										/>
									)}
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>ANDROID BUNDLE NAME</StyledSpan>
									{!isEditing && <StyledSpan>{getValues('androidBundleName')}</StyledSpan>}
									{isEditing && (
										<InputText
											accessor={'androidBundleName'}
											control={control}
											style={{
												width: '100%',
											}}
										/>
									)}
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<FlexRowAlignCenter
										style={{
											gap: '20px',
										}}>
										<StyledSpan
											style={{
												fontSize: '0.9rem',
												fontWeight: 'bold',
											}}>
											Key CM
										</StyledSpan>
										{isEditing && (
											<StyledUploadNewKeyCMButton
												hasShadow={true}
												type={'button'}
												height={'30px'}
												onClick={() => {
													setPopupUploadKeyCMIsOpen(true);
												}}>
												<StyledSpan>Upload New</StyledSpan>
											</StyledUploadNewKeyCMButton>
										)}
									</FlexRowAlignCenter>
									<StyledKeyCMStatus status={getValues('keyCM') ? 'found' : 'not-found'}>
										{`${getValues('keyCM') ? 'Found' : 'Not Found'}`}
									</StyledKeyCMStatus>
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>SLACK ID CHANNEL</StyledSpan>
									{!isEditing && <StyledSpan>{getValues('slackIdChannel') || 'N/A'}</StyledSpan>}
									{isEditing && (
										<InputText
											accessor={'slackIdChannel'}
											control={control}
											style={{
												width: '100%',
											}}
										/>
									)}
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>REPO URL</StyledSpan>
									{!isEditing && <StyledSpan>{getValues('repoURL') || 'N/A'}</StyledSpan>}
									{isEditing && (
										<InputText
											accessor={'repoURL'}
											control={control}
											style={{
												width: '100%',
											}}
										/>
									)}
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>GROUPS</StyledSpan>
									<Flipper flipKey={fields.map((item) => item.idGroup).join('')}>
										<FlexRowAlignCenter
											style={{
												flexWrap: 'wrap',
												gap: '10px',
											}}>
											{fields.map((item, index) => (
												<Flipped
													key={item.idGroup}
													flipId={item.idGroup}>
													<FlexRowAlignCenter
														style={{
															alignItems: 'center',
															gap: '10px',
															backgroundColor: isEditing
																? theme.colors.grays.light
																: theme.colors.base.white,
															borderRadius: '5px',
															padding: '5px 10px',
														}}>
														<GroupMiniCard
															name={item.name}
															iconSVGPath={item.iconSVGPath}
															color={item.color}
														/>
														{isEditing && (
															<StyledButtonNoStyle
																type={'button'}
																onClick={() => {
																	remove(index);
																}}>
																<SVG
																	d={actions.closeFillD}
																	width={15}
																	height={15}
																	fill={theme.colors.reds.midStrong}
																/>
															</StyledButtonNoStyle>
														)}
													</FlexRowAlignCenter>
												</Flipped>
											))}
										</FlexRowAlignCenter>
									</Flipper>
								</StyledPopupEditForm.LabelValuePair>
								<FlexRow
									style={{
										justifyContent: 'space-between',
										gap: '15px',
									}}>
									{!isEditing && (
										<>
											<div></div>
											<ButtonEditInfo
												onClick={() => {
													setIsEditing(true);
												}}
											/>
										</>
									)}
									{isEditing && (
										<>
											<ButtonAdd
												onClick={() => {
													getGroups();
													setIsPopupAddGroupOpen(true);
												}}
												text={'Add Group'}
												color={theme.colors.blues.midPastel}
											/>
											<div></div>
											<CancelSaveRow
												onCancel={() => {
													setIsEditing(false);
													getData();
												}}
												saveDisabled={!isDirty && !newKeyCMUploaded}
												showCancelButton={requestType != 'POST'}
											/>
										</>
									)}
								</FlexRow>
							</StyledPopupEditForm.Body>
						</>
					)}
				</FlexCol>
			</StyledPopupEditForm>
		</>
	);
}
