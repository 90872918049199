// Third-party
import { ThemeProvider, createGlobalStyle } from 'styled-components';

// Components
import AppRouter from '@/components/app-router.component';

// Contexts
import { UserAuthContextProvider } from '@/contexts/user-auth.context';
import { CurrentMenuContextProvider } from '@/contexts/current-menu.context';
import { CheatDefinitionsContextProvider } from '@/contexts/cheat-definitions.context';
import { CustomNavigateContextProvider } from '@/contexts/custom-navigate.context';
import { TableFiltersContextProvider } from '@/contexts/table-filters.context';

// Styles
import theme from '@/styles/theme.styles';

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
	}

	body {
		margin: 0;
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		color: ${theme.colors.fonts.green};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: ${theme.colors.base.white};
	}

	input {
		color:  ${theme.colors.fonts.green};
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}

	div::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 8px; 
	}

	div::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5); 
		border-radius: 8px;
		border: 1px solid #ffffff; 
	}


	div {
		scrollbar-face-color: rgba(0, 0, 0, 0.5);
		scrollbar-track-color: #ffffff;
	}
`;

function App() {
	return (
		// Prevent the context menu from appearing
		<UserAuthContextProvider>
			<CurrentMenuContextProvider>
				<CheatDefinitionsContextProvider>
					<CustomNavigateContextProvider>
						<TableFiltersContextProvider>
							<ThemeProvider theme={theme}>
								<GlobalStyle />
								<AppRouter />
							</ThemeProvider>
						</TableFiltersContextProvider>
					</CustomNavigateContextProvider>
				</CheatDefinitionsContextProvider>
			</CurrentMenuContextProvider>
		</UserAuthContextProvider>
	);
}

export default App;
