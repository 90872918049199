// Third-party
import { useAsyncDebounce } from 'react-table';
import { useState } from 'react';
import styled from 'styled-components';

// Assets
import { actions } from '@/assets/icons';

// Components
import SVG from '@/components/svg.component';

// Styles
import { FlexRowAlignCenter, StyledButtonNoStyle } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledSearchBar = styled(FlexRowAlignCenter)`
	width: 100%;
	height: 42px;
	padding: 0 10px;
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
`;

export default function GlobalFilter({ globalFilter, setGlobalFilter, onGlobalFilterChanged = (value) => {}, style = {} }) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
		onGlobalFilterChanged(value);
	}, 200);

	return (
		<StyledSearchBar style={{
			...style,
			backgroundColor: value != '' && value != undefined ? theme.colors.oranges.light : theme.colors.base.white
		}}>
			<SVG
				d={actions.searchD}
				width={'25'}
				height={'25'}
				style={{
					marginRight: '10px',
				}}
				fill={theme.colors.base.black}
			/>
			<input
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={'Search'}
				style={{
					fontSize: '1.1rem',
					border: '0',
					width: '100%',
					outline: 'none',
					backgroundColor: value != '' && value != undefined ? theme.colors.oranges.light : theme.colors.base.white
				}}
			/>
			<StyledButtonNoStyle
				onClick={() => {
					setValue('');
					onChange('');
				}}
			>
			<SVG
				d={actions.closeD}
				width={'25'}
				height={'25'}
				fill={theme.colors.base.black}
			/>
			</StyledButtonNoStyle>
		</StyledSearchBar>
	);
}
