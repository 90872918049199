// Third-party
import * as R from 'ramda';

// Libs
import { argumentPattern } from '@/libs/regex-patterns.lib';
import { variableOperationsAsVerbs } from '@/libs/variable-operations.lib';

export function getFormattedVariablesData(variablesData) {
	return R.map((item) => {
		return R.assoc('isArgument', argumentPattern.test(item.value), item);
	})(variablesData);
}

export function getFormattedMethodsData(methodsData) {
	const formattedData = R.pipe(
		R.map((method) => {
			if (method.params == null) {
				return method;
			}
			return {
				...method,
				params: JSON.parse('[' + method.params + ']'),
			};
		}),
		R.map((method) => {
			if (method.params == null) {
				return method;
			}
			const params = R.pipe(
				R.map((param) => {
					return {
						...param,
						isArgument: argumentPattern.test(param.value),
						sortOrder: parseInt(param.sortOrder),
						value: param.value === undefined ? '' : param.value,
					};
				}),
				R.sort((a, b) => {
					return a.sortOrder - b.sortOrder;
				})
			)(method.params);

			return {
				...method,
				randomizedId: method.idMethodDescription + Math.random(),
				params: params,
			};
		})
	)(methodsData);

	return formattedData;
}

export function getArgumentsFromVariablesAndMethodsValues(variables, methods) {
	let args = new Set();

	if (variables != null && variables.length > 0) {
		R.forEach((item) => {
			if (item.value == null) {
				return;
			}
			let match = item.value.match(argumentPattern);
			if (match) {
				let number = parseInt(match[1], 10);
				args.add(
					JSON.stringify({
						number,
						value: match[0],
						dataType: item.dataType,
						idVariableDescription: item.idVariableDescription,
						idCheatDefinitionVariable: item.idCheatDefinitionVariable,
					})
				);
			}
		})(variables);
	}

	if (methods != null && methods.length > 0) {
		R.forEach((item) => {
			if (item.params == null || item.params.length == 0) {
				return;
			}
			R.forEach((param) => {
				let match = param.value.match(argumentPattern);
				if (match) {
					let number = parseInt(match[1], 10);
					args.add(
						JSON.stringify({
							number,
							value: match[0],
							dataType: param.dataType,
							idMethodDescription: item.idMethodDescription,
							idMethodParam: param.idMethodParam,
							idCheatDefinitionMethodMethodParam: param.idCheatDefinitionMethodMethodParam,
						})
					);
				}
			}, item.params);
		})(methods);
	}

	let parsedArgs = R.map((arg) => {
		return JSON.parse(arg);
	}, Array.from(args));

	// Sort the arguments by their number
	parsedArgs = R.sort((a, b) => {
		return a.number - b.number;
	}, parsedArgs);

	return parsedArgs;
}

export function getUpdatedArguments(pivotNumber, argumentsValues) {
	let updatedArguments = [];

	for (let argument of argumentsValues) {
		if (argument.number < pivotNumber) {
			updatedArguments.push(argument);
			continue;
		}

		if (argument.number == pivotNumber) {
			continue;
		}

		const newArgument = {
			...argument,
			number: argument.number - 1,
			value: `%%${argument.number - 1}%%`,
		};

		updatedArguments.push(newArgument);
	}

	return updatedArguments;
}

export function getUpdatedVariableFields(variablesFields, argumentsValues) {
	let argumentsForVariables = R.filter((argument) => R.prop('idVariableDescription', argument), argumentsValues);

	let variablesWithArguments = variablesFields.map((variable) => {
		let argument = argumentsForVariables.find(
			(argument) => argument.idVariableDescription == variable.idVariableDescription
		);

		if (argument) {
			return {
				...variable,
				value: argument.value,
			};
		}

		return variable;
	});

	return variablesWithArguments;
}

export function getUpdatedMethodFields(methodsFields, argumentsValues) {
	let argumentsForMethods = R.filter((argument) => R.prop('idMethodDescription', argument), argumentsValues);

	let methodsWithArguments = methodsFields.map((method) => {
		if (method.params === null) {
			return method;
		}

		return {
			...method,
			params: method.params.map((param) => {
				let argument = argumentsForMethods.find(
					(argument) =>
						method.randomizedId === argument.randomizedId &&
						argument.idMethodParam === param.idMethodParam
				);

				if (argument) {
					return {
						...param,
						value: argument.value,
					};
				}

				return param;
			}),
		};
	});

	return methodsWithArguments;
}

export function extractArgumentNumber(value) {
	let match = value.match(argumentPattern);
	if (match) {
		return parseInt(match[1], 10);
	}

	return null;
}

export function getHighestArgumentNumber(argumentsValues) {
	if (argumentsValues.length > 0) {
		return Math.max(...argumentsValues.map((argument) => argument.number));
	}

	return 0;
}

const renameKey = (oldKey, newKey, obj) => {
	return R.pipe(R.assoc(newKey, R.prop(oldKey, obj)), R.dissoc(oldKey))(obj);
};

export function convertVariableToAction(variable) {
	let variableClone = R.clone(variable);
	variableClone = renameKey('idVariableDescription', 'idDescription', variableClone);
	variableClone = renameKey('idVariable', 'id', variableClone);
	variableClone = R.assoc('actionType', 'variable', variableClone);
	variableClone = R.assoc('argument', 'value', variableClone);

	let nameMissing = false;
	if (
		variableClone.name == null ||
		variableClone.name === '' ||
		[3, 5, 6, 7, 8, 9, 10].includes(variableClone.idVariableOperation)
	) {
		nameMissing = true;
		variableClone = R.assoc('nameMissing', true, variableClone);
	}

	variableClone = R.assoc(
		'actionName',
		`${variableOperationsAsVerbs[variableClone.idVariableOperation]} ${
			nameMissing ? variableClone.descriptionName : variableClone.name
		}`,
		variableClone
	);

	if (variableClone.description == null || variableClone.description === '') {
		variableClone = R.assoc('descriptionMissing', true, variableClone);
	}

	return variableClone;
}

export function convertMethodToAction(method) {
	let methodClone = R.clone(method);
	methodClone = renameKey('idMethodDescription', 'idDescription', methodClone);
	methodClone = renameKey('idMethod', 'id', methodClone);
	methodClone = R.assoc('actionType', 'method', methodClone);

	let nameMissing = false;
	if (methodClone.name == null || methodClone.name === '') {
		nameMissing = true;
		methodClone = R.assoc('nameMissing', true, methodClone);
	}

	methodClone = R.assoc('actionName', nameMissing ? methodClone.descriptionName : methodClone.name, methodClone);

	if (methodClone.description == null || methodClone.description === '') {
		methodClone = R.assoc('descriptionMissing', true, methodClone);
	}

	return methodClone;
}

export function convertActionToVariable(action) {
	let actionClone = R.clone(action);
	actionClone = renameKey('idDescription', 'idVariableDescription', actionClone);
	actionClone = renameKey('id', 'idVariable', actionClone);
	actionClone = R.dissoc('actionType', actionClone);
	actionClone = R.dissoc('actionName', actionClone);
	actionClone = R.dissoc('argument', actionClone);
	actionClone = R.dissoc('nameMissing', actionClone);
	actionClone = R.dissoc('descriptionMissing', actionClone);

	return actionClone;
}

export function convertActionToMethod(action) {
	let actionClone = R.clone(action);
	actionClone = renameKey('idDescription', 'idMethodDescription', actionClone);
	actionClone = renameKey('id', 'idMethod', actionClone);
	actionClone = R.dissoc('actionType', actionClone);
	actionClone = R.dissoc('actionName', actionClone);
	actionClone = R.dissoc('nameMissing', actionClone);
	actionClone = R.dissoc('descriptionMissing', actionClone);

	return actionClone;
}

export const isThereNonCuratedDataInActions = (variablesFields, methodsFields) => {
	let thereIsNonCuratedDataInActions = false;

	for (let variable of variablesFields) {
		const action = convertVariableToAction(variable);
		if (action.nameMissing || action.descriptionMissing) {
			thereIsNonCuratedDataInActions = true;
			break;
		}
	}

	if (!thereIsNonCuratedDataInActions) {
		for (let method of methodsFields) {
			const action = convertMethodToAction(method);
			if (action.nameMissing || action.descriptionMissing) {
				thereIsNonCuratedDataInActions = true;
				break;
			}
		}
	}

	return thereIsNonCuratedDataInActions;
};

export const removeArgumentsFromVariables = (variables) => {
	const variablesWithNoArgs = R.map((variable) => {
		if (argumentPattern.test(variable.value)) {
			return R.pipe(R.assoc('isArgument', 0), R.assoc('value', variable.defaultValue))(variable);
		}

		return variable;
	})(variables);

	return variablesWithNoArgs;
};

export const removeArgumentsFromMethods = (methods) => {
	const methodsWithNoArgs = R.map((method) => {
		if (method.params === null) {
			return method;
		}

		let paramsWithNoArgs = R.map((param) => {
			if (argumentPattern.test(param.value)) {
				return R.pipe(R.assoc('isArgument', 0), R.assoc('value', param.defaultValue))(param);
			}

			return param;
		})(method.params);

		return R.assoc('params', paramsWithNoArgs, method);
	})(methods);

	return methodsWithNoArgs;
};

export const examplesByDataType = {
    Int: [1, 10, 75, 250, 500, 1200],
    Float: [25.5, 10.2, 46.7, 124.2, 676.4, 400.5],
    String: ["hello", "world", "cheat", "code", "example", "here"],
    Bool: ["true", "false"],
    DateTime: [
        "2021-10-10T00:00:00.000Z",
        "2021-10-10T00:00:00.000Z",
        "2021-10-10T00:00:00.000Z",
        "2021-10-10T00:00:00.000Z",
        "2021-10-10T00:00:00.000Z",
        "2021-10-10T00:00:00.000Z",
    ],
    Double: [25.5, 10.2, 46.7, 124.2, 676.4, 400.5],
    JSON: ["{key1: value1, key2: value2}"],
};