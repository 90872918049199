const root = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: {
            roles: ["*"],
        },
        create: true,
        update: {
            avatar: true,
            username: true,
            roles: ["*"],
            active: true,
            groups: true,
        },
    },
    groups: {
        read: true,
        create: true,
        update: true,
    },
    roles: {
        read: true,
        create: true,
        update: true,
    },
    logs: {
        read: true,
        create: false,
        update: false,
    },
    devices: {
        read: true,
        create: false,
        update: true,
    },
    games: {
        read: true,
        create: true,
        update: true,
        curate: true,
    },
    cheatDefinitions: {
        read: true,
        create: true,
        update: {
            modes: ["full", "simple"],
        },
    },
    snapshots: {
        read: true,
        create: false,
        update: true,
    },
    partialSnapshots: {
        read: true,
        create: true,
        update: true,
    },
    macros: {
        read: true,
        create: false,
        update: true,
    },
    pushNotifications: {
        create: true,
    },
    approvePushNotifications: {
        read: true,
        update: true,
    },
};

const qaPrograLeader = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: {
            roles: [
                "qaPrograLeader",
                "qaProgra",
                "newUser",
                "socialMediaLeader",
                "socialMedia",
                "playerSupportLeader",
                "playerSupport",
            ],
        },
        create: false,
        update: {
            avatar: true,
            username: true,
            roles: [
                "qaProgra",
                "newUser",
                "socialMediaLeader",
                "socialMedia",
                "playerSupportLeader",
                "playerSupport",
            ],
            active: true,
            groups: true,
        },
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: true,
        create: false,
        update: false,
    },
    devices: {
        read: {
            roles: [
                "qaPrograLeader",
                "qaProgra",
                "socialMediaLeader",
                "socialMedia",
                "playerSupportLeader",
                "playerSupport",
            ],
        },
        create: false,
        update: true,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: {
            groups: ["self"],
        }
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["full", "simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: {
            groups: ["self"],
        },
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: {
            groups: ["self"],
        },
        update: {
            groups: ["self"],
        },
    },
    macros: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: {
            groups: ["self"],
        },
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const qaProgra = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: false,
        create: false,
        update: false,
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: {
            owner: ["self"],
        },
        create: false,
        update: false,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: {
            groups: ["self"],
        }
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["full", "simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: {
            groups: ["self"],
        },
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: {
            groups: ["self"],
        },
        update: {
            groups: ["self"],
        },
    },
    macros: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: {
            groups: ["self"],
        },
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const socialMediaLeader = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: {
            roles: ["socialMediaLeader", "socialMedia", "newUser"],
        },
        create: false,
        update: {
            avatar: true,
            username: true,
            roles: ["socialMedia", "newUser"],
            active: true,
            groups: true,
        },
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: {
            roles: ["socialMediaLeader", "socialMedia"],
        },
        create: false,
        update: true,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: {
            groups: ["self"],
        }
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["full", "simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const socialMedia = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: false,
        create: false,
        update: false,
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: {
            owner: ["self"],
        },
        create: false,
        update: false,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: false
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const playerSupportLeader = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: {
            roles: ["playerSupportLeader", "playerSupport", "newUser"],
        },
        create: false,
        update: {
            avatar: true,
            username: true,
            roles: ["playerSupport", "newUser"],
            active: true,
            groups: true,
        },
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: {
            roles: ["playerSupportLeader", "playerSupport"],
        },
        create: false,
        update: true,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: {
            groups: ["self"],
        }
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["full", "simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const playerSupport = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: false,
        create: false,
        update: false,
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: {
            owner: ["self"],
        },
        create: false,
        update: false,
    },
    games: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
        curate: false
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: true,
        update: {
            modes: ["simple"],
        },
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: {
            groups: ["self"],
        },
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const newUser = {
    profile: {
        read: true,
        update: true,
    },
    users: {
        read: false,
        create: false,
        update: false,
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: false,
        create: false,
        update: false,
    },
    games: {
        read: false,
        create: false,
        update: false,
        curate: false
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    snapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: false,
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const general = {
    profile: {
        read: false,
        update: false,
    },
    users: {
        read: false,
        create: false,
        update: false,
    },
    groups: {
        read: false,
        create: false,
        update: false,
    },
    roles: {
        read: false,
        create: false,
        update: false,
    },
    logs: {
        read: false,
        create: false,
        update: false,
    },
    devices: {
        read: false,
        create: false,
        update: false,
    },
    games: {
        read: false,
        create: false,
        update: false,
        curate: false
    },
    cheatDefinitions: {
        read: {
            groups: ["self"],
        },
        create: false,
        update: false,
    },
    snapshots: {
        read: false,
        create: false,
        update: false,
    },
    partialSnapshots: {
        read: false,
        create: false,
        update: false,
    },
    macros: {
        read: false,
        create: false,
        update: false,
    },
    pushNotifications: {
        create: false,
    },
    approvePushNotifications: {
        read: false,
        update: false,
    },
};

const permissions = {
    root,
    qaPrograLeader,
    qaProgra,
    socialMediaLeader,
    socialMedia,
    playerSupportLeader,
    playerSupport,
    newUser,
    general,
};

export default permissions;
