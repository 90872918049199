import chat from './chat.svg';
import chatD from './chat';
import bolt from './bolt.svg';
import boltD from './bolt';
import build from './build.svg';
import buildD from './build';
import bug from './bug.svg';
import bugD from './bug';
import celebration from './celebration.svg';
import celebrationD from './celebration';
import cognition from './cognition.svg';
import cognitionD from './cognition';
import description from './description.svg';
import descriptionD from './description';
import diamond from './diamond.svg';
import diamondD from './diamond';
import eco from './eco.svg';
import ecoD from './eco';
import emojiObjects from './emoji_objects.svg';
import emojiObjectsD from './emoji_objects';
import extension from './extension.svg';
import extensionD from './extension';
import forest from './forest.svg';
import forestD from './forest';
import key from './key.svg';
import keyD from './key';
import keyboard from './keyboard.svg';
import keyboardD from './keyboard';
import medicalInformation from './medical_information.svg';
import medicalInformationD from './medical_information';
import palette from './palette.svg';
import paletteD from './palette';
import pets from './pets.svg';
import petsD from './pets';
import public_ from './public.svg';
import publicD from './public';
import share from './share.svg';
import shareD from './share';
import smartToy from './smart_toy.svg';
import smartToyD from './smart_toy';
import star from './star.svg';
import starD from './star';
import warning from './warning.svg';
import warningD from './warning';

const groupIcons = {
	chat: chatD,
	bolt: boltD,
	build: buildD,
	bug: bugD,
	celebration: celebrationD,
	cognition: cognitionD,
	description: descriptionD,
	diamond: diamondD,
	eco: ecoD,
	emojiObjects: emojiObjectsD,
	extension: extensionD,
	forest: forestD,
	key: keyD,
	keyboard: keyboardD,
	medicalInformation: medicalInformationD,
	palette: paletteD,
	pets: petsD,
	public: publicD,
	share: shareD,
	smartToy: smartToyD,
	star: starD,
	warning: warningD,
};

export default groupIcons;
