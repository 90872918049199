// Third-party
import { useState } from 'react';

// Components
import TableViewGenericProps from '@/components/table-view-generic-props.component';
import PopupGeneric from '@/components/popup-generic.component';
import TableActions from '@/components/table-actions.component';
import GenericMiniCard from '@/components/generic-mini-card.component';
import { ThDefault, ColGroups } from '@/components/table.component';

// Views
import PartialSnapshotPostView from '../views/partial-snapshots-post.view';

// Libs
import { getColumnFromHeaderGroupByHeaderName } from '@/libs/tables.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';

// Styles
import Table from '@/styles/table.styles';
import { StyledContent, StyledSpan, StyledSpanBold, FlexCol } from '@/styles/shared.styles';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { CurrentMenuContext } from '@/contexts/current-menu.context';
import { useContext } from 'react';
import * as R from "ramda";

export default function PartialSnapshotsTableView() {
	const { userAuthState } = useContext(UserAuthContext);
	const { currentMenuState, setCurrentMenuState } = useContext(CurrentMenuContext);
	const { navigateTo } = useCustomNavigate();

	const [showCreatePopup, setShowCreatePopup] = useState(false);
	const [tableViewGenericPropsRandomKey, setTableViewGenericPropsRandomKey] = useState(Math.random());

	const editRow = (rowId) => {
		setCurrentMenuState({
			...currentMenuState,
			partialSnapshots: {
				...currentMenuState.partialSnapshots,
				currentlySelectedId: rowId,
			},
		});
		navigateTo({
			mainPath: 'partialSnapshots',
			subPath: 'patch',
		});
	};

	return (
		<StyledContent>
			<TableViewGenericProps
				menuName='partialSnapshots'
				key={tableViewGenericPropsRandomKey}
				buttonAddText='New Partial Snapshot'
				onAddClick={() => {
					setShowCreatePopup(true);
				}}
				onPopupSuccessClose={() => {
					setShowCreatePopup(false);
					setTableViewGenericPropsRandomKey(Math.random());
				}}
				preFilteringFunction={(data) => {
					if (userAuthState.user.permissions.partialSnapshots.read === true) {
						return data;
					}
					
					const groupsInUser = userAuthState.user.groups;

					const filteredData = R.filter(
						R.pipe(
							R.prop('groups'),
							R.intersection(groupsInUser),
							R.isEmpty,
							R.not
						)
					)(data);

					return filteredData;
				}}
				>
				{({
					getTableProps,
					headerGroups,
					getTableBodyProps,
					rows,
					prepareRow,
					deleteRow,
					setPopupErrorMessage,
					setShowErrorPopup,
					setPopupSuccessMessage,
					setShowSuccessPopup,
				}) => {
					const headerGroup = headerGroups[0];
					const columnGame = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Game');
					const columnName = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Name');
					return (
						<>
							{showCreatePopup && (
								<PopupGeneric onClickOutside={() => {}}>
									<PartialSnapshotPostView
										onCancel={() => {
											setShowCreatePopup(false);
										}}
										onError={(error) => {
											setPopupErrorMessage(error);
											setShowErrorPopup(true);
										}}
										onSuccess={() => {
											setPopupSuccessMessage('Partial Snapshot successfully created.');
											setShowSuccessPopup(true);
										}}
									/>
								</PopupGeneric>
							)}
							<Table {...getTableProps()}>
								<ColGroups widths={['25%', '35%', '35%', '5%']} />
								<Table.THead>
									<Table.Tr {...headerGroup.getHeaderGroupProps()}>
										<ThDefault
											column={columnGame}
											text={columnGame.render('Header')}
										/>
										<ThDefault
											column={columnName}
											text={columnName.render('Header')}
										/>
										<Table.Th>Origin</Table.Th>
										<Table.Th></Table.Th>
									</Table.Tr>
								</Table.THead>
								<Table.TBody {...getTableBodyProps()}>
									{rows.map((row) => {
										prepareRow(row);
										return (
											<Table.Tr {...row.getRowProps()}>
												<Table.Td>
													<GenericMiniCard
														image={row.values.gameImage}
														topText={row.values.gameName}
														bottomText={row.values.gameVersion}
													/>
												</Table.Td>
												<Table.Td>
													<StyledSpan>{row.values.name}</StyledSpan>
												</Table.Td>
												<Table.Td>
													<FlexCol
														style={{
															gap: '10px',
														}}>
														<StyledSpan>
															<StyledSpanBold>{`Start ID: `}</StyledSpanBold>
															{`${row.values.idSnapshot1}`}
														</StyledSpan>
														<StyledSpan>
															<StyledSpanBold>{`End ID: `}</StyledSpanBold>
															{`${row.values.idSnapshot2}`}
														</StyledSpan>
													</FlexCol>
												</Table.Td>
												<Table.Td>
													{userAuthState.user.permissions.partialSnapshots.update !==
														false && (
														<TableActions
															onEditRow={() => {
																editRow(row.values.idPartialSnapshot);
															}}
															onDeleteRow={() => {
																deleteRow(row.values.idPartialSnapshot);
															}}
														/>
													)}
												</Table.Td>
											</Table.Tr>
										);
									})}
								</Table.TBody>
							</Table>
						</>
					);
				}}
			</TableViewGenericProps>
		</StyledContent>
	);
}
