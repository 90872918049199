import { useState } from 'react';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonEditInfo, CancelSaveRow } from '@/components/buttons.component';
import InputErrorMessage from '@/components/input-error-message.component';
import LoadingIcon from '@/components/loading-icon.component';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import { FlexCol, FlexRowAlignCenter, StyledHRFullWidth, StyledSpan } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Hooks
import { useEditViewGenericProps } from '@/libs/hooks.lib';

export default function RolesEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = null,
	onSubmitError = null,
}) {
	const [isEditing, setIsEditing] = useState(requestType === 'POST');
	const [loading, setLoading] = useState(false);

	const { handleSubmit, isDirty, errors, control, getValues, onSubmit, getData, RequiredAsterisk } =
		useEditViewGenericProps({
			tableName: 'roles',
			requestType,
			contentType: 'application/json',
			currentlySelectedId,
			onStartRequest: () => {
				setLoading(true);
			},
			onFinishRequest: () => {
				setLoading(false);
			},
			onSubmitSuccess,
			onSubmitError,
		});

	return (
		<StyledPopupEditForm
			onSubmit={handleSubmit(onSubmit)}
			style={{
				height: '400px',
			}}>
			<FlexCol
				style={{
					height: '100%',
					justifyContent: 'space-between',
				}}>
				{loading && (
					<LoadingIcon
						style={{
							alignSelf: 'center',
							margin: '150px 0',
						}}
					/>
				)}
				{!loading && (
					<>
						<StyledPopupEditForm.Header
							style={{
								height: '160px',
							}}>
							<RowCloseButton onClose={onClose} />
							<FlexRowAlignCenter
								style={{
									gap: '20px',
								}}>
								<FlexCol
									style={{
										alignItems: 'flex-start',
										gap: '10px',
										marginTop: '20px',
									}}>
									{!isEditing && (
										<StyledSpan
											style={{
												fontSize: '1.3rem',
												fontWeight: 'bold',
											}}>
											{getValues('name')}
										</StyledSpan>
									)}
									{isEditing && (
										<>
											<FlexRowAlignCenter>
												<InputText
													accessor={'name'}
													control={control}
													validation={{ required: true }}
													placeholder={'Enter a name'}
												/>
												<RequiredAsterisk />
											</FlexRowAlignCenter>
											<InputErrorMessage
												formErrors={errors}
												accessor={'name'}
											/>
										</>
									)}
									{requestType != 'POST' && <StyledSpan>{`ID: ${getValues('idRole')}`}</StyledSpan>}
								</FlexCol>
							</FlexRowAlignCenter>
							<StyledHRFullWidth
								style={{
									marginTop: '20px',
									backgroundColor: theme.colors.grays.light,
								}}
							/>
						</StyledPopupEditForm.Header>
						<StyledPopupEditForm.Body
							style={{
								flexGrow: 1,
								justifyContent: 'space-between',
							}}>
							<StyledPopupEditForm.LabelValuePair>
								<FlexRowAlignCenter>
									<StyledSpan>DESCRIPTION</StyledSpan>
									<RequiredAsterisk />
								</FlexRowAlignCenter>
								{!isEditing && <StyledSpan>{getValues('description')}</StyledSpan>}
								{isEditing && (
									<>
										<InputTextArea
											accessor={'description'}
											control={control}
											validation={{ required: true }}
											style={{
												width: '100%',
											}}
											placeholder={'Enter a description'}
										/>
										<InputErrorMessage
											formErrors={errors}
											accessor={'description'}
										/>
									</>
								)}
							</StyledPopupEditForm.LabelValuePair>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									gap: '15px',
								}}>
								{!isEditing && (
									<>
										<div></div>
										<ButtonEditInfo
											onClick={() => {
												setIsEditing(true);
											}}
										/>
									</>
								)}
								{isEditing && (
									<>
										<div></div>
										<CancelSaveRow
											onCancel={() => {
												setIsEditing(false);
												getData();
											}}
											saveDisabled={!isDirty}
											showCancelButton={requestType != 'POST'}
										/>
									</>
								)}
							</FlexRow>
						</StyledPopupEditForm.Body>
					</>
				)}
			</FlexCol>
		</StyledPopupEditForm>
	);
}
