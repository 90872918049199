import styled from 'styled-components';
import theme from '@/styles/theme.styles';

export const StyledCol = styled.div`
	justify-self: start;
	align-self: center;
	display: flex;
	align-items: center;
	border: 1px solid ${theme.colors.grays.light};
	width: 100%;
	height: ${(props) => props.height || '40px'};
	padding: 0.5rem;
`;
