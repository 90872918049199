import styled from 'styled-components';

const StyledGridForm = styled.form`
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 0.5rem;
	height: 100%;
	align-content: start;

	.edit-label {
		grid-column: 1 / 2;
		justify-self: start;
		align-self: center;
		font-size: 1.2rem;
		font-weight: bold;
	}

	.edit-input {
		grid-column: 2 / 3;
		justify-self: start;
		align-self: center;
	}

	.edit-input-checkbox {
		grid-column: 2 / 3;
		justify-self: start;
		align-self: center;
	}

	.edit-input-error {
		grid-column: 2 / 3;
		justify-self: start;
		align-self: center;
	}

	.edit-gap {
		grid-column: 1 / 3;
	}
`;

const Label = styled.label.attrs((props) => ({
	className: 'edit-label',
}))``;

const Input = styled.div.attrs((props) => ({
	className: 'edit-input',
}))`
	width: ${(props) => props.width || '100%'}};

	input {
		width: 100%;
	}
`;

const InputCheckbox = styled.div.attrs((props) => ({
	className: 'edit-input-checkbox',
}))``;

const InputError = styled.div.attrs((props) => ({
	className: 'edit-input-error',
}))``;

const Gap = styled.div.attrs((props) => ({
	className: 'edit-gap',
}))`
	height: ${(props) => props.height || '0.8rem'};
`;

StyledGridForm.Gap = Gap;
StyledGridForm.Input = Input;
StyledGridForm.InputCheckbox = InputCheckbox;
StyledGridForm.InputError = InputError;
StyledGridForm.Label = Label;

export default StyledGridForm;
