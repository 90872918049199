const users = [
	{
		Header: 'ID',
		accessor: 'idUser',
	},
	{
		Header: 'Username',
		accessor: 'username',
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Avatar',
		accessor: 'avatar',
	},
	{
		Header: 'ID Role',
		accessor: 'idRole',
	},
	{
		Header: 'Role',
		accessor: 'roleName',
	},
	{
		Header: 'Groups',
		accessor: 'groups',
	},
	{
		Header: 'Active',
		accessor: 'active',

		type: 'checkbox',
	},
];

const devices = [
	{
		Header: 'ID',
		accessor: 'idDevice',
	},
	{
		Header: 'Unique Identifier',
		accessor: 'deviceUniqueIdentifier',
	},
	{
		Header: 'Device Description',
		accessor: 'deviceDescription',
	},
	{
		Header: 'Id User',
		accessor: 'idUser',
	},
	{
		Header: 'User',
		accessor: 'username',
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Avatar',
		accessor: 'avatar',
	},
	{
		Header: 'OS',
		accessor: 'OS',
	},
	{
		Header: 'Resolution Height',
		accessor: 'resH',
	},
	{
		Header: 'Resolution Width',
		accessor: 'resW',
	},
	{
		Header: 'Frame Rate',
		accessor: 'framerate',
	},
	{
		Header: 'Active',
		accessor: 'active',
	},
	{
		Header: 'Auto Active',
		accessor: 'autoActive',
	},
	{
		Header: 'Last Use',
		accessor: 'lastUse',
	},
	{
		Header: 'Active Games',
		accessor: 'gamesCount',
	},
];

const games = [
	{
		Header: 'ID',
		accessor: 'idGame',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'iOS Bundle Name',
		accessor: 'iosBundleName',
	},
	{
		Header: 'Android Bundle Name',
		accessor: 'androidBundleName',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Image',
		accessor: 'image',
	},
	{
		Header: 'Key CM',
		accessor: 'keyCM',
	},
];

const snapshots = [
	{
		Header: 'ID',
		accessor: 'idSnapshot',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Image',
		accessor: 'image',

		type: 'image',
	},
	{
		Header: 'Id Device',
		accessor: 'idDevice',
	},
	{
		Header: 'Game',
		accessor: 'gameName',
	},
	{
		Header: 'Game Version',
		accessor: 'gameVersion',
	},
	{
		Header: 'Game Image',
		accessor: 'gameImage',
	},
	{
		Header: 'OS',
		accessor: 'OS',
	},
	{
		Header: 'Creation Datetime',
		accessor: 'creationTime',
	},
];

const partialSnapshots = [
	{
		Header: 'ID',
		accessor: 'idPartialSnapshot',
	},
	{
		Header: 'Game',
		accessor: 'gameName',
	},
	{
		Header: 'Game Version',
		accessor: 'gameVersion',
	},
	{
		Header: 'Game Image',
		accessor: 'gameImage',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Id Cheat Definition',
		accessor: 'idCheatDefinition',
	},
	{
		Header: 'Id Snapshot 1',
		accessor: 'idSnapshot1',
	},
	{
		Header: 'Name Snapshot 1',
		accessor: 'nameSnapshot1',
	},
	{
		Header: 'Id Snapshot 2',
		accessor: 'idSnapshot2',
	},
	{
		Header: 'Name Snapshot 2',
		accessor: 'nameSnapshot2',
	},
	{
		Header: 'Selection',
		accessor: 'selection',
	},
];

const macros = [
	{
		Header: 'ID',
		accessor: 'idMacro',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Macro',
		accessor: 'macro',
	},
	{
		Header: 'Game',
		accessor: 'gameName',
	},
	{
		Header: 'Game Version',
		accessor: 'gameVersion',
	},
	{
		Header: 'Id Device',
		accessor: 'idDevice',
	},
	{
		Header: 'OS',
		accessor: 'OS',
	},
	{
		Header: 'Resolution Height',
		accessor: 'resH',
	},
	{
		Header: 'Resolution Width',
		accessor: 'resW',
	},
	{
		Header: 'Frame Rate',
		accessor: 'framerate',
	},
	{
		Header: 'Creation Datetime',
		accessor: 'creationTime',
	},
];

const cheatDefinitions = [
	{
		Header: 'ID',
		accessor: 'idCheatDefinition',
	},
	{
		Header: 'Id Game',
		accessor: 'idGame',
	},
	{
		Header: 'Game',
		accessor: 'gameName',
	},
	{
		Header: 'Game Version',
		accessor: 'gameVersion',
	},
	{
		Header: 'Game Image',
		accessor: 'gameImage',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Active',
		accessor: 'active',
	},
	{
		Header: 'Id Game Definition',
		accessor: 'idGameDefinition',
	},
	{
		Header: 'Cheat Keys',
		accessor: 'cheatKeys',
	},
];

const cheatKeys = [
	{
		Header: 'Key',
		accessor: 'key',
	},
	{
		Header: 'Active',
		accessor: 'active',

		type: 'checkbox',
	},
	{
		Header: 'Redeem Counter',
		accessor: 'redeemCounter',
	},
	{
		Header: 'Max Num Uses',
		accessor: 'maxNumUses',
	},
	{
		Header: 'Expire Date',
		accessor: 'expireDate',

		type: 'dateTime',
	},
];

const approvePushNotifications = [];

const roles = [
	{
		Header: 'ID',
		accessor: 'idRole',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
];


const logs = [
	{
		Header: 'User',
		accessor: 'user',
	},
	{
		Header: 'Action',
		accessor: 'endpoint',
	},
	{
		Header: 'Params',
		accessor: 'description',
	},
	{
		Header: 'Date',
		accessor: 'dateTime',
	},
];

const variables = [
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Data Type',
		accessor: 'dataType',
	},
	{
		Header: 'Default Value',
		accessor: 'defaultValue',
	},
	{
		Header: 'Value',
		accessor: 'value',
	},
	{
		Header: 'Operation',
		accessor: 'variableOperation',
	},
	{
		Header: 'Icon',
		accessor: 'icon',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
];

const selectedVariables = [
	{
		Header: 'Id Variable Description',
		accessor: 'idVariableDescription',
	},
	{
		Header: 'Id Variable',
		accessor: 'idVariable',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Icon',
		accessor: 'icon',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Favorite',
		accessor: 'favorite',
	},
	{
		Header: 'Description Name',
		accessor: 'descriptionName',
	},
];

const selectedMethods = [
	{
		Header: 'Id Method Description',
		accessor: 'idMethodDescription',
	},
	{
		Header: 'Id Method',
		accessor: 'idMethod',
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		Header: 'Icon',
		accessor: 'icon',
	},
	{
		Header: 'Description',
		accessor: 'description',
	},
	{
		Header: 'Favorite',
		accessor: 'favorite',
	},
	{
		Header: 'Description Name',
		accessor: 'descriptionName',
	},
];

export default {
	users,
	devices,
	games,
	variables,
	snapshots,
	partialSnapshots,
	macros,
	cheatDefinitions,
	approvePushNotifications,
	roles,
	logs,
	cheatKeys,
	variables,
	selectedVariables,
	selectedMethods,
};
