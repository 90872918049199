import { Controller } from 'react-hook-form';
import styled from 'styled-components';

// Styles
import { StyledInputBase } from '@/styles/shared.styles';

import { argumentPattern } from '@/libs/regex-patterns.lib';

const StyledInputNumber = styled(StyledInputBase)`
	height: 40px;
	width: 100%;
`;

export default function InputNumber({
	accessor,
	editable,
	control,
	defaultValue = 0,
	validation = {},
	min = null,
	max = null,
	style = {},
}) {
	return (
		<Controller
			name={accessor}
			control={control}
			defaultValue={defaultValue}
			rules={validation}
			render={({ field: { onChange, value } }) => {
				return (
					<StyledInputNumber
						style={style}
						value={argumentPattern.test(value) ? '' : value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
						type='number'
						readOnly={!editable}
						disabled={!editable}
						min={min}
						max={max}
					/>
				);
			}}
		/>
	);
}
