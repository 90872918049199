// Third-party
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import * as R from 'ramda';
import { Flipper, Flipped } from 'react-flip-toolkit';

// Libs
import { postApplicationJson, getOneToManyById } from '@/libs/requests.lib';
import { get } from '@/libs/requests.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputAsyncSelectGameAndVersion from '@/components/input-async-select-game-and-version.component';
import GenericMiniCard from '@/components/generic-mini-card.component';
import PopupGeneric from '@/components/popup-generic.component';
import { ButtonEdit, ButtonIcon, ButtonCancel, ButtonSubmit } from '@/components/buttons.component';
import SearchBar from '@/components/search-bar.component';
import RowCloseButton from '@/components/row-close-button.component';
import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import PopupAddGroup from '@/components/popup-add-group.component';
import GroupMiniCard from '@/components/group-mini-card.component';
import SVG from '@/components/svg.component';
import LoadingIcon from '@/components/loading-icon.component';

// Styles
import { FlexRowAlignCenter, FlexCol, StyledSpanBold, StyledSpan, StyledButtonNoStyle } from '@/styles/shared.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';
import theme from '@/styles/theme.styles';

// Assets
import { actions } from '@/assets/icons';

export default function PartialSnapshotPostView({
	onCancel = () => {},
	onSuccess = () => {},
	onError = (error) => {},
}) {
	const navigate = useNavigate();
	const { userAuthState } = useContext(UserAuthContext);

	const [showPopupAddGroup, setShowPopupAddGroup] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		getValues,
		formState: { errors, isDirty },
	} = useForm();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'groups',
	});

	const [nonSelectedGroups, setNonSelectedGroups] = useState([]);

	const getGroups = async () => {
		const response = await get({ endpoint: 'groups', navigate });
		if (response) {
			const processedGroups = R.pipe(
				R.map((group) => {
					return R.dissoc('users', group);
				}),
				R.filter((group) => !R.find(R.propEq('idGroup', group.idGroup))(fields))
			)(response);
			setNonSelectedGroups(processedGroups);
		}
	};

	useEffect(() => {
		const filteredGroups = R.filter((group) => {
			return group.name != 'Hyperbeard';
		})(userAuthState.user.groups);

		reset({ groups: filteredGroups });
	}, []);

	const [snapshots, setSnapshots] = useState([]);

	const idGame = watch('idGame', null);
	const idGameDefinition = watch('idGameDefinition', null);

	const getSnapshots = useCallback(() => {
		setLoading(true);
		getOneToManyById({
			endpointOwner: 'gameDefinitions',
			idOwner: idGameDefinition,
			endpointOwned: 'snapshots',
			navigate,
		})
			.then((data) => {
				setSnapshots(data);
				setLoading(false);
			})
			.catch((error) => {
				onError(error);
				setLoading(false);
			});
	}, [idGameDefinition]);

	const [showSnapshotSelector, setShowSnapshotSelector] = useState(false);
	const [editingSnapshot1, setEditingSnapshot1] = useState(false);

	// Final Submit
	const onSubmit = useCallback(async (finalData) => {
		setLoading(true);
		try {
			await postApplicationJson({
				endpoint: 'partialSnapshots',
				body: finalData,
				navigate,
			});

			onSuccess();
			setLoading(false);
		} catch (error) {
			onError(error);
			setLoading(false);
		}
	}, []);

	return (
		<>
			{showSnapshotSelector && (
				<PopupGeneric
					zIndex={20}
					onClickOutside={() => {
						setShowSnapshotSelector(false);
					}}>
					<PopupSelectSnapshot
						onClose={() => {
							setShowSnapshotSelector(false);
						}}
						snapshots={snapshots}
						onSnapshotSelected={(snapshot) => {
							setValue(editingSnapshot1 ? 'idSnapshot1' : 'idSnapshot2', snapshot.idSnapshot);
							setValue(editingSnapshot1 ? 'nameSnapshot1' : 'nameSnapshot2', snapshot.name);
						}}
					/>
				</PopupGeneric>
			)}
			{showPopupAddGroup && (
				<PopupAddGroup
					nonSelectedGroups={nonSelectedGroups}
					onClose={() => {
						setShowPopupAddGroup(false);
					}}
					onGroupSelected={(group) => {
						append(group);
						setShowPopupAddGroup(false);
					}}
				/>
			)}
			<StyledGridForm
				onSubmit={handleSubmit(onSubmit)}
				style={{
					padding: '30px',
				}}>
				<FlexRowAlignCenter
					style={{
						gridColumn: '1 / 3',
						justifyContent: 'center',
					}}>
					<StyledSpanBold
						style={{
							fontSize: '1.5rem',
						}}>
						Create Partial Snapshot
					</StyledSpanBold>
				</FlexRowAlignCenter>
				<StyledGridForm.Gap />
				<StyledGridForm.Gap />
				<InputAsyncSelectGameAndVersion 
                        control={control}
                        getValues={getValues}
                        requestType={'POST'}
                        setValue={setValue}
                        errors={errors}
                        onError={onError}
						watch={watch}
						filterByGroups={true}
						idGroupsToAllow={userAuthState.user.groups.map(group => group.idGroup)}
                    />
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Name</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputText
						control={control}
						accessor={'name'}
						editable={true}
						validation={{ required: true }}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'name'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Description</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputTextArea
						control={control}
						accessor={'description'}
						editable={true}
						cols={null}
						style={{
							width: '350px',
						}}
						validation={{ required: true }}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'description'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>Groups</StyledGridForm.Label>
				<StyledGridForm.Input>
					<Flipper flipKey={fields.map((item) => item.idGroup).join('')}>
						<FlexRowAlignCenter
							style={{
								gap: '10px',
								width: '100%',
							}}>
							<FlexRowAlignCenter
								style={{
									width: '350px',
									flexWrap: 'wrap',
									gap: '10px',
									flexGrow: 1,
									height: '100px',
									overflowY: 'auto',
									border: `1px solid ${theme.colors.grays.light}`,
									borderRadius: '5px',
									padding: '5px 10px',
								}}>
								{fields.map((item, index) => (
									<Flipped
										key={item.idGroup}
										flipId={item.idGroup}>
										<FlexRowAlignCenter
											style={{
												alignItems: 'center',
												gap: '10px',
												backgroundColor: theme.colors.grays.light,
												borderRadius: '5px',
												padding: '5px 10px',
											}}>
											<GroupMiniCard
												name={item.name}
												iconSVGPath={item.iconSVGPath}
												color={item.color}
											/>
											<StyledButtonNoStyle
												type={'button'}
												onClick={() => {
													remove(index);
												}}>
												<SVG
													d={actions.closeFillD}
													width={15}
													height={15}
													fill={theme.colors.reds.midStrong}
												/>
											</StyledButtonNoStyle>
										</FlexRowAlignCenter>
									</Flipped>
								))}
							</FlexRowAlignCenter>
							<ButtonIcon
								hoverable={true}
								d={actions.addCircleD}
								fill={theme.colors.blues.midPastel}
								hoverFill={theme.colors.blues.midStrong}
								onClick={() => {
									getGroups();
									setShowPopupAddGroup(true);
								}}
								width={'35px'}
								height={'35px'}
							/>
						</FlexRowAlignCenter>
					</Flipper>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Origin Snapshot</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<FlexRowAlignCenter
						style={{
							gap: '10px',
						}}>
						<InputText
							control={control}
							accessor={'nameSnapshot1'}
							editable={false}
							validation={{
								required: true,
							}}
						/>
						<ButtonEdit
							onClick={() => {
								setEditingSnapshot1(true);
								getSnapshots();
								setShowSnapshotSelector(true);
							}}
							disabled={!watch('idGame')}
						/>
					</FlexRowAlignCenter>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'nameSnapshot1'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Final Snapshot</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<FlexRowAlignCenter
						style={{
							gap: '10px',
						}}>
						<InputText
							control={control}
							accessor={'nameSnapshot2'}
							editable={false}
							validation={{
								required: true,
							}}
						/>
						<ButtonEdit
							onClick={() => {
								setEditingSnapshot1(false);
								getSnapshots();
								setShowSnapshotSelector(true);
							}}
							disabled={!watch('idGame')}
						/>
					</FlexRowAlignCenter>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'nameSnapshot2'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Gap />
				<StyledGridForm.Gap />
				{loading && (
					<FlexRowAlignCenter
						style={{
							gridColumn: '1 / 3',
							width: '100%',
							justifyContent: 'center',
							gap: '20px',
						}}>
						<LoadingIcon />
					</FlexRowAlignCenter>
				)}
				{!loading && (
					<FlexRowAlignCenter
						style={{
							gridColumn: '1 / 3',
							width: '100%',
							justifyContent: 'center',
							gap: '20px',
						}}>
						<ButtonCancel
							onClick={() => {
								onCancel();
							}}
						/>
						<ButtonSubmit disabled={!watch('idSnapshot1') && !watch('idSnapshot2') && !isDirty} />
					</FlexRowAlignCenter>
				)}
			</StyledGridForm>
		</>
	);
}

const StyledPopupSelectSnapshot = styled(FlexCol)`
	width: 400px;
	height: 400px;
	padding: 15px 20px 20px 20px;
	gap: 10px;
`;

function PopupSelectSnapshot({ snapshots, onSnapshotSelected = (snapshot) => {}, onClose }) {
	const [filtered, setFiltered] = useState(snapshots);

	useEffect(() => {
		setFiltered(snapshots);
	}, [snapshots]);

	return (
		<StyledPopupSelectSnapshot>
			<RowCloseButton
				onClose={onClose}
				iconFill={theme.colors.base.black}
			/>
			<StyledSpanBold
				style={{
					alignSelf: 'center',
				}}>
				Select a Snapshot
			</StyledSpanBold>
			<SearchBar
				unfilteredValues={snapshots}
				setFilteredValues={setFiltered}
				keysToSearch={['name', 'description', 'idSnapshot']}
			/>
			<FlexCol
				style={{
					overflowY: 'scroll',
					height: '100%',
					gap: '10px',
					alignItems: 'flex-start',
				}}>
				{filtered.map((snapshot) => (
					<StyledButtonNoStyle
						key={snapshot.idSnapshot}
						type='button'
						style={{
							width: '100%',
						}}
						onClick={() => {
							onSnapshotSelected(snapshot);
							onClose();
						}}>
						<GenericMiniCard
							hoverable={true}
							key={snapshot.idSnapshot}
							image={snapshot.image}
							topText={`${snapshot.idSnapshot}: ${snapshot.name}`}
							bottomText={snapshot.description}
							imageSize={'50px'}
						/>
					</StyledButtonNoStyle>
				))}
			</FlexCol>
		</StyledPopupSelectSnapshot>
	);
}
