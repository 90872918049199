// Components
import SVG from '@/components/svg.component';

// Styles
import { FlexRowAlignCenter, StyledSpanBold } from '@/styles/shared.styles';

export default function GroupMiniCard({ name, iconSVGPath, color, iconSize = '25', fontSize = '1rem' }) {
	return (
		<FlexRowAlignCenter
			style={{
				gap: '5px',
			}}>
			<SVG
				d={iconSVGPath}
				height={iconSize}
				width={iconSize}
				fill={color}
			/>
			<StyledSpanBold
				style={{
					fontSize,
				}}>
				{name}
			</StyledSpanBold>
		</FlexRowAlignCenter>
	);
}
