import styled from 'styled-components';
import {useEffect, useState} from 'react';
import * as R from 'ramda';
import { Tooltip } from 'react-tooltip';

// Components
import { TooltipActivatorQuestion } from '@/components/tooltip-activators.component';
import InputCheckbox from "@/components/input-checkbox.component";

// State
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { useContext } from 'react';

import { AppropriateInputForDataType } from '@/libs/inputs.lib';
import { argumentPattern } from '@/libs/regex-patterns.lib';

import theme from '@/styles/theme.styles';
import {
    ButtonBack,
} from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import SVG from '@/components/svg.component';
import {
    FlexRowAlignCenter,
    StyledSpanBold,
    StyledSpan,
    FlexCol
} from '@/styles/shared.styles';

import { alerts } from '@/assets/icons';

const StyledCheatKeysOverrideParamsView = styled(FlexCol)`
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export default function CheatKeysOverrideParamsView({idCheatKey, control, watch, fieldsVariables, appendVariable, removeVariable, updateVariable, fieldsMethodParams, appendMethodParam, removeMethodParam, updateMethodParam, fieldsMethods, appendMethod, removeMethod, updateMethod, width, setValue, getValues, onBackClick = () => {}}) {
    const { cheatDefinitionsState } = useContext(CheatDefinitionsContext);
    const [popupErrorMessage, setPopupErrorMessage] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    const thereAreNewNonSavedVariablesOrMethods = R.any((variable) => variable.idCheatDefinitionVariable === undefined, cheatDefinitionsState.variables) || R.any((method) => method.idCheatDefinitionMethod === undefined, cheatDefinitionsState.methods);
    
    return (
        <StyledCheatKeysOverrideParamsView
            style={{
                width,
            }}
        >
            <Tooltip
                id='information-tooltip'
                place='right'
                effect='solid'
            />
            {showErrorPopup && (
                <PopupError
                    error={popupErrorMessage}
                    onClose={() => setShowErrorPopup(false)}
                />
            )}
            <ButtonBack 
                text="Back"
                onClick={() => {
                    const values = getValues();
                    const thereIsEmptyValueInVariables = values.overrideVariables.some((field) =>
                    {
                        const isDisabled = field.disabled === 1;
                        return !isDisabled && field.value === '';
                    });
                    const thereIsEmptyValueInMethodParams = values.overrideMethodParams.some((field) => {
                        return field.value === '';
                    });

                    if (thereIsEmptyValueInVariables || thereIsEmptyValueInMethodParams) {
                        setPopupErrorMessage('There are some empty values in the overridden variables or method parameters. Please fill them all.');
                        setShowErrorPopup(true);
                        return;
                    }

                    onBackClick();
                }}
            />
            {thereAreNewNonSavedVariablesOrMethods && (
                <FlexRowAlignCenter>
                    <SVG 
                        d={alerts.warningFillD}
                        width={50}
                        height={50}
                        fill={theme.colors.reds.midStrong}
                    />
                    <StyledSpan
                        style={{
                            marginTop: '15px',
                            textAlign: 'center',
                            color: theme.colors.reds.midStrong,
                        }}
                    >
                        Newly added variables or methods cannot be overriden. Save the code batch first to override them.
                    </StyledSpan>
                    <SVG 
                        d={alerts.warningFillD}
                        width={50}
                        height={50}
                        fill={theme.colors.reds.midStrong}
                    />
                </FlexRowAlignCenter>
            )}
            <StyledSpanBold
                style={{
                    marginTop: '15px',
                    marginBottom: '20px',
                    fontSize: '1.3em',
                    alignSelf: 'center'
                }}
            >Disable And/Or Override Parameters </StyledSpanBold>
            <StyledSpan
                style={{
                    marginBottom: '20px',
                    textAlign: 'center'
                }}
            >
                - Parameters marked with an <StyledSpan
                    style={{
                        color: theme.colors.reds.midStrong,
                        fontWeight: 'bold'
                    }}>* (asterisk)</StyledSpan> were selected as arguments in the code batch's definition.
            </StyledSpan>
            <StyledSpan
                style={{
                    marginBottom: '20px',
                    textAlign: 'center'
                }}
            >
                - Disabled indicates that this method or variable will be ignored when the code is redeemed.
            </StyledSpan>
            <StyledSpan
                style={{
                    marginBottom: '20px',
                    textAlign: 'center'
                }}
            >
                - Override determines if the parameter's value will be overriden manually.
            </StyledSpan>
            <FlexCol
                style={{
                    width: '100%',
                    backgroundColor: theme.colors.grays.light,
                    padding: '15px',
                    marginBottom: '20px'
                }}
            >
                <StyledSpanBold>
                    Methods
                </StyledSpanBold>
            </FlexCol>
            <FlexCol
                style={{
                    marginBottom: '20px',
                    gap: '20px'
                }}
            >
                {cheatDefinitionsState.methods.filter((method) => method.params !== null && method.params !== undefined && method.idCheatDefinitionMethod !== undefined).map((method, index) => {
                    return (
                        <StyledSpan
                            key={index}
                        >
                            <ItemMethod
                                method={method}
                                index={index + 1}
                                control={control}
                                appendMethodParam={appendMethodParam}
                                idCheatKey={idCheatKey}
                                removeMethodParam={removeMethodParam}
                                fieldsMethodParams={fieldsMethodParams}
                                updateMethodParam={updateMethodParam}
                                fieldsMethods={fieldsMethods}
                                appendMethod={appendMethod}
                                removeMethod={removeMethod}
                                updateMethod={updateMethod}
                                watch={watch}
                                setValue={setValue}
                            />
                        </StyledSpan>
                    )
                })}
            </FlexCol>
            <FlexCol
                style={{
                    width: '100%',
                    backgroundColor: theme.colors.grays.light,
                    padding: '15px',
                }}
            >
                <StyledSpanBold>
                    Variables
                </StyledSpanBold>
            </FlexCol>
            <FlexCol
                style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    gap: '20px'
                }}
            >
                {cheatDefinitionsState.variables.filter((variable) => variable.idCheatDefinitionVariable !== undefined).map((variable, index) => {
                    const fieldIndex = fieldsVariables.findIndex((field) => Number(field.idCheatDefinitionVariable) === variable.idCheatDefinitionVariable);
                    
                    return (
                        <StyledSpan
                            key={index}
                        >
                            <ItemVariable
                                variable={variable}
                                index={index + 1}
                                control={control}
                                appendVariable={appendVariable}
                                idCheatKey={idCheatKey}
                                fieldIndex={fieldIndex}
                                removeVariable={removeVariable}
                                watch={watch}
                                setValue={setValue}
                                updateVariable={updateVariable}
                            />
                        </StyledSpan>
                    )
                })}
            </FlexCol>
        </StyledCheatKeysOverrideParamsView>
    );
}

const StyledCheckbox = styled.input`
    width: 20px;
    height: 20px;
    color: ${theme.colors.grays.mid};
    background-color: ${theme.colors.grays.light};
`;

const ItemVariable = ({idCheatKey, appendVariable, removeVariable, updateVariable, variable, index, fieldIndex, control, watch, setValue}) => {
    const [isChecked, setIsChecked] = useState(fieldIndex !== -1); 
    const [isDisabled, setIsDisabled] = useState(
        watch(`overrideVariables.${fieldIndex}.disabled`) === "0" ||
        watch(`overrideVariables.${fieldIndex}.disabled`) === 0 ||
        watch(`overrideVariables.${fieldIndex}.disabled`) === undefined
        ? 0 : 1);

    const isArgument = argumentPattern.test(variable.value);
    return (
        <FlexCol
            style={{
                gap: '10px',
            }}
        >
            <FlexRowAlignCenter
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <FlexRowAlignCenter
                    style={{
                        gap: 10,
                        marginRight: '20px'
                    }}
                >
                    <StyledSpan>Disabled</StyledSpan>
                    <StyledCheckbox type="checkbox" 
                                    defaultValue={null}
                        checked={isDisabled}
                        onChange={(e) => {
                            if (e.target.checked) {
                                if (!isChecked) {
                                    appendVariable({
                                        idCheatKey: idCheatKey,
                                        value: argumentPattern.test(variable.value) ? '' : variable.value,
                                        idVariableOperation: variable.idVariableOperation,
                                        idCheatDefinitionVariable: variable.idCheatDefinitionVariable,
                                        disabled: 1
                                    });
                                    setIsChecked(1);
                                }
                                else {
                                    updateVariable(fieldIndex,
                                        {
                                            idCheatKey: idCheatKey,
                                            value: argumentPattern.test(variable.value) ? '' : variable.value,
                                            idVariableOperation: variable.idVariableOperation,
                                            idCheatDefinitionVariable: variable.idCheatDefinitionVariable,
                                            disabled: 1
                                        })
                                }
                                
                                setIsDisabled(1);
                            }
                            else {
                                setIsDisabled(0);
                                updateVariable(fieldIndex,
                                    {
                                        idCheatKey: idCheatKey,
                                        value: argumentPattern.test(variable.value) ? '' : variable.value,
                                        idVariableOperation: variable.idVariableOperation,
                                        idCheatDefinitionVariable: variable.idCheatDefinitionVariable,
                                        disabled: 0
                                    })
                            }
                        }}
                    />
                </FlexRowAlignCenter>
                <StyledSpanBold style={{marginRight: '5px'}}>{`${index}.`}</StyledSpanBold>
                <StyledSpan>{`${variable.descriptionName} (${variable.dataType})`}</StyledSpan>
                {isArgument && (
                    <StyledSpan
                        style={{
                            color: theme.colors.reds.midStrong,
                            fontWeight: 'bold',
                            marginLeft: '5px',
                        }}
                    >*</StyledSpan>
                )}
                <FlexRowAlignCenter
                    style={{
                        flexGrow: 1,
                        justifyContent: 'flex-end'
                    }}
                >
                    <FlexRowAlignCenter
                        style={{
                            gap: 10,
                        }}
                    >
                        <StyledSpan>Override</StyledSpan>
                        <StyledCheckbox type="checkbox"
                                        disabled={isDisabled}
                            checked={isChecked}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    appendVariable({
                                        idCheatKey: idCheatKey,
                                        value: argumentPattern.test(variable.value) ? '' : variable.value,
                                        idVariableOperation: variable.idVariableOperation,
                                        idCheatDefinitionVariable: variable.idCheatDefinitionVariable,
                                    });
                                }
                                else {
                                    removeVariable(fieldIndex);
                                }
    
                                setIsChecked(e.target.checked);
                            }}
                        />
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
            {isChecked && !isDisabled && (
                <FlexRowAlignCenter
                    style={{
                        paddingLeft: '20px',
                        gap: '10px',
                    }}
                >
                    <StyledSpan>Value:</StyledSpan>
                    <AppropriateInputForDataType
                        dataType={variable.dataType}
                        style={{
                            width: '100%'
                        }}
                        control={control}
                        accessor={`overrideVariables.${fieldIndex}.value`}
                    />
                </FlexRowAlignCenter>
            )}
        </FlexCol>
    )
}

const ItemMethod = ({method, index, control, appendMethodParam, removeMethodParam, updateMethodParam, idCheatKey, fieldsMethodParams, fieldsMethods, appendMethod, removeMethod, updateMethod, watch, setValue}) => {
    const [isDisabled, setIsDisabled] = useState(0);

    useEffect(() => {
        if (fieldsMethods.length > 0) {
            const fieldIndex = R.findIndex((field) => Number(field.idCheatDefinitionMethod) === method.idCheatDefinitionMethod, fieldsMethods);
            
            console.log(fieldsMethods);
            console.log(fieldIndex);
            console.log(method);
            
            if (fieldIndex !== -1) {
                const overrideMethodDisable = watch(`overrideMethods.${fieldIndex}.disabled`);
                
                setIsDisabled(
                    overrideMethodDisable === "0" ||
                    overrideMethodDisable === 0 ||
                    overrideMethodDisable === undefined
                    ? 0 : 1
                );
            }
        }
    }, []);
    
    return (
        <FlexCol
            style={{
                gap: '20px',
            }}
        >
            <FlexRowAlignCenter
                style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                }}
            >
                <FlexRowAlignCenter
                    style={{
                        gap: 10,
                        marginRight: '20px'
                    }}
                >
                    <StyledSpan>Disabled</StyledSpan>
                    <StyledCheckbox 
                        type="checkbox"
                        defaultValue={null}
                        checked={isDisabled}
                        onChange={(e) => {
                            if (e.target.checked) {
                                appendMethod({
                                    idCheatKey: idCheatKey,
                                    idCheatDefinitionMethod: method.idCheatDefinitionMethod,
                                    disabled: 1
                                });
                                setIsDisabled(1);
                            }
                            else {
                                setIsDisabled(0);
                                const fieldIndex = R.findIndex((field) => Number(field.idCheatDefinitionMethod) === method.idCheatDefinitionMethod, fieldsMethods);
                                removeMethod(fieldIndex);
                            }
                        }}
                    />
                </FlexRowAlignCenter>
                <StyledSpanBold style={{marginRight: '5px'}}>{`${index}.`}</StyledSpanBold>
                <StyledSpan>{`${method.descriptionName}`}</StyledSpan>
            </FlexRowAlignCenter>
            <FlexCol
                style={{
                    gap: '20px'
                }}
            >
                {method.params.map((param, index) => {
                    const fieldIndex = fieldsMethodParams.findIndex((field) => field.idCheatDefinitionMethodMethodParam === param.idCheatDefinitionMethodMethodParam);

                    return (
                        <ItemParameter 
                            key={index}
                            param={param}
                            control={control}
                            appendMethodParam={appendMethodParam}
                            removeMethodParam={removeMethodParam}
                            idCheatKey={idCheatKey}
                            fieldIndex={fieldIndex}
                            watch={watch}
                            setValue={setValue}
                            updateMethodParam={updateMethodParam}
                            methodIsDisabled={isDisabled}
                        />
                    )
                })}
            </FlexCol>
        </FlexCol>
    );
}

const ItemParameter = ({param, control, appendMethodParam, removeMethodParam, methodIsDisabled, idCheatKey, fieldIndex}) => {
    const [isChecked, setIsChecked] = useState(fieldIndex !== -1);

    const isArgument = argumentPattern.test(param.value);

    return (
        <FlexCol
            style={{
                gap: '10px',
            }}
        >
            <FlexRowAlignCenter
                style={{
                    paddingLeft: '35px',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <StyledSpanBold>{`- ${param.paramName}`}</StyledSpanBold>
                <StyledSpan
                    style={{
                        marginLeft: '5px',
                    }}
                >{`(${param.dataType})`}</StyledSpan>
                {isArgument && (
                    <StyledSpan
                        style={{
                            color: theme.colors.reds.midStrong,
                            fontWeight: 'bold',
                            marginLeft: '5px',
                        }}
                    >*</StyledSpan>
                )}
                <FlexRowAlignCenter
                        style={{
                            flexGrow: 1,
                            justifyContent: 'flex-end'
                        }}
                    >
                    <FlexRowAlignCenter
                        style={{
                            gap: 10,
                        }}
                    >
                        <StyledSpan>Override</StyledSpan>
                        <StyledCheckbox type="checkbox"
                                        disabled={methodIsDisabled}
                            checked={isChecked}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    appendMethodParam({
                                        idCheatKey: idCheatKey,
                                        value: argumentPattern.test(param.value) ? '' : param.value,
                                        idCheatDefinitionMethodMethodParam: param.idCheatDefinitionMethodMethodParam,
                                    });
                                }
                                else {
                                    removeMethodParam(fieldIndex);
                                }

                                setIsChecked(e.target.checked);
                            }}
                        />
                    </FlexRowAlignCenter>
                    </FlexRowAlignCenter>
            </FlexRowAlignCenter>
            {isChecked && !methodIsDisabled && (
                <FlexRowAlignCenter
                    style={{
                        paddingLeft: '65px',
                        gap: '10px',
                    }}
                >
                    <StyledSpan>Value:</StyledSpan>
                    <AppropriateInputForDataType
                        dataType={param.dataType}
                        style={{
                            width: '100%'
                        }}
                        control={control}
                        accessor={`overrideMethodParams.${fieldIndex}.value`}
                    />
                </FlexRowAlignCenter>
            )}
        </FlexCol>
    )
}