import LoadingIcons from 'react-loading-icons';

// Style
import theme from '@/styles/theme.styles';

export default function LoadingIcon({ style = {} }) {
	return (
		<LoadingIcons.BallTriangle
			style={style}
			stroke={theme.colors.reds.midStrong}
			speed={0.75}
		/>
	);
}
