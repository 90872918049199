import styled from 'styled-components';

// Components
import PopupMousePos from '@/components/popup-mouse-pos.component';
import SVG from '@/components/svg.component';

// Styles
import { FlexCol, FlexRow, StyledHR, StyledButtonGeneric, StyledSpan, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledGroupDetail = styled.div`
	background-color: ${(props) => props.theme.colors.base.white};
	box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 10px;
	max-width: 300px;
`;

export default function PopupGroupDetail({
	groupData,
	x,
	y,
	onClickOutside = () => {},
	buttonEditIsVisible = true,
	onClickEdit = () => {},
}) {
	return (
		<PopupMousePos
			x={x}
			y={y}
			onClickOutside={onClickOutside}>
			<StyledGroupDetail>
				<FlexCol>
					<FlexRow
						style={{
							gap: '5px',
							alignItems: 'center',
							marginBottom: '10px',
						}}>
						<SVG
							d={groupData.iconSVGPath}
							width={25}
							height={25}
							fill={groupData.color}
						/>
						<StyledSpan
							style={{
								fontSize: '1.1rem',
								fontWeight: 'bold',
								color: theme.colors.greens.dark,
							}}>{`${groupData.name} `}</StyledSpan>
						<StyledSpan
							style={{
								fontSize: '0.8rem',
								fontWeight: 'bold',
								color: theme.colors.grays.dark,
							}}>
							{`(${groupData.userCount})`}
						</StyledSpan>
					</FlexRow>
					<StyledHR />
					<StyledSpan
						style={{
							color: theme.colors.grays.dark,
							marginTop: '10px',
						}}>
						{groupData.description}
					</StyledSpan>
					{buttonEditIsVisible && (
						<StyledButtonGeneric
							style={{
								alignSelf: 'flex-end',
								marginTop: '10px',
							}}
							backgroundColor={theme.colors.greens.midStrong}
							onClick={onClickEdit}>
							<StyledSpanBold
								style={{
									fontSize: '0.9rem',
								}}>
								Edit Group
							</StyledSpanBold>
						</StyledButtonGeneric>
					)}
				</FlexCol>
			</StyledGroupDetail>
		</PopupMousePos>
	);
}
