// Third-party
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useExpanded,
} from 'react-table';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import PaginationFooter from '@/components/pagination-footer.component';
import GlobalFilter from '@/components/filter-global.component';
import { filters } from '@/libs/tables.lib';
import { FlexRow } from '@/styles/shared.styles';
import SVG from '@/components/svg.component';
import PopupCancelConfirm from '@/components/popup-cancel-confirm.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import { ButtonAdd } from '@/components/buttons.component';
import LoadingIcon from '@/components/loading-icon.component';

// Assets
import { navigation } from '@/assets/icons';

// Libs
import { applyFilters, getFilterComponents } from '@/libs/filters.lib';
import headers from '@/libs/headers.lib';
import { deleteById, get } from '@/libs/requests.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Styles
import theme from '@/styles/theme.styles';
import {
    StyledTableContainer,
    StyledDataTableViewFlexCol,
    StyledDataTableTitle,
    StyledCategoricFilterContainer,
} from '@/styles/shared.styles';

const tableNameToLabel = {
    cheatDefinitions: 'Code Batches',
    devices: 'Devices',
    games: 'Games',
    macros: 'Macros',
    partialSnapshots: 'Partial Snapshots',
    pushNotifications: 'Push Notifications',
    snapshots: 'Snapshots',
    users: 'Users',
    roles: 'Roles',
    logs: 'Logs',
};

export default function TableViewGenericProps({
    menuName,
    preParsingFunction = (data) => data,
    preFilteringFunction = (data) => data,
    children = () => {},
    buttonAddText = 'New',
    onAddClick = () => {},
    onPopupSuccessClose = () => {},
    defaultFilters = null,
    onFiltersChanged = (filters) => {},
    useDefaultGlobalFilter = true,
    onGlobalFilterChanged = (value) => {},
    globalFilterValue = "",
    extraGlobalFilterFunction = (data) => data,
}) {
  
    const navigate = useNavigate();
    const { userAuthState } = useContext(UserAuthContext);

    // Popups
    const [loading, setLoading] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [popupErrorMessage, setPopupErrorMessage] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [popupSuccessMessage, setPopupSuccessMessage] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    // Data from server
    const [data, setData] = useState([]);
    const getData = useCallback(async () => {
        setLoading(true);
        try {
            let data = await get({
                endpoint: menuName,
                navigate,
            });
            data = preParsingFunction(data);
            data = preFilteringFunction(data);
            setData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setPopupErrorMessage(error);
            setShowErrorPopup(true);
        }
    }, []);

    useEffect(() => {
        getData();
    }, []);

    // Filters used for this dataset
    const [realTimeFilters, setRealTimeFilters] = useState(defaultFilters || filters[menuName]);
    
    useEffect(() => {
        onFiltersChanged(realTimeFilters);
    }, [realTimeFilters]);

    // Filtered data after applying filters to data
    const tableData = useMemo(() => {
        const applyFilterFunction = applyFilters(realTimeFilters);
        let filteredData = data;
        if (useDefaultGlobalFilter) {
            filteredData = applyFilterFunction(filteredData);
        }
        filteredData = extraGlobalFilterFunction(filteredData);
        return filteredData;
    }, [data, realTimeFilters, globalFilterValue]);

    const [currentPage, setCurrentPage] = useState(0);
    const memoizedHeaders = useMemo(() => headers[menuName], []);
    // React-Table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns: memoizedHeaders,
            data: tableData,
            initialState: { pageIndex: currentPage, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const [selectedRowIdToDelete, setSelectedRowIdToDelete] = useState(null);
    const deleteRow = (rowId) => {
        setSelectedRowIdToDelete(rowId);
        setShowDeletePopup(true);
    };
    const onDeleteRowConfirmed = useCallback(() => {
        deleteById({
            endpoint: menuName,
            id: selectedRowIdToDelete,
            idUser: userAuthState.user.id,
            navigate,
        }).then((response) => {
            if (response.success == 0) {
                setPopupErrorMessage(response.message.message);
                setShowErrorPopup(true);
            } else {
                getData();
            }
        });
    }, [selectedRowIdToDelete]);

    return (
        <>
            {showErrorPopup && (
                <PopupError
                    error={popupErrorMessage}
                    onClose={() => {
                        setShowErrorPopup(false);
                    }}
                />
            )}
            {showSuccessPopup && (
                <PopupSuccess
                    message={popupSuccessMessage}
                    onClose={() => {
                        setShowSuccessPopup(false);
                        onPopupSuccessClose();
                    }}
                />
            )}
            {showDeletePopup && (
                <PopupCancelConfirm
                    message={'Are you sure you want to delete this row?'}
                    onCancel={() => {
                        setShowDeletePopup(false);
                    }}
                    onConfirm={() => {
                        setShowDeletePopup(false);
                        onDeleteRowConfirmed();
                    }}
                />
            )}
            <StyledDataTableViewFlexCol>
                {loading && (
                    <LoadingIcon
                        style={{
                            marginTop: '250px',
                            alignSelf: 'center',
                        }}
                    />
                )}
                {!loading && (
                    <>
                        <FlexRow
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '10px',
                            }}>
                            <IconWithLabel
                                icon={navigation[menuName + 'D']}
                                label={tableNameToLabel[menuName]}
                            />
                            <GlobalFilter
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                onGlobalFilterChanged={(value) => {
                                    onGlobalFilterChanged(value);
                                    setCurrentPage(0);
                                }}
                            />
                            <StyledCategoricFilterContainer>
                                {getFilterComponents(
                                    realTimeFilters,
                                    setRealTimeFilters,
                                    data
                                )}
                            </StyledCategoricFilterContainer>
                            {userAuthState.user.permissions[menuName] &&
                                userAuthState.user.permissions[menuName]
                                    .create !== false && (
                                    <ButtonAdd
                                        onClick={onAddClick}
                                        text={buttonAddText}
                                        color={theme.colors.oranges.midStrong}
                                    />
                                )}
                        </FlexRow>
                        <StyledTableContainer>
                            {children({
                                getTableProps,
                                headerGroups,
                                getTableBodyProps,
                                prepareRow,
                                rows: page,
                                deleteRow,
                                getData,
                                setShowErrorPopup,
                                setPopupErrorMessage,
                                setShowSuccessPopup,
                                setPopupSuccessMessage,
                            })}
                        </StyledTableContainer>
                        <PaginationFooter
                            currentPageLength={page.length}
                            totalRows={tableData.length}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            callback={(value) => {
                                setCurrentPage(value);
                            }}
                        />
                    </>
                )}
            </StyledDataTableViewFlexCol>
        </>
    );
}

function IconWithLabel({ icon, label }) {
    return (
        <FlexRow
            style={{
                alignItems: 'center',
            }}>
            <SVG d={icon} fill={'#4E4E4C'} width='50px' height='50px' />
            <StyledDataTableTitle>{label}</StyledDataTableTitle>
        </FlexRow>
    );
}
