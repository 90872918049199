// Third-party
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Tooltip } from 'react-tooltip';

// Components
import PopupGeneric from '@/components/popup-generic.component';
import SVG from '@/components/svg.component';
import { ButtonCancel, ButtonAccept, ButtonRemoveCircle } from '@/components/buttons.component';
import RowCloseButton from './row-close-button.component';
import { TooltipActivatorQuestion } from './tooltip-activators.component';

// Styles
import {
	FlexRowAlignCenter,
	FlexRow,
	FlexCol,
	StyledSpanBold,
	StyledSpan,
	StyledSearchBar,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Assets
import { alerts } from '@/assets/icons';

const StyledPopupSelectDevices = styled.div`
	display: grid;
	grid-template-rows: 25px 70px 1fr 40px;
	grid-template-columns: 1fr 1.25fr;
	grid-template-areas:
		'close close'
		'headerUnselected headerSelected'
		'unselected selected'
		'footer footer';
	height: 450px;
	width: 750px;
	padding: 10px 10px 10px 10px;
`;

const StyledUnselectedDevices = styled(FlexCol)`
	grid-area: unselected;
	overflow-y: scroll;
	padding: 0px 15px;
	border-right: 1px solid ${theme.colors.grays.mid};
	gap: 35px;

	.flipper {
		width: 100%;
	}
`;

const StyledSelectedDevices = styled(FlexCol)`
	grid-area: selected;
	overflow-y: scroll;
	padding: 0px 15px;
	gap: 5px;

	.flipper {
		width: 100%;
	}
`;

export default function PopupSelectDevices({
	startingSelectedDevices = [],
	startingUnselectedDevices = [],
	onSelectedDevicesDone = (selectedDevices) => {},
}) {
	const navigate = useNavigate();
	const [selectedDevices, setSelectedDevices] = useState(startingSelectedDevices);

	useEffect(() => {
		setSelectedDevices(startingSelectedDevices);
	}, [startingSelectedDevices]);

	const [unselectedDevices, setUnselectedDevices] = useState(startingUnselectedDevices);

	useEffect(() => {
		setUnselectedDevices(startingUnselectedDevices);
	}, [startingUnselectedDevices]);

	const [search, setSearch] = useState('');
	const filteredUnselectedDevices = unselectedDevices.filter((device) => {
		return device.username.includes(search) || device.email.includes(search);
	});

	return (
		<PopupGeneric onClickOutside={() => {}}>
			<Tooltip
				id='device-description-tooltip'
				place='right'
				effect='solid'
			/>
			<StyledPopupSelectDevices>
				<RowCloseButton
					onClose={() => {
						onSelectedDevicesDone(startingSelectedDevices);
					}}
					iconFill={theme.colors.base.black}
					style={{
						gridArea: 'close',
					}}
				/>
				<FlexCol
					style={{
						gridArea: 'headerUnselected',
						justifyContent: 'flex-start',
						marginBottom: '15px',
					}}>
					<StyledSpanBold
						style={{
							alignSelf: 'center',
							marginBottom: '10px',
						}}>
						Unselected Devices
					</StyledSpanBold>
					<FlexRowAlignCenter
						style={{
							padding: '0 10px',
							gap: '5px',
						}}>
						<StyledSearchBar
							style={{
								flexGrow: 1,
							}}
							type='text'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder='Search by username or email...'
						/>
					</FlexRowAlignCenter>
				</FlexCol>
				<FlexRow
					style={{
						gridArea: 'headerSelected',
						justifyContent: 'center',
					}}>
					<StyledSpanBold>Selected Devices</StyledSpanBold>
				</FlexRow>

				<StyledUnselectedDevices>
					<Flipper
						flipKey={filteredUnselectedDevices.length}
						className='flipper'>
						{filteredUnselectedDevices.map((device) => (
							<Flipped
								key={device.idDevice}
								flipId={device.idDevice}>
								<DeviceOption
									device={device}
									hoverable={true}
									onClick={() => {
										setSelectedDevices([...selectedDevices, device]);
										setUnselectedDevices(
											unselectedDevices.filter(
												(unselectedDevice) => unselectedDevice.idDevice !== device.idDevice
											)
										);
									}}
								/>
							</Flipped>
						))}
					</Flipper>
				</StyledUnselectedDevices>
				<StyledSelectedDevices>
					<Flipper
						flipKey={selectedDevices.length}
						className='flipper'>
						{selectedDevices.map((device) => (
							<Flipped
								key={device.idDevice}
								flipId={device.idDevice}>
								<FlexRowAlignCenter
									style={{
										justifyContent: 'space-between',
										marginBottom: '5px',
										gap: '10px',
									}}>
									<DeviceOption device={device} />
									<ButtonRemoveCircle
										onClick={() => {
											setUnselectedDevices([...unselectedDevices, device]);
											setSelectedDevices(
												selectedDevices.filter(
													(selectedDevice) => selectedDevice.idDevice !== device.idDevice
												)
											);
										}}
									/>
								</FlexRowAlignCenter>
							</Flipped>
						))}
					</Flipper>
				</StyledSelectedDevices>
				<FlexRowAlignCenter
					style={{
						gridArea: 'footer',
						justifyContent: 'center',
						width: '100%',
						gap: '25px',
					}}>
					<ButtonCancel
						onClick={() => {
							onSelectedDevicesDone(startingSelectedDevices);
						}}
					/>

					<ButtonAccept
						onClick={() => {
							onSelectedDevicesDone(selectedDevices);
						}}
					/>
				</FlexRowAlignCenter>
			</StyledPopupSelectDevices>
		</PopupGeneric>
	);
}

const StyledDeviceOption = styled(FlexCol)`
	gap: 5px;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid ${theme.colors.grays.light};

	&:hover {
		cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
		background-color: ${(props) => (props.hoverable ? props.theme.colors.greens.mid : 'white')};
	}
`;

function DeviceOption({ device, hoverable = false, onClick = () => {} }) {
	return (
		<StyledDeviceOption
			onClick={() => {
				onClick();
			}}
			hoverable={hoverable}>
			<FlexRow
				style={{
					justifyContent: 'space-between',
					width: '100%',
				}}>
				<StyledSpanBold
					style={{
						fontSize: '0.8rem',
						whiteSpace: 'normal',
					}}>
					{`UID: ${device.deviceUniqueIdentifier}`}
				</StyledSpanBold>
				<TooltipActivatorQuestion
					id='device-description-tooltip'
					content={device.deviceDescription}
				/>
			</FlexRow>
			<StyledSpan>{`OS: ${device.OS === '' ? 'N/A' : device.OS}`}</StyledSpan>
			<StyledSpan>{`User: ${device.username}`}</StyledSpan>
		</StyledDeviceOption>
	);
}
