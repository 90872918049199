// Third-party
import styled from 'styled-components';
import { Buffer } from 'buffer';
import { useState, useRef } from 'react';

// Assets
// import logo from './HyperBeardGames_flatLogo.png';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Libs
import { useOutsideClickHandler, useCustomNavigate } from '@/libs/hooks.lib';

// Styles
import {
	StyledRoundImageWrapper,
	StyledRoundImagePlaceholder,
	StyledRoundImage,
	FlexRowAlignCenter,
	FlexCol,
	StyledHRFullWidth,
	StyledButtonNoStyle,
} from '@/styles/shared.styles';

// Requests
import { getById } from '@/libs/requests.lib';

const StyledHeader = styled.div`
	grid-area: header;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 94px;
	position: relative;

	::after {
		content: '';
		position: absolute;
		bottom: -13px;
		left: 0;
		width: 100%;
		height: 10px;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent);
	}
`;

const StyledLogo = styled.img`
	margin-left: 53px;
	margin-top: 19px;
	margin-bottom: 28px;
	width: 169px;
	height: 60px;
`;

const StyledUserMenu = styled.div`
	position: absolute;
	top: 100%; // position it right below the button
	right: 0; // align it to the right edge of the button
	width: 150px;
	background: #fff;
	border: 1px solid #ccc;
	z-index: 10;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	padding: 10px;
`;

const StyledMenuOption = styled.div`
	cursor: pointer;
	width: 100%;
	padding: 5px 0px;
	font-weight: 600;

	&:hover {
		background-color: ${(props) => props.theme.colors.purples.light};
	}
`;

export default function Header({}) {
	const { navigateToRoot, navigateTo, navigate, navigateToApp } = useCustomNavigate();
	const { userAuthState } = useContext(UserAuthContext);
	const [imageSrc, setImageSrc] = useState('');
	if (userAuthState !== null && userAuthState.user.avatar !== '' && userAuthState.user.avatar != undefined) {
		const base64 = Buffer.from(userAuthState.user.avatar).toString('base64');
		const imageSrcData = `data:image/png;base64,${base64}`;
		if (imageSrc === '' || imageSrcData !== imageSrc) {
			setImageSrc(imageSrcData);
		}
	}

	const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

	const wrapperRef = useRef(null);
	useOutsideClickHandler({ ref: wrapperRef, onClickOutside: () => setIsUserMenuVisible(false) });

	const logout = async () => {
		try {
			localStorage.removeItem('userAuthState');
			localStorage.removeItem('cheatDefinitionsState');
			localStorage.removeItem('currentMenuState');
			localStorage.removeItem('customNavigateState');
			
			await getById({
				endpoint: 'authentication/logout',
				id: userAuthState.user.id,
				navigate,
			});

			navigateToRoot();
		} catch (error) {
			alert(error);
		}
	};

	return (
		<StyledHeader>
			<StyledButtonNoStyle
				onClick={() => {
					navigateToApp();
				}}>
				<StyledLogo
					src={'/HyperBeardGames_flatLogo.png'}
					alt='Hyperbeard Logo'
				/>
			</StyledButtonNoStyle>
			<FlexRowAlignCenter
				style={{
					marginRight: '61px',
					justifyContent: 'space-between',
					position: 'relative',
				}}>
				{userAuthState && userAuthState.user.permissions.profile && userAuthState.user.permissions.profile.read && (
					<FlexCol
						ref={wrapperRef}
						style={{
							position: 'relative',
						}}>
						<StyledRoundImageWrapper
							width='60px'
							height='60px'
							style={{
								marginLeft: '28px',
								cursor: 'pointer',
								padding: '0px',
							}}
							onClick={() => {
								setIsUserMenuVisible(!isUserMenuVisible);
							}}>
							{imageSrc === '' && <StyledRoundImagePlaceholder />}
							{imageSrc !== '' && (
								<StyledRoundImage
									src={imageSrc}
									alt='User Avatar'
								/>
							)}
						</StyledRoundImageWrapper>
						{isUserMenuVisible && (
							<StyledUserMenu>
								<FlexCol
									style={{
										gap: '10px',
										width: '100%',
										alignItems: 'stretch',
										justifyContent: 'stretch',
									}}>
									<StyledButtonNoStyle
										style={{
											width: '100%',
										}}
										onClick={() => {
											navigateTo({
												mainPath: 'users',
												subPath: 'profile',
											});
											setIsUserMenuVisible(false);
										}}>
										<StyledMenuOption>Profile</StyledMenuOption>
									</StyledButtonNoStyle>
									<StyledHRFullWidth />
									<StyledButtonNoStyle
										style={{
											width: '100%',
										}}
										onClick={logout}>
										<StyledMenuOption>Logout</StyledMenuOption>
									</StyledButtonNoStyle>
								</FlexCol>
							</StyledUserMenu>
						)}
					</FlexCol>
				)}
			</FlexRowAlignCenter>
		</StyledHeader>
	);
}
