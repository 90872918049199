import { useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import { FlexCol, FlexRowAlignCenter, StyledHRFullWidth, StyledSpan, StyledCheckbox, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Components
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonCancel, ButtonSave, ButtonAccept } from '@/components/buttons.component';
import InputText from '@/components/input-text.component';
import InputNumber from '@/components/input-number.component';
import InputCheckbox from '@/components/input-checkbox.component';
import InputDateTime from '@/components/input-date-time.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import InputErrorMessage from '@/components/input-error-message.component';
import PopupError from '@/components/popup-error.component';

// Views
import CheatKeysOverrideParamsView from '@/views/cheat-keys-override-params.view';

import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { useContext } from 'react';

import {
    examplesByDataType
} from "@/libs/cheat-definitions.lib";

const StyledFullWidthRow = styled(FlexRowAlignCenter)`
	gap: 25px;
	width: 100%;
	justify-content: space-between;
`;

export default function CheatKeysEditView({ data, cheatArguments, existingCheatKeys, onSubmit = (newData) => {}, onClose = () => {}, editCheatKeyType = "ADD" }) {

	const {
        cheatDefinitionsState,
    } = useContext(CheatDefinitionsContext);
	const [viewState, setViewState] = useState('EDIT'); // 'EDIT' or 'OVERRIDE_PARAMS'
	const [isMaxUsageAmountInfinite, setIsMaxUsageAmountInfinite] = useState(false);
	const [isMaxUsesPerUserInfinite, setIsMaxUsesPerUserInfinite] = useState(false);
	const [isExpireDateInfinite, setIsExpireDateInfinite] = useState(false);
	const [infiniteHasChanged, setInfiniteHasChanged] = useState(false);
	const [keyNameOnLoad] = useState(R.prop('key', data));
	
	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState(false);

	const {
		handleSubmit,
		formState: { errors, isDirty },
		control,
		setValue,
		getValues,
		watch,
	} = useForm({
		defaultValues: data,
	});

	const { fields: fieldsMethodParams, append: appendMethodParam, remove: removeMethodParam, update: updateMethodParam } = useFieldArray({
        control,
        name: 'overrideMethodParams',
    });

	const { fields: fieldsVariables, append: appendVariable, remove: removeVariable, update: updateVariable } = useFieldArray({
		control,
		name: 'overrideVariables',
	});

	const { fields: fieldsMethods, append: appendMethod, remove: removeMethod, update: updateMethod } = useFieldArray({
		control,
		name: 'overrideMethods',
	});

	const overridenParams = (fieldsMethodParams !== null || fieldsMethodParams !== undefined ? fieldsMethodParams.length : 0) + (fieldsVariables !== null || fieldsVariables !== undefined ? fieldsVariables.length : 0);

	const onPreSubmit = (newData) => {
		const maxUsageAmount = newData.maxUsageAmount;
		const maxUsesPerUser = newData.maxUsesPerUser;

		if (Number(maxUsesPerUser) > Number(maxUsageAmount)) {
			setPopupErrorMessage('"Max Uses Per User" cannot be greater than "Max Usage Amount"');
			setShowErrorPopup(true);
			return;
		}

		let finalData = newData;
		if (editCheatKeyType == "ADD") {
			finalData = R.assoc('currentUsageCount', 0, finalData);
		}
		
		onSubmit(finalData);
	};

	const key = watch('key');

	return (
		<>
			{viewState === 'EDIT' && <StyledPopupEditForm onSubmit={handleSubmit(onPreSubmit)}
				style={{
					width: '700px',
				}}
			>
				{showErrorPopup && (
					<PopupError
						error={popupErrorMessage}
						onClose={() => {
							setShowErrorPopup(false);
						}}
					/>
				)}
				<FlexCol
					style={{
						width: '100%',
						height: '100%',
					}}
				>
					<StyledPopupEditForm.Header>
						<RowCloseButton onClose={onClose} />
						<FlexCol
							style={{
								gap: '10px',
								marginTop: '20px',
							}}>
							<StyledSpanBold>{`ID: ${data.idCheatKey}`}</StyledSpanBold>
							<FlexRowAlignCenter>
								<StyledSpan
									style={{
										marginRight: '5px',
									}}>
									Code:
								</StyledSpan>
								<InputText
									accessor={'key'}
									control={control}
									validation={{
										required: true,
										validate: (value) => {
											if (/\s/.test(value)) return "Input can't contain spaces";
											if (keyNameOnLoad === value) return true;
											if (R.any(R.propEq('key', value), existingCheatKeys)) return "A key with the same name already exists"
											return true;
										},
									}}
								/>
								<RequiredAsterisk />
							</FlexRowAlignCenter>
							<InputErrorMessage
								formErrors={errors}
								accessor={'key'}
							/>
							<StyledSpan>	
								<StyledSpanBold>How to Redeem: </StyledSpanBold>
								<i>
									{key}
								</i>{" "}
								{cheatArguments && cheatArguments.length > 0 &&
                                    cheatArguments.filter(ca => {
										if (ca.idCheatDefinitionVariable !== undefined) {
											const overrideVariables = getValues('overrideVariables');

											if (overrideVariables && overrideVariables.length > 0) { 
												const found = overrideVariables.find(ov => Number(ov.idCheatDefinitionVariable) === Number(ca.idCheatDefinitionVariable));
												if (found) {
													return false;
												}
											}
											
											return true;
										}
										else if (ca.idCheatDefinitionMethodMethodParam !== undefined) {
											const overrideMethodParams = getValues('overrideMethodParams');

											if (overrideMethodParams && overrideMethodParams.length > 0) { 
												const found = overrideMethodParams.find(ov => Number(ov.idCheatDefinitionMethodMethodParam) === Number(ca.idCheatDefinitionMethodMethodParam));
												if (found) {
													return false;
												}
											}

											return true;
										}

										return false;
									}).map(
                                        (argument, index) => {
                                            return (
                                                <i key={index}>
                                                    {
                                                        examplesByDataType[
                                                            argument.dataType
                                                        ][
                                                            Math.floor(
                                                                Math.random() *
                                                                    examplesByDataType[
                                                                        argument
                                                                            .dataType
                                                                    ].length
                                                            )
                                                        ]
                                                    }{" "}
                                                </i>
                                            );
                                        }
                                    )}
							</StyledSpan>
						</FlexCol>
						<StyledHRFullWidth
							style={{
								marginTop: '20px',
								backgroundColor: theme.colors.grays.light,
							}}
						/>
					</StyledPopupEditForm.Header>
					<StyledPopupEditForm.Body>
						{cheatDefinitionsState.requestType !== 'POST' && (
							<FlexCol
								style={{
									gap: '10px',
									alignItems: 'center',
								}}
							>
								<StyledSpan>
									{`Overriden Parameters: ${overridenParams}`}
								</StyledSpan>
								<ButtonAccept 
									text="Disable And/Or Override Parameters"
									onClick={() => {
										setViewState('OVERRIDE_PARAMS');
									}}
									style={{
										width: '100%',
									}}
								/>
							</FlexCol>
						)}
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>TICKET ID</StyledSpan>
							<StyledFullWidthRow>
							<InputText
									accessor={'ticketId'}
									control={control}
								/>
							</StyledFullWidthRow>
						</StyledPopupEditForm.LabelValuePair>
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>MAX USAGE AMOUNT</StyledSpan>
							<StyledFullWidthRow>
								<InputNumber
									accessor={'maxUsageAmount'}
									control={control}
									validation={{ required: true }}
									editable={!isMaxUsageAmountInfinite}
									min={0}
									defaultValue={1}
								/>
								<IsInfiniteCheckbox 
									checked={isMaxUsageAmountInfinite}
									onClick={() => {
										setInfiniteHasChanged(true);
										if (isMaxUsageAmountInfinite) {
											setValue('maxUsageAmount', 1);
										}
										else {
											setValue('maxUsageAmount', 100000000);						
										}
										setIsMaxUsageAmountInfinite(!isMaxUsageAmountInfinite);
									}}		
									disabled={isMaxUsesPerUserInfinite}
								/>
							</StyledFullWidthRow>
						</StyledPopupEditForm.LabelValuePair>
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>MAX USES PER USER</StyledSpan>
							<StyledFullWidthRow>
								<InputNumber
									accessor={'maxUsesPerUser'}
									control={control}
									validation={{ required: true }}
									editable={!isMaxUsesPerUserInfinite}
									min={0}
									defaultValue={0}
								/>
								<IsInfiniteCheckbox 
									checked={isMaxUsesPerUserInfinite}
									onClick={() => {
										setInfiniteHasChanged(true);
										if (isMaxUsesPerUserInfinite) {
											setValue('maxUsesPerUser', 1);
										}
										else {
											setValue('maxUsesPerUser', 100000000);	
											
											setIsMaxUsageAmountInfinite(true);
											setValue('maxUsageAmount', 100000000);
										}
										setIsMaxUsesPerUserInfinite(!isMaxUsesPerUserInfinite);
									}}
								/>
							</StyledFullWidthRow>
						</StyledPopupEditForm.LabelValuePair>
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>PUBLIC</StyledSpan>
							<InputCheckbox
								accessor={'public'}
								control={control}
								editable={true}
								defaultValue={1}
							/>
						</StyledPopupEditForm.LabelValuePair>
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>ACTIVE</StyledSpan>
							<InputCheckbox
								accessor={'active'}
								control={control}
								editable={true}
								defaultValue={1}
							/>
						</StyledPopupEditForm.LabelValuePair>
						<StyledPopupEditForm.LabelValuePair>
							<StyledSpan>EXPIRE DATE</StyledSpan>
							<StyledFullWidthRow>
								<InputDateTime
									accessor={'expireDate'}
									control={control}
									validation={{ required: true }}
									defaultValue={new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000).toISOString()}
									editable={!isExpireDateInfinite}
								/>
								<IsInfiniteCheckbox
									checked={isExpireDateInfinite}
									onClick={() => {
										setInfiniteHasChanged(true);
										if (isExpireDateInfinite) {
											setValue('expireDate', new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000).toISOString());
										}
										else {
											setValue('expireDate', new Date(9000, 11, 31, 23, 59, 59).toISOString());
										}
										setIsExpireDateInfinite(!isExpireDateInfinite);
									}}
								/>
							</StyledFullWidthRow>
						</StyledPopupEditForm.LabelValuePair>
						<FlexRowAlignCenter
							style={{
								width: '100%',
								justifyContent: 'center',
								gap: '20px',
								marginBottom: '20px',
							}}>
							<ButtonCancel
								onClick={() => {
									onClose();
								}}
							/>
							<ButtonSave disabled={!isDirty && !infiniteHasChanged} />
						</FlexRowAlignCenter>
					</StyledPopupEditForm.Body>
				</FlexCol>
			</StyledPopupEditForm>}
			{viewState === 'OVERRIDE_PARAMS' && <CheatKeysOverrideParamsView
				control={control}
				width={'700px'}
				height={'820px'}
				onBackClick={() => {
					setViewState('EDIT');
				}}
				watch={watch}
				fieldsMethodParams={fieldsMethodParams}
				appendMethodParam={appendMethodParam}
				removeMethodParam={removeMethodParam}
				updateMethodParam={updateMethodParam}
				fieldsVariables={fieldsVariables}
				appendVariable={appendVariable}
				removeVariable={removeVariable}
				updateVariable={updateVariable}
				fieldsMethods={fieldsMethods}
				appendMethod={appendMethod}
				removeMethod={removeMethod}
				updateMethod={updateMethod}
				idCheatKey={data.idCheatKey}
				getValues={getValues}
				setValue={setValue}
			/>}
		</>
	);
}

const IsInfiniteCheckbox = ({onClick, checked, disabled}) => {
	return (
		<FlexRowAlignCenter
			style={{
				gap: '15px',
				width: '150px',
				justifyContent: 'flex-end',
			}}
		>
			<StyledCheckbox
				type="checkbox"
				onChange={onClick}
				checked={checked}
				disabled={disabled}
			/>
			<StyledSpan>Is Infinite</StyledSpan>
		</FlexRowAlignCenter>
	);
}