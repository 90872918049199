import arrowBack from './arrow_back.svg';
import arrowBackD from './arrow_back';
import arrowDown from './arrow_down.svg';
import arrowDownD from './arrow_down';
import arrowDropDown from './arrow_drop_down.svg';
import arrowDropDownD from './arrow_drop_down';
import arrowDropDownPngBlack from './arrow_drop_down_black.png';
import arrowDropDownPngGray from './arrow_drop_down_gray.png';
import arrowDropDownPngRed from './arrow_drop_down_red.png';
import arrowDropUp from './arrow_drop_up.svg';
import arrowDropUpD from './arrow_drop_up';
import arrowDropUpPngBlack from './arrow_drop_up_black.png';
import arrowDropUpPngGray from './arrow_drop_up_gray.png';
import arrowDropUpPngRed from './arrow_drop_up_red.png';
import arrowForward from './arrow_forward.svg';
import arrowForwardD from './arrow_forward';
import arrowFirstPage from './first_page.svg';
import arrowFirstPageD from './first_page';
import arrowLastPage from './last_page.svg';
import arrowLastPageD from './last_page';
import arrowBackLong from './arrow_back_long.svg';
import arrowBackLongD from './arrow_back_long';
import arrowBackLongBold from './arrow_back_long_bold.svg';
import arrowBackLongBoldD from './arrow_back_long_bold';
import arrowForwardLong from './arrow_forward_long.svg';
import arrowForwardLongD from './arrow_forward_long';

const arrows = {
	arrowBack,
	arrowBackD,
	arrowDown,
	arrowDownD,
	arrowDropDown,
	arrowDropDownD,
	arrowDropDownPngBlack,
	arrowDropDownPngGray,
	arrowDropDownPngRed,
	arrowDropUp,
	arrowDropUpD,
	arrowDropUpPngBlack,
	arrowDropUpPngGray,
	arrowDropUpPngRed,
	arrowForward,
	arrowForwardD,
	arrowFirstPage,
	arrowFirstPageD,
	arrowLastPage,
	arrowLastPageD,
	arrowBackLong,
	arrowBackLongD,
	arrowBackLongBold,
	arrowBackLongBoldD,
	arrowForwardLong,
	arrowForwardLongD,
};

export default arrows;
