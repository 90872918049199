import styled from 'styled-components';

import THead from './THead';
import TBody from './TBody';
import Tr from './Tr';
import Th from './Th';
import Td from './Td';

const Table = styled.table`
	width: 100%;
	max-height: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	overflow-y: auto;

	th {
		padding: 10px 10px 10px 20px;
	}

	td {
		padding: 10px 10px 10px 20px;
	}

	tr:not(:last-child) {
		border-bottom: 1px solid ${(props) => props.theme.colors.grays.light};
`;

Table.THead = THead;
Table.TBody = TBody;
Table.Tr = Tr;
Table.Th = Th;
Table.Td = Td;

export default Table;
