// Third-party
import { useState } from "react";
import * as R from "ramda";

// Components
import TableViewGenericProps from "@/components/table-view-generic-props.component";
import PopupGeneric from "@/components/popup-generic.component";
import TableActions from "@/components/table-actions.component";
import GenericMiniCard from "@/components/generic-mini-card.component";
import { ThDefault, ColGroups } from "@/components/table.component";

// Views
import GamesEditView from "@/views/games-edit.view";

// Libs
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";
import { useCustomNavigate } from "@/libs/hooks.lib";

// Styles
import Table from "@/styles/table.styles";
import {
  StyledContent,
  StyledSpan,
  FlexCol,
  StyledSpanBold,
  StyledButtonGeneric,
} from "@/styles/shared.styles";
import theme from "@/styles/theme.styles";

// State
import { CurrentMenuContext } from "../contexts/current-menu.context";
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from "react";

export default function GamesTableView() {
  const { userAuthState } = useContext(UserAuthContext);
  const { currentMenuState, setCurrentMenuState } =
    useContext(CurrentMenuContext);
  const { navigateTo } = useCustomNavigate();

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editRequestType, setEditRequestType] = useState("PATCH");
  const [selectedRowIdToEdit, setSelectedRowId] = useState(null);
  const [tableViewGenericPropsRandomKey, setTableViewGenericPropsRandomKey] =
    useState(Math.random());

  const editRow = (rowId) => {
    setEditRequestType("PATCH");
    setSelectedRowId(rowId);
    setShowEditPopup(true);
  };

  return (
    <StyledContent>
      <TableViewGenericProps
        menuName="games"
        key={tableViewGenericPropsRandomKey}
        buttonAddText="New Game"
        onAddClick={() => {
          setEditRequestType("POST");
          setSelectedRowId(null);
          setShowEditPopup(true);
        }}
        onPopupSuccessClose={() => {
          setShowEditPopup(false);
          setTableViewGenericPropsRandomKey(Math.random());
        }}
        preFilteringFunction={(data) => {
            if (userAuthState.user.permissions.games.read === true) {
                return data;
            }
            
            const groupsInUser = userAuthState.user.groups;
            
            const filteredData = R.filter(
                R.pipe(
                    R.prop('groups'),
                    R.intersection(groupsInUser),
                    R.isEmpty,
                    R.not
                )
            )(data);
                
            return filteredData;
        }}
      >
        {({
          getTableProps,
          headerGroups,
          getTableBodyProps,
          rows,
          prepareRow,
          deleteRow,
          setPopupErrorMessage,
          setShowErrorPopup,
          setPopupSuccessMessage,
          setShowSuccessPopup,
        }) => {
          const headerGroup = headerGroups[0];
          const columnName = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Name"
          );
          return (
            <>
              {showEditPopup && (
                <PopupGeneric onClickOutside={() => {}}>
                  <GamesEditView
                    requestType={editRequestType}
                    currentlySelectedId={selectedRowIdToEdit}
                    onClose={() => {
                      setShowEditPopup(false);
                    }}
                    onSubmitSuccess={() => {
                      setPopupSuccessMessage("Game successfully edited.");
                      setShowSuccessPopup(true);
                    }}
                    onSubmitError={(errorMessage) => {
                      setPopupErrorMessage(errorMessage);
                      setShowErrorPopup(true);
                    }}
                  />
                </PopupGeneric>
              )}
              <Table {...getTableProps()}>
                <ColGroups widths={["40%", "40%", "10%", "10%"]} />
                <Table.THead>
                  <Table.Tr {...headerGroup.getHeaderGroupProps()}>
                    <ThDefault
                      column={columnName}
                      text={columnName.render("Header")}
                    />
                    <Table.Th>
                      <StyledSpan>Bundle Names</StyledSpan>
                    </Table.Th>
                    <Table.Th></Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.THead>
                <Table.TBody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <Table.Tr {...row.getRowProps()}>
                        <Table.Td>
                          <GenericMiniCard
                            image={row.values.image}
                            topText={row.values.name}
                            bottomText={null}
                          />
                        </Table.Td>
                        <Table.Td>
                          <FlexCol>
                            <StyledSpan>
                              <StyledSpanBold>{`iOS: `}</StyledSpanBold>
                              {`${row.values.iosBundleName}`}
                            </StyledSpan>
                            <StyledSpan>
                              <StyledSpanBold>{`Android: `}</StyledSpanBold>
                              {`${row.values.androidBundleName}`}
                            </StyledSpan>
                          </FlexCol>
                        </Table.Td>
                        <Table.Td>
                          {userAuthState.user.permissions.games.curate === true && (
                            <StyledButtonGeneric
                              onClick={() => {
                                setCurrentMenuState({
                                  ...currentMenuState,
                                  games: {
                                    ...currentMenuState.games,
                                    currentlySelectedId: row.values.idGame,
                                  },
                                });

                                navigateTo({
                                  mainPath: "games",
                                  subPath: "curate",
                                });
                              }}
                              backgroundColor={theme.colors.blues.midPastel}
                              hoverBackgroundColor={theme.colors.blues.midStrong}
                            >
                              <StyledSpan>Curate</StyledSpan>
                            </StyledButtonGeneric>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {userAuthState.user.permissions.games.update !==
                            false && (
                            <TableActions
                              onEditRow={() => {
                                editRow(row.values.idGame);
                              }}
                              onDeleteRow={() => {
                                deleteRow(row.values.idGame);
                              }}
                            />
                          )}
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.TBody>
              </Table>
            </>
          );
        }}
      </TableViewGenericProps>
    </StyledContent>
  );
}
