// Third-Party
import { useState } from 'react';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputTextArea from '@/components/input-text-area.component';
import InputCheckbox from '@/components/input-checkbox.component';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonEditInfo, CancelSaveRow } from '@/components/buttons.component';
import TitleAndText from '@/components/title-and-text.component';
import LoadingIcon from '@/components/loading-icon.component';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import { FlexCol, FlexRowAlignCenter, StyledHRFullWidth } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Hooks
import { useEditViewGenericProps } from '@/libs/hooks.lib';

export default function DevicesEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = null,
	onSubmitError = null,
}) {
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const { handleSubmit, isDirty, control, getValues, onSubmit, getData } = useEditViewGenericProps({
		tableName: 'devices',
		requestType,
		contentType: 'application/json',
		currentlySelectedId,
		onStartRequest: () => {
			setLoading(true);
		},
		onFinishRequest: () => {
			setLoading(false);
		},
		onSubmitSuccess,
		onSubmitError,
	});

	return (
		<StyledPopupEditForm onSubmit={handleSubmit(onSubmit)}>
			<FlexCol>
				{loading && (
					<LoadingIcon
						style={{
							alignSelf: 'center',
							margin: '200px 0',
						}}
					/>
				)}
				{!loading && (
					<>
						<StyledPopupEditForm.Header backgroundThreshold={25}>
							<RowCloseButton onClose={onClose} />
							<FlexRowAlignCenter
								style={{
									marginTop: '15px',
								}}>
								<TitleAndText
									title={`UID: ${getValues('deviceUniqueIdentifier')}`}
									text={getValues('email')}
									titleFontSize={'1.3rem'}
									textFontSize={'1rem'}
									gap={'10px'}
								/>
							</FlexRowAlignCenter>
							<StyledHRFullWidth
								style={{
									marginTop: '20px',
									backgroundColor: theme.colors.grays.light,
								}}
							/>
						</StyledPopupEditForm.Header>
						<StyledPopupEditForm.Body>
							<StyledPopupEditForm.LabelValuePair>
								<span>DESCRIPTION</span>
								{!isEditing && <span>{getValues('deviceDescription') || 'N/A'}</span>}
								{isEditing && (
									<InputTextArea
										accessor={'deviceDescription'}
										control={control}
										placeholder={'Enter a description'}
										style={{
											width: '100%',
										}}
									/>
								)}
							</StyledPopupEditForm.LabelValuePair>
							<StyledPopupEditForm.LabelValuePair>
								<span>ACTIVE</span>
								<InputCheckbox
									accessor={'active'}
									control={control}
									editable={isEditing}
									defaultValue={1}
								/>
							</StyledPopupEditForm.LabelValuePair>
							<StyledPopupEditForm.LabelValuePair>
								<span>AUTO ACTIVE</span>
								<InputCheckbox
									accessor={'autoActive'}
									control={control}
									editable={isEditing}
									defaultValue={1}
								/>
							</StyledPopupEditForm.LabelValuePair>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									gap: '15px',
								}}>
								{!isEditing && (
									<>
										<div></div>
										<ButtonEditInfo
											onClick={() => {
												setIsEditing(true);
											}}
										/>
									</>
								)}
								{isEditing && (
									<>
										<div></div>
										<CancelSaveRow
											onCancel={() => {
												setIsEditing(false);
												getData();
											}}
											saveDisabled={!isDirty}
										/>
									</>
								)}
							</FlexRow>
						</StyledPopupEditForm.Body>
					</>
				)}
			</FlexCol>
		</StyledPopupEditForm>
	);
}
