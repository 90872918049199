// Styles
import { FlexRow, StyledButtonNoStyle } from '@/styles/shared.styles';

// Components
import SVG from '@/components/svg.component';

// Icons
import { actions } from '@/assets/icons';

export default function TableActions({ onEditRow = () => {}, onDeleteRow = () => {} }) {
	return (
		<FlexRow
			style={{
				justifyContent: 'flex-end',
			}}>
			<StyledButtonNoStyle
				onClick={() => {
					onEditRow();
				}}
				style={{
					marginRight: '15px',
				}}>
				<SVG
					d={actions.editFillD}
					height='20'
					width='20'
					fill='#0D99FF'
				/>
			</StyledButtonNoStyle>
			<StyledButtonNoStyle
				onClick={() => {
					onDeleteRow();
				}}>
				<SVG
					d={actions.deleteFillD}
					height='20'
					width='20'
					fill='#FF640D'
				/>
			</StyledButtonNoStyle>
		</FlexRow>
	);
}
