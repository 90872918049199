import { FlexCol } from '@/styles/shared.styles';
import styled from 'styled-components';

const StyleErrorMessage = styled.div`
	color: red;
	font-size: 12px;
`;

export default function InputErrorMessage({ formErrors, accessor, className = '', style = {} }) {
	return (
		<FlexCol
			className={className}
			style={style}>
			{formErrors[accessor] && formErrors[accessor].type === 'required' && (
				<StyleErrorMessage>This field is required</StyleErrorMessage>
			)}
			{formErrors[accessor] && formErrors[accessor].type === 'validate' && (
				<StyleErrorMessage>{formErrors[accessor].message}</StyleErrorMessage>
			)}
			{formErrors[accessor] && formErrors[accessor].type === 'maxLength' && (
				<StyleErrorMessage>{`Max length (${formErrors[accessor].message}) exceeded`}</StyleErrorMessage>
			)}
			{formErrors[accessor] && formErrors[accessor].type === 'minLength' && (
				<StyleErrorMessage>{`Min length (${formErrors[accessor].message}) not reached`}</StyleErrorMessage>
			)}
			{formErrors[accessor] && formErrors[accessor].type === 'pattern' && (
				<StyleErrorMessage>{'Invalid format'}</StyleErrorMessage>
			)}
			{formErrors[accessor] && formErrors[accessor].type === 'manual' && (
				<StyleErrorMessage>{formErrors[accessor].message}</StyleErrorMessage>
			)}
			{((formErrors[accessor] && formErrors[accessor].type === 'min') ||
				(formErrors[accessor] && formErrors[accessor].type === 'max')) && (
				<StyleErrorMessage>{formErrors[accessor].message}</StyleErrorMessage>
			)}
		</FlexCol>
	);
}
