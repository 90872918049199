// Third-party
import { useEffect } from 'react';

// Requests
import { get } from '@/libs/requests.lib';

// Libs
import { useCustomNavigate } from '@/libs/hooks.lib';

export default function CheckGUIDView() {
	const { navigate, navigateToAuth } = useCustomNavigate();

	useEffect(() => {
		async function checkGUID() {
			try {
				await get({
					endpoint: 'authentication/checkGUID',
					navigate,
				});

				navigateToAuth({
					subPath: 'login',
				});
			} catch (error) {
				if (error == 'NoGUIDExists') {
					await get({
						endpoint: 'authentication/getGUID',
						navigate,
					});

					navigateToAuth({
						subPath: 'login',
					});
				} else {
					alert(error);
				}
			}
		}

		checkGUID();
	}, []);

	return <></>;
}
