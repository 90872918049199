// Third-party
import styled from 'styled-components';
import { useRef } from 'react';

// Hooks
import { useOutsideClickHandler } from '@/libs/hooks.lib';

const StyledPopupMousePos = styled.div`
	position: absolute;
	top: ${(props) => props.y}px;
	left: ${(props) => props.x}px;
	z-index: 100;
`;

export default function PopupMousePos({ x, y, children, onClickOutside = () => {} }) {
	const ref = useRef(null);
	useOutsideClickHandler({ ref, onClickOutside });

	return (
		<StyledPopupMousePos
			ref={ref}
			x={x}
			y={y}>
			{children}
		</StyledPopupMousePos>
	);
}
