import React, { useState } from 'react';
import styled from 'styled-components';

// Libs
import { AppropriateInputForDataType } from '@/libs/inputs.lib';
import {
	getUpdatedArguments,
	getUpdatedVariableFields,
	getUpdatedMethodFields,
	extractArgumentNumber,
	getHighestArgumentNumber,
} from '@/libs/cheat-definitions.lib';

// Styles
import { StyledSpan } from '@/styles/shared.styles';
import { StyledCol } from '@/styles/styled-cheat-definitions-definition-editor.styles';

// Components
import { ButtonRemoveCircle } from '@/components/buttons.component';

const StyledParameterEditorTable = styled.div`
	display: grid;
	grid-template-columns: 1fr 1.5fr 2.5fr 1fr;
	width: 100%;
`;

export default function CheatDefinitionsMethodRow({
	method,
	index,
	control,
	setValue,
	argumentsValues,
	setArgumentsValues,
	methodsFields,
	methodsUpdate,
	methodsReplace,
	methodsRemove,
	variablesFields,
	variablesReplace,
}) {
	return (
		<React.Fragment>
			<StyledCol
				style={{
					gridColumn: '1 / 2',
				}}>
				<StyledSpan>{method.descriptionName}</StyledSpan>
			</StyledCol>

			<StyledParameterEditorTable
				style={{
					gridColumn: '2 / 6',
				}}>
				{method.params === null && <CheatDefinitionsParameterRow isDummy={true} />}
				{method.params !== null &&
					method.params.map((param, paramIndex) => {
						return (
							<CheatDefinitionsParameterRow
								key={param.idMethodParam}
								methodIndex={index}
								param={param}
								paramIndex={paramIndex}
								control={control}
								setValue={setValue}
								onArgumentAdded={() => {
									let highestArgument = getHighestArgumentNumber(argumentsValues);

									const argumentValue = `%%${highestArgument + 1}%%`;

									const newArgument = {
										randomizedId: method.randomizedId,
										idMethodDescription: method.idMethodDescription,
										idMethodParam: param.idMethodParam,
										number: highestArgument + 1,
										value: argumentValue,
										dataType: param.dataType,
									};

									setArgumentsValues([...argumentsValues, newArgument]);

									// Build the updated method with the updated value for the appropiate param
									const updatedMethod = {
										...method,
										params: method.params.map((param, index) => {
											if (index === paramIndex) {
												return {
													...param,
													value: argumentValue,
												};
											}
											return param;
										}),
									};

									methodsUpdate(index, {
										...updatedMethod,
									});
								}}
								onArgumentRemoved={() => {
									const argumentNumber = extractArgumentNumber(param.value);
									
									let updatedArguments = getUpdatedArguments(argumentNumber, argumentsValues);
									setArgumentsValues(updatedArguments);

									let updatedVariableFields = getUpdatedVariableFields(
										variablesFields,
										updatedArguments
									);
									variablesReplace(updatedVariableFields);

									let updatedMethodFields = getUpdatedMethodFields(methodsFields, updatedArguments);
									methodsReplace(updatedMethodFields);
								}}
							/>
						);
					})}
			</StyledParameterEditorTable>

			<StyledCol
				style={{
					gridColumn: '6 / 7',
				}}>
				<ButtonRemoveCircle
					onClick={() => {
						methodsRemove(index);
					}}
				/>
			</StyledCol>
		</React.Fragment>
	);
}

function CheatDefinitionsParameterRow({
	methodIndex,
	param,
	paramIndex,
	control,
	setValue,
	onArgumentAdded,
	onArgumentRemoved,
	isDummy = false,
}) {
	const [checked, setChecked] = useState(isDummy ? false : param.isArgument);

	return (
		<React.Fragment>
			<StyledCol
				style={{
					gridColumn: '1 / 2',
				}}>
				<StyledSpan>{isDummy ? '-' : param.paramName}</StyledSpan>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '2 / 3',
				}}>
				<StyledSpan>{isDummy ? '-' : param.dataType}</StyledSpan>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '3 / 4',
				}}>
				{isDummy && <StyledSpan>-</StyledSpan>}
				{!isDummy && checked == 1 && <StyledSpan>{param.value}</StyledSpan>}
				{!isDummy &&
					checked == 0 &&
					AppropriateInputForDataType({
						dataType: param.dataType,
						accessor: `methods.${methodIndex}.params.${paramIndex}.value`,
						control,
						style: {
							width: '100%',
						},
					})}
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '4 / 5',
				}}>
				{isDummy && <StyledSpan>-</StyledSpan>}
				{!isDummy && (
					<input
						type='checkbox'
						checked={checked}
						onChange={(e) => {
							let formattedValue;
							if (e.target.checked == true) formattedValue = 1;
							else if (e.target.checked == false) formattedValue = 0;

							if (formattedValue === 0) {
								onArgumentRemoved();
							}

							if (formattedValue === 1) {
								onArgumentAdded();
							}

							setChecked(formattedValue);
							setValue(`methods.${methodIndex}.params.${paramIndex}.isArgument`, formattedValue);
						}}
					/>
				)}
			</StyledCol>
		</React.Fragment>
	);
}
