// Third-party
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

// Styles
import { StyledInputBase } from '@/styles/shared.styles';

const StyledInputText = styled(StyledInputBase)`
	height: 40px;
`;

export default function InputText({
	accessor,
	control,
	editable = true,
	onChanged = () => {},
	defaultValue = '',
	validation = {},
	placeholder = 'Enter a value',
	style = {},
	type = 'text',
}) {
	return (
		<Controller
			name={accessor}
			control={control}
			rules={validation}
			defaultValue={defaultValue}
			render={({ field: { onChange, value } }) => {
				return (
					<StyledInputText
						style={style}
						placeholder={placeholder}
						value={value || ''}
						onChange={(e) => {
							onChange(e.target.value);
							onChanged(e.target.value);
						}}
						type={type}
						readOnly={!editable}
					/>
				);
			}}
		/>
	);
}
