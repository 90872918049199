// Third-party
import { useContext, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Flipper, Flipped } from 'react-flip-toolkit';

// Assets
import { arrows, navigation } from '@/assets/icons';

// State
import { CurrentMenuContext } from '@/contexts/current-menu.context';
import { UserAuthContext } from '@/contexts/user-auth.context';

// Components
import { FlexRow } from '@/styles/shared.styles';
import SVG from '@/components/svg.component';

// Libs
import { useCustomNavigate } from '@/libs/hooks.lib';

const StyledNavigationBar = styled.div`
	grid-area: nav;

	background-color: ${(props) => props.theme.colors.base.white};
	height: 100%;
	width: 100%;
	min-width: 284px;
	padding: 58px 0px 0 25px;

	.flipper {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.change-opacity-enter {
		opacity: 0;
	}
	.change-opacity-enter-active {
		opacity: 1;
		transition: opacity 1000ms;
	}
	.change-opacity-exit {
		opacity: 1;
	}
	.change-opacity-exit-active {
		opacity: 0;
		transition: opacity 300ms;
	}
`;

const StyledLink = styled(FlexRow)`
	box-sizing: border-box;
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => (props.isSelected ? props.theme.colors.greens.mid : props.theme.colors.base.white)};
	width: 100%;
	height: 52px;
	border-radius: 22px;
	cursor: pointer;
	padding: 0 10px 0 20px;

	&:hover {
		background-color: ${(props) =>
			props.isSelected ? props.theme.colors.greens.mid : props.theme.colors.greens.light};
	}

	&:active {
		background-color: ${(props) => props.theme.colors.greens.mid};
	}

	span {
		color: #606959;
		font-size: 18px;
		text-decoration: none;
	}
`;

export default function NavigationBar() {
	const {
		cheatDefinitionsD,
		devicesD,
		gamesD,
		groupsD,
		macrosD,
		partialSnapshotsD,
		pushNotificationsD,
		snapshotsD,
		rolesD,
		usersD,
		logsD,
	} = navigation;
	const { currentMenuState, setCurrentMenuState } = useContext(CurrentMenuContext);
	const { userAuthState } = useContext(UserAuthContext);
	const { navigateTo } = useCustomNavigate();

	const [usersIsOpen, setUsersIsOpen] = useState(false);
	const [userDropDownTransition, setUserDropDownTransition] = useState(false);
	const usersNodeRef = useRef(null);

	const [snapshotsIsOpen, setSnapshotsIsOpen] = useState(false);
	const [snapshotsDropDownTransition, setSnapshotsDropDownTransition] = useState(false);
	const snapshotsNodeRef = useRef(null);

	const setContext = useCallback(({ menuName }) => {
		setCurrentMenuState({
			...currentMenuState,
			menuName,
		});
	});

	return (
		<StyledNavigationBar>
			<Flipper
				flipKey={currentMenuState.menuName + `${usersIsOpen}` + `${snapshotsIsOpen}`}
				className='flipper'>
				{userAuthState && userAuthState.user.permissions.users && userAuthState.user.permissions.users.read !== false && (
					<>
						<Flipped flipId='users'>
							<NavigationLink
								label='Users'
								icon={usersD}
								onClick={() => {
									setContext({
										menuName: 'users',
									});

									if (
										userAuthState.user.permissions.groups.read !== false ||
										userAuthState.user.permissions.roles.read !== false
									)
										setUserDropDownTransition(!userDropDownTransition);

									if (snapshotsDropDownTransition) {
										setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
									}

									navigateTo({
										mainPath: 'users',
										subPath: 'table',
									});
								}}
								isDropdown={
									userAuthState.user.permissions.groups.read !== false ||
									userAuthState.user.permissions.roles.read !== false
								}
								isOpen={usersIsOpen}
								isSelected={currentMenuState.menuName === 'users'}
								style={{
									marginBottom: usersIsOpen ? '10px' : '0px',
								}}
							/>
						</Flipped>
						<CSSTransition
							nodeRef={usersNodeRef}
							in={userDropDownTransition}
							timeout={300}
							classNames='change-opacity'
							onEnter={() => setUsersIsOpen(true)}
							onExited={() => setUsersIsOpen(false)}>
							<div
								ref={usersNodeRef}
								className='change-opacity'
								style={{
									width: '100%',
									paddingLeft: '20px',
									height: usersIsOpen ? 'auto' : '0px',
									marginBottom: usersIsOpen ? '10px' : '20px',
								}}>
								{usersIsOpen && (
									<>
										{userAuthState && userAuthState.user.permissions.groups.read !== false && (
											<NavigationLink
												label='Groups'
												icon={groupsD}
												onClick={() => {
													setContext({
														menuName: 'groups',
													});

													navigateTo({
														mainPath: 'groups',
														subPath: 'post',
													});
												}}
												isSelected={currentMenuState.menuName === 'groups'}
											/>
										)}
										{userAuthState && userAuthState.user.permissions.roles.read !== false && (
											<NavigationLink
												label='Roles'
												icon={rolesD}
												onClick={() => {
													setContext({
														menuName: 'roles',
													});

													navigateTo({
														mainPath: 'roles',
														subPath: 'table',
													});
												}}
												isSelected={currentMenuState.menuName === 'roles'}
											/>
										)}
										{userAuthState && userAuthState.user.permissions.logs.read !== false && (
											<NavigationLink
												label='Logs'
												icon={logsD}
												onClick={() => {
													setContext({
														menuName: 'logs',
													});

													navigateTo({
														mainPath: 'logs',
														subPath: 'table',
													});
												}}
												isSelected={currentMenuState.menuName === 'logs'}
											/>
										)}
									</>
								)}
							</div>
						</CSSTransition>
					</>
				)}
				{userAuthState && userAuthState.user.permissions.devices && userAuthState.user.permissions.devices.read !== false && (
					<Flipped flipId='devices'>
						<NavigationLink
							label='Devices'
							icon={devicesD}
							onClick={() => {
								setContext({
									menuName: 'devices',
								});

								if (userDropDownTransition) {
									setUserDropDownTransition(!userDropDownTransition);
								}

								if (snapshotsDropDownTransition) {
									setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
								}

								navigateTo({
									mainPath: 'devices',
									subPath: 'table',
								});
							}}
							isSelected={currentMenuState.menuName === 'devices'}
							style={{
								marginBottom: '20px',
							}}
						/>
					</Flipped>
				)}
				{userAuthState && userAuthState.user.permissions.games && userAuthState.user.permissions.games.read !== false && (
					<Flipped flipId='games'>
						<NavigationLink
							label='Games'
							icon={gamesD}
							onClick={() => {
								setContext({
									menuName: 'games',
								});

								if (userDropDownTransition) {
									setUserDropDownTransition(!userDropDownTransition);
								}

								if (snapshotsDropDownTransition) {
									setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
								}

								navigateTo({
									mainPath: 'games',
									subPath: 'table',
								});
							}}
							isSelected={currentMenuState.menuName === 'games'}
							style={{
								marginBottom: '20px',
							}}
						/>
					</Flipped>
				)}
				{userAuthState && userAuthState.user.permissions.cheatDefinitions &&
					userAuthState.user.permissions.cheatDefinitions.read !== false && (
						<Flipped flipId='cheatDefinitions'>
							<NavigationLink
								label='Code Batches'
								icon={cheatDefinitionsD}
								onClick={() => {
									setContext({
										menuName: 'cheatDefinitions',
									});

									if (userDropDownTransition) {
										setUserDropDownTransition(!userDropDownTransition);
									}

									if (snapshotsDropDownTransition) {
										setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
									}

									navigateTo({
										mainPath: 'cheatDefinitions',
										subPath: 'table',
									});
								}}
								isSelected={currentMenuState.menuName === 'cheatDefinitions'}
								style={{
									marginBottom: '20px',
								}}
							/>
						</Flipped>
					)}
				{userAuthState && userAuthState.user.permissions.snapshots &&
					userAuthState.user.permissions.snapshots.read !== false && (
						<Flipped flipId='snapshots'>
							<NavigationLink
								label='Snapshots'
								icon={snapshotsD}
								onClick={() => {
									setContext({
										menuName: 'snapshots',
									});

									if (userAuthState.user.permissions.partialSnapshots.read !== false)
										setSnapshotsDropDownTransition(!snapshotsDropDownTransition);

									if (userDropDownTransition) {
										setUserDropDownTransition(!userDropDownTransition);
									}

									navigateTo({
										mainPath: 'snapshots',
										subPath: 'table',
									});
								}}
								isDropdown={userAuthState.user.permissions.partialSnapshots.read !== false}
								isOpen={snapshotsIsOpen}
								isSelected={currentMenuState.menuName === 'snapshots'}
								style={{
									marginBottom: snapshotsIsOpen ? '10px' : '0px',
								}}
							/>
						</Flipped>
					)}
				{userAuthState && userAuthState.user.permissions.partialSnapshots &&
					userAuthState.user.permissions.partialSnapshots.read !== false && (
						<CSSTransition
							nodeRef={snapshotsNodeRef}
							in={snapshotsDropDownTransition}
							timeout={300}
							classNames='change-opacity'
							onEnter={() => setSnapshotsIsOpen(true)}
							onExited={() => setSnapshotsIsOpen(false)}>
							<div
								ref={snapshotsNodeRef}
								className='change-opacity'
								style={{
									width: '100%',
									paddingLeft: '20px',
									height: snapshotsIsOpen ? 'auto' : '0px',
									marginBottom: snapshotsIsOpen ? '10px' : '20px',
								}}>
								{snapshotsIsOpen && (
									<NavigationLink
										label='Partial Snapshots'
										icon={partialSnapshotsD}
										onClick={() => {
											setContext({
												menuName: 'partialSnapshots',
											});

											navigateTo({
												mainPath: 'partialSnapshots',
												subPath: 'table',
											});
										}}
										isSelected={currentMenuState.menuName === 'partialSnapshots'}
									/>
								)}
							</div>
						</CSSTransition>
					)}
				{userAuthState && userAuthState.user.permissions.macros && userAuthState.user.permissions.macros.read !== false && (
					<Flipped flipId='macros'>
						<NavigationLink
							label='Macros'
							icon={macrosD}
							onClick={() => {
								setContext({
									menuName: 'macros',
								});

								if (userDropDownTransition) {
									setUserDropDownTransition(!userDropDownTransition);
								}

								if (snapshotsDropDownTransition) {
									setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
								}

								navigateTo({
									mainPath: 'macros',
									subPath: 'table',
								});
							}}
							isSelected={currentMenuState.menuName === 'macros'}
							style={{
								marginBottom: '20px',
							}}
						/>
					</Flipped>
				)}
				{userAuthState && userAuthState.user.permissions.pushNotifications &&
					userAuthState.user.permissions.pushNotifications.create !== false && (
						<Flipped flipId='pushNotifications'>
							<NavigationLink
								label='Push Notifications'
								icon={pushNotificationsD}
								onClick={() => {
									setContext({
										menuName: 'pushNotifications',
									});

									if (userDropDownTransition) {
										setUserDropDownTransition(!userDropDownTransition);
									}

									if (snapshotsDropDownTransition) {
										setSnapshotsDropDownTransition(!snapshotsDropDownTransition);
									}

									navigateTo({
										mainPath: 'pushNotifications',
										subPath: 'post',
									});
								}}
								isSelected={currentMenuState.menuName === 'pushNotifications'}
							/>
						</Flipped>
					)}
			</Flipper>
		</StyledNavigationBar>
	);
}

function NavigationLink({
	icon,
	onClick,
	label,
	isSelected,
	isDropdown = false,
	isOpen = false,
	style = {},
	...flippedProps
}) {
	return (
		<div
			{...flippedProps}
			style={{
				width: '100%',
				...style,
			}}>
			<StyledLink
				isSelected={isSelected}
				onMouseDown={(e) => {
					e.preventDefault();
					e.stopPropagation();
					onClick();
				}}>
				<FlexRow
					style={{
						alignItems: 'center',
						gap: '11px',
					}}>
					<SVG d={icon} />
					<span>{label}</span>
				</FlexRow>
				{isDropdown && <SVG d={isOpen ? arrows.arrowDropUpD : arrows.arrowDropDownD} />}
			</StyledLink>
		</div>
	);
}
