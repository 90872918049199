// Third-party
import { useState, useRef } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import * as R from 'ramda';

// Components
import SVG from '@/components/svg.component';

// Assets
import { actions } from '@/assets/icons';

// Styles
import { StyledRoundImage, StyledRoundImagePlaceholder, StyledRoundImageWrapper } from '@/styles/shared.styles';

// Libs
import settings from '@/libs/settings.lib';

const StyledOuterWrapper = styled.div`
	position: relative;
	width: 102px; // Adjusted for border width
	height: 102px; // Adjusted for border width
`;

const StyledFileInput = styled.input`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')}};
	opacity: 0;
`;

const StyledCircleIconWrapper = styled.div`
	position: absolute;
	bottom: 0px; // Adjust these values to position the circle accordingly
	right: 0px;
	width: 40px; // Size of the circle
	height: 40px;
	border-radius: 50%; // Makes it a circle
	background-color: #fff; // or any other background color you want
	display: flex;
	justify-content: center; // These two lines center the icon
	align-items: center;
	z-index: 2; // Ensures it's above the image
	overflow: hidden;
	border: 1px solid ${(props) => props.theme.colors.grays.light};
	padding: 8px;
	cursor: pointer;

	&:hover {
		background-color: ${(props) => props.theme.colors.grays.mid};
	}
`;

export default function InputImage({
	accessor,
	control,
	getValues,
	disabled = false,
	style = {},
	onFileError = (error) => {},
}) {
	const [imageSrc, setImageSrc] = useState('');
	if (getValues(accessor) && R.prop('data', getValues(accessor)) && imageSrc === '') {
		const base64 = Buffer.from(getValues(accessor).data).toString('base64');
		setImageSrc(`data:image/png;base64,${base64}`);
	}

	const fileInputRef = useRef(null);

	const handleIconClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<Controller
			name={accessor}
			control={control}
			render={({ field: { onChange } }) => {
				return (
					<>
						<StyledOuterWrapper>
							<StyledRoundImageWrapper style={{ padding: '0px' }}>
								{imageSrc === '' && <StyledRoundImagePlaceholder />}
								{imageSrc !== '' && (
									<StyledRoundImage
										src={imageSrc}
										alt='User Avatar'
									/>
								)}
								<StyledFileInput
									ref={fileInputRef}
									disabled={disabled}
									type='file'
									accept='image/*'
									onChange={(e) => {
										const file = e.target.files[0];
										if (file.size > settings.images.maxImageSize.inBytes) {
											onFileError(
												`The file size is too large! Maximum file size is ${settings.images.maxImageSize.inString}.`
											);
											return;
										}
										if (file) {
											const reader = new FileReader();
											reader.onload = (e) => {
												setImageSrc(e.target.result);
											};
											reader.readAsDataURL(file);
											onChange(e.target.files[0]);
										}
									}}
								/>
							</StyledRoundImageWrapper>
							{!disabled && (
								<StyledCircleIconWrapper onClick={handleIconClick}>
									<SVG d={actions.photoCameraD} />
								</StyledCircleIconWrapper>
							)}
						</StyledOuterWrapper>
					</>
				);
			}}
		/>
	);
}
