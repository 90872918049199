export const variableOperationsOptions = [
	{ value: 1, label: 'Reset' },
	{ value: 2, label: 'Assignation' },
	{ value: 3, label: 'Negation' },
	{ value: 4, label: 'Addition' },
	{ value: 5, label: 'Subtraction' },
	{ value: 6, label: 'Multiplication' },
	{ value: 7, label: 'Division' },
	{ value: 8, label: 'Modulus' },
	{ value: 9, label: 'Exponentiation' },
	{ value: 10, label: 'Floor' },
];

export const variableOperationsAsVerbs = {
	1: 'Reset',
	2: 'Assign',
	3: 'Negate',
	4: 'Add',
	5: 'Subtract',
	6: 'Multiply',
	7: 'Divide',
	8: 'Modulus',
	9: 'Exponentiate',
	10: 'Floor',
};

export const variableOperationsPerDataType = {
	Int: [
		'Reset',
		'Assignation',
		'Negation',
		'Addition',
		'Subtraction',
		'Multiplication',
		'Division',
		'Modulus',
		'Exponentiation',
		'Floor',
	],
	Float: [
		'Reset',
		'Assignation',
		'Negation',
		'Addition',
		'Subtraction',
		'Multiplication',
		'Division',
		'Modulus',
		'Exponentiation',
		'Floor',
	],
	String: ['Reset', 'Assignation'],
	Bool: ['Reset', 'Assignation', 'Negation'],
	DateTime: ['Reset', 'Assignation'],
	Double: [
		'Reset',
		'Assignation',
		'Negation',
		'Addition',
		'Subtraction',
		'Multiplication',
		'Division',
		'Modulus',
		'Exponentiation',
		'Floor',
	],
	JSON: ['Reset', 'Assignation'],
};
