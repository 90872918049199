import styled from 'styled-components';
import { useState } from 'react';
import { Buffer } from 'buffer';

// Component
import TitleAndText from '@/components/title-and-text.component';
import ImageRounded from './image-rounded.component';

// Styles
import { FlexRowAlignCenter } from '@/styles/shared.styles';

const StyledGenericMiniCard = styled(FlexRowAlignCenter)`
	justify-content: flex-start;
	background-color: white;
	height: 100%;
	width: 100%;
	max-height: 100px;
	transition: all 0.2s ease-in-out;
	gap: 10px;
	border-radius: 5px;

	&:hover {
		background-color: ${(props) => (props.hoverable ? props.theme.colors.greens.mid : 'white')};
		cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
	}
`;

export default function GenericMiniCard({
	image,
	topText = null,
	topTextFontSize = '0.9rem',
	bottomText = null,
	bottomTextFontSize = '0.8rem',
	active = true,
	hoverable = false,
	imageSize = '50px',
}) {
	return (
		<StyledGenericMiniCard hoverable={hoverable}>
			<ImageRounded
				data={image != undefined ? image.data : null}
				active={active}
				imageSize={imageSize}
			/>
			<TitleAndText
				title={topText}
				text={bottomText}
				titleFontSize={topTextFontSize}
				textFontSize={bottomTextFontSize}
			/>
		</StyledGenericMiniCard>
	);
}
