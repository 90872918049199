import { useState } from 'react';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputImage from '@/components/input-image.component';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonEditInfo, CancelSaveRow } from '@/components/buttons.component';
import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import LoadingIcon from '@/components/loading-icon.component';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import { FlexCol, FlexRowAlignCenter, StyledHRFullWidth } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Hooks
import { useEditViewGenericProps } from '@/libs/hooks.lib';

export default function SnapshotsEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = null,
	onSubmitError = null,
}) {
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const { handleSubmit, isDirty, control, getValues, onSubmit, getData, errors } = useEditViewGenericProps({
		tableName: 'snapshots',
		requestType,
		contentType: 'multipart/form-data',
		currentlySelectedId,
		onStartRequest: () => {
			setLoading(true);
		},
		onFinishRequest: () => {
			setLoading(false);
		},
		onSubmitSuccess,
		onSubmitError,
	});

	return (
		<StyledPopupEditForm onSubmit={handleSubmit(onSubmit)}>
			<FlexCol>
				{loading && (
					<LoadingIcon
						style={{
							alignSelf: 'center',
							margin: '100px 0',
						}}
					/>
				)}
				{!loading && (
					<>
						<StyledPopupEditForm.Header>
							<RowCloseButton onClose={onClose} />
							<FlexRowAlignCenter
								style={{
									gap: '20px',
								}}>
								<InputImage
									accessor={'image'}
									control={control}
									getValues={getValues}
									disabled={!isEditing}
									onFileError={onSubmitError}
								/>
								<FlexCol
									style={{
										alignItems: 'flex-start',
										gap: '10px',
									}}>
									{!isEditing && (
										<div
											style={{
												fontSize: '1.3rem',
												fontWeight: 'bold',
											}}>
											{getValues('name')}
										</div>
									)}
									{isEditing && (
										<>
											<FlexRowAlignCenter>
												<InputText
													accessor={'name'}
													control={control}
													validation={{ required: true }}
												/>
												<RequiredAsterisk />
											</FlexRowAlignCenter>
											<InputErrorMessage
												formErrors={errors}
												accessor={'name'}
											/>
										</>
									)}
									<div>{`ID: ${getValues('idSnapshot')}`}</div>
								</FlexCol>
							</FlexRowAlignCenter>
							<StyledHRFullWidth
								style={{
									marginTop: '20px',
									backgroundColor: theme.colors.grays.light,
								}}
							/>
						</StyledPopupEditForm.Header>
						<StyledPopupEditForm.Body>
							<StyledPopupEditForm.LabelValuePair>
								<span>DESCRIPTION</span>
								{!isEditing && <span>{getValues('description')}</span>}
								{isEditing && (
									<InputTextArea
										accessor={'description'}
										control={control}
										validation={{ required: true }}
										style={{
											width: '100%',
										}}
									/>
								)}
							</StyledPopupEditForm.LabelValuePair>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									gap: '15px',
								}}>
								{!isEditing && (
									<>
										<div></div>
										<ButtonEditInfo
											onClick={() => {
												setIsEditing(true);
											}}
										/>
									</>
								)}
								{isEditing && (
									<>
										<div></div>
										<CancelSaveRow
											onCancel={() => {
												setIsEditing(false);
												getData();
											}}
											saveDisabled={!isDirty}
										/>
									</>
								)}
							</FlexRow>
						</StyledPopupEditForm.Body>
					</>
				)}
			</FlexCol>
		</StyledPopupEditForm>
	);
}
