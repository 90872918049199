import { Controller } from 'react-hook-form';

import { StyledCheckbox } from '@/styles/shared.styles';

export default function InputCheckbox({
	accessor,
	control,
	editable = true,
	defaultValue = 0,
	onChanged = (value) => {},
	style = {},
}) {
	return (
		<Controller
			name={accessor}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value } }) => {
				return (
					<StyledCheckbox
						style={style}
						checked={value}
						onChange={(e) => {
							let formattedValue;
							if (e.target.checked == true) formattedValue = 1;
							else if (e.target.checked == false) formattedValue = 0;
							else formattedValue = e.target.checked;

							onChange(formattedValue);
							onChanged(formattedValue);
						}}
						disabled={!editable}
						type='checkbox'
						readOnly={!editable}
					/>
				);
			}}
		/>
	);
}
