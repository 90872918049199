import React, { useState } from 'react';

// Libs
import { variableOperationsOptions, variableOperationsPerDataType } from '@/libs/variable-operations.lib';
import { AppropriateInputForDataType } from '@/libs/inputs.lib';
import {
	getUpdatedArguments,
	getUpdatedVariableFields,
	getUpdatedMethodFields,
	extractArgumentNumber,
	getHighestArgumentNumber,
} from '@/libs/cheat-definitions.lib';

// Styles
import { StyledSpan } from '@/styles/shared.styles';
import { StyledCol } from '@/styles/styled-cheat-definitions-definition-editor.styles';

// Components
import { ButtonRemoveCircle } from '@/components/buttons.component';
import InputSelect from '@/components/input-select.component';

export default function CheatDefinitionsVariableRow({
	variable,
	index,
	control,
	setValue,
	argumentsValues,
	setArgumentsValues,
	variablesFields,
	variablesUpdate,
	variablesReplace,
	variablesRemove,
	methodsFields,
	methodsReplace,
}) {
	const [checked, setChecked] = useState(variable.isArgument);
	const [variableOperation, setVariableOperation] = useState(variable.variableOperation);
	const [optionsPerDataType] = useState(variableOperationsOptions.filter((c) =>
	variableOperationsPerDataType[variable.dataType].includes(c.label)
));

	const onArgumentAdded = () => {
		let highestArgument = getHighestArgumentNumber(argumentsValues);

		const argumentValue = `%%${highestArgument + 1}%%`;

		const newArgument = {
			idVariableDescription: variable.idVariableDescription,
			number: highestArgument + 1,
			value: argumentValue,
			dataType: variable.dataType,
		};

		setArgumentsValues([...argumentsValues, newArgument]);

		variablesUpdate(index, {
			...variable,
			value: argumentValue,
		});
	};

	const onArgumentRemoved = () => {
		const argumentNumber = extractArgumentNumber(variable.value);

		let updatedArguments = getUpdatedArguments(argumentNumber, argumentsValues);
		setArgumentsValues(updatedArguments);

		let updatedVariableFields = getUpdatedVariableFields(variablesFields, updatedArguments);
		variablesReplace(updatedVariableFields);

		let updatedMethodFields = getUpdatedMethodFields(methodsFields, updatedArguments);
		methodsReplace(updatedMethodFields);
	};

	return (
		<React.Fragment>
			<StyledCol
				style={{
					gridColumn: '1 / 2',
				}}>
				<StyledSpan>{variable.descriptionName}</StyledSpan>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '2 / 3',
				}}>
				<StyledSpan>{variable.dataType}</StyledSpan>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '3 / 4',
				}}>
				<InputSelect
					options={optionsPerDataType}
					control={control}
					accessor={`variables.${index}.idVariableOperation`}
					editable={true}
					onChanged={(option) => {
						setValue(`variables.${index}.variableOperation`, option.label);
						setVariableOperation(optionsPerDataType.filter(c => c.value === option)[0].label);
					}}
				/>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '4 / 5',
				}}>
				{checked == 1 && <StyledSpan>{variable.value}</StyledSpan>}
				{checked == 0 &&
					AppropriateInputForDataType({
						dataType: variable.dataType,
						accessor: `variables.${index}.value`,
						control,
						style: {
							width: '100%',
						},
						editable: variableOperation !== 'Reset',
					})}
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '5 / 6',
				}}>
				<input
					type='checkbox'
					checked={checked}
					onChange={(e) => {
						let formattedValue;
						if (e.target.checked == true) formattedValue = 1;
						else if (e.target.checked == false) formattedValue = 0;

						if (formattedValue === 0) {
							onArgumentRemoved();
						}
						if (formattedValue === 1) {
							onArgumentAdded();
						}

						setChecked(formattedValue);
						setValue(`variables.${index}.isArgument`, formattedValue);
					}}
				/>
			</StyledCol>
			<StyledCol
				style={{
					gridColumn: '6 / 7',
				}}>
				<ButtonRemoveCircle
					onClick={() => {
						if (checked === 1) onArgumentRemoved();
						variablesRemove(index);
					}}
				/>
			</StyledCol>
		</React.Fragment>
	);
}
