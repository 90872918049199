import { createContext, useState, useEffect } from 'react';

export const CustomNavigateContext = createContext();

const initialCustomNavigateState = {
	mainPath: '',
	subPath: '',
};

export const CustomNavigateContextProvider = ({ children }) => {
	const [customNavigateState, setCustomNavigateState] = useState(JSON.parse(localStorage.getItem('customNavigateState')) || initialCustomNavigateState);
	
	useEffect(() => {
		localStorage.setItem('customNavigateState', JSON.stringify(customNavigateState));
	}, [customNavigateState]);

	return (
		<CustomNavigateContext.Provider value={{ customNavigateState, setCustomNavigateState }}>
			{children}
		</CustomNavigateContext.Provider>
	);
};
