import { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import GroupMiniCard from '@/components/group-mini-card.component';
import PopupGeneric from '@/components/popup-generic.component';
import SearchBar from '@/components/search-bar.component';
import CheatKeysTable from './cheat-keys-table.component';
import RowCloseButton from './row-close-button.component';

// Styles
import { FlexCol } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledPopupShowCheatKeys = styled(FlexCol)`
	width: 100%;
	height: 100%;
	padding: 10px 10px 10px 10px;
`;

export default function PopupShowCheatKeys({ data, onClose = () => {} }) {
	return (
		<PopupGeneric
			onClickOutside={onClose}
			style={{
				width: '70%',
				height: '70%',
			}}>
			<StyledPopupShowCheatKeys>
				<RowCloseButton
					onClose={onClose}
					style={{
						marginBottom: '10px',
					}}
					iconFill={theme.colors.base.black}
				/>
				<CheatKeysTable
					data={data}
					hasActions={false}
				/>
			</StyledPopupShowCheatKeys>
		</PopupGeneric>
	);
}
