const theme = {
    colors: {
        pills: {
            root: '#FF5E57',
            qaPrograLeader: '#FF9856',
            qaProgra: '#F0CE2A',
            socialMediaLeader: '#7F90FF',
            socialMedia: '#6DF4FF',
            newUser: '#7EFF89',
            general: '#EE84FF',
            playerSupportLeader: '#255B94',
            playerSupport: '#942569',
        },
        fonts: {
            green: '#606959',
        },
        base: {
            black: '#000000',
            white: '#FFFFFF',
        },
        grays: {
            mid: '#ACB1A9',
            dark: '#909090',
            light: '#e0e0e0',
        },
        greens: {
            light: '#EEF6F6',
            mid: '#D1EAEA',
            midStrong: '#48AA4C',
            midDark: '#4e4e4c',
            dark: '#3A4531',
        },
        reds: {
            midStrong: '#FF625A',
            dark: '#A33531',
        },
        blues: {
            midPastel: '#54B4D3',
            midStrong: '#0D99FF',
        },
        oranges: {
            light: '#fdcc8c',
            midStrong: '#FD9000',
        },
        purples: {
            light: '#D8D5F5',
        },
    },
};

export default theme;
