import styled from 'styled-components';
import { useState } from 'react';
import { Buffer } from 'buffer';

const StyledImageWrapper = styled.div`
	width: ${(props) => props.imageSize || '50px'};
	height: ${(props) => props.imageSize || '50px'};
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.base.white};
	overflow: hidden;
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	filter: ${(props) => (props.active ? 'none' : 'grayscale(100%)')};
`;

const StyledImagePlaceholder = styled.div`
	width: ${(props) => props.imageSize || '50px'};
	height: ${(props) => props.imageSize || '50px'};
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.greens.light};
`;

export default function ImageRounded({ data, active = true, imageSize }) {
	const [imageSrc, setImageSrc] = useState('');

	if (data != undefined) {
		const base64 = Buffer.from(data).toString('base64');
		if (imageSrc != `data:image/png;base64,${base64}`) {
			setImageSrc(`data:image/png;base64,${base64}`);
		}
	}

	return (
		<>
			{imageSrc != '' && (
				<StyledImageWrapper imageSize={imageSize}>
					<StyledImage
						active={active}
						src={imageSrc}
						alt='image'
					/>
				</StyledImageWrapper>
			)}
			{imageSrc === '' && <StyledImagePlaceholder imageSize={imageSize} />}
		</>
	);
}
