// Third-party
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import { useState } from 'react';

// Components
import InputText from '@/components/input-text.component';
import InputDateTime from '@/components/input-date-time.component';
import InputNumber from '@/components/input-number.component';
import InputCheckbox from '@/components/input-checkbox.component';
import InputErrorMessage from '@/components/input-error-message.component';
import { FlexCol } from '@/styles/shared.styles';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonSubmit } from '@/components/buttons.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import LoadingIcon from '@/components/loading-icon.component';

// Services
import { postApplicationJson } from '@/libs/requests.lib';

// State
import { useContext } from 'react';
import { UserAuthContext } from '@/contexts/user-auth.context';

// Styles
import StyledGridForm from '@/styles/styled-grid-form.styles';
import { StyledSpanBold, StyledSpan, FlexRowAlignCenter } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

export default function CheatKeysGeneratorView({
	idCheatDefinition,
	onCheatKeysGenerated = (data) => {},
	onClose = () => {},
	onError = (error) => {},
}) {
	const navigate = useNavigate();
	const { userAuthState } = useContext(UserAuthContext);

	const [loading, setLoading] = useState(false);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		setLoading(true);
		try {
			const generatedKeys = await postApplicationJson({
				endpoint: 'cheatKeys/generate',
				body: {
					prefix: data.prefix,
					amount: data.amount,
				},
				idUser: userAuthState.user.id,
				navigate,
			});

			const finalData = R.map((key) => {
				return {
					key,
					public: data.public,
					active: data.active,
					maxUsesPerUser: data.maxUsesPerUser,
					maxUsageAmount: data.maxUsageAmount,
					currentUsageCount: 0,
					expireDate: data.expireDate,
					idCheatDefinition: idCheatDefinition,
				};
			})(generatedKeys);

			onCheatKeysGenerated(finalData);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			onError(error);
		}
	};

	return (
		<FlexCol
			style={{
				padding: '20px',
				gap: '20px',
			}}>
			<RowCloseButton
				onClose={onClose}
				iconFill={theme.colors.base.black}
			/>
			<FlexRowAlignCenter
				style={{
					justifyContent: 'center',
				}}>
				<StyledSpanBold
					style={{
						fontSize: '20px',
					}}>
					Generate Keys
				</StyledSpanBold>
			</FlexRowAlignCenter>

			<StyledGridForm onSubmit={handleSubmit(onSubmit)}>
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Prefix</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputText
						accessor='prefix'
						editable={true}
						control={control}
						validation={{
							required: true,
							validate: (value) => {
								if (/\s/.test(value)) return "Input can't contain spaces";
								return true;
							},
						}}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.InputError
					style={{
						marginTop: '10px',
					}}>
					<InputErrorMessage
						formErrors={errors}
						accessor='prefix'
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Amount</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputNumber
						accessor='amount'
						editable={true}
						control={control}
						defaultValue={1}
						min={1}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Public</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.InputCheckbox>
					<InputCheckbox
						accessor='public'
						editable={true}
						control={control}
						defaultValue={1}
					/>
				</StyledGridForm.InputCheckbox>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Active</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.InputCheckbox>
					<InputCheckbox
						accessor='active'
						editable={true}
						control={control}
						defaultValue={1}
					/>
				</StyledGridForm.InputCheckbox>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Max Uses Per User</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputNumber
						accessor='maxUsesPerUser'
						editable={true}
						control={control}
						defaultValue={1}
						min={1}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Max Usage Amount</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputNumber
						accessor='maxUsageAmount'
						editable={true}
						control={control}
						defaultValue={1}
						min={1}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<StyledSpan>Expire Date</StyledSpan>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputDateTime
						accessor='expireDate'
						editable={true}
						control={control}
						defaultValue={new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000).toISOString()}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				{loading && (
					<FlexRowAlignCenter
						style={{
							marginTop: '20px',
							gridColumn: '1 / -1',
							justifyContent: 'center',
						}}>
						<LoadingIcon />
					</FlexRowAlignCenter>
				)}
				{!loading && (
					<FlexRowAlignCenter
						style={{
							marginTop: '20px',
							gridColumn: '1 / -1',
							justifyContent: 'center',
						}}>
						<ButtonSubmit text='Generate' />
					</FlexRowAlignCenter>
				)}
			</StyledGridForm>
		</FlexCol>
	);
}
