// Components
import PopupGeneric from '@/components/popup-generic.component';
import { ButtonAccept } from '@/components/buttons.component';
import styled from 'styled-components';
import * as R from 'ramda';

// Styles
import { FlexCol, StyledSpan, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledPopupError = styled(FlexCol)`
	width: 100%;
	height: 100%;
	gap: 10px;
	padding: 20px;
	align-items: center;
	border: 2px solid ${theme.colors.reds.midStrong};
	border-radius: 5px;
`;

export default function PopupError({ error, onClose }) {
	return (
		<PopupGeneric
			onClickOutside={onClose}
			zIndex={30}>
			<StyledPopupError>
				<StyledSpanBold
					style={{
						fontSize: '1.5rem',
					}}>
					{'Error'}
				</StyledSpanBold>
				<StyledSpan>{R.prop('message', error) ? String(error.message) : String(error)}</StyledSpan>
				<ButtonAccept
					style={{
						width: '100px',
						marginTop: '20px',
					}}
					onClick={onClose}
					text={'Ok'}
					backgroundColor={theme.colors.reds.midStrong}
				/>
			</StyledPopupError>
		</PopupGeneric>
	);
}
