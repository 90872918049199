import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Syles
import { FlexRowAlignCenter } from '@/styles/shared.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputCheckbox from '@/components/input-checkbox.component';
import { ButtonCancel, ButtonSave } from '@/components/buttons.component';
import PopupGeneric from '@/components/popup-generic.component';
import InputImage from '@/components/input-image.component';

// Libs
import { submitPatchById } from '@/libs/requests.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

export default function PopupCurate({
	id,
	defaultValues = {},
	onClose = () => {},
	onSuccess = () => {},
	onError = () => {},
	isVariable = false,
}) {
	const { userAuthState } = useContext(UserAuthContext);
	const navigate = useNavigate();

	const {
		handleSubmit,
		formState: { errors, isDirty },
		control,
		getValues,
	} = useForm({
		defaultValues,
	});

	const onSubmit = (data) => {
		submitPatchById({
			endpoint: isVariable ? 'variables' : 'methods',
			id,
			body: data,
			contentType: 'multipart/form-data',
			idUser: userAuthState.user.id,
			navigate,
		})
			.then(() => {
				onSuccess();
			})
			.catch((error) => {
				onError(error);
			});
	};

	return (
		<PopupGeneric>
			<StyledGridForm
				onSubmit={handleSubmit(onSubmit)}
				style={{
					width: '450px',
					padding: '20px',
				}}>
				<FlexRowAlignCenter
					style={{
						gridColumn: '1 / -1',
						justifyContent: 'center',
						fontSize: '1.5rem',
						fontWeight: 'bold',
						width: '100%',
					}}>
					{`Curate ${isVariable ? 'Variable' : 'Method'}`}
				</FlexRowAlignCenter>
				<StyledGridForm.Gap />
				<StyledGridForm.Gap />
				<StyledGridForm.Label>Icon</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputImage
						control={control}
						accessor='icon'
						getValues={getValues}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>Name</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputText
						control={control}
						accessor='name'
						validation={{ required: true }}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>Description</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputTextArea
						control={control}
						accessor='description'
						validation={{ required: true }}
						cols={null}
						style={{
							width: '100%',
						}}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>Curated</StyledGridForm.Label>
				<StyledGridForm.InputCheckbox>
					<InputCheckbox
						control={control}
						accessor='favorite'
						validation={{ required: true }}
					/>
				</StyledGridForm.InputCheckbox>
				<StyledGridForm.Gap />
				<FlexRowAlignCenter
					style={{
						gridColumn: '1 / -1',
						justifyContent: 'center',
						gap: '20px',
					}}>
					<ButtonCancel
						onClick={() => {
							onClose();
						}}
					/>
					<ButtonSave disabled={!isDirty} />
				</FlexRowAlignCenter>
			</StyledGridForm>
		</PopupGeneric>
	);
}
