import { Controller } from 'react-hook-form';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

export default function InputDateTime({
	accessor,
	control,
	editable = true,
	validation = {},
	style = {},
	defaultValue = new Date().toISOString(),
}) {
	return (
		<Controller
			name={accessor}
			control={control}
			defaultValue={defaultValue}
			rules={validation}
			render={({ field: { onChange, value } }) => {
				return (
					<>
						<DateTimePicker
							onChange={(value) => {
								const dateAsString = new Date(value).toISOString();
								onChange(dateAsString);
							}}
							style={style}
							value={value}
							is24Hour
							disabled={!editable}
						/>
					</>
				);
			}}
		/>
	);
}
