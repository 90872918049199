import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';

// Components
import PopupGeneric from '@/components/popup-generic.component';
import RowCloseButton from '@/components/row-close-button.component';
import SearchBar from '@/components/search-bar.component';
import SVG from '@/components/svg.component';

// Styles
import { FlexCol, FlexRowAlignCenter, StyledSpan, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Assets
import { alerts } from '@/assets/icons';

const StyledPopupSelectCheatDefinition = styled(FlexCol)`
	width: 100%;
	height: 200px;
	padding: 5px 10px 10px 10px;
`;

const StyledOption = styled(FlexRowAlignCenter)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	gap: 10px;

	&:hover {
		background-color: ${(props) => props.theme.colors.grays.mid};
	}
`;

export default function PopupSelectCheatDefinition({
	cheatDefinitions,
	onClose = () => {},
	onCheatDefinitionSelected = (cheatDefinition) => {},
}) {
	const [filteredCheatDefinitions, setFilteredCheatDefinitions] = useState(cheatDefinitions);

	useEffect(() => {
		setFilteredCheatDefinitions(cheatDefinitions);
	}, [cheatDefinitions]);

	return (
		<PopupGeneric onClickOutside={onClose}>
			<Tooltip
				id='cd-description-tooltip'
				place='right'
				effect='solid'
			/>
			<StyledPopupSelectCheatDefinition>
				<RowCloseButton
					onClose={onClose}
					iconFill={theme.colors.base.black}
				/>
				<SearchBar
					unfilteredValues={cheatDefinitions}
					setFilteredValues={setFilteredCheatDefinitions}
					keysToSearch={['name']}
				/>
				<FlexCol
					style={{
						overflowY: 'scroll',
						height: '100%',
						gap: '10px',
					}}>
					{filteredCheatDefinitions.map((cd) => (
						<StyledOption
							key={cd.idCheatDefinition}
							onClick={() => {
								onCheatDefinitionSelected(cd);
							}}>
							<StyledSpanBold
								style={{
									width: '25px',
								}}>
								{cd.idCheatDefinition}
							</StyledSpanBold>
							<StyledSpan
								style={{
									flexGrow: 1,
								}}>
								{cd.name}
							</StyledSpan>
							<SVG
								data-tooltip-id='cd-description-tooltip'
								data-tooltip-content={
									cd.description != null
										? cd.description != ''
											? cd.description
											: 'No description'
										: 'No description'
								}
								d={alerts.helpD}
								width={20}
								height={20}
								fill={theme.colors.base.black}
							/>
						</StyledOption>
					))}
				</FlexCol>
			</StyledPopupSelectCheatDefinition>
		</PopupGeneric>
	);
}
