// Third-party
import React, { useRef } from 'react';
import styled from 'styled-components';

// Hooks
import { useOutsideClickHandler } from '@/libs/hooks.lib';

const StyledPopupGeneric = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	z-index: ${(props) => props.zIndex || 10};
	max-width: ${(props) => props.$maxWidth || 'none'};
	max-height: ${(props) => props.$maxHeight || 'none'};
	overflow: auto;
`;

export default function PopupGeneric({ children, onClickOutside, zIndex = 10, style = {}, maxHeight = '90%', maxWidth = '90%'}) {
	const wrapperRef = useRef(null);
	useOutsideClickHandler({ ref: wrapperRef, onClickOutside });

	return (
		<StyledPopupGeneric
			ref={wrapperRef}
			style={style}
			$maxHeight={maxHeight}
			$maxWidth={maxWidth}
			zIndex={zIndex}>
			{children}
		</StyledPopupGeneric>
	);
}
