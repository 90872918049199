// Component
import SortedSign from '../components/sorted-sign.component';

// Styles
import Table from '@/styles/table.styles';
import { FlexRowAlignCenter } from '@/styles/shared.styles';

export function ThDefault({ column, text, isSortable = true }) {
	return (
		<Table.Th {...column.getHeaderProps(column.getSortByToggleProps())}>
			<FlexRowAlignCenter
				style={{
					justifyContent: 'flex-start',
					gap: '10px',
				}}>
				{text}
				{isSortable && (
					<SortedSign
						isSorted={column.isSorted}
						isSortedDesc={column.isSortedDesc}
					/>
				)}
			</FlexRowAlignCenter>
		</Table.Th>
	);
}

export function ColGroups({ widths }) {
	return (
		<colgroup>
			{widths.map((width, index) => (
				<col
					key={index}
					style={{ width: width }}
				/>
			))}
		</colgroup>
	);
}
