// Styles
import theme from '@/styles/theme.styles';
import { StyledSpan } from '@/styles/shared.styles';

export default function RequiredAsterisk() {
	return (
		<StyledSpan
			style={{
				fontSize: '1.3rem',
				fontWeight: 'bold',
				color: theme.colors.reds.midStrong,
				alignSelf: 'flex-start',
				marginLeft: '5px',
			}}>
			*
		</StyledSpan>
	);
}
