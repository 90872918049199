import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

// Libs
import { AppropriateInputForDataType } from '@/libs/inputs.lib';

// Styles
import { StyledSpan } from '@/styles/shared.styles';
import { StyledCol } from '@/styles/styled-cheat-definitions-definition-editor.styles';

// Components
import { ButtonRemoveCircle } from '@/components/buttons.component';
import ImageRounded from './image-rounded.component';

const StyledParameterEditorTable = styled.div`
	grid-column: 5 / 7;
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
`;

export default function CheatDefinitionsActionRow({
	action,
	index,
	control,
	variablesRemove,
	methodsRemove,
	onRemoved = () => {},
}) {
	return (
		<>
			<React.Fragment>
				<StyledCol
					style={{
						gridColumn: '1 / 2',
					}}>
					<StyledSpan>
						<ImageRounded
							data={action.icon}
							imageSize={'40px'}
						/>
					</StyledSpan>
				</StyledCol>
				<StyledCol
					style={{
						gridColumn: '2 / 3',
					}}>
					<StyledSpan
						style={{
							color: action.nameMissing ? 'red' : 'inherit',
						}}>
						{action.actionName}
					</StyledSpan>
				</StyledCol>
				<StyledCol
					style={{
						gridColumn: '3 / 5',
						color: action.descriptionMissing ? 'red' : 'inherit',
					}}>
					<StyledSpan>
						{action.descriptionMissing ? action.descriptionDescription : action.description}
					</StyledSpan>
				</StyledCol>
				{action.actionType === 'variable' && (
					<>
						<StyledCol
							style={{
								gridColumn: '5 / 6',
							}}>
							<StyledSpan>{action.argument}</StyledSpan>
						</StyledCol>
						<StyledCol
							style={{
								gridColumn: '6 / 7',
							}}>
							{AppropriateInputForDataType({
								dataType: action.dataType,
								accessor: `variables.${index}.value`,
								control,
							})}
						</StyledCol>
					</>
				)}
				{action.actionType === 'method' && (
					<StyledParameterEditorTable>
						{action.params != null &&
							action.params.map((param, paramIndex) => {
								return (
									<React.Fragment key={`method-${index}-param-${paramIndex}`}>
										<StyledCol
											style={{
												gridColumn: '1 / 2',
											}}>
											<StyledSpan>{param.paramName}</StyledSpan>
										</StyledCol>
										<StyledCol
											style={{
												gridColumn: '2 / 3',
											}}>
											{AppropriateInputForDataType({
												dataType: param.dataType,
												accessor: `methods.${index}.params.${paramIndex}.value`,
												control,
												style: {
													width: '100%',
												},
											})}
										</StyledCol>
									</React.Fragment>
								);
							})}
					</StyledParameterEditorTable>
				)}
				<StyledCol
					style={{
						gridColumn: '7 / 8',
					}}>
					<ButtonRemoveCircle
						onClick={() => {
							if (action.actionType === 'variable') {
								variablesRemove(index);
							} else if (action.actionType === 'method') {
								methodsRemove(index);
							}

							onRemoved();
						}}
					/>
				</StyledCol>
			</React.Fragment>
		</>
	);
}
