const settings = {
	images: {
		maxImageSize: {
			inBytes: 1 * 1024 * 64,
			inString: '64KB',
		},
	},
};

export default settings;
