// Components
import SVG from '@/components/svg.component';

// Assets
import { alerts } from '@/assets/icons';

// Styles
import theme from '@/styles/theme.styles';

export function TooltipActivatorQuestion({ id, content, style = {} }) {
	return (
		<SVG
			style={style}
			data-tooltip-id={id}
			data-tooltip-content={content}
			d={alerts.helpD}
			width={20}
			height={20}
			fill={theme.colors.base.black}
		/>
	);
}

export function TooltipActivatorWarning({ id, content, style = {}, size = 20 }) {
	return (
		<SVG
			style={style}
			data-tooltip-id={id}
			data-tooltip-content={content}
			d={alerts.warningFillD}
			width={size}
			height={size}
			fill={theme.colors.reds.midStrong}
		/>
	);
}
