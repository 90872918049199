import styled from 'styled-components';

export const StyledForm = styled.form``;

export const StyledFormVertical = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	border: 1px solid ${(props) => props.theme.colors.grays.light};
	border-radius: 10px;
	padding: 50px 75px;
	background-color: ${(props) => props.theme.colors.base.white};
	box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
`;

export const FlexCol = styled.div.attrs((props) => ({
	className: props.className || '',
}))`
	display: flex;
	flex-direction: column;
`;

export const FlexRow = styled.div.attrs((props) => ({
	className: props.className || '',
}))`
	display: flex;
	flex-direction: row;
`;

export const FlexRowAlignCenter = styled(FlexRow)`
	align-items: center;
`;

export const StyledButtonNoStyle = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;
	outline: none;
`;

export const StyledTableContainer = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	overflow-x: auto;
	word-wrap: break-word;
`;

export const StyledDataTableViewFlexCol = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	gap: 10px;
`;

export const StyledDataTableTitle = styled.span`
	margin-left: 8px;
	color: ${(props) => props.theme.colors.greens.midDark};
	font-size: 36px;
	font-weight: 700;
	line-height: 130%;
	padding-top: 3px;
	padding-right: 10px;
`;

export const StyledCategoricFilterContainer = styled(FlexRow)`
	gap: 20px;
`;

export const StyledContent = styled.div`
	position: relative;
	grid-area: content;
	max-width: 100%;
	max-height: 100%;
	margin: 40px 30px 40px 30px;
	padding: 18px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow: auto;
	background-color: ${(props) => props.theme.colors.base.white};
	box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
`;

export const StyledHR = styled.div`
	height: 1px;
	background-color: ${(props) => props.theme.colors.grays.light}; /* Color of the vertical line */
`;

export const StyledHRFullWidth = styled(StyledHR)`
	width: 100%;
`;

export const StyledHRVerticalFullHeight = styled.div`
	width: 1px; /* Width of the vertical line */
	height: 100px; /* Height of the vertical line */
	background-color: ${(props) => props.theme.colors.grays.light}; /* Color of the vertical line */
	margin-left: 10px; /* Adjust as needed for spacing */
	margin-right: 10px;
`;

export const StyledInputBase = styled.input`
	padding: 0 10px;
	border: 1px solid ${(props) => props.theme.colors.grays.light};
	border-radius: 10px;
	font-size: 1rem;
	font-family: 'Open Sans', sans-serif;
	transition: border 0.2s ease-in-out;

	&:focus {
		border: 1px solid ${(props) => props.theme.colors.base.white};
	}

	&:disabled {
		background-color: ${(props) => props.theme.colors.base.gray};
	}

	&::placeholder {
		color: ${(props) => props.theme.colors.grays.mid};
	}

	&:read-only {
		background-color: ${(props) => props.theme.colors.base.gray};
	}
`;

export const StyledButtonGeneric = styled.button`
	height: ${(props) => (props.height ? props.height : '40px')};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 10px;
	border-style: solid;
	border-width: ${(props) => (props.hasBorder ? '1px' : '0px')};
	border-color: ${(props) => props.theme.colors.grays.light};
	border-radius: 10px;
	background-color: ${(props) => props.backgroundColor || props.theme.colors.grays.light};
	color: ${(props) =>
		props.backgroundColor === null ||
		props.backgroundColor === props.theme.colors.grays.light ||
		props.backgroundColor === props.theme.colors.base.white
			? props.theme.colors.base.black
			: props.theme.colors.base.white};
	cursor: pointer;
	box-shadow: ${(props) => (props.hasShadow ? '0px 2px 4px rgba(0, 0, 0, 0.25)' : null)};
	transition: all 0.2s ease-in-out;

	&:hover {
		&:not([disabled]) {
			transform: ${(props) => (props.disabled ? null : 'scale(1.05)')};

			label {
				cursor: ${(props) => (props.disabled ? null : 'pointer')};
			}

			background-color: ${(props) => props.hoverBackgroundColor || null};
		}
	}

	&:active {
		transform: scale(0.95);
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;

		label {
			cursor: not-allowed;
		}
	}

	& > span {
		font-size: 1rem;
		font-weight: 600;
	}
`;

export const StyledSpanBold = styled.span`
	font-weight: 600;
`;

export const StyledSpan = styled.span``;

export const StyledSearchBar = styled.input`
	height: 30px;
	border-radius: 5px;
	border: 1px solid ${(props) => props.theme.colors.grays.mid};
	padding: 5px;
`;

export const StyledRoundImageWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.base.white};
	width: ${(props) => props.width || '100px'};
	height: ${(props) => props.height || '100px'};
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #333;
	padding: 5px;
	&:hover input {
		display: block;
	}
`;

export const StyledRoundImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const StyledRoundImagePlaceholder = styled.div`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.greens.light};
`;

export const StyledCheckbox = styled.input`
	height: 20px;
	width: 20px;
`;