import * as R from 'ramda';
import FilterCategoric from '@/components/filter-categoric.component';
import FilterGroups from '../components/filter-groups.component';

export function applyFilters(filters) {
	return R.filter((row) => {
		let keep = true;
		R.forEachObjIndexed((filter, key) => {
			if (filter['type'] == 'categoric') {
				const optionKeys = R.map((option) => option.value)(filter['value']);
				keep = (keep && R.isEmpty(filter['value'])) || (keep && R.includes(row[key], optionKeys));
			} else if (filter['type'] == 'groups') {
				if (filter['value'].length === 0) {
					keep = keep && true;
					return;
				}

				const idGroupsInFilter = R.map((option) => option.value)(filter['value']);
				const idGroupsInUser = R.map((group) => group.idGroup)(row['groups']);

				keep = keep && R.intersection(idGroupsInFilter, idGroupsInUser).length > 0;
			}
		})(filters);
		return keep;
	});
}

export function getFilterComponents(filters, setFilters, data, style = {}) {
	return R.pipe(
		R.mapObjIndexed((filter, key) => {
			if (filter['type'] == 'categoric') {
				return (
					<FilterCategoric
						key={key}
						style={style}
						filters={filters}
						valueKey={key}
						labelKey={filter['labelKey']}
						data={data}
						callback={(value) => {
							setFilters({
								...filters,
								[key]: {
									...filters[key],
									value: value,
									type: filters[key]['type'],
								},
							});
						}}
					/>
				);
			} else if (filter['type'] == 'groups') {
				return (
					<FilterGroups
						key={key}
						filters={filters}
						valueKey={key}
						labelKey={filter['labelKey']}
						data={data}
						callback={(value) => {
							setFilters({
								...filters,
								[key]: {
									...filters[key],
									value: value,
									type: filters[key]['type'],
								},
							});
						}}
					/>
				);
			}
		}),
		R.values
	)(filters);
}
