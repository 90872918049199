import { useState } from 'react';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import RowCloseButton from '@/components/row-close-button.component';
import { ButtonEditInfo, CancelSaveRow } from '@/components/buttons.component';
import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import LoadingIcon from '@/components/loading-icon.component';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import { FlexCol, FlexRowAlignCenter, StyledHRFullWidth, StyledSpan } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Hooks
import { useEditViewGenericProps } from '@/libs/hooks.lib';

export default function MacrosEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = null,
	onSubmitError = null,
}) {
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const { handleSubmit, isDirty, control, getValues, errors, onSubmit, getData } = useEditViewGenericProps({
		tableName: 'macros',
		requestType,
		contentType: 'application/json',
		currentlySelectedId,
		onStartRequest: () => {
			setLoading(true);
		},
		onFinishRequest: () => {
			setLoading(false);
		},
		onSubmitSuccess,
		onSubmitError,
	});

	return (
		<StyledPopupEditForm onSubmit={handleSubmit(onSubmit)}>
			<FlexCol>
				{loading && (
					<LoadingIcon
						style={{
							alignSelf: 'center',
							margin: '200px 0',
						}}
					/>
				)}
				{!loading && (
					<>
						<StyledPopupEditForm.Header>
							<RowCloseButton onClose={onClose} />
							<FlexRowAlignCenter
								style={{
									marginTop: '15px',
								}}>
								<FlexCol
									style={{
										alignItems: 'flex-start',
										gap: '10px',
									}}>
									{!isEditing && (
										<StyledSpan
											style={{
												fontSize: '1.3rem',
												fontWeight: 'bold',
											}}>
											{getValues('name')}
										</StyledSpan>
									)}
									{isEditing && (
										<>
											<FlexRowAlignCenter>
												<InputText
													accessor={'name'}
													control={control}
													validation={{ required: true }}
												/>
												<RequiredAsterisk />
											</FlexRowAlignCenter>
											<InputErrorMessage
												formErrors={errors}
												accessor={'name'}
											/>
										</>
									)}
									<StyledSpan>{`ID: ${getValues('idMacro')}`}</StyledSpan>
								</FlexCol>
							</FlexRowAlignCenter>
							<StyledHRFullWidth
								style={{
									marginTop: '20px',
									backgroundColor: theme.colors.grays.light,
								}}
							/>
						</StyledPopupEditForm.Header>
						<StyledPopupEditForm.Body>
							<StyledPopupEditForm.LabelValuePair>
								<FlexRowAlignCenter>
									<StyledSpan>DESCRIPTION</StyledSpan>
									<RequiredAsterisk />
								</FlexRowAlignCenter>
								{!isEditing && <StyledSpan>{getValues('description')}</StyledSpan>}
								{isEditing && (
									<InputTextArea
										accessor={'description'}
										control={control}
										validation={{ required: true }}
										style={{
											width: '100%',
										}}
									/>
								)}
								<InputErrorMessage
									formErrors={errors}
									accessor={'description'}
								/>
							</StyledPopupEditForm.LabelValuePair>
							<StyledPopupEditForm.LabelValuePair>
								<FlexRowAlignCenter>
									<StyledSpan>MACRO</StyledSpan>
									<RequiredAsterisk />
								</FlexRowAlignCenter>
								{!isEditing && (
									<StyledSpan
										style={{
											height: '200px',
											overflowY: 'auto',
										}}>
										{getValues('macro')}
									</StyledSpan>
								)}
								{isEditing && (
									<InputTextArea
										accessor={'macro'}
										control={control}
										validation={{ required: true }}
										style={{
											width: '100%',
											height: '200px',
										}}
									/>
								)}
								<InputErrorMessage
									formErrors={errors}
									accessor={'macro'}
								/>
							</StyledPopupEditForm.LabelValuePair>
							<FlexRow
								style={{
									justifyContent: 'space-between',
									gap: '15px',
								}}>
								{!isEditing && (
									<>
										<div></div>
										<ButtonEditInfo
											onClick={() => {
												setIsEditing(true);
											}}
										/>
									</>
								)}
								{isEditing && (
									<>
										<div></div>
										<CancelSaveRow
											onCancel={() => {
												setIsEditing(false);
												getData();
											}}
											saveDisabled={!isDirty}
										/>
									</>
								)}
							</FlexRow>
						</StyledPopupEditForm.Body>
					</>
				)}
			</FlexCol>
		</StyledPopupEditForm>
	);
}
