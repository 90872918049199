// Third-party
import { browserName, browserVersion } from 'react-device-detect';
import { useState } from 'react';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import styled from 'styled-components';

// State
import { useContext } from 'react';
import { UserAuthContext } from '@/contexts/user-auth.context';

// Libs
import { postApplicationJson } from '@/libs/requests.lib';
import permissions from '@/libs/permissions.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';

// Style
import { FlexCol } from '@/styles/shared.styles';

// Components
import PopupError from '@/components/popup-error.component';
import LoadingIcon from '@/components/loading-icon.component';

const StyledLogo = styled.img`
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 360px;
`;

function LoginView() {
	const { navigateToApp, navigate } = useCustomNavigate();
	const { userAuthState, setUserAuthState } = useContext(UserAuthContext);

	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showPopupError, setShowPopupError] = useState(false);
	const [loading, setLoading] = useState(false);

	const postAuth = (credentialResponse = null) => {
		const body = {
			browser: `${browserName} ${browserVersion}`,
			clientId: credentialResponse.clientId,
			credential: credentialResponse.credential,
		}

		postApplicationJson({
			endpoint: 'authentication/googleLogin',
			body,
			navigate,
		})
			.then((result) => {
				setUserAuthState({
					...userAuthState,
					user: {
						id: result.id,
						username: result.username,
						idRole: result.idRole,
						avatar: result.avatar,
						permissions: permissions[result.roleName],
						groups: result.groups,
					},
				});

				navigateToApp();

				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setPopupErrorMessage(error);
				setShowPopupError(true);
			});
	}

	useGoogleOneTapLogin({
		onSuccess: credentialResponse => {
			setLoading(true);
			postAuth(credentialResponse);
		},
		onError: () => {
			setLoading(false);
			setPopupErrorMessage('Login Failed');
			setShowPopupError(true);
		},
		promptMomentNotification: (notification) => {
			if (notification.g === 'skipped' || notification.h === false) {
				document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
			} 
		},
		cancel_on_tap_outside: false,
		disabled: false,
	});

	return (
		<FlexCol
			style={{
				justifyContent: 'flex-start',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
				padding: '180px 0px',
			}}>
			{showPopupError && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => setShowPopupError(false)}
				/>
			)}
			
				<FlexCol
					style={{
						alignItems: 'center',
						gap: '75px',
					}}>
					<StyledLogo
						src={'/HyperBeardGames_flatLogo.png'}
						alt='Hyperbeard Logo'
					/>
				</FlexCol>
			{loading && (
				<LoadingIcon
					style={{
						marginTop: '30px',
					}}
				/>
			)}
		</FlexCol>
	);
}

export default LoginView;
