import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { postApplicationJson } from '@/libs/requests.lib';
import { emailPattern } from '@/libs/regex-patterns.lib';
import { useState } from 'react';

// Style
import { FlexCol, StyledFormVertical, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Components
import InputText from '@/components/input-text.component';
import InputErrorMessage from '@/components/input-error-message.component';
import { ButtonSubmit, ButtonAccept } from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import LoadingIcon from '@/components/loading-icon.component';

// Libs
import { useCustomNavigate } from '@/libs/hooks.lib';

function SetPasswordView() {
	const { navigateToAuth } = useCustomNavigate();
	const navigate = useNavigate();

	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showPopupError, setShowPopupError] = useState(false);

	const [popupSuccessMessage, setPopupSuccessMessage] = useState('');
	const [showPopupSuccess, setShowPopupSuccess] = useState(false);

	const [loading, setLoading] = useState(false);

	const {
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = () => {
		setLoading(true);
		postApplicationJson({
			endpoint: 'authentication/setPassword',
			body: {
				email: watch('email'),
				password: watch('password'),
				verificationKey: watch('verificationKey'),
			},
			navigate,
		})
			.then(() => {
				setLoading(false);
				setPopupSuccessMessage('Password changed successfully.');
				setShowPopupSuccess(true);
			})
			.catch((error) => {
				setLoading(false);
				setPopupErrorMessage(error);
				setShowPopupError(true);
			});
	};

	return (
		<FlexCol
			style={{
				justifyContent: 'flex-start',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
				padding: '100px 0px',
			}}>
			{showPopupSuccess && (
				<PopupSuccess
					message={popupSuccessMessage}
					onClose={() => {
						setShowPopupSuccess(false);
						navigateToAuth({
							subPath: 'login',
						});
					}}
				/>
			)}
			{showPopupError && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => setShowPopupError(false)}
				/>
			)}
			<StyledFormVertical onSubmit={handleSubmit(onSubmit)}>
				<FlexCol
					style={{
						alignItems: 'center',
						gap: '10px',
					}}>
					<StyledSpanBold>Verification Key</StyledSpanBold>
					<InputText
						accessor='verificationKey'
						control={control}
						validation={{
							required: true,
						}}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor='verificationKey'
					/>
				</FlexCol>

				<FlexCol
					style={{
						alignItems: 'center',
						gap: '10px',
					}}>
					<StyledSpanBold>Email</StyledSpanBold>
					<InputText
						accessor='email'
						control={control}
						type='email'
						validation={{
							required: true,
							pattern: emailPattern,
						}}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor='email'
					/>
				</FlexCol>

				<FlexCol
					style={{
						alignItems: 'center',
						gap: '10px',
					}}>
					<StyledSpanBold>New Password</StyledSpanBold>
					<InputText
						accessor='password'
						control={control}
						type='password'
						validation={{
							required: true,
						}}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor='password'
					/>
				</FlexCol>

				<FlexCol
					style={{
						alignItems: 'center',
						gap: '10px',
					}}>
					<StyledSpanBold>Confirm Password</StyledSpanBold>
					<InputText
						accessor='confirmPassword'
						control={control}
						type='password'
						validation={{
							required: true,
							validate: () => {
								if (watch('password') === watch('confirmPassword')) return true;
								else return 'Passwords does not match';
							},
						}}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor='confirmPassword'
					/>
				</FlexCol>

				<ButtonSubmit text={'Change Password'} />

				<ButtonAccept
					text={'Back'}
					onClick={() =>
						navigateToAuth({
							subPath: 'resetPassword',
						})
					}
					backgroundColor={theme.colors.grays.dark}
				/>
			</StyledFormVertical>
			{loading && (
				<LoadingIcon
					style={{
						marginTop: '30px',
					}}
				/>
			)}
		</FlexCol>
	);
}

export default SetPasswordView;
