import styled from 'styled-components';

// Styles
import theme from '@/styles/theme.styles';
import { StyledSpan } from '@/styles/shared.styles';

const StyledPill = styled.div`
    background-color: ${(props) => {
        return props.backgroundColor;
    }};
    color: white;
    border-radius: 20px;
    font-size: 0.7rem;
    font-weight: 500;
    width: ${(props) => props.width || 'fit-content'};
    padding: 0px 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function Pill({ text, backgroundColor, width = '100px' }) {
    return (
        <StyledPill backgroundColor={backgroundColor} width={width}>
            <StyledSpan>{text}</StyledSpan>
        </StyledPill>
    );
}

const roleToNameForPill = {
    root: 'ROOT',
    qaPrograLeader: 'QA / Progra Lead',
    qaProgra: 'QA / Progra',
    socialMediaLeader: 'Social Media Lead',
    socialMedia: 'Social Media',
    newUser: 'New User',
    general: 'General',
    playerSupportLeader: 'Player Support Leader',
    playerSupport: 'Player Support',
};

export function PillRole({ role, active = true, width = '100px' }) {
    const getBackgroundColor = () => {
        if (!active) return theme.colors.grays.mid;
        switch (role) {
            case 'root':
                return theme.colors.pills.root;
            case 'qaPrograLeader':
                return theme.colors.pills.qaPrograLeader;
            case 'qaProgra':
                return theme.colors.pills.qaProgra;
            case 'socialMediaLeader':
                return theme.colors.pills.socialMediaLeader;
            case 'socialMedia':
                return theme.colors.pills.socialMedia;
            case 'newUser':
                return theme.colors.pills.newUser;
            case 'general':
                return theme.colors.pills.general;
            case 'playerSupportLeader':
                return theme.colors.pills.playerSupportLeader;
            case 'playerSupport':
                return theme.colors.pills.playerSupport;
            default:
                return theme.colors.base.black;
        }
    };

    return (
        <Pill
            width={width}
            text={roleToNameForPill[role]}
            backgroundColor={getBackgroundColor()}
        />
    );
}
