// Third-party
import { useTable, useSortBy, useGlobalFilter, usePagination, useExpanded } from 'react-table';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Switch from 'react-switch';

// Components
import TableActions from '@/components/table-actions.component';
import GenericMiniCard from '@/components/generic-mini-card.component';
import { ThDefault, ColGroups } from '@/components/table.component';
import PaginationFooter from '@/components/pagination-footer.component';
import GlobalFilter from '@/components/filter-global.component';
import { filters } from '@/libs/tables.lib';
import { FlexRow } from '@/styles/shared.styles';
import PopupError from '@/components/popup-error.component';
import { ButtonAdd, ButtonBack } from '@/components/buttons.component';
import InputAsyncSelectVersion from '@/components/input-async-select-version.component';
import PopupChooseVariable from '@/components/popup-choose-variable.component';
import PopupChooseMethod from '@/components/popup-choose-method.component';
import PopupCurate from '@/components/popup-curate.component';

// Libs
import { getColumnFromHeaderGroupByHeaderName } from '@/libs/tables.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';
import { getOneToManyById, submitPatchById } from '@/libs/requests.lib';
import headers from '@/libs/headers.lib';

// Styles
import Table from '@/styles/table.styles';
import {
	StyledContent,
	StyledSpan,
	StyledTableContainer,
	StyledDataTableViewFlexCol,
	FlexRowAlignCenter,
	FlexCol,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// State
import { useContext } from 'react';
import { CurrentMenuContext } from '../contexts/current-menu.context';
import { UserAuthContext } from '@/contexts/user-auth.context';

export default function GamesCurateView({}) {
	const { navigateTo, navigate } = useCustomNavigate();
	const { userAuthState } = useContext(UserAuthContext);
	const { currentMenuState, setCurrentMenuState } = useContext(CurrentMenuContext);

	useEffect(() => {
		if (currentMenuState.games.currentlySelectedId === null) {
			
		}
	}, []);

	// View State
	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [curationType, setCurationType] = useState('variables'); // ['variables', 'methods']
	const [showAddPopup, setShowAddPopup] = useState(false);
	const [idtoCurate, setIdtoCurate] = useState(null);
	const [defaultValuesForCuration, setDefaultValuesForCuration] = useState({});
	const [showCuratePopup, setShowCuratePopup] = useState(false);

	const { control, setValue, watch } = useForm();

	const idGame = currentMenuState.games.currentlySelectedId;
	const idGameDefinition = watch('idGameDefinition', null);

	const [nonFilteredData, setNonFilteredData] = useState([]);

	useEffect(() => {
		getSelected();
	}, [idGameDefinition, curationType]);

	const getSelected = useCallback(() => {
		if (idGameDefinition) {
			getOneToManyById({
				endpointOwner: 'gameDefinitions',
				idOwner: idGameDefinition,
				endpointOwned:
					curationType === 'variables' ? 'selectedVariableDescriptions' : 'selectedMethodDescriptions',
				navigate,
			})
				.then((data) => {
					setNonFilteredData(data);
				})
				.catch((error) => {
					setPopupErrorMessage(error);
					setShowErrorPopup(true);
				});
		}
	}, [idGameDefinition, curationType]);

	const filteredData = useMemo(() => nonFilteredData, [nonFilteredData]);

	const [currentPage, setCurrentPage] = useState(0);
	const memoizedHeaders = useMemo(
		() => (curationType === 'variables' ? headers['selectedVariables'] : headers['selectedMethods']),
		[]
	);

	// React-Table hooks
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
	} = useTable(
		{
			columns: memoizedHeaders,
			data: filteredData,
			initialState: { pageIndex: currentPage, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination
	);

	const onAddClick = () => {
		setDefaultValuesForCuration({});
		setShowAddPopup(true);
	};

	const onEditRow = (rowId, defaultValues) => {
		setDefaultValuesForCuration(defaultValues);
		setIdtoCurate(rowId);
		setShowCuratePopup(true);
	};

	const onDeleteRow = (rowId) => {
		submitPatchById({
			endpoint: curationType,
			id: rowId,
			body: {
				icon: null,
				name: null,
				description: null,
				favorite: 0,
			},
			contentType: 'multipart/form-data',
			idUser: userAuthState.user.id,
			navigate,
		})
			.then(() => {
				getSelected();
			})
			.catch((error) => {
				setPopupErrorMessage(error);
				setShowErrorPopup(true);
			});
	};

	const headerGroup = headerGroups[0];
	const columnDescriptionName = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Description Name');
	const columnName = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Name');
	const columnDescriptionn = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Description');

	return (
		<StyledContent>
			{showErrorPopup && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => {
						setShowErrorPopup(false);
					}}
				/>
			)}
			{showAddPopup && curationType === 'variables' && (
				<PopupChooseVariable
					onVariableSelected={(variable) => {
						setIdtoCurate(variable.idVariable);
						setShowAddPopup(false);
						setShowCuratePopup(true);
					}}
					onClose={() => {
						setShowAddPopup(false);
					}}
					idGameDefinition={idGameDefinition}
					getOnlyNonSelected={true}
					onError={(error) => {
						setPopupErrorMessage(error);
						setShowErrorPopup(true);
					}}
				/>
			)}
			{showAddPopup && curationType === 'methods' && (
				<PopupChooseMethod
					onMethodSelected={(method) => {
						setIdtoCurate(method.idMethod);
						setShowAddPopup(false);
						setShowCuratePopup(true);
					}}
					onClose={() => {
						setShowAddPopup(false);
					}}
					idGameDefinition={idGameDefinition}
					getOnlyNonSelected={true}
					onError={(error) => {
						setPopupErrorMessage(error);
						setShowErrorPopup(true);
					}}
				/>
			)}
			{showCuratePopup && (
				<PopupCurate
					id={idtoCurate}
					defaultValues={defaultValuesForCuration}
					onClose={() => {
						setShowCuratePopup(false);
					}}
					onSuccess={() => {
						setShowCuratePopup(false);
						getSelected();
					}}
					onError={() => {
						setShowCuratePopup(false);
					}}
					isVariable={curationType === 'variables'}
				/>
			)}
			<ButtonBack
				onClick={() => {
					setCurrentMenuState({
						...currentMenuState,
						games: {
							...currentMenuState.games,
							currentlySelectedId: null,
						},
					});

					navigateTo({
						mainPath: 'games',
						subPath: 'table',
					});
				}}
				text={'Back to Games'}
			/>
			<FlexCol
				style={{
					gap: '20px',
					marginBottom: '20px',
				}}>
				<FlexRowAlignCenter
					style={{
						width: '100%',
						gap: '20px',
					}}>
					<StyledSpan>Game Version</StyledSpan>
					<InputAsyncSelectVersion
						control={control}
						idGame={idGame}
						idGameDefinition={idGameDefinition}
						setValue={setValue}
						disabled={false}
						validation={{}}
					/>
				</FlexRowAlignCenter>
				<FlexRowAlignCenter
					style={{
						gap: '20px',
					}}>
					<StyledSpan>Methods</StyledSpan>
					<Switch
						onChange={(checked) => {
							if (!checked) {
								setCurationType('methods');
							} else {
								setCurationType('variables');
							}
						}}
						offColor={theme.colors.reds.midStrong}
						onColor={theme.colors.greens.midStrong}
						checked={curationType === 'variables'}
						checkedIcon={false}
						uncheckedIcon={false}
					/>
					<StyledSpan>Variables</StyledSpan>
				</FlexRowAlignCenter>
			</FlexCol>
			<StyledDataTableViewFlexCol>
				<FlexRow
					style={{
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: '10px',
					}}>
					<GlobalFilter
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					{/* <StyledCategoricFilterContainer>
						{getFilterComponents(realTimeFilters, setRealTimeFilters, data)}
					</StyledCategoricFilterContainer> */}
					<ButtonAdd
						onClick={onAddClick}
						text={`Add ${curationType === 'variables' ? 'Variable' : 'Method'}`}
						color={theme.colors.oranges.midStrong}
					/>
				</FlexRow>
				<StyledTableContainer>
					<Table {...getTableProps()}>
						<ColGroups widths={['20%', '20%', '20%', '20%', '10%', '10%']} />
						<Table.THead>
							<Table.Tr {...headerGroups[0].getHeaderGroupProps()}>
								<ThDefault
									column={columnDescriptionName}
									text={'Original Name'}
								/>
								<Table.Th>
									<StyledSpan>Curated Icon</StyledSpan>
								</Table.Th>
								<ThDefault
									column={columnName}
									text={'Curated Name'}
								/>
								<ThDefault
									column={columnDescriptionn}
									text={'Curated Description'}
								/>
								<Table.Th>
									<StyledSpan>Curated</StyledSpan>
								</Table.Th>
								<Table.Th></Table.Th>
							</Table.Tr>
						</Table.THead>
						<Table.TBody {...getTableBodyProps()}>
							{page.map((row) => {
								prepareRow(row);
								return (
									<Table.Tr {...row.getRowProps()}>
										<Table.Td>
											<StyledSpan>{row.original.descriptionName}</StyledSpan>
										</Table.Td>
										<Table.Td>
											<GenericMiniCard image={row.original.icon} />
										</Table.Td>
										<Table.Td>
											<StyledSpan>{row.original.name}</StyledSpan>
										</Table.Td>
										<Table.Td>
											<StyledSpan>{row.original.description}</StyledSpan>
										</Table.Td>
										<Table.Td>
											<StyledSpan>{row.original.favorite}</StyledSpan>
										</Table.Td>
										<Table.Td>
											<TableActions
												onEditRow={() => {
													onEditRow(
														curationType === 'variables'
															? row.original.idVariable
															: row.original.idMethod,
														{
															name: row.original.name,
															description: row.original.description,
															icon: row.original.icon,
															favorite: row.original.favorite,
														}
													);
												}}
												onDeleteRow={() => {
													onDeleteRow(
														curationType === 'variables'
															? row.original.idVariable
															: row.original.idMethod
													);
												}}
											/>
										</Table.Td>
									</Table.Tr>
								);
							})}
						</Table.TBody>
					</Table>
				</StyledTableContainer>
				<PaginationFooter
					currentPageLength={page.length}
					totalRows={filteredData.length}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageOptions={pageOptions}
					pageCount={pageCount}
					gotoPage={gotoPage}
					nextPage={nextPage}
					previousPage={previousPage}
					setPageSize={setPageSize}
					pageIndex={pageIndex}
					pageSize={pageSize}
					callback={(value) => {
						setCurrentPage(value);
					}}
				/>
			</StyledDataTableViewFlexCol>
		</StyledContent>
	);
}
