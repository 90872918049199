import bell from './bell.svg';
import help from './help.svg';
import helpD from './help';
import warningFill from './warning_fill.svg';
import warningFillD from './warning_fill';

export default {
	bell,
	help,
	helpD,
	warningFill,
	warningFillD,
};
