// Third-party
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import * as R from 'ramda';

// Services
import { get, getOneToManyById } from '@/libs/requests.lib';

// Select component that gets its options from an async function
const InputAsyncSelect = ({
	endpoint,
	isManyToMany = false,
	id = undefined,
	endpointOwned = undefined,
	valueColumn,
	labelColumn,
	accessor,
	control,
	isDisabled,
	validation,
	width = '100%',
	minWidth = '100%',
	filterOptions = false,
	filterColumn = '',
	optionsToShow = [],
	defaultOption = undefined,
	sortingFunction = (a, b) => 0,
	onLoadOptions = (data) => {},
	onChanged = (option) => {},
	onError = (error) => {},
}) => {
	const [options, setOptions] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const requestEndpoint = isManyToMany
			? getOneToManyById({ endpointOwner: endpoint, idOwner: id, endpointOwned, navigate })
			: get({ endpoint, navigate });

		requestEndpoint
			.then((data) => {
				onLoadOptions(data);
				const formattedOptions = R.pipe(
					R.filter((row) => {
						if (!filterOptions) return true;
						if (optionsToShow.length === 0) return true;
						return R.includes(row[filterColumn], optionsToShow);
					}),
					R.sort(sortingFunction),
					R.map((row) => {
						return {
							value: row[valueColumn],
							label: row[labelColumn],
						};
					})
				)(data);
				setOptions(formattedOptions);
			})
			.catch((err) => {
				onError(err);
			});
	}, [id]);

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width,
			minWidth,
		}),
		menu: (provided, state) => ({
			...provided,
			width,
			minWidth,
			flexGrow: 1,
		}),
	};

	return (
		<Controller
			name={accessor}
			control={control}
			rules={validation}
			render={({ field: { onChange, value } }) => {
				return (
					<Select
						value={options.find((c) => c.value === value)}
						onChange={(val) => {
							onChange(val.value);
							onChanged(val.value);
						}}
						defaultValue={defaultOption}
						options={options}
						isSearchable={false}
						isDisabled={isDisabled}
						styles={customStyles}
					/>
				);
			}}
		/>
	);
};

export default InputAsyncSelect;
