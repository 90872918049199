import { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import GroupMiniCard from '@/components/group-mini-card.component';
import PopupGeneric from '@/components/popup-generic.component';
import SearchBar from '@/components/search-bar.component';

// Styles
import { FlexCol } from '@/styles/shared.styles';

const StyledPopupAddGroup = styled(FlexCol)`
	width: 100%;
	height: 200px;
	padding: 10px 10px 10px 10px;
`;

const StyledOption = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background-color: ${(props) => props.theme.colors.grays.mid};
	}
`;

export default function PopupAddGroup({
	nonSelectedGroups,
	onClose = () => {},
	onGroupSelected = (group) => {},
	style = {},
}) {
	const [filteredGroups, setFilteredGroups] = useState(nonSelectedGroups);

	useEffect(() => {
		setFilteredGroups(nonSelectedGroups);
	}, [nonSelectedGroups]);

	return (
		<PopupGeneric
			onClickOutside={onClose}
			style={style}
			maxHeight='none'
			maxWidth='none'
			>
			<StyledPopupAddGroup>
				<SearchBar
					unfilteredValues={nonSelectedGroups}
					setFilteredValues={setFilteredGroups}
					keysToSearch={['name']}
				/>
				<FlexCol
					style={{
						overflowY: 'scroll',
						height: '100%',
						gap: '10px',
					}}>
					{filteredGroups.map((group) => (
						<StyledOption
							key={group.idGroup}
							onClick={() => {
								onGroupSelected(group);
							}}>
							<GroupMiniCard
								name={group.name}
								iconSVGPath={group.iconSVGPath}
								color={group.color}
							/>
						</StyledOption>
					))}
				</FlexCol>
			</StyledPopupAddGroup>
		</PopupGeneric>
	);
}
