import InputText from '@/components/input-text.component';
import InputNumber from '@/components/input-number.component';
import InputCheckbox from '@/components/input-checkbox.component';
import InputTextArea from '@/components/input-text-area.component';
import InputDateTime from '@/components/input-date-time.component';

export function AppropriateInputForDataType({ dataType, accessor, control, editable = true, style = {} }) {
	return (
		<>
			{dataType === 'Int' && (
				<InputNumber
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'Float' && (
				<InputNumber
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'Double' && (
				<InputNumber
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'String' && (
				<InputText
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'Bool' && (
				<InputCheckbox
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'DateTime' && (
				<InputDateTime
					accessor={accessor}
					editable={editable}
					control={control}
					style={style}
				/>
			)}
			{dataType === 'JSON' && (
				<InputTextArea
					accessor={accessor}
					editable={editable}
					control={control}
					cols={null}
					style={style}
				/>
			)}
		</>
	);

	// switch (dataType) {
	// 	case 'Int':
	// 	case 'Float':
	// 	case 'Double':
	// 		return (
	// 			<InputNumber
	// 				accessor={accessor}
	// 				editable={editable}
	// 				control={control}
	// 				style={style}
	// 			/>
	// 		);
	// 	case 'String':
	// 		return (
	// 			<InputText
	// 				accessor={accessor}
	// 				editable={editable}
	// 				control={control}
	// 				style={style}
	// 			/>
	// 		);
	// 	case 'Bool':
	// 		return (
	// 			<InputCheckbox
	// 				accessor={accessor}
	// 				editable={editable}
	// 				control={control}
	// 				style={style}
	// 			/>
	// 		);
	// 	case 'DateTime':
	// 		return (
	// 			<InputDateTime
	// 				accessor={accessor}
	// 				editable={editable}
	// 				control={control}
	// 				style={style}
	// 			/>
	// 		);
	// 	case 'JSON':
	// 		return (
	// 			<InputTextArea
	// 				accessor={accessor}
	// 				editable={editable}
	// 				control={control}
	// 				cols={null}
	// 				style={style}
	// 			/>
	// 		);
	// }
}
