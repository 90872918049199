import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as R from "ramda";
import styled from "styled-components";
import Switch from "react-switch";
import { Tooltip } from "react-tooltip";

// Libs
import {
    getArgumentsFromVariablesAndMethodsValues,
    getFormattedMethodsData,
    convertVariableToAction,
    convertMethodToAction,
    isThereNonCuratedDataInActions,
    removeArgumentsFromVariables,
    removeArgumentsFromMethods,
    examplesByDataType
} from "@/libs/cheat-definitions.lib";
import { useCustomNavigate } from "@/libs/hooks.lib";

// State
import { CheatDefinitionsContext } from "@/contexts/cheat-definitions.context";
import { UserAuthContext } from "@/contexts/user-auth.context";
import { useContext } from "react";

// Styles
import {
    StyledContent,
    FlexRowAlignCenter,
    FlexCol,
    StyledSpanBold,
    StyledSpan,
    StyledButtonGeneric,
} from "@/styles/shared.styles";
import { StyledCol } from "@/styles/styled-cheat-definitions-definition-editor.styles";
import theme from "@/styles/theme.styles";

// Components
import { ButtonCancel, ButtonSave } from "@/components/buttons.component";
import PopupChooseVariable from "@/components/popup-choose-variable.component";
import PopupChooseMethod from "@/components/popup-choose-method.component";
import PopupChooseAction from "@/components/popup-choose-action.component";
import PopupCancelConfirm from "../components/popup-cancel-confirm.component";
import { ButtonAdd } from "@/components/buttons.component";
import CheatDefinitionsVariableRow from "@/components/cheat-definitions-variable-row.component";
import CheatDefinitionsMethodRow from "@/components/cheat-definitions-method-row.component";
import CheatDefinitionsActionRow from "../components/cheat-definitions-action-row.component";
import { TooltipActivatorWarning } from "@/components/tooltip-activators.component";
import PopupError from "@/components/popup-error.component";

const StyledEditorTable = styled.div`
    display: grid;
    grid-template-columns: ${(props) =>
        props.gridTemplateColumns || "2fr 1fr 2fr 2fr 1fr 1fr"};
    width: 100%;
    height: 100%;
    align-content: start;
    overflow-y: scroll;
`;

const StyledCheatDefinitionsDefinitionEditor = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
`;

const StyledEditor = styled(FlexCol)`
    grid-area: editor;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    overflow-y: scroll;
`;

export default function CheatDefinitionsDefinitionEditor({}) {
    const { navigateTo } = useCustomNavigate();
    const { cheatDefinitionsState,
        setCheatDefinitionsState, } = useContext(
        CheatDefinitionsContext
    );

	const { userAuthState } = useContext(UserAuthContext);

    const [popupErrorMessage, setPopupErrorMessage] = useState("");
    const [showPopupError, setShowPopupError] = useState(false);

    const [editorMode, setEditorMode] = useState(
        userAuthState.user.permissions.cheatDefinitions.update.modes.includes(
            "full"
        )
            ? "full"
            : "simple"
    ); // ['full', 'simple']
    const [editingType, setEditingType] = useState(
        userAuthState.user.permissions.cheatDefinitions.update.modes.includes(
            "full"
        )
            ? "variables"
            : "actions"
    ); // ['variables', 'methods', 'actions']
    const [showPopupChooseVariable, setShowPopupChooseVariable] =
        useState(false);
    const [showPopupChooseMethod, setShowPopupChooseMethod] = useState(false);
    const [showPopupChooseAction, setShowPopupChooseAction] = useState(false);
    const [showPopupCancelConfirm, setShowPopupCancelConfirm] = useState(false);
    const [randomCheatKey] = useState(
        cheatDefinitionsState.cheatKeys.length > 0
            ? cheatDefinitionsState.cheatKeys[
                  Math.floor(
                      Math.random() * cheatDefinitionsState.cheatKeys.length
                  )
              ].key
            : "your_code_here"
    );

    const { handleSubmit, control, setValue, getValues } = useForm({
        defaultValues: {
            variables: cheatDefinitionsState.variables,
            methods: cheatDefinitionsState.methods,
        },
    });

    const [argumentsValues, setArgumentsValues] = useState([]);

    useEffect(() => {
        const argumentsValues = getArgumentsFromVariablesAndMethodsValues(
            cheatDefinitionsState.variables,
            cheatDefinitionsState.methods
        );

        setArgumentsValues(argumentsValues);
    }, []);

    const {
        fields: variablesFields,
        append: variablesAppend,
        remove: variablesRemove,
        update: variablesUpdate,
        replace: variablesReplace,
    } = useFieldArray({
        control,
        name: "variables",
    });

    const {
        fields: methodsFields,
        append: methodsAppend,
        remove: methodsRemove,
        update: methodsUpdate,
        replace: methodsReplace,
    } = useFieldArray({
        control,
        name: "methods",
    });

    const thereIsNonCuratedDataInActions = isThereNonCuratedDataInActions(
        variablesFields,
        methodsFields
    );

    const onSubmit = (data) => {
        let currentSortOrder = 0;
        const methodsWithSortOrder = R.map((method) => {
            return {
                ...method,
                sortOrder: currentSortOrder++,
            };
        })(data.methods);

        setCheatDefinitionsState({
            ...cheatDefinitionsState,
            variables: data.variables,
            methods: methodsWithSortOrder,
            variablesOrMethodsUpdated: true,
        });

        navigateTo({
            mainPath: "cheatDefinitions",
            subPath: "edit",
        });
    };

    return (
        <StyledContent
            style={{
                justifyContent: "flex-start",
            }}>
            <Tooltip
                id="curation-warning-tooltip"
                place="right"
                effect="solid"
            />
            {showPopupError && (
                <PopupError
                    onClose={() => {
                        setShowPopupError(false);
                    }}
                    error={popupErrorMessage}
                />
            )}
            {showPopupCancelConfirm && (
                <PopupCancelConfirm
                    onCancel={() => {
                        setShowPopupCancelConfirm(false);
                    }}
                    onConfirm={() => {
                        setEditorMode("simple");
                        setEditingType("actions");

                        setArgumentsValues([]);

                        const currentValues = getValues();

                        const variablesWithNoArgs =
                            removeArgumentsFromVariables(
                                currentValues.variables
                            );
                        variablesReplace(variablesWithNoArgs);

                        const methodsWithNoArgs = removeArgumentsFromMethods(
                            currentValues.methods
                        );
                        methodsReplace(methodsWithNoArgs);

                        setShowPopupCancelConfirm(false);
                    }}
                    title={"Warning!"}
                    message={
                        'If you change the editor type to "Simple", you will lose your current arguments. Do you want to continue?'
                    }
                />
            )}
            {showPopupChooseVariable && (
                <PopupChooseVariable
                    alreadySelectedVariables={variablesFields}
                    onVariableSelected={(variable) => {
                        const formattedVariable = R.assoc(
                            "isArgument",
                            0,
                            variable
                        );

                        const newField = {
                            ...formattedVariable,
                            idVariableOperation: 1,
                            value: variable.defaultValue,
                        };
                        variablesAppend(newField);
                    }}
                    onClose={() => {
                        setShowPopupChooseVariable(false);
                    }}
                    filterSelected={true}
                    idGameDefinition={
                        cheatDefinitionsState.currentlySelectedIdGameDefinition
                    }
                    onError={(error) => {
                        setPopupErrorMessage(error);
                        setShowPopupError(true);
                    }}
                />
            )}
            {showPopupChooseMethod && (
                <PopupChooseMethod
                    onMethodSelected={(method) => {
                        const newField = getFormattedMethodsData([method])[0];

                        methodsAppend(newField);
                    }}
                    onClose={() => {
                        setShowPopupChooseMethod(false);
                    }}
                    filterSelected={false}
                    idGameDefinition={
                        cheatDefinitionsState.currentlySelectedIdGameDefinition
                    }
                    onError={(error) => {
                        setPopupErrorMessage(error);
                        setShowPopupError(true);
                    }}
                />
            )}
            {showPopupChooseAction && (
                <PopupChooseAction
                    filterSelected={false}
                    idGameDefinition={
                        cheatDefinitionsState.currentlySelectedIdGameDefinition
                    }
                    onVariableSelected={(variable) => {
                        const formattedVariable = R.assoc(
                            "isArgument",
                            0,
                            variable
                        );

                        const newField = {
                            ...formattedVariable,
                            value: variable.defaultValue,
                        };
                        variablesAppend(newField);
                    }}
                    onMethodSelected={(method) => {
                        const newField = getFormattedMethodsData([method])[0];

                        methodsAppend(newField);
                    }}
                    onClose={() => {
                        setShowPopupChooseAction(false);
                    }}
                    onError={(error) => {
                        setPopupErrorMessage(error);
                        setShowPopupError(true);
                    }}
                />
            )}
            <FlexRowAlignCenter
                style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    gap: "20px",
                }}>
                <StyledSpanBold>Simple</StyledSpanBold>
                <Switch
                    disabled={
                        !userAuthState.user.permissions.cheatDefinitions.update.modes.includes(
                            "full"
                        )
                    }
                    onChange={(checked) => {
                        if (!checked) {
                            if (argumentsValues.length > 0) {
                                setShowPopupCancelConfirm(true);
                                return;
                            }

                            setEditorMode("simple");
                            setEditingType("actions");

                            setArgumentsValues([]);

                            const currentValues = getValues();

                            const variablesWithNoArgs =
                                removeArgumentsFromVariables(
                                    currentValues.variables
                                );
                            variablesReplace(variablesWithNoArgs);

                            const methodsWithNoArgs =
                                removeArgumentsFromMethods(
                                    currentValues.methods
                                );
                            methodsReplace(methodsWithNoArgs);
                        } else {
                            setEditorMode("full");
                            setEditingType("variables");
                        }
                    }}
                    offColor={theme.colors.reds.midStrong}
                    onColor={theme.colors.greens.midStrong}
                    checked={editorMode === "full"}
                    checkedIcon={false}
                    uncheckedIcon={false}
                />
                <StyledSpanBold>Full</StyledSpanBold>
            </FlexRowAlignCenter>
            <StyledCheatDefinitionsDefinitionEditor
                onSubmit={handleSubmit(onSubmit)}>
                <FlexRowAlignCenter
                    style={{
                        gridArea: "example",
                        justifyContent: "center",
                        gap: "20px",
                    }}>
                    <FlexCol
                        style={{
                            width: "100%",
                            gap: "10px",
                            alignItems: "center",
                        }}>
                        <FlexRowAlignCenter
                            style={{
                                gap: "10px",
                            }}>
                            <StyledSpanBold
                                style={{
                                    fontSize: "1.1rem",
                                }}>
                                How to redeem:
                            </StyledSpanBold>
                            <StyledSpan
                                style={{
                                    fontSize: "1.1rem",
                                }}>
                                <i>{`code`}</i>{" "}
                                {argumentsValues.length > 0 &&
                                    argumentsValues.map(
                                        (argument, index) => {
                                            return (
                                                <i key={index}>
                                                    {argument.value}{" "}
                                                </i>
                                            );
                                        }
                                    )}
                            </StyledSpan>
                        </FlexRowAlignCenter>
                        <FlexRowAlignCenter
                            style={{
                                gap: "10px",
                            }}>
                            <StyledSpanBold
                                style={{
                                    fontSize: "1.1rem",
                                }}>
                                Example:
                            </StyledSpanBold>
                            <StyledSpan
                                style={{
                                    fontSize: "1.1rem",
                                }}>
                                <i>{randomCheatKey}</i>{" "}
                                {argumentsValues.length > 0 &&
                                    argumentsValues.map(
                                        (argument, index) => {
                                            return (
                                                <i key={index}>
                                                    {
                                                        examplesByDataType[
                                                            argument.dataType
                                                        ][
                                                            Math.floor(
                                                                Math.random() *
                                                                    examplesByDataType[
                                                                        argument
                                                                            .dataType
                                                                    ].length
                                                            )
                                                        ]
                                                    }{" "}
                                                </i>
                                            );
                                        }
                                    )}
                            </StyledSpan>
                        </FlexRowAlignCenter>
                    </FlexCol>
                </FlexRowAlignCenter>
                {editorMode === "full" && (
                    <FlexRowAlignCenter
                        style={{
                            gridArea: "selectors",
                            justifyContent: "center",
                            gap: "20px",
                        }}>
                        <StyledButtonGeneric
                            type="button"
                            onClick={() => {
                                setEditingType("variables");
                            }}
                            backgroundColor={
                                editingType === "variables"
                                    ? theme.colors.blues.midStrong
                                    : theme.colors.blues.midPastel
                            }>
                            <StyledSpanBold>Variables</StyledSpanBold>
                        </StyledButtonGeneric>
                        <StyledButtonGeneric
                            type="button"
                            onClick={() => {
                                setEditingType("methods");
                            }}
                            backgroundColor={
                                editingType === "methods"
                                    ? theme.colors.blues.midStrong
                                    : theme.colors.blues.midPastel
                            }>
                            <StyledSpanBold>Methods</StyledSpanBold>
                        </StyledButtonGeneric>
                    </FlexRowAlignCenter>
                )}
                {editingType === "variables" && (
                    <StyledEditor>
                        <FlexRowAlignCenter
                            style={{
                                gap: "20px",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingRight: "10px",
                            }}>
                            <StyledSpanBold
                                style={{
                                    fontSize: "1.5rem",
                                }}>
                                Variables
                            </StyledSpanBold>
                            <ButtonAdd
                                onClick={() => {
                                    setShowPopupChooseVariable(true);
                                }}
                                text="Add Variable"
                            />
                        </FlexRowAlignCenter>
                        <StyledEditorTable
                            gridTemplateColumns={"1fr 1fr 1.3fr 2.2fr 0.5fr 1fr"}
                        >
                            <VariablesHeaders />
                            {variablesFields.map((variable, index) => {
                                return (
                                    <CheatDefinitionsVariableRow
                                        key={variable.id}
                                        variable={variable}
                                        index={index}
                                        control={control}
                                        setValue={setValue}
                                        argumentsValues={argumentsValues}
                                        setArgumentsValues={setArgumentsValues}
                                        variablesRemove={variablesRemove}
                                        variablesUpdate={variablesUpdate}
                                        variablesFields={variablesFields}
                                        methodsFields={methodsFields}
                                        methodsReplace={methodsReplace}
                                        variablesReplace={variablesReplace}
                                    />
                                );
                            })}
                        </StyledEditorTable>
                    </StyledEditor>
                )}
                {editingType === "methods" && (
                    <StyledEditor>
                        <FlexRowAlignCenter
                            style={{
                                gap: "20px",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingRight: "10px",
                            }}>
                            <StyledSpanBold
                                style={{
                                    fontSize: "1.5rem",
                                }}>
                                Methods
                            </StyledSpanBold>
                            <ButtonAdd
                                onClick={() => {
                                    setShowPopupChooseMethod(true);
                                }}
                                text="Add Method"
                            />
                        </FlexRowAlignCenter>
                        <StyledEditorTable
                            gridTemplateColumns={"2fr 1fr 1.5fr 2.5fr 1fr 1fr"}
                        >
                            <MethodsHeaders />
                            {methodsFields.map((method, index) => {
                                return (
                                    <CheatDefinitionsMethodRow
                                        key={method.id}
                                        method={method}
                                        index={index}
                                        control={control}
                                        setValue={setValue}
                                        methodsRemove={methodsRemove}
                                        argumentsValues={argumentsValues}
                                        setArgumentsValues={setArgumentsValues}
                                        variablesReplace={variablesReplace}
                                        methodsReplace={methodsReplace}
                                        methodsUpdate={methodsUpdate}
                                        variablesFields={variablesFields}
                                        methodsFields={methodsFields}
                                    />
                                );
                            })}
                        </StyledEditorTable>
                    </StyledEditor>
                )}
                {editingType === "actions" && (
                    <StyledEditor>
                        <FlexRowAlignCenter
                            style={{
                                gap: "20px",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingRight: "10px",
                            }}>
                            <FlexRowAlignCenter
                                style={{
                                    gap: "10px",
                                }}>
                                <StyledSpanBold
                                    style={{
                                        fontSize: "1.5rem",
                                    }}>
                                    Actions
                                </StyledSpanBold>
                                {thereIsNonCuratedDataInActions && (
                                    <TooltipActivatorWarning
                                        id={"curation-warning-tooltip"}
                                        content={
                                            "The names and descriptions marked in red have not been curated yet."
                                        }
                                        size={30}
                                    />
                                )}
                            </FlexRowAlignCenter>
                            <ButtonAdd
                                onClick={() => {
                                    setShowPopupChooseAction(true);
                                }}
                                text="Add Action"
                            />
                        </FlexRowAlignCenter>
                        <StyledEditorTable
                            gridTemplateColumns={"1fr 2fr 2fr 1fr 1fr 1fr"}>
                            <ActionHeaders />
                            {variablesFields.map((variable, index) => {
                                const action =
                                    convertVariableToAction(variable);
                                return (
                                    <CheatDefinitionsActionRow
                                        key={variable.id}
                                        action={action}
                                        index={index}
                                        control={control}
                                        variablesRemove={variablesRemove}
                                        methodsRemove={methodsRemove}
                                    />
                                );
                            })}
                            {methodsFields.map((method, index) => {
                                const action = convertMethodToAction(method);
                                return (
                                    <CheatDefinitionsActionRow
                                        key={method.id}
                                        action={action}
                                        index={index}
                                        control={control}
                                        variablesRemove={variablesRemove}
                                        methodsRemove={methodsRemove}
                                    />
                                );
                            })}
                        </StyledEditorTable>
                    </StyledEditor>
                )}
                <FlexRowAlignCenter
                    style={{
                        gridArea: "buttons",
                        marginTop: "20px",
                        width: "100%",
                        justifyContent: "center",
                        gap: "50px",
                    }}>
                    <ButtonCancel
                        onClick={() => {
                            navigateTo({
                                mainPath: "cheatDefinitions",
                                subPath: "edit",
                            });
                        }}
                    />
                    <ButtonSave />
                </FlexRowAlignCenter>
            </StyledCheatDefinitionsDefinitionEditor>
        </StyledContent>
    );
}

const VariablesHeaders = () => {
    return (
        <>
            <StyledCol
                style={{
                    gridColumn: "1 / 2",
                }}>
                <StyledSpan>Name</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "2 / 3",
                }}>
                <StyledSpan>Data Type</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "3 / 4",
                }}>
                <StyledSpan>Operation</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "4 / 5",
                }}>
                <StyledSpan>Value</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "5 / 6",
                }}>
                <StyledSpan>Is Argument</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "6 / 7",
                }}></StyledCol>
        </>
    );
};

const MethodsHeaders = () => {
    return (
        <>
            <StyledCol
                style={{
                    gridColumn: "1 / 2",
                }}>
                <StyledSpan>Name</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "2 / 6",
                }}>
                <StyledSpan>Parameters</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "6 / 7",
                }}></StyledCol>
            {/*PARAMETERS HEADERS*/}
            <StyledCol
                style={{
                    gridColumn: "1 / 2",
                }}></StyledCol>
            <StyledCol
                style={{
                    gridColumn: "2 /3",
                }}>
                <StyledSpan>Name</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "3 / 4",
                }}>
                <StyledSpan>Data Type</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "4 / 5",
                }}>
                <StyledSpan>Value</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "5 / 6",
                }}>
                <StyledSpan>Is Argument</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "6 / 7",
                }}></StyledCol>
        </>
    );
};

const ActionHeaders = () => {
    return (
        <>
            <StyledCol
                style={{
                    gridColumn: "1 / 2",
                }}>
                <StyledSpan>Icon</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "2 / 3",
                }}>
                <StyledSpan>Name</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "3 / 5",
                }}>
                <StyledSpan>Description</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "5 / 6",
                }}>
                <StyledSpan>Argument</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "6 / 7",
                }}>
                <StyledSpan>Value</StyledSpan>
            </StyledCol>
            <StyledCol
                style={{
                    gridColumn: "7 / 8",
                }}></StyledCol>
        </>
    );
};
