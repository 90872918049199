// Third-party
import { useState } from 'react';

// Components
import TableViewGenericProps from '@/components/table-view-generic-props.component';
import PopupGeneric from '@/components/popup-generic.component';
import TableActions from '@/components/table-actions.component';
import GenericMiniCard from '@/components/generic-mini-card.component';
import { ThDefault, ColGroups } from '@/components/table.component';

// Views
import SnapshotsEditView from '@/views/snapshots-edit.view';

// Utils
import { getColumnFromHeaderGroupByHeaderName } from '@/libs/tables.lib';

// Styles
import Table from '@/styles/table.styles';
import { StyledContent } from '@/styles/shared.styles';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';
import * as R from "ramda";

export default function SnapshotsTableView() {
	const { userAuthState } = useContext(UserAuthContext);

	const [showEditPopup, setShowEditPopup] = useState(false);
	const [selectedRowIdToEdit, setSelectedRowId] = useState(null);
	const [tableViewGenericPropsRandomKey, setTableViewGenericPropsRandomKey] = useState(Math.random());

	const editRow = (rowId) => {
		setSelectedRowId(rowId);
		setShowEditPopup(true);
	};

	return (
		<StyledContent>
			<TableViewGenericProps
				menuName='snapshots'
				key={tableViewGenericPropsRandomKey}
				onPopupSuccessClose={() => {
					setShowEditPopup(false);
					setTableViewGenericPropsRandomKey(Math.random());
				}}
				preFilteringFunction={(data) => {
					if (userAuthState.user.permissions.snapshots.read === true) {
						return data;
					}

					const groupsInUser = userAuthState.user.groups;

					const filteredData = R.filter(
						R.pipe(
							R.prop('groups'),
							R.intersection(groupsInUser),
							R.isEmpty,
							R.not
						)
					)(data);

					return filteredData;
				}}
				>
				{({
					getTableProps,
					headerGroups,
					getTableBodyProps,
					rows,
					prepareRow,
					deleteRow,
					setPopupErrorMessage,
					setShowErrorPopup,
					setPopupSuccessMessage,
					setShowSuccessPopup,
				}) => {
					const headerGroup = headerGroups[0];
					const columnName = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Name');
					const columnGame = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Game');
					const columnCreationDatetime = getColumnFromHeaderGroupByHeaderName(
						headerGroup,
						'Creation Datetime'
					);
					return (
						<>
							{showEditPopup && (
								<PopupGeneric onClickOutside={() => {}}>
									<SnapshotsEditView
										requestType={'PATCH'}
										currentlySelectedId={selectedRowIdToEdit}
										onClose={() => {
											setShowEditPopup(false);
										}}
										onSubmitSuccess={() => {
											setPopupSuccessMessage('Snapshot edited successfully!');
											setShowSuccessPopup(true);
										}}
										onSubmitError={(errorMessage) => {
											setPopupErrorMessage(errorMessage);
											setShowErrorPopup(true);
										}}
									/>
								</PopupGeneric>
							)}
							<Table {...getTableProps()}>
								<ColGroups widths={['25%', '25%', '25%', '20%', '5%']} />
								<Table.THead>
									<Table.Tr {...headerGroup.getHeaderGroupProps()}>
										<ThDefault
											column={columnName}
											text={columnName.render('Header')}
										/>
										<ThDefault
											column={columnGame}
											text={columnGame.render('Header')}
										/>
										<Table.Th>Description</Table.Th>
										<ThDefault
											column={columnCreationDatetime}
											text={columnCreationDatetime.render('Header')}
										/>
										<Table.Th></Table.Th>
									</Table.Tr>
								</Table.THead>
								<Table.TBody {...getTableBodyProps()}>
									{rows.map((row) => {
										prepareRow(row);

										const date = new Date(row.values.creationTime);
										const options = {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
											hour: '2-digit',
											minute: '2-digit',
											second: '2-digit',
											timeZoneName: 'short',
										};
										const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
										return (
											<Table.Tr {...row.getRowProps()}>
												<Table.Td>
													<GenericMiniCard
														image={row.values.image}
														topText={row.values.name}
														bottomText={`ID: ${row.values.idSnapshot}`}
													/>
												</Table.Td>
												<Table.Td>
													<GenericMiniCard
														image={row.values.gameImage}
														topText={row.values.gameName}
														bottomText={row.values.gameVersion}
													/>
												</Table.Td>
												<Table.Td>
													<span
														style={{
															fontSize: '0.9rem',
														}}>
														{row.values.description || 'N/A'}
													</span>
												</Table.Td>
												<Table.Td>
													<span>{formattedDate}</span>
												</Table.Td>
												<Table.Td>
													{userAuthState.user.permissions.snapshots.update !== false && (
														<TableActions
															onEditRow={() => {
																editRow(row.values.idSnapshot);
															}}
															onDeleteRow={() => {
																deleteRow(row.values.idSnapshot);
															}}
														/>
													)}
												</Table.Td>
											</Table.Tr>
										);
									})}
								</Table.TBody>
							</Table>
						</>
					);
				}}
			</TableViewGenericProps>
		</StyledContent>
	);
}
