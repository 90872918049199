import { createContext, useState, useEffect } from 'react';

export const CheatDefinitionsContext = createContext();

export const CheatDefinitionsContextProvider = ({ children }) => {
	const [cheatDefinitionsState, setCheatDefinitionsState] = useState(JSON.parse(localStorage.getItem('cheatDefinitionsState')));

	useEffect(() => {
		// If this condition is met, it means that either the user logged out or there was some kind of data corruption
		if (cheatDefinitionsState == null || cheatDefinitionsState == undefined || cheatDefinitionsState.requestType == null) {
			resetCheatDefinitionsState();
		}
	}, []);


	useEffect(() => {
		localStorage.setItem('cheatDefinitionsState', JSON.stringify(cheatDefinitionsState));
	}, [cheatDefinitionsState]);

	const resetCheatDefinitionsState = () => {
		setCheatDefinitionsState({
			requestType: null,
			isEditing: false,
			currentlySelectedId: null,
			currentlySelectedIdGame: null,
			currentlySelectedIdGameDefinition: null,
			cheatDefinition: {},
			cheatKeys: [],
			cheatKeysUpdated: false,
			variables: [],
			methods: [],
			variablesOrMethodsUpdated: false,
			isPartialSnapshot: false,
		});
	};

	return (
		<CheatDefinitionsContext.Provider
			value={{ cheatDefinitionsState, setCheatDefinitionsState, resetCheatDefinitionsState }}>
			{children}
		</CheatDefinitionsContext.Provider>
	);
};
