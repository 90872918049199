import styled from 'styled-components';

// Styles
import {
	StyledButtonGeneric,
	FlexRowAlignCenter,
	StyledButtonNoStyle,
	StyledSpanBold,
	StyledSpan,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Assets
import { actions, arrows, state } from '@/assets/icons';

// Components
import SVG from '@/components/svg.component';

export function ButtonEditInfo({ onClick }) {
	return (
		<StyledButtonGeneric
			type={'button'}
			hasShadow={true}
			backgroundColor={theme.colors.blues.midPastel}
			onClick={onClick}>
			<StyledSpan>Edit Info</StyledSpan>
		</StyledButtonGeneric>
	);
}

export function ButtonCancel({ onClick, style = {} }) {
	return (
		<StyledButtonGeneric
			style={style}
			type={'button'}
			hasShadow={true}
			hasBorder={true}
			backgroundColor={theme.colors.base.white}
			onClick={onClick}>
			<StyledSpan>Cancel</StyledSpan>
		</StyledButtonGeneric>
	);
}

export function ButtonAccept({ onClick, text = null, backgroundColor = theme.colors.greens.midStrong, style = {} }) {
	return (
		<StyledButtonGeneric
			style={style}
			type={'button'}
			hasShadow={true}
			hasBorder={true}
			backgroundColor={backgroundColor}
			onClick={onClick}>
			<StyledSpan>{text === null ? 'Accept' : text}</StyledSpan>
		</StyledButtonGeneric>
	);
}

export function ButtonSave({ disabled = false, style }) {
	return (
		<StyledButtonGeneric
			style={style}
			type='submit'
			disabled={disabled}
			hasShadow={true}
			hasBorder={false}
			backgroundColor={theme.colors.greens.midStrong}>
			<StyledSpan>Save</StyledSpan>
		</StyledButtonGeneric>
	);
}

export function ButtonSubmit({
	disabled = false,
	text = 'Submit',
	style = {},
	backgroundColor = theme.colors.greens.midStrong,
}) {
	return (
		<StyledButtonGeneric
			style={style}
			type='submit'
			disabled={disabled}
			hasShadow={true}
			hasBorder={false}
			backgroundColor={backgroundColor}>
			<StyledSpan>{text}</StyledSpan>
		</StyledButtonGeneric>
	);
}

export function CancelSaveRow({
	onCancel,
	saveDisabled,
	showCancelButton = true,
	buttonCancelStyle = {},
	buttonSaveStyle = {},
}) {
	return (
		<FlexRowAlignCenter
			style={{
				gap: '10px',
			}}>
			{showCancelButton && (
				<ButtonCancel
					onClick={onCancel}
					style={{
						...buttonCancelStyle,
						width: '80px',
					}}
				/>
			)}
			<ButtonSave
				style={{
					...buttonSaveStyle,
					width: '80px',
				}}
				disabled={saveDisabled}
			/>
		</FlexRowAlignCenter>
	);
}

export function ButtonAdd({ onClick, text, color = theme.colors.blues.midPastel }) {
	return (
		<StyledButtonGeneric
			type={'button'}
			hasShadow={true}
			backgroundColor={color}
			onClick={onClick}>
			<FlexRowAlignCenter
				style={{
					gap: '10px',
				}}>
				<SVG
					d={actions.addCircleD}
					width={25}
					height={25}
					fill={theme.colors.base.white}
				/>
				<StyledSpan
					style={{
						color: theme.colors.base.white,
						fontSize: '1rem',
						fontWeight: '600',
						whiteSpace: 'nowrap',
					}}>
					{text}
				</StyledSpan>
			</FlexRowAlignCenter>
		</StyledButtonGeneric>
	);
}

export function ButtonRemoveCircle({ onClick, color = theme.colors.reds.midStrong }) {
	return (
		<StyledButtonNoStyle
			type={'button'}
			onClick={onClick}>
			<SVG
				d={actions.removeCircleD}
				width={25}
				height={25}
				fill={color}
				hoverable={true}
				hoverFill={theme.colors.reds.dark}
			/>
		</StyledButtonNoStyle>
	);
}

export function ButtonEdit({ onClick, disabled = false }) {
	return (
		<StyledButtonNoStyle
			type={'button'}
			onClick={onClick}
			disabled={disabled}>
			<SVG
				d={actions.editD}
				width={20}
				height={20}
			/>
		</StyledButtonNoStyle>
	);
}

export function ButtonBack({ onClick, text, disabled = false }) {
	return (
		<StyledButtonNoStyle
			type={'button'}
			disabled={disabled}
			style={{
				alignSelf: 'flex-start',
			}}
			onClick={onClick}>
			<FlexRowAlignCenter
				style={{
					gap: '5px',
				}}>
				<SVG
					d={arrows.arrowBackLongBoldD}
					height='20'
					width='20'
					fill={theme.colors.fonts.green}
				/>
				<StyledSpanBold>{text}</StyledSpanBold>
			</FlexRowAlignCenter>
		</StyledButtonNoStyle>
	);
}

const StyledButtonColorFrame = styled.div`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	padding: 2px;
	border-width: ${(props) => (props.selected ? '2px' : '0px')};
	border-style: solid;
	border-color: ${(props) => props.borderColor || theme.colors.base.black};
	border-radius: 5px;
	cursor: pointer;
`;

const StyledButtonColor = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.color};
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export function ButtonColor({ onClick, color, width, height, selected = false }) {
	return (
		<StyledButtonNoStyle
			onClick={onClick}
			type='button'>
			<StyledButtonColorFrame
				width={width}
				height={height}
				borderColor={theme.colors.blues.midStrong}
				selected={selected}>
				<StyledButtonColor color={color}>
					{selected && (
						<SVG
							d={state.doneD}
							fill={theme.colors.base.white}
						/>
					)}
				</StyledButtonColor>
			</StyledButtonColorFrame>
		</StyledButtonNoStyle>
	);
}

const StyledButtonIconFrame = styled.div`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	padding: 2px;
	border-width: ${(props) => (props.selected ? '2px' : '0px')};
	border-style: solid;
	border-color: ${(props) => props.borderColor || theme.colors.base.black};
	border-radius: 5px;
	cursor: pointer;
`;

export function ButtonIcon({
	onClick,
	d,
	width,
	height,
	selected = false,
	fill = theme.colors.base.black,
	hoverFill = theme.colors.base.white,
	hoverable = false,
}) {
	return (
		<StyledButtonNoStyle
			onClick={onClick}
			type='button'>
			<StyledButtonIconFrame
				width={width}
				height={height}
				borderColor={theme.colors.blues.midStrong}
				selected={selected}>
				<SVG
					d={d}
					height='100%'
					width='100%'
					fill={fill}
					hoverable={hoverable}
					hoverFill={hoverFill}
				/>
			</StyledButtonIconFrame>
		</StyledButtonNoStyle>
	);
}
