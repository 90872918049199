import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useExpanded,
} from "react-table";
import { useState, useMemo, useEffect } from "react";
import * as R from "ramda";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

// Libs
import { getOneToManyById } from "@/libs/requests.lib";
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";
import {
    convertVariableToAction,
    convertMethodToAction,
    convertActionToVariable,
    convertActionToMethod,
} from "@/libs/cheat-definitions.lib";

// Components
import PopupGeneric from "@/components/popup-generic.component";
import GlobalFilter from "@/components/filter-global.component";
import PaginationFooter from "@/components/pagination-footer.component";
import Table from "@/styles/table.styles";
import { ThDefault, ColGroups } from "@/components/table.component";
import RowCloseButton from "@/components/row-close-button.component";
import ImageRounded from "@/components/image-rounded.component";
import LoadingIcon from "@/components/loading-icon.component";

// Styles
import {
    FlexRowAlignCenter,
    FlexCol,
    StyledSpan,
    StyledTableContainer,
} from "@/styles/shared.styles";
import theme from "@/styles/theme.styles";

const headers = [
    {
        Header: "Id",
        accessor: "id",
    },
    {
        Header: "Id Description",
        accessor: "idDescription",
    },
    {
        Header: "Action Type",
        accessor: "actionType",
    },
    {
        Header: "Action Name",
        accessor: "actionName",
    },
    {
        Header: "Icon",
        accessor: "icon",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Description Name",
        accessor: "descriptionName",
    },
    {
        Header: "Description",
        accessor: "description",
    },
    {
        Header: "Description Description",
        accessor: "descriptionDescription",
    },
    {
        Header: "Id Variable Operation",
        accessor: "idVariableOperation",
    },
    {
        Header: "Data Type",
        accessor: "dataType",
    },
    {
        Header: "Params",
        accessor: "params",
    },
    {
        Header: "Default Value",
        accessor: "defaultValue",
    },
];

const StyledPopupChooseAction = styled(FlexCol)`
    width: 100%;
    height: 100%;
    padding: 10px;
    gap: 10px;
    height: 600px;
`;

const StyledSelectableTr = styled(Table.Tr)`
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.base.white};
    &:hover {
        background-color: ${(props) => props.theme.colors.grays.mid};
    }
`;

export default function PopupChooseAction({
    alreadySelectedVariables = [],
    alreadySelectedMethods = [],
    filterSelected = true,
    idGameDefinition,
    onVariableSelected = (variable) => {},
    onMethodSelected = (method) => {},
    onClose = () => {},
    onError = (error) => {},
}) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [variablesData, setVariablesData] = useState([]);
    const [methodsData, setMethodsData] = useState([]);

    useEffect(() => {
        const getUnusedSelectedVariablesAndMethods = async () => {
            setLoading(true);
            try {
                let data = await getOneToManyById({
                    endpointOwner: "gameDefinitions",
                    idOwner: idGameDefinition,
                    endpointOwned: "selectedVariableDescriptions",
                    navigate,
                });

                let actions = [];

                data.forEach((variable) => {
                    let variableReset = R.pipe(
                        R.assoc("idVariableOperation", 1)
                    )(variable);
                    actions.push(variableReset);

                    let variableAssignation = R.pipe(
                        R.assoc("idVariableOperation", 2)
                    )(variable);
                    actions.push(variableAssignation);

                    const dataType = variable.dataType;
                    if (
                        dataType === "Int" ||
                        dataType === "Float" ||
                        dataType === "Double"
                    ) {
                        let variableAddition = R.pipe(
                            R.assoc("idVariableOperation", 4)
                        )(variable);
                        actions.push(variableAddition);
                    }
                });

                actions = R.map(convertVariableToAction, actions);

                let shouldExclude = (action, selectedVariables) => {
                    return R.any(
                        (variable) =>
                            variable.idVariableDescription ===
                                action.idDescription &&
                            variable.idVariableOperation ===
                                action.idVariableOperation,
                        selectedVariables
                    );
                };

                if (filterSelected) {
                    const filteredActions = R.reject(
                        (action) =>
                            shouldExclude(action, alreadySelectedVariables),
                        actions
                    );
                    setVariablesData(filteredActions);
                } else {
                    setVariablesData(actions);
                }

                data = await getOneToManyById({
                    endpointOwner: "gameDefinitions",
                    idOwner: idGameDefinition,
                    endpointOwned: "selectedMethodDescriptions",
                    navigate,
                });

                actions = R.map(convertMethodToAction, data);

                shouldExclude = (action, selectedMethods) => {
                    return R.any(
                        (method) =>
                            method.idMethodDescription === action.idDescription,
                        selectedMethods
                    );
                };

                if (filterSelected) {
                    const filteredMethods = R.reject(
                        (action) =>
                            shouldExclude(action, alreadySelectedMethods),
                        actions
                    );
                    setMethodsData(filteredMethods);
                } else {
                    setMethodsData(actions);
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                onError(error);
            }
        };

        getUnusedSelectedVariablesAndMethods();
    }, []);

    // Filtered data after applying filters to data
    const tableData = useMemo(() => {
        return [...variablesData, ...methodsData];
    }, [variablesData, methodsData]);
    const [currentPage, setCurrentPage] = useState(0);
    const memoizedHeaders = useMemo(() => headers, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns: memoizedHeaders,
            data: tableData,
            initialState: { pageIndex: currentPage, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const headerGroup = headerGroups[0];
    const columnName = getColumnFromHeaderGroupByHeaderName(
        headerGroup,
        "Name"
    );
    const columnDescription = getColumnFromHeaderGroupByHeaderName(
        headerGroup,
        "Description"
    );

    return (
        <PopupGeneric onClickOutside={onClose}>
            <Tooltip
                id="variable-description-tooltip"
                place="right"
                effect="solid"
            />
            <StyledPopupChooseAction>
                {loading && (
                    <LoadingIcon
                        style={{
                            alignSelf: "center",
                            margin: "200px",
                        }}
                    />
                )}
                {!loading && (
                    <>
                        <RowCloseButton
                            onClose={onClose}
                            iconFill={theme.colors.base.black}
                        />
                        <FlexRowAlignCenter>
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                        </FlexRowAlignCenter>
                        <StyledTableContainer>
                            <Table {...getTableProps()}>
                                <ColGroups widths={["20%", "40%", "40%"]} />
                                <Table.THead>
                                    <Table.Tr
                                        {...headerGroups[0].getHeaderGroupProps()}>
                                        <Table.Th>Icon</Table.Th>
                                        <ThDefault
                                            column={columnName}
                                            text={columnName.render("Header")}
                                        />
                                        <ThDefault
                                            column={columnDescription}
                                            text={columnDescription.render(
                                                "Header"
                                            )}
                                        />
                                    </Table.Tr>
                                </Table.THead>
                                <Table.TBody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <StyledSelectableTr
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    if (
                                                        row.values
                                                            .actionType ===
                                                        "variable"
                                                    ) {
                                                        onVariableSelected(
                                                            convertActionToVariable(
                                                                row.values
                                                            )
                                                        );
                                                    } else if (
                                                        row.values
                                                            .actionType ===
                                                        "method"
                                                    ) {
                                                        onMethodSelected(
                                                            convertActionToMethod(
                                                                row.values
                                                            )
                                                        );
                                                    }
                                                    onClose();
                                                }}>
                                                <Table.Td>
                                                    <ImageRounded
                                                        data={row.values.icon}
                                                        imageSize="30px"
                                                    />
                                                </Table.Td>
                                                <Table.Td
                                                    style={{
                                                        wordWrap: "break-word",
                                                        whiteSpace: "normal",
                                                    }}>
                                                    <StyledSpan>
                                                        {row.values.actionName}
                                                    </StyledSpan>
                                                </Table.Td>
                                                <Table.Td>
                                                    {row.values.description}
                                                </Table.Td>
                                            </StyledSelectableTr>
                                        );
                                    })}
                                </Table.TBody>
                            </Table>
                        </StyledTableContainer>
                        <PaginationFooter
                            currentPageLength={page.length}
                            totalRows={tableData.length}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            callback={(value) => {
                                setCurrentPage(value);
                            }}
                        />
                    </>
                )}
            </StyledPopupChooseAction>
        </PopupGeneric>
    );
}
