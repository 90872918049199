import { useState } from 'react';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import SVG from '@/components/svg.component';
import { CancelSaveRow, ButtonBack } from '@/components/buttons.component';
import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import PopupError from '@/components/popup-error.component';
import LoadingIcon from '@/components/loading-icon.component';

// Assets
import { navigation } from '@/assets/icons';

// Styles
import {
	StyledContent,
	FlexRowAlignCenter,
	StyledSpanBold,
	StyledButtonGeneric,
	StyledSpan,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';

// State
import { useContext } from 'react';
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { CurrentMenuContext } from '../contexts/current-menu.context';

// Libs
import { useCustomNavigate } from '@/libs/hooks.lib';
import { useEditViewGenericProps } from '@/libs/hooks.lib';

export default function PartialSnapshotsPatchView() {
	const { navigateTo } = useCustomNavigate();
	const { cheatDefinitionsState, setCheatDefinitionsState } = useContext(CheatDefinitionsContext);
	const { currentMenuState, setCurrentMenuState } = useContext(CurrentMenuContext);

	const [loading, setLoading] = useState(true);
	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState(false);

	const { handleSubmit, isDirty, errors, control, getValues, onSubmit } = useEditViewGenericProps({
		tableName: 'partialSnapshots',
		requestType: 'PATCH',
		contentType: 'application/json',
		currentlySelectedId: currentMenuState.partialSnapshots.currentlySelectedId,
		onStartRequest: () => {
			setLoading(true);
		},
		onFinishRequest: () => {
			setLoading(false);
		},
		onSubmitSuccess: () => {
			setCurrentMenuState({
				...currentMenuState,
				partialSnapshots: {
					...currentMenuState.partialSnapshots,
					currentlySelectedId: null,
				},
			});
			navigateTo({
				mainPath: 'partialSnapshots',
				subPath: 'table',
			});
		},
		onSubmitError: (error) => {
			setPopupErrorMessage(error);
			setShowErrorPopup(true);
		},
	});

	return (
		<StyledContent
			style={{
				justifyContent: 'flex-start',
			}}>
			{showErrorPopup && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => {
						setShowErrorPopup(false);
					}}
				/>
			)}
			<ButtonBack
				text={'Back to Partial Snapshots'}
				onClick={() => {
					setCurrentMenuState({
						...currentMenuState,
						partialSnapshots: {
							...currentMenuState.partialSnapshots,
							currentlySelectedId: null,
						},
					});
					navigateTo({
						mainPath: 'partialSnapshots',
						subPath: 'table',
					});
				}}
			/>
			<FlexRowAlignCenter
				style={{
					gap: '10px',
					marginTop: '20px',
					marginBottom: '50px',
				}}>
				<SVG
					d={navigation.partialSnapshotsD}
					width={50}
					height={50}
					fill={theme.colors.greens.midStrong}
				/>
				<StyledSpanBold
					style={{
						fontSize: '30px',
					}}>
					Edit Partial Snapshot
				</StyledSpanBold>
			</FlexRowAlignCenter>
			{loading && <LoadingIcon />}
			{!loading && (
				<StyledGridForm onSubmit={handleSubmit(onSubmit)}>
					<StyledGridForm.Label>
						<FlexRowAlignCenter>
							<StyledSpan>Name</StyledSpan>
							<RequiredAsterisk />
						</FlexRowAlignCenter>
					</StyledGridForm.Label>
					<StyledGridForm.Input>
						<InputText
							control={control}
							accessor={'name'}
							editable={true}
							validation={{
								required: true,
							}}
						/>
					</StyledGridForm.Input>
					<StyledGridForm.InputError>
						<InputErrorMessage
							formErrors={errors}
							accessor={'name'}
						/>
					</StyledGridForm.InputError>
					<StyledGridForm.Gap />
					<StyledGridForm.Label>
						<FlexRowAlignCenter>
							<StyledSpan>Description</StyledSpan>
							<RequiredAsterisk />
						</FlexRowAlignCenter>
					</StyledGridForm.Label>
					<StyledGridForm.Input>
						<InputTextArea
							control={control}
							accessor={'description'}
							editable={true}
							validation={{
								required: true,
							}}
							cols={null}
							style={{
								width: '350px',
							}}
						/>
					</StyledGridForm.Input>
					<StyledGridForm.InputError>
						<InputErrorMessage
							formErrors={errors}
							accessor={'description'}
						/>
					</StyledGridForm.InputError>
					<StyledGridForm.Gap />
					<StyledGridForm.Label>Cheat Definition</StyledGridForm.Label>
					<StyledGridForm.Input>
						<StyledButtonGeneric
							type={'button'}
							hasShadow={true}
							backgroundColor={theme.colors.greens.midStrong}
							onClick={() => {
								setCheatDefinitionsState({
									...cheatDefinitionsState,
									currentlySelectedId: getValues('idCheatDefinition'),
									currentlySelectedIdGameDefinition: getValues('idGameDefinition'),
									isPartialSnapshot: true,
									requestType: 'PATCH',
								});
								navigateTo({
									mainPath: 'cheatDefinitions',
									subPath: 'edit',
								});
							}}
							style={{
								width: '100%',
							}}>
							Edit
						</StyledButtonGeneric>
					</StyledGridForm.Input>
					<StyledGridForm.Gap />
					<StyledGridForm.Gap />
					<StyledGridForm.Gap />
					<FlexRowAlignCenter
						style={{
							gridColumn: '1 / 3',
							justifyContent: 'center',
							width: '100%',
						}}>
						<CancelSaveRow
							onCancel={() => {
								setCurrentMenuState({
									...currentMenuState,
									partialSnapshots: {
										...currentMenuState.partialSnapshots,
										currentlySelectedId: null,
									},
								});
								navigateTo({
									mainPath: 'partialSnapshots',
									subPath: 'table',
								});
							}}
							saveDisabled={!isDirty}
						/>
					</FlexRowAlignCenter>
				</StyledGridForm>
			)}
		</StyledContent>
	);
}
