export function getColumnFromHeaderGroupByHeaderName(headerGroup, headerName) {
	const column = headerGroup.headers.find((header) => header.Header === headerName);
	return column;
}

const users = {
	idRole: {
		value: [],
		type: 'categoric',
		title: 'Role',
		labelKey: 'roleDescription',
		toUpperCase: false,
	},
	group: {
		value: [],
		type: 'groups',
		title: 'Groups',
		labelKey: 'group',
		toUpperCase: false,
	},
};

const devices = {
	OS: {
		value: [],
		type: 'categoric',
		title: 'OS',
		labelKey: 'OS',
	},
};

const snapshots = {
	username: {
		value: [],
		type: 'categoric',
		title: 'User',
		labelKey: 'username',
	},
	OS: {
		value: [],
		type: 'categoric',
		title: 'OS',
		labelKey: 'OS',
	},
	gameName: {
		value: [],
		type: 'categoric',
		title: 'Game',
		labelKey: 'gameName',
	},
};

const partialSnapshots = {
	gameName: {
		value: [],
		type: 'categoric',
		title: 'Game',
		labelKey: 'gameName',
	},
};

const macros = {
	gameName: {
		value: [],
		type: 'categoric',
		title: 'Game',
		labelKey: 'gameName',
	},
	OS: {
		value: [],
		type: 'categoric',
		title: 'OS',
		labelKey: 'OS',
	},
};

const cheatDefinitions = {
	gameName: {
		value: [],
		type: 'categoric',
		title: 'Game',
		labelKey: 'gameName',
	},
	group: {
		value: [],
		type: 'groups',
		title: 'Groups',
		labelKey: 'group',
		toUpperCase: false,
	},
};

const approvePushNotifications = {};

export const filters = {
	users,
	devices,
	snapshots,
	partialSnapshots,
	macros,
	cheatDefinitions,
	approvePushNotifications,
};
