// Third-party
import { set, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// Libs
import { postApplicationJson } from '@/libs/requests.lib';
import { emailPattern } from '@/libs/regex-patterns.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';

// Style
import { FlexCol, StyledFormVertical, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Components
import InputText from '@/components/input-text.component';
import InputErrorMessage from '@/components/input-error-message.component';
import { ButtonSubmit, ButtonAccept } from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import LoadingIcon from '../components/loading-icon.component';

function ResetPasswordView() {
	const { navigateToAuth } = useCustomNavigate();
	const navigate = useNavigate();

	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showPopupError, setShowPopupError] = useState(false);

	const [popupSuccessMessage, setPopupSuccessMessage] = useState('');
	const [showPopupSuccess, setShowPopupSuccess] = useState(false);

	const [loading, setLoading] = useState(false);

	const {
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = () => {
		setLoading(true);
		postApplicationJson({
			endpoint: 'authentication/resetPassword',
			body: {
				email: watch('email'),
			},
			navigate,
		})
			.then(() => {
				setLoading(false);
				setPopupSuccessMessage('Check your email for a reset code.');
				setShowPopupSuccess(true);
			})
			.catch((error) => {
				setLoading(false);
				setPopupErrorMessage(error);
				setShowPopupError(true);
			});
	};

	return (
		<FlexCol
			style={{
				justifyContent: 'flex-start',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
				padding: '180px 0px',
			}}>
			{showPopupSuccess && (
				<PopupSuccess
					message={popupSuccessMessage}
					onClose={() => {
						setShowPopupSuccess(false);
						navigateToAuth({
							subPath: 'setPassword',
						});
					}}
				/>
			)}
			{showPopupError && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => setShowPopupError(false)}
				/>
			)}
			<StyledFormVertical onSubmit={handleSubmit(onSubmit)}>
				<FlexCol
					style={{
						alignItems: 'center',
						gap: '10px',
					}}>
					<StyledSpanBold>Email</StyledSpanBold>
					<InputText
						accessor='email'
						control={control}
						type='email'
						validation={{
							required: true,
							pattern: emailPattern,
						}}
						placeholder={'Enter an email'}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor='email'
					/>
				</FlexCol>
				<ButtonSubmit text={'Get Code'} />
				<ButtonAccept
					text={'I already have a code'}
					onClick={() => navigateToAuth({ subPath: 'setPassword' })}
				/>
				<ButtonAccept
					text={'Back to Login'}
					onClick={() =>
						navigateToAuth({
							subPath: 'login',
						})
					}
					backgroundColor={theme.colors.grays.dark}
				/>
			</StyledFormVertical>
			{loading && (
				<LoadingIcon
					style={{
						marginTop: '30px',
					}}
				/>
			)}
		</FlexCol>
	);
}

export default ResetPasswordView;
