// Third-party
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import * as R from 'ramda';

// Components
import TableViewGenericProps from '@/components/table-view-generic-props.component';
import Table from '@/styles/table.styles';
import PopupGeneric from '@/components/popup-generic.component';
import UserMiniCard from '../components/user-mini-card.component';
import TableActions from '@/components/table-actions.component';
import { PillRole } from '@/components/pills.component';
import { ThDefault, ColGroups } from '@/components/table.component';

// Views
import EditUsersView from '@/views/users-edit.view';

// Utils
import { getColumnFromHeaderGroupByHeaderName } from '@/libs/tables.lib';

// Styles
import { StyledContent } from '@/styles/shared.styles';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

export default function UsersTableView() {
	const { userAuthState, refreshUserState } = useContext(UserAuthContext);
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [editRequestType, setEditRequestType] = useState('PATCH');
	const [selectedRowIdToEdit, setSelectedRowId] = useState(null);
	const [tableViewGenericPropsRandomKey, setTableViewGenericPropsRandomKey] = useState(Math.random());

	const editRow = (rowId) => {
		setEditRequestType('PATCH');
		setSelectedRowId(rowId);
		setShowEditPopup(true);
	};

	return (
		<StyledContent>
			<Tooltip
				id='user-not-active-tooltip'
				place='right'
				effect='solid'
			/>
			<TableViewGenericProps
				menuName='users'
				key={tableViewGenericPropsRandomKey}
				onAddClick={() => {
					setEditRequestType('POST');
					setSelectedRowId(null);
					setShowEditPopup(true);
				}}
				buttonAddText='New User'
				preFilteringFunction={(data) => {
					if (
						userAuthState.user.permissions.users.read.roles.length > 0 &&
						userAuthState.user.permissions.users.read.roles[0] === '*'
					) {
						return data;
					}

					const filteredData = R.filter(
						R.pipe(R.prop('roleName'), R.includes(R.__, userAuthState.user.permissions.users.read.roles))
					)(data);

					return filteredData;
				}}
				onPopupSuccessClose={() => {
					setShowEditPopup(false);
					setTableViewGenericPropsRandomKey(Math.random());
				}}>
				{({
					getTableProps,
					headerGroups,
					getTableBodyProps,
					rows,
					prepareRow,
					deleteRow,
					setPopupErrorMessage,
					setShowErrorPopup,
					setPopupSuccessMessage,
					setShowSuccessPopup,
				}) => {
					const headerGroup = headerGroups[0];
					const columnUsername = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Username');
					const columnRole = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Role');
					return (
						<>
							{showEditPopup && (
								<PopupGeneric onClickOutside={() => {}}>
									<EditUsersView
										requestType={editRequestType}
										currentlySelectedId={selectedRowIdToEdit}
										onClose={() => {
											setShowEditPopup(false);
										}}
										onSubmitSuccess={() => {
											if (selectedRowIdToEdit === userAuthState.user.id) {
												refreshUserState();
											}

											setPopupSuccessMessage('User saved successfully');
											setShowSuccessPopup(true);
										}}
										onSubmitError={(errorMessage) => {
											setPopupErrorMessage(errorMessage);
											setShowErrorPopup(true);
										}}
									/>
								</PopupGeneric>
							)}
							<Table {...getTableProps()}>
								<ColGroups widths={['70%', '20%', '10%']} />
								<Table.THead>
									<Table.Tr {...headerGroup.getHeaderGroupProps()}>
										<ThDefault
											column={columnUsername}
											text={columnUsername.render('Header')}
										/>
										<ThDefault
											column={columnRole}
											text={columnRole.render('Header')}
										/>
										<Table.Th></Table.Th>
									</Table.Tr>
								</Table.THead>
								<Table.TBody {...getTableBodyProps()}>
									{rows.map((row) => {
										prepareRow(row);
										const canBeEdited = userAuthState.user.permissions.users &&
											userAuthState.user.permissions.users.update !== false && 
											(userAuthState.user.permissions.users.update.roles[0] === '*' ||
											 userAuthState.user.permissions.users.update.roles.includes(row.values.roleName));
										return (
											<Table.Tr
												{...row.getRowProps()}
												active={row.values.active == 1}>
												<Table.Td>
													<UserMiniCard
														username={row.values.username}
														email={row.values.email}
														active={row.values.active == 1}
														avatar={row.values.avatar}
													/>
												</Table.Td>
												<Table.Td>
													<PillRole
														role={row.values.roleName}
														active={row.values.active == 1}
													/>
												</Table.Td>
												<Table.Td>
													{canBeEdited && (
															<TableActions
																onEditRow={() => {
																	editRow(row.values.idUser);
																}}
																onDeleteRow={() => {
																	deleteRow(row.values.idUser);
																}}
															/>
														)}
												</Table.Td>
											</Table.Tr>
										);
									})}
								</Table.TBody>
							</Table>
						</>
					);
				}}
			</TableViewGenericProps>
		</StyledContent>
	);
}
