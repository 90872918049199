import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputAsyncSelect from '@/components/input-async-select.component';
import InputCheckbox from '@/components/input-checkbox.component';
import InputErrorMessage from '@/components/input-error-message.component';
import SVG from '@/components/svg.component';
import { PillRole } from '@/components/pills.component';
import InputImage from '@/components/input-image.component';
import GroupMiniCard from '@/components/group-mini-card.component';
import PopupAddGroup from '@/components/popup-add-group.component';
import { ButtonEditInfo, CancelSaveRow, ButtonAdd } from '@/components/buttons.component';
import RowCloseButton from '@/components/row-close-button.component';
import LoadingIcon from '@/components/loading-icon.component';

// Assets
import { actions } from '@/assets/icons';

// Styles
import StyledPopupEditForm from '@/styles/styled-popup-edit-form.styles';
import {
	FlexCol,
	FlexRowAlignCenter,
	StyledButtonNoStyle,
	StyledHRFullWidth,
	StyledSpan,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Libs
import { useEditViewGenericProps } from '@/libs/hooks.lib';
import { get } from '@/libs/requests.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

export default function UsersEditView({
	requestType,
	currentlySelectedId,
	onClose = null,
	onSubmitSuccess = (message) => {},
	onSubmitError = (message) => {},
}) {
	const navigate = useNavigate();
	const { userAuthState } = useContext(UserAuthContext);

	const [isEditing, setIsEditing] = useState(requestType === 'POST');
	const [isPopupAddGroupOpen, setIsPopupAddGroupOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const { handleSubmit, isDirty, errors, control, getValues, setValue, onSubmit, getData, RequiredAsterisk } =
		useEditViewGenericProps({
			tableName: 'users',
			requestType,
			contentType: 'multipart/form-data',
			currentlySelectedId,
			onStartRequest: () => {
				setLoading(true);
			},
			onFinishRequest: () => {
				setLoading(false);
			},
			onSubmitSuccess,
			onSubmitError,
			defaultValues: {
				idRole: 6,
			},
		});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'groups',
	});

	const [nonSelectedGroups, setNonSelectedGroups] = useState([]);

	const getGroups = async () => {
		try {
			const response = await get({ endpoint: 'groups', navigate });
			if (response) {
				const processedGroups = R.pipe(
					R.map((group) => {
						return R.dissoc('users', group);
					}),
					R.filter((group) => !R.find(R.propEq('idGroup', group.idGroup))(fields))
				)(response);
				setNonSelectedGroups(processedGroups);
			}
		} catch (error) {
			onSubmitError(error);
		}
	};

	return (
		<>
			{isPopupAddGroupOpen && (
				<PopupAddGroup
					nonSelectedGroups={nonSelectedGroups}
					onClose={() => {
						setIsPopupAddGroupOpen(false);
					}}
					onGroupSelected={(group) => {
						append(group);
						setIsPopupAddGroupOpen(false);
					}}
				/>
			)}
			<StyledPopupEditForm onSubmit={handleSubmit(onSubmit)}>
				<FlexCol>
					{loading && (
						<LoadingIcon
							style={{
								alignSelf: 'center',
								margin: '200px 0',
							}}
						/>
					)}
					{!loading && (
						<>
							<StyledPopupEditForm.Header>
								<RowCloseButton onClose={onClose} />
								<FlexRowAlignCenter
									style={{
										gap: '20px',
									}}>
									<InputImage
										accessor={'avatar'}
										control={control}
										getValues={getValues}
										disabled={!isEditing}
										onFileError={onSubmitError}
									/>
									<FlexCol
										style={{
											alignItems: 'flex-start',
											gap: '10px',
										}}>
										{!isEditing && (
											<StyledSpan
												style={{
													fontSize: '1.3rem',
													fontWeight: 'bold',
												}}>
												{getValues('username')}
											</StyledSpan>
										)}
										{isEditing && (
											<FlexRowAlignCenter>
												<InputText
													placeholder={'Enter a username'}
													accessor={'username'}
													control={control}
													validation={{ required: true }}
												/>
												<RequiredAsterisk />
											</FlexRowAlignCenter>
										)}
										<InputErrorMessage
											formErrors={errors}
											accessor={'username'}
										/>
										{requestType === 'PATCH' && <div>{getValues('email')}</div>}
										{requestType === 'POST' && (
											<FlexRowAlignCenter>
												<InputText
													placeholder={'Enter an email'}
													accessor={'email'}
													control={control}
													validation={{ required: true }}
												/>
												<RequiredAsterisk />
											</FlexRowAlignCenter>
										)}
										<InputErrorMessage
											formErrors={errors}
											accessor={'email'}
										/>
									</FlexCol>
								</FlexRowAlignCenter>
								<StyledHRFullWidth
									style={{
										marginTop: '20px',
										backgroundColor: theme.colors.grays.light,
									}}
								/>
							</StyledPopupEditForm.Header>
							<StyledPopupEditForm.Body>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>ROLE</StyledSpan>
									{!isEditing && (
										<PillRole
											role={getValues('roleName')}
											active={getValues('active')}
										/>
									)}
									{isEditing && (
										<InputAsyncSelect
											endpoint={'roles'}
											valueColumn={'idRole'}
											labelColumn={'description'}
											accessor={'idRole'}
											control={control}
											isDisabled={false}
											validation={{ required: true }}
											setValue={setValue}
											minWidth={'200px'}
											filterOptions={
												userAuthState.user.permissions.users.read.roles.length > 0 &&
												userAuthState.user.permissions.users.read.roles[0] !== '*'
											}
											filterColumn={'name'}
											optionsToShow={userAuthState.user.permissions.users.read.roles}
											defaultOption={{ value: 6, label: 'New User' }}
											onError={onSubmitError}
										/>
									)}
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>ACTIVE</StyledSpan>
									<InputCheckbox
										accessor={'active'}
										control={control}
										editable={isEditing}
										defaultValue={1}
									/>
								</StyledPopupEditForm.LabelValuePair>
								<StyledPopupEditForm.LabelValuePair>
									<StyledSpan>GROUPS</StyledSpan>
									<Flipper flipKey={fields.map((item) => item.idGroup).join('')}>
										<FlexRowAlignCenter
											style={{
												flexWrap: 'wrap',
												gap: '10px',
											}}>
											{fields.map((item, index) => (
												<Flipped
													key={item.idGroup}
													flipId={item.idGroup}>
													<FlexRowAlignCenter
														style={{
															alignItems: 'center',
															gap: '10px',
															backgroundColor: isEditing
																? theme.colors.grays.light
																: theme.colors.base.white,
															borderRadius: '5px',
															padding: '5px 10px',
														}}>
														<GroupMiniCard
															name={item.name}
															iconSVGPath={item.iconSVGPath}
															color={item.color}
														/>
														{isEditing && (
															<StyledButtonNoStyle
																type={'button'}
																onClick={() => {
																	remove(index);
																}}>
																<SVG
																	d={actions.closeFillD}
																	width={15}
																	height={15}
																	fill={theme.colors.reds.midStrong}
																/>
															</StyledButtonNoStyle>
														)}
													</FlexRowAlignCenter>
												</Flipped>
											))}
										</FlexRowAlignCenter>
									</Flipper>
								</StyledPopupEditForm.LabelValuePair>
								<FlexRow
									style={{
										justifyContent: 'space-between',
										gap: '15px',
									}}>
									{!isEditing && (
										<>
											<div></div>
											<ButtonEditInfo
												onClick={() => {
													setIsEditing(true);
												}}
											/>
										</>
									)}
									{isEditing && (
										<>
											<ButtonAdd
												onClick={() => {
													getGroups();
													setIsPopupAddGroupOpen(true);
												}}
												text={'Add Group'}
												color={theme.colors.blues.midPastel}
											/>
											<div></div>
											<CancelSaveRow
												onCancel={() => {
													setIsEditing(false);
													getData();
												}}
												saveDisabled={!isDirty}
												showCancelButton={requestType != 'POST'}
											/>
										</>
									)}
								</FlexRow>
							</StyledPopupEditForm.Body>
						</>
					)}
				</FlexCol>
			</StyledPopupEditForm>
		</>
	);
}
