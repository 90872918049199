// Third-party
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback, useReducer } from "react";
import { useForm } from "react-hook-form";
import * as R from "ramda";

// Libs
import { get, getById, getOneToManyById } from "@/libs/requests.lib";

// Services
import { submitGeneric } from "@/libs/requests.lib";

// State
import { UserAuthContext } from "@/contexts/user-auth.context";
import { useContext } from "react";
import { CheatDefinitionsContext } from "@/contexts/cheat-definitions.context";
import { CustomNavigateContext } from "@/contexts/custom-navigate.context";
import { TableFiltersContext } from "@/contexts/table-filters.context";

// Components
import RequiredAsterisk from "@/components/required-asterisk.component";

export function useGetData({
  endpoint,
  callback,
  dependencies,
  onError = (error) => {},
}) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const data = await get({ endpoint, navigate });

        setData(data);
        if (callback != undefined) {
          callback(data);
        }
      } catch (error) {
        onError(error);
      }
    })();
  }, [dependencies]);

  return data;
}

export function useGetDataById({
  endpoint,
  id,
  callback,
  dependencies = [],
  onError = (error) => {},
}) {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (id == undefined) {
        callback({});
        return;
      }
      try {
        const data = await getById({ endpoint, id, navigate });
        setData(data);
        if (callback != undefined) {
          callback(data);
        }
      } catch (error) {
        onError(error);
      }
    })();
  }, dependencies);

  return data;
}

export function useGetOneToManyById({
  endpointOwner,
  idOwner,
  endpointOwned,
  callback,
  onError = (error) => {},
}) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (idOwner == undefined || idOwner == "") return;
      try {
        const data = await getOneToManyById({
          endpointOwner,
          idOwner,
          endpointOwned,
          navigate,
        });
        setData(data);
        if (callback) callback(data);
      } catch (error) {
        onError(error);
      }
    })();
  }, []);

  return data;
}

export function useCustomNavigate() {
  const navigate = useNavigate();
  const { resetCheatDefinitionsState } = useContext(CheatDefinitionsContext);
  const { customNavigateState, setCustomNavigateState } = useContext(
    CustomNavigateContext
  );
  const { resetTableFiltersState } = useContext(
    TableFiltersContext
  );

  const navigateTo = useCallback(
    ({ mainPath, subPath }) => {
      // This applies when we are navigating from cheatDefinitions to another mainPath
      if (customNavigateState !== null && customNavigateState.mainPath === "cheatDefinitions") {
        // If we are navigating to another mainPath, we reset the cheatDefinitionsState and the tableFiltersState
        if (mainPath !== "cheatDefinitions") {
          resetCheatDefinitionsState();
          resetTableFiltersState();
        } else if (
          // If we are not leaving cheatDefinitions, but we are moving from edit to table, we reset the cheatDefinitionsState
          customNavigateState.subPath !== "table" &&
          subPath === "table"
        ) {
          resetCheatDefinitionsState();
        }
      }

      setCustomNavigateState({ mainPath, subPath });
      navigate(`/app/${mainPath}/${subPath}`);
    },
    [customNavigateState]
  );

  const navigateToRoot = useCallback(() => {
    resetCheatDefinitionsState();
    resetTableFiltersState();
    setCustomNavigateState({ mainPath: "", subPath: "" });
    navigate(`/`);
  }, []);

  const navigateToApp = useCallback(() => {
    resetCheatDefinitionsState();
    resetTableFiltersState();
    setCustomNavigateState({ mainPath: "", subPath: "" });
    navigate(`/app/welcome`);
  }, []);

  const navigateToAuth = useCallback(({ subPath }) => {
    resetCheatDefinitionsState();
    resetTableFiltersState();
    setCustomNavigateState({ mainPath: "", subPath: "" });
    navigate(`/auth/${subPath}`);
  }, []);

  return {
    navigate,
    navigateTo,
    navigateToRoot,
    navigateToApp,
    navigateToAuth,
  };
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideClickHandler({ ref, onClickOutside }) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useEditViewGenericProps({
  tableName,
  requestType,
  contentType,
  currentlySelectedId,
  defaultValues = {},
  defaultExtraData = {},
  onStartRequest = () => {},
  onFinishRequest = () => {},
  onSubmitSuccess = () => {},
  onSubmitError = (error) => {},
}) {
  const navigate = useNavigate();
  const { userAuthState } = useContext(UserAuthContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    control,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues,
  });

  const getData = useCallback(async () => {
    onStartRequest();
    try {
      if (currentlySelectedId === null || currentlySelectedId === undefined) {
        onFinishRequest();
        return;
      }

      const data = await getById({
        endpoint: tableName,
        id: currentlySelectedId,
        navigate,
      });

      const parsedData = R.mapObjIndexed((value, key) => {
        try {
          return JSON.parse(value);
        } catch (error) {
          return value;
        }
      })(data[0]);

      reset({ ...parsedData });
      onFinishRequest();
    } catch (error) {
      onFinishRequest();
      onSubmitError(error);
    }
  }, [tableName, currentlySelectedId]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const [extraData, dispatchExtraData] = useReducer((state, newState) => {
    return { ...state, ...newState };
  }, defaultExtraData);

  const onSubmit = useCallback(
    async (data) => {
      onStartRequest();
      try {
        await submitGeneric({
          endpoint: tableName,
          data: { ...data, ...extraData },
          id: currentlySelectedId,
          contentType,
          requestType,
          idUser: userAuthState.user.id,
          navigate,
        });

        onSubmitSuccess();

        onFinishRequest();
      } catch (error) {
        onSubmitError(error);

        onFinishRequest();
      }
    },
    [extraData, requestType]
  );

  return {
    register,
    handleSubmit,
    reset,
    errors,
    isDirty,
    control,
    setValue,
    getValues,
    onSubmit,
    extraData,
    dispatchExtraData,
    setError,
    getData,
    RequiredAsterisk,
  };
}
