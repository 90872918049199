export const colors = [
	'#ED3D34',
	'#DF1B61',
	'#9524AE',
	'#1E90ED',
	'#1B82DF',
	'#02A5F1',
	'#00BAD2',
	'#009283',
	'#48AA4C',
	'#84BB46',
	'#C8D534',
	'#FFB703',
	'#FD9000',
	'#F75523',
	'#735043',
	'#5E7C89',
	'#FFBFD6',
	'#E98CAD',
	'#D86A91',
	'#C14D76',
	'#B22A5A',
	'#961341',
];
