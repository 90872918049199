import { createContext, useState, useEffect } from 'react';
import { getById } from '@/libs/requests.lib';
import permissions from '@/libs/permissions.lib';

export const UserAuthContext = createContext();

const initialUserAuthState = {
	user: {
		id: null,
		username: null,
		idRole: null,
		roleName: null,
		avatar: null,
		permissions: {},
		groups: [],
	},
};

export const UserAuthContextProvider = ({ children }) => {
	const [userAuthState, setUserAuthState] = useState(JSON.parse(localStorage.getItem('userAuthState')) || initialUserAuthState);
	
	useEffect(() => {
		localStorage.setItem('userAuthState', JSON.stringify(userAuthState));
	}, [userAuthState]);

	const refreshUserState = async () => {
		const newUser = await getById({ endpoint: 'users', id: userAuthState.user.id, navigate: undefined });
		setUserAuthState({
			...userAuthState,
			user: {
				id: newUser[0].idUser,
				username: newUser[0].username,
				idRole: newUser[0].idRole,
				roleName: newUser[0].roleName,
				avatar: newUser[0].avatar,
				permissions: permissions[newUser[0].roleName],
				groups: newUser[0].groups,
			},
		});

		return newUser;
	};

	return (
		<UserAuthContext.Provider value={{ userAuthState, setUserAuthState, refreshUserState }}>
			{children}
		</UserAuthContext.Provider>
	);
};
