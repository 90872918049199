import styled from 'styled-components';

// Components
import PopupGeneric from '@/components/popup-generic.component';
import { ButtonCancel, ButtonAccept } from '@/components/buttons.component';

// Styles
import { FlexCol, FlexRowAlignCenter, StyledSpan, StyledSpanBold } from '@/styles/shared.styles';

const StyledPopupCancelConfirm = styled(FlexCol)`
	border: 1px solid ${(props) => props.theme.colors.blues.midPastel};
	border-radius: 5px;
	width: 100%;
	height: 100%;
	padding: 25px;
	gap: 10px;
`;

function PopupCancelConfirm({ onCancel, onConfirm, title = null, message = '' }) {
	return (
		<PopupGeneric onClickOutside={onCancel}>
			<StyledPopupCancelConfirm>
				{title && <StyledSpanBold>{title}</StyledSpanBold>}
				<StyledSpan>{message}</StyledSpan>
				<FlexRowAlignCenter
					style={{
						width: '100%',
						justifyContent: 'center',
						gap: '20px',
						marginTop: '20px',
					}}>
					<ButtonCancel
						onClick={onCancel}
						hasIcon={false}
						hasLabel={true}
					/>
					<ButtonAccept onClick={onConfirm} />
				</FlexRowAlignCenter>
			</StyledPopupCancelConfirm>
		</PopupGeneric>
	);
}

export default PopupCancelConfirm;
