// Assets
import { arrows } from '@/assets/icons';

// Styles
import { FlexCol } from '@/styles/shared.styles';

export default function SortedSign({ isSorted, isSortedDesc }) {
	return (
		<FlexCol
			style={{
				gap: '5px',
			}}>
			<img
				src={
					isSortedDesc
						? isSortedDesc
							? arrows.arrowDropUpPngRed
							: arrows.arrowDropUpPngGray
						: arrows.arrowDropUpPngGray
				}
				style={{
					height: '6px',
					width: '12px',
				}}
			/>
			<img
				src={
					isSorted
						? !isSortedDesc
							? arrows.arrowDropDownPngRed
							: arrows.arrowDropDownPngGray
						: arrows.arrowDropDownPngGray
				}
				style={{
					height: '6px',
					width: '12px',
				}}
			/>
		</FlexCol>
	);
}
