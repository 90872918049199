import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import {Form, useNavigate} from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';
import * as R from 'ramda';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputAsyncSelectGameAndVersion from '@/components/input-async-select-game-and-version.component';
import InputErrorMessage from '@/components/input-error-message.component';
import SVG from '@/components/svg.component';
import {
    CancelSaveRow,
    ButtonBack,
    ButtonIcon,
} from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import GroupMiniCard from '@/components/group-mini-card.component';
import PopupAddGroup from '@/components/popup-add-group.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import LoadingIcon from '@/components/loading-icon.component';
import PopupGeneric from '@/components/popup-generic.component';
import CheatKeysEditView from '@/views/cheat-keys-edit.view'

// Assets
import { navigation, actions, alerts } from '@/assets/icons';

// Styles
import {
    StyledContent,
    FlexRowAlignCenter,
    StyledSpanBold,
    StyledSpan,
    StyledButtonGeneric,
    StyledButtonNoStyle,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';

// Requests
import { getById, getOneToManyById } from '@/libs/requests.lib';

// State
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Libs
import {
    getFormattedMethodsData,
    getFormattedVariablesData,
    getArgumentsFromVariablesAndMethodsValues
} from '@/libs/cheat-definitions.lib';
import {
    postApplicationJson,
    patchApplicationJsonById,
} from '@/libs/requests.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';
import { get } from '@/libs/requests.lib';

function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function PopupNewCodeShortcut({onClose}) {
    const { navigateTo } = useCustomNavigate();
    const navigate = useNavigate();

    const { userAuthState } = useContext(UserAuthContext);
    const {
        cheatDefinitionsState,
        setCheatDefinitionsState,
        resetCheatDefinitionsState,
    } = useContext(CheatDefinitionsContext);

    const [argumentsValues, setArgumentsValues] = useState([]);

    const [loading, setLoading] = useState(true);
    const [showPopupAddGroup, setShowPopupAddGroup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPopupError, setShowPopupError] = useState(false);
    const [showPopupSuccess, setShowPopupSuccess] = useState(false);
    const [hasValidCode, setHasValidCode] = useState(true);

    const {
        handleSubmit,
        formState: { errors, isDirty },
        control,
        setValue,
        watch,
        getValues,
        reset,
    } = useForm({
        active: true,
    });

    const idGameDefinition = watch('idGameDefinition', cheatDefinitionsState.currentlySelectedIdGameDefinition);

    useEffect(() => {
        async function loadCheatDefinitionData() {
            setLoading(true);
            try {
                let cheatDefinitionsState_ = cheatDefinitionsState;
                
                let cheatDefinition = await getById({
                    endpoint: 'cheatDefinitions',
                    id: cheatDefinitionsState_.currentlySelectedId,
                    navigate,
                });

                let cheatKeys = await getOneToManyById({
                    endpointOwner: 'cheatDefinitions',
                    idOwner: cheatDefinitionsState_.currentlySelectedId,
                    endpointOwned: 'cheatKeys',
                    navigate,
                });
                
                cheatKeys = R.map((cheatKey) => {
                    return {
                        ...cheatKey,
                        overrideMethodParams: cheatKey.overrideMethodParams === null ? [] : JSON.parse('[' +
                            cheatKey.overrideMethodParams + ']'
                        ),
                        overrideVariables: cheatKey.overrideVariables === null ? [] : JSON.parse('[' +
                            cheatKey.overrideVariables + ']'
                        ),
                    }
                }, cheatKeys);

                const variables = await getOneToManyById({
                    endpointOwner: 'cheatDefinitions',
                    idOwner: cheatDefinitionsState_.currentlySelectedId,
                    endpointOwned: 'variableDescriptions',
                    navigate,
                });
                const formattedVariables =
                    getFormattedVariablesData(variables);

                const methods = await getOneToManyById({
                    endpointOwner: 'cheatDefinitions',
                    idOwner: cheatDefinitionsState_.currentlySelectedId,
                    endpointOwned: 'methodDescriptions',
                    navigate,
                });
                const formattedMethods =
                    getFormattedMethodsData(methods);
                
                setArgumentsValues(getArgumentsFromVariablesAndMethodsValues(formattedVariables, formattedMethods));
                
                console.log(formattedMethods);
                
                setCheatDefinitionsState({
                    ...cheatDefinitionsState_,
                    cheatDefinition: cheatDefinition[0],
                    cheatKeys,
                    variables: formattedVariables,
                    methods: formattedMethods,
                });

                setLoading(false);
                
            } catch (error) {
                setLoading(false);
                setErrorMessage(error);
                setShowPopupError(true);
            }
        }

        loadCheatDefinitionData();
    }, []);

    const onSubmit = async (data) => {
        
        let cheatKeys_ = cheatDefinitionsState.cheatKeys;
        cheatKeys_.push(data);
        
        setLoading(true);
        const finalData = {
            ...cheatDefinitionsState,
            ...cheatDefinitionsState.cheatDefinition,
            cheatKeys: cheatKeys_,
        };
        
        const requestFunction = patchApplicationJsonById({
            endpoint: 'cheatDefinitions',
            id: cheatDefinitionsState.currentlySelectedId,
            body: finalData,
            idUser: userAuthState.user.id,
            navigate,
        });

        try {
            await requestFunction;

            setShowPopupSuccess(true);
            setLoading(false);
        } catch (error) {
            setErrorMessage(error);
            setShowPopupError(true);
            setLoading(false);
        }
    };
    
    return <>
        <PopupGeneric 
            onClickOutside={onClose}
        >
            {showPopupError && (
                <PopupError
                    error={errorMessage}
                    onClose={() => {
                        setShowPopupError(false);
                    }}
                />
            )}
            {showPopupSuccess && (
                <PopupSuccess 
                    message={"Success!"}
                    onClose={onClose}
                />
            )}
            <CheatKeysEditView 
                data={{}}
                cheatArguments={argumentsValues}
                existingCheatKeys={cheatDefinitionsState.cheatKeys}
                onSubmit={onSubmit}
                onClose={onClose}
            />
        </PopupGeneric>
    </>;
}
