// Third-party
import * as R from 'ramda';

// Components
import InputAsyncSelect from '@/components/input-async-select.component';

export default function InputAsyncSelectVersion({
	control,
	idGame,
	idGameDefinition,
	setValue,
	disabled = false,
	validation = {},
	onMaxVersionSelected = (idGameDefinition) => {},
	onChange = (val) => {},
	onError = (error) => {},
}) {
	return (
		<InputAsyncSelect
			endpoint={'games'}
			isManyToMany={true}
			id={idGame}
			endpointOwned={'gameDefinitions'}
			valueColumn='idGameDefinition'
			labelColumn='version'
			accessor='idGameDefinition'
			setValue={setValue}
			control={control}
			isDisabled={disabled || idGame === undefined}
			sortingFunction={compareVersions}
			onLoadOptions={(data) => {
				if (disabled && idGameDefinition !== undefined && idGameDefinition !== null) return;

				const idGameDefinitionOfMaxVersion = R.pipe(
					R.sort(compareVersions), // Sort using the custom comparator
					R.head, // Get the first element
					R.prop('idGameDefinition') // Get the idGameDefinition
				)(data);
				onMaxVersionSelected(idGameDefinitionOfMaxVersion);
				setValue('idGameDefinition', idGameDefinitionOfMaxVersion);
			}}
			onChanged={onChange}
			validation={validation}
			onError={onError}
		/>
	);
}

const versionStringToNumbers = R.pipe(
	R.split('.'), // Split the version string into an array of strings
	R.map(Number) // Convert each string to a number
);

const compareVersions = (a, b) => {
	const versionA = versionStringToNumbers(a.version);
	const versionB = versionStringToNumbers(b.version);

	for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
		if ((versionA[i] || 0) > (versionB[i] || 0)) return -1;
		if ((versionA[i] || 0) < (versionB[i] || 0)) return 1;
	}

	return 0;
};
