import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { Flipper, Flipped } from 'react-flip-toolkit';
import * as R from 'ramda';
import styled from 'styled-components';

// Components
import { FlexRow } from '@/styles/shared.styles';
import InputText from '@/components/input-text.component';
import InputCheckbox from '@/components/input-checkbox.component';
import InputErrorMessage from '@/components/input-error-message.component';
import SVG from '@/components/svg.component';
import { PillRole } from '@/components/pills.component';
import InputImage from '@/components/input-image.component';
import GroupMiniCard from '../components/group-mini-card.component';
import PopupAddGroup from '../components/popup-add-group.component';
import { ButtonAdd, ButtonSave } from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import LoadingIcon from '@/components/loading-icon.component';

// Assets
import { actions } from '@/assets/icons';

// Styles
import {
	FlexCol,
	FlexRowAlignCenter,
	StyledButtonNoStyle,
	StyledContent,
	StyledSpanBold,
	StyledSpan,
	StyledHRFullWidth,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Libs
import { get, getById, submitGeneric } from '@/libs/requests.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

const StyledProfileEditView = styled.form`
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px 0px 0px 0px;
	gap: 10px;
`;

export default function ProfileEditView() {
	const { navigate } = useCustomNavigate();
	const { userAuthState, refreshUserState } = useContext(UserAuthContext);

	const [loading, setLoading] = useState(false);
	const [randomKey, setRandomKey] = useState(Math.random());
	const [isPopupErrorOpen, setIsPopupErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isPopupSuccessOpen, setIsPopupSuccessOpen] = useState(false);
	const [isPopupAddGroupOpen, setIsPopupAddGroupOpen] = useState(false);
	const canModifyGroups = R.includes(userAuthState.user.roleName, ['root', 'qaPrograLeader', 'socialMediaLeader', 'playerSupportLeader']);

	const [username, setUsername] = useState('');

	const {
		handleSubmit,
		formState: { errors, isDirty },
		control,
		getValues,
		reset,
	} = useForm({});

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const data = await getById({ endpoint: 'users', id: userAuthState.user.id, navigate });
				const parsedData = R.mapObjIndexed((value, key) => {
					try {
						return JSON.parse(value);
					} catch (error) {
						return value;
					}
				})(data[0]);

				setUsername(parsedData.username);

				reset({ ...parsedData });
				setLoading(false);
			} catch (error) {
				setErrorMessage(error);
				setIsPopupErrorOpen(true);
				setLoading(false);
			}
		})();
	}, []);

	const onSubmit = useCallback(async (data) => {
		setLoading(true);
		try {
			await submitGeneric({
				endpoint: 'users',
				data,
				id: userAuthState.user.id,
				contentType: 'multipart/form-data',
				requestType: 'PATCH',
				idUser: userAuthState.user.id,
				navigate,
			});

			const newUser = await refreshUserState();

			setUsername(newUser[0].username);
			setRandomKey(Math.random());

			setIsPopupSuccessOpen(true);
			setLoading(false);
		} catch (error) {
			setErrorMessage(error);
			setIsPopupErrorOpen(true);
			setLoading(false);
		}
	}, []);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'groups',
	});

	const [nonSelectedGroups, setNonSelectedGroups] = useState([]);

	const getGroups = async () => {
		const response = await get({ endpoint: 'groups', navigate });
		if (response) {
			const processedGroups = R.pipe(
				R.map((group) => {
					return R.dissoc('users', group);
				}),
				R.filter((group) => !R.find(R.propEq('idGroup', group.idGroup))(fields))
			)(response);
			setNonSelectedGroups(processedGroups);
		}
	};

	return (
		<StyledContent
			style={{
				justifyContent: 'flex-start',
				gap: '20px',
			}}>
			{isPopupErrorOpen && (
				<PopupError
					error={errorMessage}
					onClose={() => {
						setIsPopupErrorOpen(false);
					}}
				/>
			)}
			{isPopupSuccessOpen && (
				<PopupSuccess
					message={'Profile Updated Successfully'}
					onClose={() => {
						setIsPopupSuccessOpen(false);
					}}
				/>
			)}
			<StyledProfileEditView
				onSubmit={handleSubmit(onSubmit)}
				key={randomKey}>
				<StyledSpanBold
					style={{
						fontSize: '1.5rem',
						fontWeight: 'bold',
					}}>
					Edit Profile
				</StyledSpanBold>
				<StyledHRFullWidth />
				<FlexRowAlignCenter
					style={{
						gap: '20px',
						marginTop: '15px',
						marginBottom: '5px',
					}}>
					<InputImage
						style={{ padding: '0px' }}
						accessor={'avatar'}
						control={control}
						getValues={getValues}
						disabled={false}
					/>
					<FlexCol
						style={{
							gap: '10px',
						}}>
						<StyledSpanBold>{username}</StyledSpanBold>
						<StyledSpan>{getValues('email')}</StyledSpan>
					</FlexCol>
				</FlexRowAlignCenter>
				<FlexCol
					style={{
						gap: '10px',
					}}>
					<StyledSpanBold
						style={{
							fontSize: '0.9rem',
						}}>
						ROLE
					</StyledSpanBold>
					<PillRole
						width={null}
						role={getValues('roleName')}
						active={getValues('active')}
					/>
				</FlexCol>
				<FlexCol
					style={{
						gap: '10px',
						marginTop: '10px',
						marginBottom: '5px',
					}}>
					<StyledSpanBold
						style={{
							fontSize: '0.9rem',
						}}>
						USERNAME
					</StyledSpanBold>
					<InputText
						placeholder={'Enter a Username'}
						accessor={'username'}
						control={control}
						validation={{ required: true }}
					/>
					<InputErrorMessage
						formErrors={errors}
						accessor={'username'}
					/>
				</FlexCol>
				<StyledHRFullWidth />
				<FlexRowAlignCenter
					style={{
						gap: '15px',
						marginTop: '15px',
						marginBottom: '10px',
					}}>
					<InputCheckbox
						accessor={'active'}
						control={control}
						editable={false}
						defaultValue={1}
					/>
					<StyledSpanBold
						style={{
							fontSize: '0.9rem',
						}}>
						ACTIVE
					</StyledSpanBold>
				</FlexRowAlignCenter>
				<FlexCol
					style={{
						flexGrow: 1,
						gap: '15px',
					}}>
					<StyledSpanBold
						style={{
							fontSize: '0.9rem',
						}}>
						GROUPS
					</StyledSpanBold>
					<FlexRow
						style={{
							flexGrow: 1,
							alignItems: 'flex-start',
						}}>
						<Flipper flipKey={fields.map((item) => item.idGroup).join('')}>
							<FlexRowAlignCenter
								style={{
									flexWrap: 'wrap',
									gap: '10px',
									width: '250px',
								}}>
								{fields.map((item, index) => (
									<Flipped
										key={item.idGroup}
										flipId={item.idGroup}>
										<FlexRowAlignCenter
											style={{
												justifyContent: 'space-between',
												gap: '10px',
												backgroundColor: theme.colors.grays.light,
												borderRadius: '5px',
												padding: '5px',
											}}>
											<GroupMiniCard
												name={item.name}
												iconSVGPath={item.iconSVGPath}
												color={item.color}
												iconSize='15'
												fontSize='0.8rem'
											/>
											{canModifyGroups && (
												<StyledButtonNoStyle
													type={'button'}
													onClick={() => {
														remove(index);
													}}>
													<SVG
														d={actions.closeFillD}
														width={15}
														height={15}
														fill={theme.colors.reds.midStrong}
													/>
												</StyledButtonNoStyle>
											)}
										</FlexRowAlignCenter>
									</Flipped>
								))}
							</FlexRowAlignCenter>
						</Flipper>
						<FlexCol
							style={{
								position: 'relative',
								width: '180px',
								justifyContent: 'flex-start',
								height: 'auto',
							}}>
							{canModifyGroups && (
								<ButtonAdd
									onClick={() => {
										getGroups();
										setIsPopupAddGroupOpen(true);
									}}
									text={'Add Group'}
									color={theme.colors.blues.midPastel}
								/>
							)}
							{isPopupAddGroupOpen && (
								<PopupAddGroup
									style={{
										top: '100%',
										left: '0%',
										transform: 'translate(0%, 0%)',
									}}
									nonSelectedGroups={nonSelectedGroups}
									onClose={() => {
										setIsPopupAddGroupOpen(false);
									}}
									onGroupSelected={(group) => {
										append(group);
										setIsPopupAddGroupOpen(false);
									}}
								/>
							)}
						</FlexCol>
					</FlexRow>
				</FlexCol>
				{loading && (
					<FlexRowAlignCenter
						style={{
							width: '100%',
							justifyContent: 'flex-end',
						}}>
						<LoadingIcon />
					</FlexRowAlignCenter>
				)}
				{!loading && (
					<ButtonSave
						style={{
							width: '100px',
							alignSelf: 'flex-end',
						}}
						disabled={!isDirty}
					/>
				)}
			</StyledProfileEditView>
		</StyledContent>
	);
}
