import styled from 'styled-components';

const StyledSVG = styled.svg`
	&:hover {
		fill: ${(props) => (props.hoverable ? props.hoverFill : props.fill)} !important;
	}
`;

export default function SVG({
	d,
	fill = '#606959',
	hoverFill = '#000000',
	hoverable = false,
	height = '32',
	width = '32',
	viewBox = '0 -960 960 960',
	style = {},
	...props
}) {
	return (
		<StyledSVG
			{...props}
			style={{
				...style,
			}}
			hoverFill={hoverFill}
			hoverable={hoverable}
			fill={fill}
			height={height}
			viewBox={viewBox}
			width={width}>
			<path d={d} />
		</StyledSVG>
	);
}
