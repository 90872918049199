import styled from 'styled-components';

// Assets
import { actions, alerts, groupIcons } from '@/assets/icons';

// Components
import SVG from '@/components/svg.component';

// Styles
import { StyledSpan, StyledHRFullWidth, FlexCol } from '@/styles/shared.styles';

const StyledFooter = styled.div`
	grid-area: footer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	padding-left: 40px;
	margin-top: 8px;
`;

const StyledA = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export default function Footer() {
	return (
		<FlexCol>
			<StyledHRFullWidth />
			<StyledFooter>
				<StyledA target='_blank'
					href='https://docs.google.com/document/d/1GMZNUgpCeKRXxnf7UEJX-M56HkzY88RLlG48MHIxBwc/edit?usp=sharing'
				>
					<SVG
						d={alerts.helpD}
						height={'24'}
						width={'24'}
					/>
				</StyledA>
				<StyledA target='_blank'
					href='https://docs.google.com/forms/d/e/1FAIpQLSfgF7E2dpTRKDJG6qbLVMMoqI_PfROJDVkwK65NrDxfTopXfg/viewform?usp=pp_url&entry.1156503059=1.+Feedback/bug+sobre+una+herramienta+actual&entry.1438833316=Sitio+de+cheats&entry.396575512=Cheats+System+(Snapshots+%26+Macros)&entry.715782456=1.1.0&entry.1858380602=Un+error+(bug)'
				>
					<SVG
						d={actions.chatD}
						height={'24'}
						width={'24'}
					/>
				</StyledA>
				<StyledA target='_blank'
					href='https://trello.com/b/m8BhaCkt/sistema-de-cheats'
				>
					<SVG
						d={groupIcons.bug}
						height={'24'}
						width={'24'}
					/>
				</StyledA>
				<StyledSpan>1.1.0</StyledSpan>
			</StyledFooter>
		</FlexCol>
	);
}
