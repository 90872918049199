// Third-party
import React, { useState } from "react";
import * as R from "ramda";
import { CSVLink } from "react-csv";

// Components
import TableViewGenericProps from "@/components/table-view-generic-props.component";
import TableActions from "@/components/table-actions.component";
import GenericMiniCard from "@/components/generic-mini-card.component";
import { ThDefault, ColGroups } from "@/components/table.component";
import SVG from "@/components/svg.component";
import PopupShowCheatKeys from "@/components/popup-show-cheat-keys.component";
import { ButtonAccept } from "@/components/buttons.component";
import PopupNewCodeShortcut from "@/components/popup-new-code-shortcut.component";

// Libs
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";
import { useCustomNavigate } from "@/libs/hooks.lib";

// Styles
import Table from "@/styles/table.styles";
import {
  StyledContent,
  StyledSpan,
  StyledSpanBold,
  StyledButtonNoStyle,
  FlexCol,
} from "@/styles/shared.styles";
import theme from "@/styles/theme.styles";

// Assets
import { arrows } from "@/assets/icons";

// State
import { useContext } from "react";
import { CheatDefinitionsContext } from "@/contexts/cheat-definitions.context";
import { TableFiltersContext } from "@/contexts/table-filters.context";
import { UserAuthContext } from '@/contexts/user-auth.context';

export default function CheatDefinitionsTableView() {
  const { userAuthState } = useContext(UserAuthContext);

  const { cheatDefinitionsState, setCheatDefinitionsState } = useContext(
    CheatDefinitionsContext
  );
  const { tableFiltersState, setTableFiltersState } = useContext(TableFiltersContext);
  const { navigateTo } = useCustomNavigate();

  const [showCheatKeysPopup, setShowCheatKeysPopup] = useState(false);
  const [showNewCodeShortcutPopup, setShowNewCodeShortcutPopup] = useState(false);
  const [cheatKeysForPopup, setCheatKeysForPopup] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [randomizedKey, setRandomizedKey] = useState(Math.random());

  const editRow = (rowId, idGameDefinition, idGame) => {
    setCheatDefinitionsState({
      ...cheatDefinitionsState,
      requestType: "PATCH",
      currentlySelectedId: rowId,
      currentlySelectedIdGameDefinition: idGameDefinition,
      currentlySelectedIdGame: idGame,
    });
    navigateTo({
      mainPath: "cheatDefinitions",
      subPath: "edit",
    });
  };

  const addRow = () => {
    setCheatDefinitionsState({
      ...cheatDefinitionsState,
      requestType: "POST",
      currentlySelectedId: null,
      currentlySelectedIdGameDefinition: null,
      currentlySelectedIdGame: null,
    });
    navigateTo({
      mainPath: "cheatDefinitions",
      subPath: "edit",
    });
  };

  return (
    <StyledContent>
      {showCheatKeysPopup && (
        <PopupShowCheatKeys
          data={cheatKeysForPopup}
          onClose={() => {
            setShowCheatKeysPopup(false);
          }}
        />
      )}
      {showNewCodeShortcutPopup && (
          <PopupNewCodeShortcut 
            onClose={() => {
              setShowNewCodeShortcutPopup(false);
              setRandomizedKey(Math.random());
            }}
          />
      )}
      <TableViewGenericProps 
          key={randomizedKey}
        menuName="cheatDefinitions"
        buttonAddText="New Code Batch"
        onAddClick={() => {
          addRow();
        }}
        defaultFilters={tableFiltersState.cheatDefinitions.currentFilters}
        onFiltersChanged={(filters) => {
          setTableFiltersState({
            ...tableFiltersState,
            cheatDefinitions: {
              currentFilters: filters,
            },
          })
        }}
        onGlobalFilterChanged={(value) => {
          setGlobalFilterValue(value);
        }}
        preFilteringFunction={(data) => {
          // If user is root, return all data
          if (userAuthState.user.permissions.cheatDefinitions.read === true) {
            return data;
          }

          const groupsInUser = userAuthState.user.groups;

          // Only return the cheat definitions that have at least one group in common with the user
          const filteredData = R.filter(
            R.pipe(
              R.prop("groups"),
              R.intersection(groupsInUser),
              R.isEmpty,
              R.not
            )
          )(data);

          return filteredData;
        }}
        globalFilterValue={globalFilterValue}
        extraGlobalFilterFunction={(data) => {
          if (R.isEmpty(globalFilterValue)) {
            return data;
          }

          const lowerCaseGlobalFilterValue = globalFilterValue.toLowerCase();

          return R.filter((row) => {
                let matchFoundName = row.name == null ? false : row.name.toLowerCase().includes(lowerCaseGlobalFilterValue);

                let matchFoundCheatKey = false;
                //iterate cheatKeys, where each cheatKey is an object
                for (let i = 0; i < row.cheatKeys.length; i++) {
                  const cheatKey = row.cheatKeys[i];
                  const lowerCaseKey = cheatKey.key.toLowerCase();
                  if (lowerCaseKey.includes(lowerCaseGlobalFilterValue)) {
                    matchFoundCheatKey = true;
                    break;
                  }
                }

                return matchFoundName || matchFoundCheatKey;
              }
          )(data);
        }}
      >
        {({
          getTableProps,
          headerGroups,
          getTableBodyProps,
          rows,
          prepareRow,
          deleteRow,
        }) => {
          const headerGroup = headerGroups[0];
          const columnGame = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Game"
          );
          const columnName = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Name"
          );
          const columnActive = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Active"
          );
          return (
            <Table {...getTableProps()}>
              <ColGroups widths={["5%", "15%", "20%", "7.5%", "7.5%", "5%"]} />
              <Table.THead>
                <Table.Tr {...headerGroup.getHeaderGroupProps()}>
                  <Table.Th></Table.Th>
                  <ThDefault
                    column={columnGame}
                    text={columnGame.render("Header")}
                  />
                  <ThDefault
                    column={columnName}
                    text={columnName.render("Header")}
                  />
                  <ThDefault column={columnActive} text={"Is Active"} />
                  <Table.Th></Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.THead>
              <Table.TBody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.id}>
                      <Table.Tr
                        {...row.getRowProps()}
                        active={row.values.active == 1}
                      >
                        <Table.Td>
                          {row.isExpanded ? (
                            <StyledButtonNoStyle
                              onClick={() => row.toggleRowExpanded()}
                            >
                              <SVG
                                d={arrows.arrowDownD}
                                width={30}
                                height={30}
                              />
                            </StyledButtonNoStyle>
                          ) : (
                            <StyledButtonNoStyle
                              onClick={() => row.toggleRowExpanded()}
                            >
                              <SVG
                                d={arrows.arrowForwardD}
                                width={20}
                                height={20}
                              />
                            </StyledButtonNoStyle>
                          )}
                        </Table.Td>
                        <Table.Td>
                          <GenericMiniCard
                            image={row.values.gameImage}
                            topText={row.values.gameName}
                            bottomText={row.values.gameVersion}
                          />
                        </Table.Td>
                        <Table.Td>
                          <StyledSpan>{row.values.name}</StyledSpan>
                        </Table.Td>
                        <Table.Td>
                          <StyledSpan>
                            {row.values.active == 1 ? "True" : "False"}
                          </StyledSpan>
                        </Table.Td>
                        <Table.Td>
                          <ButtonAccept 
                            text={"Add New Code"}
                            onClick={() => {
                              setCheatDefinitionsState({
                                ...cheatDefinitionsState,
                                currentlySelectedId: row.values.idCheatDefinition,
                                currentlySelectedIdGameDefinition: row.values.idGameDefinition,
                                currentlySelectedIdGame: row.values.idGame,
                              });
                              setShowNewCodeShortcutPopup(true);
                            }}
                          />
                        </Table.Td>
                        <Table.Td>
                          {userAuthState.user.permissions.cheatDefinitions
                            .update !== false && (
                            <TableActions
                              onEditRow={() => {
                                editRow(
                                  row.values.idCheatDefinition,
                                  row.values.idGameDefinition,
                                  row.values.idGame
                                );
                              }}
                              onDeleteRow={() => {
                                deleteRow(row.values.idCheatDefinition);
                              }}
                            />
                          )}
                        </Table.Td>
                      </Table.Tr>
                      {row.isExpanded && (
                        <Table.Tr key={`${index}_bottom`}>
                          <Table.Td
                            colSpan={4}
                            style={{
                              padding: "20px",
                            }}
                          >
                            <FlexCol
                              style={{
                                gap: "10px",
                              }}
                            >
                              <StyledSpan>
                                <StyledSpanBold>{`Description: `}</StyledSpanBold>
                                {row.values.description}
                              </StyledSpan>
                              <ButtonAccept
                                text={"Show Codes"}
                                style={{
                                  width: "200px",
                                  marginTop: "10px",
                                }}
                                onClick={() => {
                                  setCheatKeysForPopup(row.values.cheatKeys);
                                  setShowCheatKeysPopup(true);
                                }}
                              />
                              <CSVLink
                                data={row.values.cheatKeys}
                                filename={`${row.values.idCheatDefinition}_${row.values.name}_codes.csv`}
                                target={"_blank"}
                                style={{ textDecoration: "none" }}
                              >
                                <ButtonAccept
                                  text={"Export Codes as CSV"}
                                  style={{
                                    width: "200px",
                                  }}
                                  onClick={() => {}}
                                  backgroundColor={
                                    theme.colors.oranges.midStrong
                                  }
                                />
                              </CSVLink>
                            </FlexCol>
                          </Table.Td>
                        </Table.Tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </Table.TBody>
            </Table>
          );
        }}
      </TableViewGenericProps>
    </StyledContent>
  );
}
