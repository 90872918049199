import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

// Components
import SVG from '@/components/svg.component';

// Assets
import { arrows } from '@/assets/icons';

// Styles
import StyledSelect from '@/styles/styled-select.styles';
import { StyledSpan, StyledButtonGeneric, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledFilterTitle = styled(StyledSpan)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export default function FilterCategoric({ filters, data, valueKey, labelKey, callback = (options) => {}, style = {} }) {
	const [title] = useState(filters[valueKey]['title']);
	const [isOpen, setIsOpen] = useState(false);
	
	const options = useMemo(() => {
		return R.pipe(
			R.map((row) => {
				return {
					value: row[valueKey],
					label: row[labelKey],
				};
			}),
			R.uniqBy(R.prop('value')),
			R.filter((option) => option.value !== null && option.label !== null),
			R.map((option) => {
				if (option.value === '') {
					return {
						...option,
						label: 'N/A',
					};
				} else if (option.label === 1) {
					return {
						...option,
						label: 'True',
					};
				} else if (option.label === 0) {
					return {
						...option,
						label: 'False',
					};
				}

				return option;
			}),
			R.sort((a, b) => a.label.localeCompare(b.label))
		)(data);
	}, [data]);

	const [selectedValues, setSelectedValues] = useState(filters[valueKey]['value'].map((option) => option.value));
	const toggleOption = (optionValue) => {
		if (selectedValues.includes(optionValue)) {
			setSelectedValues(selectedValues.filter((selectedOption) => selectedOption !== optionValue));
		} else {
			setSelectedValues([...selectedValues, optionValue]);
		}
	};

	const [fullOptions, setFullOptions] = useState([]);
	useEffect(() => {
		if (options.length == 0) {
			return;
		}
		let fullOptions = R.filter((option) => {
			return selectedValues.includes(option.value);
		})(options);
		setFullOptions(fullOptions);
		callback(fullOptions);
	}, [selectedValues]);

	const processedTitle = fullOptions.length == 0 ? title : fullOptions.length == 1 ? fullOptions[0].label : `${fullOptions[0].label}...`;

	return (
		<StyledSelect
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
			style={style}>
			<StyledSelect.Header
				style={{
					backgroundColor: selectedValues.length > 0 ? theme.colors.oranges.light : theme.colors.base.white
				}}
			>
				<StyledFilterTitle>{processedTitle}</StyledFilterTitle>
				<SVG
					d={isOpen ? arrows.arrowDropUpD : arrows.arrowDropDownD}
					width='32px'
					height='32px'
				/>
			</StyledSelect.Header>
			{isOpen && (
				<StyledSelect.List maxHeight='200px'>
					<StyledButtonGeneric
						style={{
							width: '100%',
							alighSelf: 'center',
							height: '30px',
						}}
						backgroundColor={theme.colors.greens.midStrong}
						onClick={() => setSelectedValues([])}>
						<StyledSpanBold>Clear</StyledSpanBold>
					</StyledButtonGeneric>
					{options.map((option, index) => (
						<StyledSelect.Option
							key={index}
							onClick={() => toggleOption(option.value)}>
							<input
								type='checkbox'
								checked={selectedValues.includes(option.value)}
								onChange={() => {}}
							/>
							<StyledSpan
								style={{
									marginLeft: '10px',
									textAlign: 'left',
									display: 'block',
								}}>
								{filters[valueKey].toUpperCase ? option.label.toUpperCase() : option.label}
							</StyledSpan>
						</StyledSelect.Option>
					))}
				</StyledSelect.List>
			)}
		</StyledSelect>
	);
}
