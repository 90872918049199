// Third-party
import { Routes, Route, BrowserRouter } from 'react-router-dom';

// Views - Auth
import CheckGUIDView from '@/views/auth-check-guid.view';
//import LoginView from '@/views/auth-login.view';
import LoginView from '@/views/auth-google-login.view';
import ResetPasswordView from '@/views/auth-reset-password.view';
import SetPasswordView from '@/views/auth-set-password.view';

// Views - Home
import HomeView from '@/views/home.view';

// Views - Tables
import UsersTableView from '@/views/users-table.view';
import DevicesTableView from '@/views/devices-table.view';
import SnapshotsTableView from '@/views/snapshots-table.view';
import MacrosTableView from '@/views/macros-table.view';
import PartialSnapshotsTableView from '@/views/partial-snapshots-table.view';
import CheatDefinitionsTableView from '@/views/cheat-definitions-table.view';
import GamesTableView from '@/views/games-table.view';
import RolesTableView from '@/views/roles-table.view';
import LogsTableView from '@/views/logs-table.view';
import CheatKeysTableView from '@/views/cheat-keys-table.view';

// Views - Edit
import ProfileEditView from '@/views/profile-edit.view';
import CheatDefinitionsEditView from '@/views/cheat-definitions-edit.view';
import CheatDefinitionsDefinitionEditor from '@/views/cheat-definitions-definition-editor.view';
import PartialSnapshotsPatchView from '@/views/partial-snapshots-patch.view';

// Views - Other
import PushNotificationsCreateView from '@/views/push-notifications-create.view';
import GroupsMainView from '@/views/groups-main.view';
import GamesCurateView from '@/views/games-curate.view';
import Welcome from '@/views/welcome.view';

function AppRouter() {
	return (
		<BrowserRouter basename='/'>
			<Routes>
				<Route
					path='/'
					element={<CheckGUIDView />}
				/>
				<Route path='/auth'>
					<Route
						path='/auth/login'
						element={<LoginView />}
					/>
					<Route
						path='/auth/resetPassword'
						element={<ResetPasswordView />}
					/>
					<Route
						path='/auth/setPassword'
						element={<SetPasswordView />}
					/>
				</Route>
				<Route
					path='/app'
					element={<HomeView />}>
					<Route
						path='/app/welcome'
						element={<Welcome />}
					/>
					<Route path='/app/roles'>
						<Route
							path='/app/roles/table'
							element={<RolesTableView />}
						/>
					</Route>
					<Route path='/app/logs'>
						<Route
							path='/app/logs/table'
							element={<LogsTableView />}
						/>
					</Route>
					<Route path='/app/users'>
						<Route
							path='/app/users/table'
							element={<UsersTableView />}
						/>
						<Route
							path='/app/users/profile'
							element={<ProfileEditView />}
						/>
					</Route>
					<Route path='/app/groups'>
						<Route
							path='/app/groups/post'
							element={<GroupsMainView />}
						/>
					</Route>
					<Route path='/app/devices'>
						<Route
							path='/app/devices/table'
							element={<DevicesTableView />}
						/>
					</Route>
					<Route path='/app/snapshots'>
						<Route
							path='/app/snapshots/table'
							element={<SnapshotsTableView />}
						/>
					</Route>
					<Route path='/app/games'>
						<Route
							path='/app/games/table'
							element={<GamesTableView />}
						/>
						<Route
							path='/app/games/curate'
							element={<GamesCurateView />}
						/>
					</Route>
					<Route path='/app/cheatDefinitions'>
						<Route
							path='/app/cheatDefinitions/table'
							element={<CheatDefinitionsTableView />}
						/>
						<Route
							path='/app/cheatDefinitions/edit'
							element={<CheatDefinitionsEditView />}
						/>
						<Route
							path='/app/cheatDefinitions/definitionEditor'
							element={<CheatDefinitionsDefinitionEditor />}
						/>
						<Route
							path='/app/cheatDefinitions/cheatKeys'
							element={<CheatKeysTableView />}
						/>
					</Route>
					<Route path='/app/partialSnapshots'>
						<Route
							path='/app/partialSnapshots/table'
							element={<PartialSnapshotsTableView />}
						/>
						<Route
							path='/app/partialSnapshots/patch'
							element={<PartialSnapshotsPatchView />}
						/>
					</Route>
					<Route path='/app/macros'>
						<Route
							path='/app/macros/table'
							element={<MacrosTableView />}
						/>
					</Route>
					<Route path='/app/pushNotifications'>
						<Route
							path='/app/pushNotifications/post'
							element={<PushNotificationsCreateView />}
						/>
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default AppRouter;
