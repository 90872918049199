import { Controller } from 'react-hook-form';
import styled from 'styled-components';

// Styles
import { StyledInputBase } from '@/styles/shared.styles';

const StyledInputTextArea = styled(StyledInputBase)`
	padding: 10px;
`;

export default function InputTextArea({
	accessor,
	control,
	editable = true,
	validation = {},
	rows = '3',
	cols = '100',
	placeholder = 'Enter a value',
	style = {},
}) {
	return (
		<Controller
			name={accessor}
			control={control}
			rules={validation}
			render={({ field: { onChange, value } }) => {
				return (
					<StyledInputTextArea
						placeholder={placeholder}
						as='textarea'
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
						rows={rows}
						cols={cols}
						style={{ resize: 'none', ...style }}
						disabled={!editable}
					/>
				);
			}}
		/>
	);
}
