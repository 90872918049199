// Third-party
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import React, {useEffect} from "react";

// State
import { useContext } from 'react';
import { UserAuthContext } from '@/contexts/user-auth.context';
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { CustomNavigateContext } from '@/contexts/custom-navigate.context';
import { CurrentMenuContext } from '@/contexts/current-menu.context';

// Libs
import { useCustomNavigate } from '@/libs/hooks.lib';

// Components
import NavigationBar from '@/components/navigation-bar.component';
import Header from '@/components/header.component';
import Footer from '@/components/footer.component';

const StyledHomeViewContainer = styled.div`
	display: grid;
	grid-template-rows: 94px 1fr 40px;
	grid-template-columns: 284px 1fr;
	grid-template-areas:
		'header header'
		'nav content'
		'footer content';
	height: 100vh;
	width: 100vw;
`;

function HomeView() {
	const { userAuthState } = useContext(UserAuthContext);
	const { navigateToRoot } = useCustomNavigate();
	
	const location = useLocation();
	const viewKey = location.pathname;

	useEffect(() => {
		if (userAuthState === null || userAuthState.user === undefined || userAuthState.user.id === null) {
			navigateToRoot();
		}
	}, []);

	return (
		<StyledHomeViewContainer>
			<Header />
			<NavigationBar />
			<Outlet key={viewKey} />
			<Footer />
		</StyledHomeViewContainer>
	);
}

export default HomeView;
