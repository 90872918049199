import { useState } from 'react';
import * as R from 'ramda';
import { CSVLink } from 'react-csv';

// State
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { useContext } from 'react';

// Libs
import {
    getArgumentsFromVariablesAndMethodsValues,
} from "@/libs/cheat-definitions.lib";
import { useCustomNavigate } from "@/libs/hooks.lib";

// Styles
import { StyledContent, FlexRowAlignCenter } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Components
import { ButtonAdd, ButtonCancel, ButtonAccept } from '@/components/buttons.component';
import PopupGeneric from '@/components/popup-generic.component';
import CheatKeysEditView from '@/views/cheat-keys-edit.view';
import CheatKeysGeneratorView from '@/views/cheat-keys-generator.view';
import CheatKeysTable from '@/components/cheat-keys-table.component';
import PopupError from '@/components/popup-error.component';

export default function CheatKeysTableView({}) {
	const { navigateTo } = useCustomNavigate();
    const { cheatDefinitionsState,
        setCheatDefinitionsState, } = useContext(
        CheatDefinitionsContext
    );

	const [editCheatKeyType, setEditCheatKeyType] = useState('EDIT');
	const [editingCheatKeyIndex, setEditingCheatKeyIndex] = useState(null);
	const [editingCheatKey, setEditingCheatKey] = useState(null);
	const [showEditPopup, setShowEditPopup] = useState(false);

	const [popupErrorMessage, setPopupErrorMessage] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState(false);

	const [showGeneratorPopup, setShowGeneratorPopup] = useState(false);

	const [data, setData] = useState(cheatDefinitionsState.cheatKeys);
	const [argumentsValues ] = useState(getArgumentsFromVariablesAndMethodsValues(
		cheatDefinitionsState.variables,
		cheatDefinitionsState.methods
	));

	return (
		<StyledContent
			style={{
				gap: '20px',
			}}>
			{showEditPopup && (
				<PopupGeneric onClickOutside={() => {}}>
					<CheatKeysEditView
						onClose={() => {
							setShowEditPopup(false);
						}}
						onSubmit={(newCheatKey) => {
							if (editCheatKeyType === 'ADD') {
								setData([...data, newCheatKey]);
							} else if (editCheatKeyType === 'EDIT') {
								let newCheatKeys = R.clone(data);
								newCheatKeys[editingCheatKeyIndex] = newCheatKey;
								setData([...newCheatKeys]);
							}
							setShowEditPopup(false);
						}}
						data={editingCheatKey}
						cheatArguments={argumentsValues}
						existingCheatKeys={data}
						editCheatKeyType={editCheatKeyType}
					/>
				</PopupGeneric>
			)}
			{showGeneratorPopup && (
				<PopupGeneric onClickOutside={() => {}}>
					<CheatKeysGeneratorView
						idCheatDefinition={cheatDefinitionsState.currentlySelectedId}
						onCheatKeysGenerated={(newCheatKeys) => {
							setData([...data, ...newCheatKeys]);
							setShowGeneratorPopup(false);
						}}
						onClose={() => {
							setShowGeneratorPopup(false);
						}}
						onError={(error) => {
							setPopupErrorMessage(error);
							setShowErrorPopup(true);
						}}
					/>
				</PopupGeneric>
			)}
			{showErrorPopup && (
				<PopupError
					error={popupErrorMessage}
					onClose={() => {
						setShowErrorPopup(false);
					}}
				/>
			)}
			<FlexRowAlignCenter
				style={{
					gap: '20px',
				}}>
				<ButtonAdd
					text={'Add New'}
					onClick={() => {
						setEditCheatKeyType('ADD');
						setEditingCheatKey({});
						setShowEditPopup(true);
						setShowGeneratorPopup(false);
					}}
				/>
				<ButtonAdd
					text={'Generate'}
					onClick={() => {
						setShowGeneratorPopup(true);
						setShowEditPopup(false);
					}}
				/>
				<CSVLink
					data={data}
					filename={`${cheatDefinitionsState.currentlySelectedId}_${cheatDefinitionsState.cheatDefinition.name}_codes.csv`}
					target={'_blank'}
					style={{ textDecoration: 'none' }}>
					<ButtonAccept
						text={'Export as CSV'}
						onClick={() => {}}
						backgroundColor={theme.colors.oranges.midStrong}
					/>
				</CSVLink>
			</FlexRowAlignCenter>
			<CheatKeysTable
				data={data}
				onEditRow={(index, values) => {
					setEditingCheatKeyIndex(index);
					setEditCheatKeyType('EDIT');
					setEditingCheatKey(values);
					setShowEditPopup(true);
				}}
				onDeleteRow={(index) => {
					let newCheatKeys = R.clone(data);
					newCheatKeys.splice(index, 1);
					setData([...newCheatKeys]);
				}}
			/>
			<FlexRowAlignCenter
				style={{
					gap: '20px',
				}}>
				<ButtonCancel
					onClick={() => {
						navigateTo({
							mainPath: 'cheatDefinitions',
							subPath: 'edit',
						});
					}}
				/>
				<ButtonAccept
					text={'Save'}
					onClick={() => {
						setCheatDefinitionsState({
							...cheatDefinitionsState,
							cheatKeys: data,
							cheatKeysUpdated: true,
						});
						navigateTo({
							mainPath: 'cheatDefinitions',
							subPath: 'edit',
						});
					}}
				/>
			</FlexRowAlignCenter>
		</StyledContent>
	);
}
