import { createContext, useState, useEffect } from 'react';

export const CurrentMenuContext = createContext();

const initialCurrentMenuState = {
	menuName: '',
	partialSnapshots: {
		currentlySelectedId: null,
	},
	games: {
		currentlySelectedId: null,
	},
};

export const CurrentMenuContextProvider = ({ children }) => {
	const [currentMenuState, setCurrentMenuState] = useState(JSON.parse(localStorage.getItem('currentMenuState')) || initialCurrentMenuState);
	
	useEffect(() => {
		localStorage.setItem('currentMenuState', JSON.stringify(currentMenuState));
	}, [currentMenuState]);

	return (
		<CurrentMenuContext.Provider value={{ currentMenuState, setCurrentMenuState }}>
			{children}
		</CurrentMenuContext.Provider>
	);
};
