import { useCallback, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';

// Syles
import {
	StyledContent,
	StyledSpanBold,
	StyledButtonGeneric,
	FlexRowAlignCenter,
	FlexCol,
	StyledSpan,
} from '@/styles/shared.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';
import theme from '@/styles/theme.styles';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputAsyncSelect from '@/components/input-async-select.component';
import InputAsyncSelectVersion from '@/components/input-async-select-version.component';
import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import { ButtonEdit, ButtonSubmit } from '@/components/buttons.component';
import PopupSelectCheatDefinition from '@/components/popup-select-cheat-definition.component';
import InputSelect from '@/components/input-select.component';
import PopupSelectDevices from '@/components/popup-select-devices.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import SVG from '@/components/svg.component';
import LoadingIcon from '@/components/loading-icon.component';
import InputAsyncSelectGameAndVersion from '@/components/input-async-select-game-and-version.component';

// Libs
import { submitGeneric, getOneToManyById, get } from '@/libs/requests.lib';
import { getArgumentsFromVariablesAndMethodsValues } from '@/libs/cheat-definitions.lib';

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Assets
import { navigation } from '@/assets/icons';

const pushNotificationTypes = [
	{
		label: 'Selected Devices',
		value: 'selectedDevices',
	},
	{
		label: 'Group',
		value: 'group',
	},
	{
		label: 'HyperBeard',
		value: 'hyperBeard',
	},
];

export default function PushNotificationsCreateView({}) {
	const navigate = useNavigate();
	const { userAuthState } = useContext(UserAuthContext);

	const [loading, setLoading] = useState(false);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showChooseDevicesPopup, setShowChooseDevicesPopup] = useState(false);
	const [showChooseCheatDefinitionPopup, setShowChooseCheatDefinitionPopup] = useState(false);

	const {
		handleSubmit,
		formState: { errors, isDirty },
		control,
		setValue,
		watch,
		getValues
	} = useForm({
		defaultValues: {
			arguments: [],
		},
	});

	useEffect(() => {
		setValue('type', pushNotificationTypes[0].value);
	}, []);

	const { fields: argumentsFields } = useFieldArray({
		control,
		name: 'arguments',
	});

	const idGame = watch('idGame', null);
	const idGameDefinition = watch('idGameDefinition', null);
	const selectedType = watch('type', pushNotificationTypes[0].value);

	const [selectedCheatDefinition, setSelectedCheatDefinition] = useState(null);
	const [cheatDefinitionData, setCheatDefinitionData] = useState([]);
	const getCompatibleCheatDefinitions = async () => {
		setLoading(true);
		try {
			const response = await getOneToManyById({
				endpointOwner: 'gameDefinitions',
				idOwner: idGameDefinition,
				endpointOwned: 'compatibleCheatDefinitions',
				navigate,
			});

			setCheatDefinitionData(response);
			setLoading(false);
		} catch (error) {
			setErrorMessage(error);
			setShowErrorPopup(true);
			setLoading(false);
		}
	};

	const getArgumentsFromCheatDefinition = async (cheatDefinition) => {
		setLoading(true);
		try {
			const response = await getOneToManyById({
				endpointOwner: 'cheatDefinitions',
				idOwner: cheatDefinition.idCheatDefinition,
				endpointOwned: 'variablesAndMethodsValues',
				navigate,
			});

			let args = getArgumentsFromVariablesAndMethodsValues(response.variables, response.methods);
			args = R.map((arg) => '')(args);
			setValue('arguments', args);
			setLoading(false);
		} catch (error) {
			setErrorMessage(error);
			setShowErrorPopup(true);
			setLoading(false);
		}
	};

	const [selectedDevices, setSelectedDevices] = useState([]);
	const [nonSelectedCompatibleDevices, setNonSelectedCompatibleDevices] = useState([]);
	const getCompatibleDevices = useCallback(async () => {
		setLoading(true);
		try {
			const response = await get({
				endpoint: `devices/groupSharingUsers/${userAuthState.user.id}/${idGame}}}`,
				navigate,
			});

			const filteredData = R.pipe(
				R.filter((device) => device.tokenCM != null && device.tokenCM != '' && device.active === 1),
				R.filter((device) => !R.any(R.propEq('idDevice', device.idDevice))(selectedDevices))
			)(response);

			setNonSelectedCompatibleDevices(filteredData);
			setLoading(false);
		} catch (error) {
			setErrorMessage(error);
			setShowErrorPopup(true);
			setLoading(false);
		}
	}, [idGame, selectedDevices]);

	const onSubmit = useCallback(
		async (data) => {
			setLoading(true);
			const idDevices = R.map(R.prop('idDevice'))(selectedDevices);

			let finalData = {
				...data,
				idWriterUser: userAuthState.user.id,
				idCheatDefinition: selectedCheatDefinition.idCheatDefinition,
				idDevices,
			};

			const type = finalData.type;

			if (type === 'selectedDevices') {
				finalData = R.dissoc('group')(finalData);
			} else if (type === 'group') {
				finalData = R.dissoc('idDevices')(finalData);
			} else if (type === 'hyperBeard') {
				finalData = R.dissoc('idDevices')(finalData);
				finalData = R.dissoc('group')(finalData);
			}

			try {
				let r = await submitGeneric({
					endpoint: 'pushNotifications',
					data: finalData,
					id: undefined,
					contentType: 'application/json',
					requestType: 'POST',
					idUser: userAuthState.user.id,
				});

				setShowSuccessPopup(true);
				setLoading(false);
			} catch (error) {
				if ('Error sending message: Error: Requested entity was not found.' === error) {
					setErrorMessage(
						'Some of the devices that you tried to send the notification to do not have a valid token.'
					);
					setShowErrorPopup(true);
				} else {
					setErrorMessage(error);
					setShowErrorPopup(true);
				}
				setLoading(false);
			}
		},
		[selectedCheatDefinition, userAuthState, selectedDevices]
	);

	return (
		<StyledContent
			style={{
				justifyContent: 'flex-start',
			}}>
			{showChooseCheatDefinitionPopup && (
				<PopupSelectCheatDefinition
					key={idGameDefinition}
					cheatDefinitions={cheatDefinitionData}
					onClose={() => {
						setShowChooseCheatDefinitionPopup(false);
					}}
					onCheatDefinitionSelected={(cheatDefinition) => {
						if (cheatDefinition.name == null) setValue('cheatDefinition', 'N/A');
						else setValue('cheatDefinition', cheatDefinition.name);
						setSelectedCheatDefinition(cheatDefinition);
						getArgumentsFromCheatDefinition(cheatDefinition);
						setShowChooseCheatDefinitionPopup(false);
					}}
				/>
			)}
			{showChooseDevicesPopup && (
				<PopupSelectDevices
					onSelectedDevicesDone={(selectedDevices) => {
						setShowChooseDevicesPopup(false);
						setSelectedDevices(selectedDevices);
						setValue('devices', selectedDevices);
					}}
					startingSelectedDevices={selectedDevices}
					startingUnselectedDevices={nonSelectedCompatibleDevices}
				/>
			)}
			{showSuccessPopup && (
				<PopupSuccess
					message={'Push notification sent successfully!'}
					onClose={() => {
						setShowSuccessPopup(false);
					}}
				/>
			)}
			{showErrorPopup && (
				<PopupError
					error={errorMessage}
					onClose={() => {
						setShowErrorPopup(false);
					}}
				/>
			)}
			<FlexRowAlignCenter
				style={{
					gap: '10px',
					marginTop: '20px',
					marginBottom: '50px',
				}}>
				<SVG
					d={navigation.pushNotificationsD}
					width={50}
					height={50}
					fill={theme.colors.greens.midStrong}
				/>
				<StyledSpanBold
					style={{
						fontSize: '30px',
					}}>{`Push Notifications`}</StyledSpanBold>
			</FlexRowAlignCenter>
			<StyledGridForm onSubmit={handleSubmit(onSubmit)}>
				<InputAsyncSelectGameAndVersion 
					control={control}
					watch={watch}
					getValues={getValues}
					requestType={'POST'}
					setValue={setValue}
					errors={errors}
					onIdGameChanged={(idGame) => {
						setSelectedCheatDefinition(null);
						setValue('cheatDefinition', '');
						setValue('arguments', []);
					}}
					onError={(error) => {
						setErrorMessage(error);
						setShowErrorPopup(true);
					}}
					filterByGroups={true}
					idGroupsToAllow={userAuthState.user.groups.map(group => group.idGroup)}
				/>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Title</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputText
						accessor={'title'}
						editable={true}
						control={control}
						validation={{
							required: true,
							maxLength: {
								value: 45,
								message: 45,
							},
						}}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'title'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Body</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<InputTextArea
						accessor={'body'}
						editable={true}
						control={control}
						validation={{
							required: true,
							maxLength: {
								value: 45,
								message: 45,
							},
						}}
						cols={40}
						style={{
							width: '100%',
						}}
					/>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'body'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				<StyledGridForm.Label>
					<FlexRowAlignCenter>
						<StyledSpan>Cheat Definition</StyledSpan>
						<RequiredAsterisk />
					</FlexRowAlignCenter>
				</StyledGridForm.Label>
				<StyledGridForm.Input>
					<FlexRowAlignCenter
						style={{
							gap: '10px',
						}}>
						<InputText
							accessor={'cheatDefinition'}
							editable={false}
							control={control}
							validation={{
								required: true,
							}}
							placeholder={''}
							style={{
								pointerEvents: 'none',
								cursos: 'not-allowed',
							}}
						/>
						<ButtonEdit
							onClick={() => {
								getCompatibleCheatDefinitions();
								setShowChooseCheatDefinitionPopup(true);
							}}
							disabled={idGame == null}
						/>
					</FlexRowAlignCenter>
				</StyledGridForm.Input>
				<StyledGridForm.InputError>
					<InputErrorMessage
						formErrors={errors}
						accessor={'cheatDefinition'}
					/>
				</StyledGridForm.InputError>
				<StyledGridForm.Gap />
				{argumentsFields.length > 0 && (
					<>
						<StyledGridForm.Label>Arguments</StyledGridForm.Label>
						<StyledGridForm.Input>
							<FlexCol
								style={{
									gap: '10px',
								}}>
								{argumentsFields.map((argument, index) => {
									return (
										<FlexRowAlignCenter
											key={index}
											style={{
												gap: '10px',
											}}>
											<StyledSpanBold>{`${index + 1}`}</StyledSpanBold>
											<InputText
												accessor={`arguments.${index}`}
												control={control}
												editable={true}
											/>
										</FlexRowAlignCenter>
									);
								})}
							</FlexCol>
						</StyledGridForm.Input>
					</>
				)}
				<StyledGridForm.Gap />
				<StyledGridForm.Label>To Whom</StyledGridForm.Label>
				<StyledGridForm.Input>
					<FlexRowAlignCenter
						style={{
							gap: '0px',
							justifyContent: 'space-between',
						}}>
						<InputSelect
							control={control}
							accessor={'type'}
							options={pushNotificationTypes}
							defaultOption={pushNotificationTypes[0]}
							editable={idGame != null}
							onChanged={(selected) => {}}
						/>
						{selectedType === 'selectedDevices' && (
							<StyledButtonGeneric
								type={'button'}
								disabled={idGame == null}
								backgroundColor={theme.colors.greens.midStrong}
								onClick={() => {
									getCompatibleDevices();
									setShowChooseDevicesPopup(true);
								}}>
								<StyledSpanBold>Select</StyledSpanBold>
							</StyledButtonGeneric>
						)}
					</FlexRowAlignCenter>
				</StyledGridForm.Input>
				<StyledGridForm.Gap />
				{selectedType === 'group' && (
					<>
						<StyledGridForm.Label>Group</StyledGridForm.Label>
						<StyledGridForm.Input>
							{userAuthState.user.idRole === 1 && (
								<InputAsyncSelect
									endpoint={'groups'}
									valueColumn='idGroup'
									labelColumn='name'
									accessor='idGroup'
									control={control}
									isDisabled={false}
									setValue={setValue}
									validation={{
										required: true,
									}}
								/>
							)}
							{userAuthState.user.idRole != 1 && (
								<InputSelect
									options={R.map((group) => {
										return {
											label: group.name,
											value: group.idGroup,
										};
									})(userAuthState.user.groups)}
									control={control}
									accessor={'idGroup'}
								/>
							)}
						</StyledGridForm.Input>
						<StyledGridForm.InputError>
							<InputErrorMessage
								formErrors={errors}
								accessor={'idGroup'}
							/>
						</StyledGridForm.InputError>
					</>
				)}
				<StyledGridForm.Gap />
				<StyledGridForm.Gap />
				{loading && (
					<FlexRowAlignCenter
						style={{
							gridColumn: '1 / 3',
							justifyContent: 'center',
							width: '100%',
						}}>
						<LoadingIcon />
					</FlexRowAlignCenter>
				)}
				{!loading && (
					<FlexRowAlignCenter
						style={{
							gridColumn: '1 / 3',
							justifyContent: 'center',
							width: '100%',
						}}>
						<ButtonSubmit />
					</FlexRowAlignCenter>
				)}
			</StyledGridForm>
		</StyledContent>
	);
}
