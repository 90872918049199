// Third-party
import { useState } from "react";
import { Tooltip } from "react-tooltip";

// Components
import TableViewGenericProps from "@/components/table-view-generic-props.component";
import Table from "@/styles/table.styles";
import PopupGeneric from "@/components/popup-generic.component";
import TableActions from "@/components/table-actions.component";
import { ThDefault, ColGroups } from "@/components/table.component";

// Views
import RolesEditView from "@/views/roles-edit.view";

// Utils
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";

// Styles
import { StyledContent } from "@/styles/shared.styles";

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

export default function RolesTableView() {
  const { userAuthState } = useContext(UserAuthContext);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editRequestType, setEditRequestType] = useState("PATCH");
  const [selectedRowIdToEdit, setSelectedRowId] = useState(null);
  const [tableViewGenericPropsRandomKey, setTableViewGenericPropsRandomKey] =
    useState(Math.random());

  const editRow = (rowId) => {
    setEditRequestType("PATCH");
    setSelectedRowId(rowId);
    setShowEditPopup(true);
  };

  return (
    <StyledContent>
      <Tooltip id="user-not-active-tooltip" place="right" effect="solid" />
      <TableViewGenericProps
        menuName="roles"
        key={tableViewGenericPropsRandomKey}
        onAddClick={() => {
          setEditRequestType("POST");
          setSelectedRowId(null);
          setShowEditPopup(true);
        }}
        buttonAddText="New Role"
        onPopupSuccessClose={() => {
          setShowEditPopup(false);
          setTableViewGenericPropsRandomKey(Math.random());
        }}
      >
        {({
          getTableProps,
          headerGroups,
          getTableBodyProps,
          rows,
          prepareRow,
          deleteRow,
          setPopupErrorMessage,
          setShowErrorPopup,
          setShowSuccessPopup,
          setPopupSuccessMessage,
        }) => {
          const headerGroup = headerGroups[0];
          const columnName = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Name"
          );
          const columnDescription = getColumnFromHeaderGroupByHeaderName(
            headerGroup,
            "Description"
          );
          return (
            <>
              {showEditPopup && (
                <PopupGeneric onClickOutside={() => {}}>
                  <RolesEditView
                    requestType={editRequestType}
                    currentlySelectedId={selectedRowIdToEdit}
                    onClose={() => {
                      setShowEditPopup(false);
                    }}
                    onSubmitSuccess={() => {
                      setPopupSuccessMessage("Role saved successfully");
                      setShowSuccessPopup(true);
                    }}
                    onSubmitError={(errorMessage) => {
                      setPopupErrorMessage(errorMessage);
                      setShowErrorPopup(true);
                    }}
                  />
                </PopupGeneric>
              )}
              <Table {...getTableProps()}>
                <ColGroups widths={["45%", "45%", "10%"]} />
                <Table.THead>
                  <Table.Tr {...headerGroup.getHeaderGroupProps()}>
                    <ThDefault
                      column={columnName}
                      text={columnName.render("Header")}
                    />
                    <Table.Th>Description</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.THead>
                <Table.TBody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <Table.Tr {...row.getRowProps()} active={true}>
                        <Table.Td>
                          <span
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {row.values.name}
                          </span>
                        </Table.Td>
                        <Table.Td>
                          <span
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {row.values.description}
                          </span>
                        </Table.Td>
                        <Table.Td>
                          {userAuthState.user.permissions.roles.update !==
                            false && (
                            <TableActions
                              onEditRow={() => {
                                editRow(row.values.idRole);
                              }}
                              onDeleteRow={() => {
                                deleteRow(row.values.idRole);
                              }}
                            />
                          )}
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.TBody>
              </Table>
            </>
          );
        }}
      </TableViewGenericProps>
    </StyledContent>
  );
}
