import cheats from './cheats.svg';
import cheatsD from './cheats';
import games from './games.svg';
import gamesD from './games';
import groups from './groups.svg';
import groupsD from './groups';
import macros from './macros.svg';
import macrosD from './macros';
import devices from './devices.svg';
import devicesD from './devices';
import partialSnapshots from './partial_snapshots.svg';
import partialSnapshotsD from './partial_snapshots';
import pushNotifications from './push_notifications.svg';
import pushNotificationsD from './push_notifications';
import pushNotificationsCreate from './push_notifications_create.svg';
import pushNotificationsCreateD from './push_notifications_create';
import pushNotificationsApprove from './push_notifications_approve.svg';
import pushNotificationsApproveD from './push_notifications_approve';
import logs from './logs.svg';
import logsD from './logs';
import roles from './roles.svg';
import rolesD from './roles';
import snapshots from './snapshots.svg';
import snapshotsD from './snapshots';
import users from './users.svg';
import usersD from './users';

export default {
	cheatDefinitions: cheats,
	cheatDefinitionsD: cheatsD,
	games,
	gamesD,
	groups,
	groupsD,
	macros,
	macrosD,
	devices,
	devicesD,
	partialSnapshots,
	partialSnapshotsD,
	pushNotifications,
	pushNotificationsD,
	pushNotificationsCreate,
	pushNotificationsCreateD,
	pushNotificationsApprove,
	pushNotificationsApproveD,
	roles,
	rolesD,
	snapshots,
	snapshotsD,
	users,
	usersD,
	logs,
	logsD,
};