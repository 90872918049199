import styled from 'styled-components';

// Styles
import { FlexCol, StyledSpanBold, StyledSpan } from '@/styles/shared.styles';

const StyledTitleAndText = styled(FlexCol)`
	justify-content: center;
	align-items: flex-start;
	padding: 10px;
	border-radius: 5px;
	width: ${(props) => props.$width};

	&:hover {
		cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
		background-color: ${(props) => (props.hoverable ? props.theme.colors.greens.mid : 'white')};
	}
`;

export default function TitleAndText({
	title,
	text = null,
	titleFontSize = '0.9rem',
	textFontSize = '0.8rem',
	gap = '5px',
	width = 'none',
	hoverable = false,
}) {
	return (
		<StyledTitleAndText
			$width={width}
			hoverable={hoverable}
			style={{
				gap,
			}}>
			<StyledSpanBold
				style={{
					fontSize: titleFontSize,
					maxWidth: '100%',
				}}>
				{title}
			</StyledSpanBold>
			{text !== null && (
				<StyledSpan
					style={{
						fontSize: textFontSize,
						maxWidth: '100%',
					}}>
					{text}
				</StyledSpan>
			)}
		</StyledTitleAndText>
	);
}
