import React, { useState, useMemo, useEffect, useRef } from 'react';
import * as R from 'ramda';

// Components
import SVG from '@/components/svg.component';

// Assets
import { arrows } from '@/assets/icons';

// Styles
import StyledSelect from '@/styles/styled-select.styles';
import { StyledSpan, StyledButtonGeneric, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

export default function FilterGroups({ filters, data, valueKey, callback = (options) => {} }) {
	const [title] = useState(filters[valueKey]['title']);
	const [isOpen, setIsOpen] = useState(false);

	const options = useMemo(() => {
		return R.pipe(
			R.map((row) => {
				return row.groups;
			}),
			R.chain(R.identity),
			R.uniqBy(R.prop('idGroup')),
			R.map((option) => {
				return {
					value: option.idGroup,
					label: option.name,
				};
			}),
			R.sort((a, b) => a.label.localeCompare(b.label))
		)(data);
	}, [data]);

	const [selectedValues, setSelectedValues] = useState(filters[valueKey]['value'].map((option) => option.value));
	const toggleOption = (optionValue) => {
		if (selectedValues.includes(optionValue)) {
			setSelectedValues(selectedValues.filter((selectedOption) => selectedOption !== optionValue));
		} else {
			setSelectedValues([...selectedValues, optionValue]);
		}
	};

	// Aquí está el problema
	const [fullOptions, setFullOptions] = useState([]);
	useEffect(() => {
		if (options.length == 0) {
			return;
		}
		let fullOptions = R.filter((option) => {
			return selectedValues.includes(option.value);
		})(options);
		setFullOptions(fullOptions);
		callback(fullOptions);
	}, [selectedValues]);

	const processedTitle = fullOptions.length == 0 ? title : fullOptions.length == 1 ? fullOptions[0].label : `${fullOptions[0].label}...`;

	return (
		<StyledSelect
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}>
			<StyledSelect.Header
				style={{
					backgroundColor: selectedValues.length > 0 ? theme.colors.oranges.light : theme.colors.base.white
				}}
			>
				<StyledSpan>{processedTitle}</StyledSpan>
				<SVG
					d={isOpen ? arrows.arrowDropUpD : arrows.arrowDropDownD}
					width='32px'
					height='32px'
				/>
			</StyledSelect.Header>
			{isOpen && (
				<StyledSelect.List maxHeight='200px'>
					<StyledButtonGeneric
						style={{
							width: '100%',
							alighSelf: 'center',
							height: '30px',
						}}
						backgroundColor={theme.colors.greens.midStrong}
						onClick={() => 
						{
							setSelectedValues([])
						}
						}>
						<StyledSpanBold>Clear</StyledSpanBold>
					</StyledButtonGeneric>
					{options.map((option, index) => (
						<StyledSelect.Option
							key={index}
							onClick={() => toggleOption(option.value)}>
							<input
								type='checkbox'
								checked={selectedValues.includes(option.value)}
								onChange={() => {}}
							/>
							<StyledSpan
								style={{
									marginLeft: '10px',
								}}>
								{filters[valueKey].toUpperCase ? option.label.toUpperCase() : option.label}
							</StyledSpan>
						</StyledSelect.Option>
					))}
				</StyledSelect.List>
			)}
		</StyledSelect>
	);
}
