// Third-party
import styled from 'styled-components';

// Components
import { FlexRow, StyledButtonNoStyle } from '@/styles/shared.styles';
import SVG from '@/components/svg.component';

// Assets
import { arrows } from '@/assets/icons';

const StyledPaginationFooter = styled(FlexRow)`
	font-size: 15px;
	font-weight: 600;
	line-height: 130%;
	justify-content: space-between;
	align-items: center;
`;

const StyledGoToPage = styled.span`
	input {
		margin-left: 10px;
		width: 70px;
		height: 28px;
		border-radius: 7px;
		border: 0.673px solid rgba(0, 0, 0, 0.12);
		padding: 0 5px;
	}
`;

const StyledRowsPerPage = styled(FlexRow)`
	align-items: center;

	select {
		margin-left: 10px;
		width: 70px;
		height: 28px;
		border-radius: 7px;
		border: 0.673px solid rgba(0, 0, 0, 0.12);
		padding: 0 5px;
	}
`;

const StyledMoveButtons = styled(FlexRow)`
	gap: 5px;
	align-items: center;
`;

function PaginationFooter({
	currentPageLength,
	totalRows,
	gotoPage,
	previousPage,
	nextPage,
	canNextPage,
	canPreviousPage,
	pageIndex,
	pageOptions,
	setPageSize,
	pageSize,
	pageCount,
	callback = undefined,
}) {
	return (
		<StyledPaginationFooter>
			<StyledGoToPage>
				<span>Go to page:</span>
				<input
					type='number'
					defaultValue={pageIndex + 1}
					onChange={(e) => {
						const page = e.target.value ? Number(e.target.value) - 1 : 0;
						if (callback) callback(page);
						gotoPage(page);
					}}
				/>
			</StyledGoToPage>
			<FlexRow
				style={{
					gap: '30px',
					alignItems: 'center',
				}}>
				<StyledRowsPerPage>
					<span>Rows per page</span>
					<select
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}>
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
				</StyledRowsPerPage>
				<span
					style={{
						width: '80px',
						textAlign: 'center',
					}}>
					{pageIndex * pageSize + 1}-{pageIndex * pageSize + currentPageLength} of {totalRows}
				</span>
				<StyledMoveButtons>
					<StyledButtonNoStyle
						onClick={() => {
							gotoPage(0);
							if (callback) callback(0);
						}}
						disabled={!canPreviousPage}>
						<SVG d={arrows.arrowFirstPageD} height='30px' width='30px' />
					</StyledButtonNoStyle>
					<StyledButtonNoStyle
						onClick={() => {
							previousPage();
							if (callback) callback(pageIndex - 1);
						}}
						disabled={!canPreviousPage}
						style={{
							marginRight: '8px',
						}}>
						<SVG d={arrows.arrowBackD} height='18px' width='18px' />
					</StyledButtonNoStyle>
					<StyledButtonNoStyle
						onClick={() => {
							nextPage();
							if (callback) callback(pageIndex + 1);
						}}
						disabled={!canNextPage}>
						<SVG d={arrows.arrowForwardD} height='18px' width='18px' />
					</StyledButtonNoStyle>
					<StyledButtonNoStyle
						onClick={() => {
							gotoPage(pageCount - 1);
							if (callback) callback(pageCount - 1);
						}}
						disabled={!canNextPage}>
						<SVG d={arrows.arrowLastPageD} height='30px' width='30px' />
					</StyledButtonNoStyle>
				</StyledMoveButtons>
			</FlexRow>
		</StyledPaginationFooter>
	);
}

export default PaginationFooter;
