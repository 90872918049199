import styled from 'styled-components';

import { FlexCol } from '@/styles/shared.styles';

const StyledPopupEditForm = styled.form`
	width: 500px;
`;

const Header = styled(FlexCol)`
	background: linear-gradient(
		to bottom,
		#ddd9fe 0%,
		#ddd9fe ${(props) => (props.backgroundThreshold ? props.backgroundThreshold : '33.33')}%,
		white ${(props) => (props.backgroundThreshold ? props.backgroundThreshold : '33.33')}%,
		white 100%
	);
	border-radius: 10px;
	padding: 10px 20px;
`;

const Body = styled(FlexCol)`
	padding: 5px 20px 20px 20px;
	background-color: white;
	border-radius: 10px;
	gap: 20px;
	height: 100%;
	justify-content: space-between;
	flex-grow: 1;
`;

const LabelValuePair = styled(FlexCol)`
	justify-content: space-between;
	align-items: flex-start;
	gap: 10px;

	& > span:first-child {
		font-size: 0.9rem;
		font-weight: bold;
	}

	& > div:first-child > span {
		font-size: 0.9rem;
		font-weight: bold;
	}
`;

StyledPopupEditForm.Header = Header;
StyledPopupEditForm.Body = Body;
StyledPopupEditForm.LabelValuePair = LabelValuePair;

export default StyledPopupEditForm;
