import styled from 'styled-components';

const Th = styled.th`
	padding: 0.5rem;
	text-align: left;
	overflow: hidden;
	width: 100px;
	white-space: normal;
	border-left: none;
	border-right: none;
	background-color: ${(props) => props.theme.colors.greens.light};
	font-weight: bold;

	@media screen and (max-width: 576px) {
		font-size: 14px;
		padding: 0.25rem;
	}
`;

export default Th;
