import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
    useExpanded,
} from "react-table";
import { useState, useMemo, useEffect } from "react";
import * as R from "ramda";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

// Libs
import { getOneToManyById } from "@/libs/requests.lib";
import { applyFilters, getFilterComponents } from "@/libs/filters.lib";
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";

// Components
import PopupGeneric from "@/components/popup-generic.component";
import GlobalFilter from "@/components/filter-global.component";
import PaginationFooter from "@/components/pagination-footer.component";
import Table from "@/styles/table.styles";
import { ThDefault, ColGroups } from "@/components/table.component";
import { TooltipActivatorQuestion } from "@/components/tooltip-activators.component";
import RowCloseButton from "@/components/row-close-button.component";
import LoadingIcon from "@/components/loading-icon.component";

// Styles
import {
    FlexRowAlignCenter,
    FlexCol,
    StyledSpan,
    StyledTableContainer,
} from "@/styles/shared.styles";
import theme from "@/styles/theme.styles";

const headers = [
    {
        Header: "Id Variable Description",
        accessor: "idVariableDescription",
    },
    {
        Header: "Id Variable",
        accessor: "idVariable",
    },
    {
        Header: "Collection",
        accessor: "collection",
    },
    {
        Header: "Icon",
        accessor: "icon",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Description Name",
        accessor: "descriptionName",
    },
    {
        Header: "Description",
        accessor: "description",
    },
    {
        Header: "Description Description",
        accessor: "descriptionDescription",
    },
    {
        Header: "Data Type",
        accessor: "dataType",
    },
    {
        Header: "Default Value",
        accessor: "defaultValue",
    },
];

const StyledPopupChooseVariable = styled(FlexCol)`
    width: 100%;
    height: 100%;
    padding: 10px;
    gap: 10px;
    height: 600px;
`;

const StyledSelectableTr = styled(Table.Tr)`
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.base.white};
    &:hover {
        background-color: ${(props) => props.theme.colors.grays.mid};
    }
`;

export default function PopupChooseVariable({
    alreadySelectedVariables = [],
    filterSelected = true,
    idGameDefinition,
    getOnlyNonSelected = false,
    onVariableSelected = (variable) => {},
    onClose = () => {},
    onError = (error) => {},
}) {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getOneToManyById({
            endpointOwner: "gameDefinitions",
            idOwner: idGameDefinition,
            endpointOwned: getOnlyNonSelected
                ? "nonSelectedVariableDescriptions"
                : "variableDescriptions",
            navigate,
        })
            .then((data) => {
                if (filterSelected) {
                    const idsOfUsedVariables = R.pluck(
                        "idVariableDescription",
                        alreadySelectedVariables
                    );
                    const filteredVariables = R.filter(
                        (variable) =>
                            !idsOfUsedVariables.includes(
                                variable.idVariableDescription
                            ),
                        data
                    );
                    setData(filteredVariables);
                } else {
                    setData(data);
                }

                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                onError(error);
            });
    }, []);

    // Filters used for this dataset
    const [realTimeFilters, setRealTimeFilters] = useState({
        collection: {
            value: [],
            type: "categoric",
            title: "Collection",
            labelKey: "collection",
        },
    });
    // Filtered data after applying filters to data
    const tableData = useMemo(() => {
        const applyFilterFunction = applyFilters(realTimeFilters);
        return applyFilterFunction(data);
    }, [data, realTimeFilters]);
    const [currentPage, setCurrentPage] = useState(0);
    const memoizedHeaders = useMemo(() => headers, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns: memoizedHeaders,
            data: tableData,
            initialState: { pageIndex: currentPage, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const headerGroup = headerGroups[0];
    const columnCollection = getColumnFromHeaderGroupByHeaderName(
        headerGroup,
        "Collection"
    );
    const columnName = getColumnFromHeaderGroupByHeaderName(
        headerGroup,
        "Name"
    );
    const columnDataType = getColumnFromHeaderGroupByHeaderName(
        headerGroup,
        "Data Type"
    );

    return (
        <PopupGeneric onClickOutside={onClose}>
            <Tooltip
                id="variable-description-tooltip"
                place="right"
                effect="solid"
            />
            <StyledPopupChooseVariable>
                {loading && (
                    <LoadingIcon
                        style={{
                            alignSelf: "center",
                            margin: "200px",
                        }}
                    />
                )}
                {!loading && (
                    <>
                        <RowCloseButton
                            onClose={onClose}
                            iconFill={theme.colors.base.black}
                        />
                        <FlexRowAlignCenter
                            style={{
                                gap: "10px",
                            }}>
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                style={{ width: "50%" }}
                            />
                            {getFilterComponents(
                                realTimeFilters,
                                setRealTimeFilters,
                                data,
                                {
                                    width: "50%",
                                }
                            )}
                        </FlexRowAlignCenter>
                        <StyledTableContainer>
                            <Table {...getTableProps()}>
                                <ColGroups
                                    widths={["30%", "5%", "40%", "25%"]}
                                />
                                <Table.THead>
                                    <Table.Tr
                                        {...headerGroups[0].getHeaderGroupProps()}>
                                        <ThDefault
                                            column={columnCollection}
                                            text={columnCollection.render(
                                                "Header"
                                            )}
                                        />
                                        <Table.Th></Table.Th>
                                        <ThDefault
                                            column={columnName}
                                            text={columnName.render("Header")}
                                        />
                                        <ThDefault
                                            column={columnDataType}
                                            text={columnDataType.render(
                                                "Header"
                                            )}
                                        />
                                    </Table.Tr>
                                </Table.THead>
                                <Table.TBody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <StyledSelectableTr
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    onVariableSelected(
                                                        row.values
                                                    );
                                                    onClose();
                                                }}>
                                                <Table.Td>
                                                    {row.values.collection}
                                                </Table.Td>
                                                <Table.Td>
                                                    <TooltipActivatorQuestion
                                                        id="variable-description-tooltip"
                                                        content={
                                                            row.values
                                                                .descriptionDescription ===
                                                                null ||
                                                            row.values
                                                                .descriptionDescription ===
                                                                ""
                                                                ? "No Description"
                                                                : row.values
                                                                      .descriptionDescription
                                                        }
                                                    />
                                                </Table.Td>
                                                <Table.Td
                                                    style={{
                                                        wordWrap: "break-word",
                                                        whiteSpace: "normal",
                                                    }}>
                                                    <StyledSpan>
                                                        {
                                                            row.values
                                                                .descriptionName
                                                        }
                                                    </StyledSpan>
                                                </Table.Td>
                                                <Table.Td>
                                                    {row.values.dataType}
                                                </Table.Td>
                                            </StyledSelectableTr>
                                        );
                                    })}
                                </Table.TBody>
                            </Table>
                        </StyledTableContainer>
                        <PaginationFooter
                            currentPageLength={page.length}
                            totalRows={tableData.length}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            callback={(value) => {
                                setCurrentPage(value);
                            }}
                        />
                    </>
                )}
            </StyledPopupChooseVariable>
        </PopupGeneric>
    );
}
