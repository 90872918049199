import styled from 'styled-components';

const Tr = styled.tr`
	color: ${(props) => {
		if (props.active === undefined) return undefined;

		switch (props.active) {
			case true:
				return undefined;
			case false:
				return props.theme.colors.grays.mid;
		}
	}};
`;

export default Tr;
