import Select from 'react-select';
import { Controller } from 'react-hook-form';

export default function InputSelect({
	options,
	control,
	accessor,
	editable = true,
	validation = {},
	defaultOption = undefined,
	width = '100%',
	onChanged = (value) => {},
}) {
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: width,
		}),
		menu: (provided, state) => ({
			...provided,
			width: width,
			flexGrow: 1,
		}),
	};

	return (
		<Controller
			name={accessor}
			control={control}
			rules={validation}
			render={({ field: { onChange, value } }) => (
				<Select
					value={options.find((c) => c.value === value)}
					onChange={(val) => {
						onChange(val.value);
						onChanged(val.value);
					}}
					defaultValue={defaultOption == undefined ? options[0] : defaultOption}
					options={options}
					isSearchable={false}
					isDisabled={!editable}
					styles={customStyles}
				/>
			)}
		/>
	);
}
