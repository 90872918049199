import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { useState, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';

// Libs
import { getColumnFromHeaderGroupByHeaderName } from '@/libs/tables.lib';

// Styles
import { StyledTableContainer, FlexRowAlignCenter, StyledSpan } from '@/styles/shared.styles';

// Components
import GlobalFilter from '@/components/filter-global.component';
import PaginationFooter from '@/components/pagination-footer.component';
import Table from '@/styles/table.styles';
import { ThDefault, ColGroups } from '@/components/table.component';
import TableActions from '@/components/table-actions.component';
import { TooltipActivatorWarning } from '@/components/tooltip-activators.component';

const headers = [
	{
		Header: 'Id Cheat Key',
		accessor: 'idCheatKey',
	},
	{
		Header: 'Key',
		accessor: 'key',
	},
	{
		Header: 'Ticket Id',
		accessor: 'ticketId',
	},
	{
		Header: 'Public',
		accessor: 'public',
	},
	{
		Header: 'Max Usage Amount',
		accessor: 'maxUsageAmount',
	},
	{
		Header: 'Current Usage Count',
		accessor: 'currentUsageCount',
	},
	{
		Header: 'Max Uses Per User',
		accessor: 'maxUsesPerUser',
	},
	{
		Header: 'Expire Date',
		accessor: 'expireDate',
	},
	{
		Header: 'Active',
		accessor: 'active',
	},
	{
		Header: 'Override Methods',
		accessor: 'overrideMethodParams',
	},
	{
		Header: 'Override Variables',
		accessor: 'overrideVariables',
	},
	{
		Header: 'Override Methods',
		accessor: 'overrideMethods'
	}
];

export default function CheatKeysTable({
	data,
	onEditRow = (index, cheatKey) => {},
	onDeleteRow = (index) => {},
	hasActions = true,
}) {
	const [currentPage, setCurrentPage] = useState(0);
	const memoizedHeaders = useMemo(() => headers, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			columns: memoizedHeaders,
			data: data,
			initialState: { pageIndex: currentPage, pageSize: 10 },
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const headerGroup = headerGroups[0];
	const columnKey = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Key');
	const columnExpireDate = getColumnFromHeaderGroupByHeaderName(headerGroup, 'Expire Date');

	return (
		<>
			<Tooltip
				id='cheat-key-not-active-tooltip'
				place='right'
				effect='solid'
			/>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>
			<StyledTableContainer
				style={{
					margin: '10px 0px 10px 0px',
				}}>
				<Table {...getTableProps()}>
					<ColGroups
						widths={
							hasActions
								? ['25%', '10%', '10%', '10%', '10%', '15%', '10%']
								: ['30%', '10%', '10%', '10%', '10%', '20%']
						}
					/>
					<Table.THead>
						<Table.Tr {...headerGroups[0].getHeaderGroupProps()}>
							<ThDefault
								column={columnKey}
								text={'Code'}
							/>
							<Table.Th>Public</Table.Th>
							<Table.Th>Max Usage Amount</Table.Th>
							<Table.Th>Current Usage Count</Table.Th>
							<Table.Th>Max Uses Per User</Table.Th>
							<ThDefault
								column={columnExpireDate}
								text={columnExpireDate.render('Header')}
							/>
							{hasActions && <Table.Th></Table.Th>}
						</Table.Tr>
					</Table.THead>
					<Table.TBody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);

							const date = new Date(row.values.expireDate);
							const options = {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
								timeZoneName: 'short',
							};
							const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
							const isActive = row.values.maxUsageAmount > row.values.currentUsageCount;
							return (
								<Table.Tr {...row.getRowProps()} active={isActive}>
									<Table.Td>
										<FlexRowAlignCenter
											style={{
												justifyContent: 'space-between',
											}}
										>
											<StyledSpan>
												{row.values.key}
											</StyledSpan>
											{!isActive && (
												<TooltipActivatorWarning
													id={'cheat-key-not-active-tooltip'}
													content={'This cheat key has been depleted. Increase "Max Usage Amount" to make it available again.'}
													size={20}
												/>
											)}
										</FlexRowAlignCenter>
									</Table.Td>
									<Table.Td>{row.values.public}</Table.Td>
									<Table.Td>{row.values.maxUsageAmount}</Table.Td>
									<Table.Td>{row.values.currentUsageCount}</Table.Td>
									<Table.Td>{row.values.maxUsesPerUser}</Table.Td>
									<Table.Td>{formattedDate}</Table.Td>
									{hasActions && (
										<Table.Td>
											<TableActions
												onEditRow={() => {
													onEditRow(index + pageIndex * pageSize, row.values);
												}}
												onDeleteRow={() => {
													onDeleteRow(index + pageIndex * pageSize);
												}}
											/>
										</Table.Td>
									)}
								</Table.Tr>
							);
						})}
					</Table.TBody>
				</Table>
			</StyledTableContainer>
			<PaginationFooter
				currentPageLength={page.length}
				totalRows={data.length}
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageOptions={pageOptions}
				pageCount={pageCount}
				gotoPage={gotoPage}
				nextPage={nextPage}
				previousPage={previousPage}
				setPageSize={setPageSize}
				pageIndex={pageIndex}
				pageSize={pageSize}
				callback={(value) => {
					setCurrentPage(value);
				}}
			/>
		</>
	);
}
