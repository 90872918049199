// Third-party
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import * as R from 'ramda';

// Services
import { get, getOneToManyById } from '@/libs/requests.lib';

import InputErrorMessage from '@/components/input-error-message.component';
import RequiredAsterisk from '@/components/required-asterisk.component';

// Styles
import {
    FlexRowAlignCenter,
    StyledSpanBold,
} from '@/styles/shared.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';

export default function InputAsyncSelectGameAndVersion({
    control,
    requestType,
    setValue,
    errors,
    watch,
    onIdGameChanged = (idGame) => {},
    onIdGameDefinitionChanged = (idGameDefinition) => {}, 
    filterByGroups = false,
    idGroupsToAllow = [],
    onError = (error) => {},
}) {
    const navigate = useNavigate();

    const [gameOptions, setGameOptions] = useState([]);
    const selectedIdGame = watch('idGame');
    const selectedIdGameDefinition = watch('idGameDefinition');

    const [gameDefinitionOptions, setGameDefinitionOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                // This will only run once
                if (gameOptions.length === 0) {
                    const data = await get({ endpoint: 'games', navigate });
                    
                    const formattedOptions = R.pipe(
                        R.filter((row) => {
                            if (!filterByGroups) return true;
                            
                            const idGroupsInGame = row['groups'].map((group) => group.idGroup);
                            
                            return R.intersection(idGroupsToAllow, idGroupsInGame).length > 0;
                        }),
                        R.map((row) => {
                            return {
                                value: row['idGame'],
                                label: row['name'],
                            };
                        })
                    )(data);

                    setGameOptions(formattedOptions);
                }
   
                if (selectedIdGame === null) return;

                const data = await getOneToManyById({ endpointOwner: 'games', idOwner: selectedIdGame, endpointOwned: 'gameDefinitions', navigate });

                const formattedOptions = R.pipe(
                    R.sort(compareVersions), // Sort using the custom comparator
                    R.map((row) => {
                        return {
                            value: row['idGameDefinition'],
                            label: row['version'],
                        };
                    })
                )(data);

                setGameDefinitionOptions(formattedOptions);

                const idGameDefinitionHasNotBeenSelected = selectedIdGameDefinition === undefined || selectedIdGameDefinition === null;
                if (requestType !== 'PATCH' && idGameDefinitionHasNotBeenSelected) {
                    const idGameDefinitionOfMaxVersion = R.pipe(
                        R.sort(compareVersions), // Sort using the custom comparator
                        R.head, // Get the first element
                        R.prop('idGameDefinition') // Get the idGameDefinition
                    )(data);
                    setValue('idGameDefinition', idGameDefinitionOfMaxVersion);
                    onIdGameDefinitionChanged(idGameDefinitionOfMaxVersion);
                }
            }
            catch (err) {
                onError(err);
            }
        }
        
        fetchData();
	}, [selectedIdGame]);

    const customStyles = {
		control: (provided, state) => ({
			...provided,
			width: '100%',
			minWidth: '100%',
            borderRadius: '10px'
		}),
		menu: (provided, state) => ({
			...provided,
			width: '100%',
			minWidth: '100%',
			flexGrow: 1,
            borderRadius: '10px'
		}),
	};

    return (
        <>
            <StyledGridForm.Label>
                <FlexRowAlignCenter>
                    <StyledSpanBold>Game</StyledSpanBold>
                    <RequiredAsterisk />
                </FlexRowAlignCenter>
            </StyledGridForm.Label>
            <StyledGridForm.Input>
                <Controller
                name={'idGame'}
                control={control}
                rules={{
                    required: true
                }}
                render={({ field: { onChange, value } }) => {
                        return (
                            <Select
                                value={gameOptions.find((option) => option.value === value)}
                                onChange={(val) => {
                                    onChange(val.value);
                                    onIdGameChanged(val.value);
                                }}
                                options={gameOptions}
                                isSearchable={false}
                                isDisabled={requestType === 'PATCH'}
                                styles={customStyles}
                            />
                        );
                    }}
                />
            </StyledGridForm.Input>
            <StyledGridForm.InputError>
                <InputErrorMessage
                    formErrors={errors}
                    accessor={'idGame'}
                />
            </StyledGridForm.InputError>
            <StyledGridForm.Gap />
            <StyledGridForm.Label>
                <FlexRowAlignCenter>
                    <StyledSpanBold>Version</StyledSpanBold>
                    <RequiredAsterisk />
                </FlexRowAlignCenter>
            </StyledGridForm.Label>
            <StyledGridForm.Input>
                <Controller
                name={'idGameDefinition'}
                control={control}
                rules={{
                    required: true
                }}
                render={({ field: { onChange, value } }) => {
                        return (
                            <Select
                                value={gameDefinitionOptions.find((option) => option.value === value)}
                                onChange={(val) => {
                                    onChange(val.value);
                                    onIdGameDefinitionChanged(val.value);
                                }}
                                options={gameDefinitionOptions}
                                isSearchable={false}
                                isDisabled={selectedIdGame === undefined || selectedIdGame === null || requestType === 'PATCH'}
                                styles={customStyles}
                            />
                        );
                    }}
                />
            </StyledGridForm.Input>
            <StyledGridForm.InputError>
                <InputErrorMessage
                    formErrors={errors}
                    accessor={'idGameDefinition'}
                />
            </StyledGridForm.InputError>
        </>
    );
}

const versionStringToNumbers = R.pipe(
	R.split('.'), // Split the version string into an array of strings
	R.map(Number) // Convert each string to a number
);

const compareVersions = (a, b) => {
	const versionA = versionStringToNumbers(a.version);
	const versionB = versionStringToNumbers(b.version);

	for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
		if ((Number(versionA[i]) || 0) > (Number(versionB[i]) || 0)) return -1;
		if ((Number(versionA[i]) || 0) < (Number(versionB[i]) || 0)) return 1;
	}

	return 0;
};