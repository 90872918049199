// Third-party
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';

// Components
import PopupGeneric from '@/components/popup-generic.component';
import { ButtonCancel, ButtonAccept, ButtonRemoveCircle } from '@/components/buttons.component';
import RowCloseButton from '@/components/row-close-button.component';
import TitleAndText from '@/components/title-and-text.component';

// Styles
import {
	StyledButtonNoStyle,
	FlexRowAlignCenter,
	FlexRow,
	FlexCol,
	StyledSpanBold,
	StyledSearchBar,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Libs
import { get } from '@/libs/requests.lib';

const StyledPopupGroupManageMembers = styled.div`
	display: grid;
	grid-template-rows: 25px 70px 1fr 40px;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'close close'
		'headerUnselected headerSelected'
		'unselected selected'
		'footer footer';
	height: 450px;
	width: 650px;
	padding: 10px 10px 10px 10px;
`;

const StyledUnselectedMembersList = styled(FlexCol)`
	grid-area: unselected;
	overflow-y: scroll;
	padding: 0px 15px;
	border-right: 1px solid ${theme.colors.grays.mid};
	gap: 5px;

	.flipper {
		width: 100%;
	}
`;

const StyledSelectedMembersList = styled(FlexCol)`
	grid-area: selected;
	overflow-y: scroll;
	padding: 0px 15px;
	gap: 5px;

	.flipper {
		width: 100%;
	}
`;

export default function PopupGroupManageMembers({ currentMembers, onManagingMembersDone = (membersInGroup) => {} }) {
	const navigate = useNavigate();
	const [membersInGroup, setMembersInGroup] = useState(currentMembers);
	const [unselectedMembers, setUnselectedMembers] = useState([]);
	const [search, setSearch] = useState('');
	const filteredUnselectedMembers = unselectedMembers.filter((member) => {
		return member.username.includes(search) || member.email.includes(search);
	});

	useEffect(() => {
		const getUnselectedMembers = async () => {
			const users = await get({
				endpoint: 'users',
				navigate,
			});
			const unselectedMembers = users.filter((user) => {
				const isUserInGroup = membersInGroup.find((member) => member.idUser === user.idUser);
				return !isUserInGroup;
			});

			setUnselectedMembers(unselectedMembers);
		};
		getUnselectedMembers();
	}, []);

	return (
		<PopupGeneric onClickOutside={() => {}}>
			<StyledPopupGroupManageMembers>
				<RowCloseButton
					onClose={() => {
						onManagingMembersDone(currentMembers);
					}}
					iconFill={theme.colors.base.black}
					style={{
						gridArea: 'close',
					}}
				/>
				<FlexCol
					style={{
						gridArea: 'headerUnselected',
						justifyContent: 'flex-start',
						marginBottom: '15px',
					}}>
					<StyledSpanBold
						style={{
							alignSelf: 'center',
							marginBottom: '10px',
						}}>
						Unselected Members
					</StyledSpanBold>
					<FlexRowAlignCenter
						style={{
							padding: '0 10px',
							gap: '5px',
						}}>
						<StyledSearchBar
							style={{
								flexGrow: 1,
							}}
							type='text'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder='Search by username or email...'
						/>
					</FlexRowAlignCenter>
				</FlexCol>
				<FlexRow
					style={{
						gridArea: 'headerSelected',
						justifyContent: 'center',
					}}>
					<StyledSpanBold>Members in Group</StyledSpanBold>
				</FlexRow>

				<StyledUnselectedMembersList>
					<Flipper
						flipKey={filteredUnselectedMembers.length}
						className='flipper'>
						{filteredUnselectedMembers.map((member) => (
							<Flipped
								key={member.idUser}
								flipId={member.idUser}>
								<StyledButtonNoStyle
									onClick={() => {
										setMembersInGroup([...membersInGroup, member]);
										setUnselectedMembers(
											unselectedMembers.filter((user) => user.idUser !== member.idUser)
										);
									}}
									style={{
										width: '100%',
										marginBottom: '5px',
									}}>
									<TitleAndText
										hoverable={true}
										title={member.username}
										text={member.email}
										titleFontSize='0.9rem'
										textFontSize='0.8rem'
										width='100%'
									/>
								</StyledButtonNoStyle>
							</Flipped>
						))}
					</Flipper>
				</StyledUnselectedMembersList>
				<StyledSelectedMembersList>
					<Flipper
						flipKey={membersInGroup.length}
						className='flipper'>
						{membersInGroup.map((member) => (
							<Flipped
								key={member.idUser}
								flipId={member.idUser}>
								<FlexRowAlignCenter
									style={{
										justifyContent: 'space-between',
										marginBottom: '5px',
									}}>
									<TitleAndText
										hoverable={false}
										title={member.username}
										text={member.email}
										titleFontSize='0.9rem'
										textFontSize='0.8rem'
									/>
									<ButtonRemoveCircle
										onClick={() => {
											setUnselectedMembers([...unselectedMembers, member]);
											setMembersInGroup(
												membersInGroup.filter((user) => user.idUser !== member.idUser)
											);
										}}
									/>
								</FlexRowAlignCenter>
							</Flipped>
						))}
					</Flipper>
				</StyledSelectedMembersList>
				<FlexRowAlignCenter
					style={{
						gridArea: 'footer',
						justifyContent: 'center',
						width: '100%',
						gap: '25px',
					}}>
					<ButtonCancel
						onClick={() => {
							onManagingMembersDone(currentMembers);
						}}
					/>

					<ButtonAccept
						onClick={() => {
							onManagingMembersDone(membersInGroup);
						}}
					/>
				</FlexRowAlignCenter>
			</StyledPopupGroupManageMembers>
		</PopupGeneric>
	);
}
