// Third-party
import { useState } from "react";
import { Tooltip } from "react-tooltip";

// Components
import TableViewGenericProps from "@/components/table-view-generic-props.component";
import Table from "@/styles/table.styles";
import UserMiniCard from "../components/user-mini-card.component";
import { ThDefault, ColGroups } from "@/components/table.component";

// Utils
import { getColumnFromHeaderGroupByHeaderName } from "@/libs/tables.lib";

// Styles
import { StyledContent } from "@/styles/shared.styles";

export default function LogsTableView() {
    const [tableViewGenericPropsRandomKey] = useState(Math.random());

    return (
        <StyledContent>
            <Tooltip id="user-not-active-tooltip" place="right" effect="solid" />
            <TableViewGenericProps
                menuName="logs"
                key={tableViewGenericPropsRandomKey}
            >
                {({
                    getTableProps,
                    headerGroups,
                    getTableBodyProps,
                    rows,
                    prepareRow,
                }) => {
                    const headerGroup = headerGroups[0];
                    const columnUser = getColumnFromHeaderGroupByHeaderName(
                        headerGroup,
                        "User"
                    );
                    const columnAction = getColumnFromHeaderGroupByHeaderName(
                        headerGroup,
                        "Action"
                    );
                    const columnParams = getColumnFromHeaderGroupByHeaderName(
                        headerGroup,
                        "Params"
                    );
                    const columnDate = getColumnFromHeaderGroupByHeaderName(
                        headerGroup,
                        "Date"
                    );

                    return (
                        <>
                            <Table {...getTableProps()}>
                                <ColGroups widths={["25%", "25%", "25%", "25%"]} />
                                <Table.THead>
                                    <Table.Tr {...headerGroup.getHeaderGroupProps()}>
                                        <ThDefault
                                            column={columnUser}
                                            text={columnUser.render("Header")}
                                        />
                                        <ThDefault
                                            column={columnAction}
                                            text={columnAction.render("Header")}
                                        />
                                        <ThDefault
                                            column={columnParams}
                                            text={columnParams.render("Header")}
                                        />
                                        <ThDefault
                                            column={columnDate}
                                            text={columnDate.render("Header")}
                                        />
                                    </Table.Tr>
                                </Table.THead>
                                <Table.TBody {...getTableBodyProps()}>
                                    {rows.map((row) => {
                                        prepareRow(row);

                                        const date = new Date(row.values.dateTime);
                                        const options = {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                            timeZoneName: "short",
                                        };
                                        const formattedDate = new Intl.DateTimeFormat(
                                            "en-US",
                                            options
                                        ).format(date);
                                        return (
                                            <Table.Tr {...row.getRowProps()} active={true}>
                                                <Table.Td>
                                                    <UserMiniCard
                                                        username={row.values.user.username}
                                                        email={row.values.user.email}
                                                        active={row.values.user.active === 1}
                                                        avatar={row.values.user.avatar}
                                                    />
                                                </Table.Td>
                                                <Table.Td>
                                                    <span
                                                        style={{
                                                            fontSize: "0.8rem",
                                                        }}
                                                    >
                                                        {row.values.endpoint}
                                                    </span>
                                                </Table.Td>
                                                <Table.Td>
                                                    <span
                                                        style={{
                                                            fontSize: "0.8rem",
                                                        }}
                                                    >
                                                        {row.values.description}
                                                    </span>
                                                </Table.Td>
                                                <Table.Td>
                                                    <span>{formattedDate}</span>
                                                </Table.Td>
                                            </Table.Tr>
                                        );
                                    })}
                                </Table.TBody>
                            </Table>
                        </>
                    );
                }}
            </TableViewGenericProps>
        </StyledContent>
    );
}
