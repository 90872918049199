import add from './add.svg';
import addCircle from './add_circle.svg';
import addCircleD from './add_circle';
import chat from './chat.svg';
import chatD from './chat';
import close from './close.svg';
import closeD from './close';
import closeFill from './close_fill.svg';
import closeFillD from './close_fill';
import deleteFillD from './delete_fill';
import deleteFill from './delete_fill.svg';
import deleteD from './delete';
import delete_ from './delete.svg';
import editFillD from './edit_fill';
import editFill from './edit_fill.svg';
import editD from './edit';
import edit from './edit.svg';
import photoCamera from './photo_camera.svg';
import photoCameraD from './photo_camera';
import remove from './remove.svg';
import removeD from './remove';
import removeCircle from './remove_circle.svg';
import removeCircleD from './remove_circle';
import renew from './renew.svg';
import renewD from './renew';
import search from './search.svg';
import searchD from './search';

export default {
	add,
	addCircle,
	addCircleD,
	chat,
	chatD,
	close,
	closeD,
	closeFill,
	closeFillD,
	deleteFill,
	deleteFillD,
	delete: delete_,
	deleteD,
	editFill,
	editFillD,
	edit,
	editD,
	photoCamera,
	photoCameraD,
	remove,
	removeD,
	removeCircle,
	removeCircleD,
	renew,
	renewD,
	search,
	searchD,
};
