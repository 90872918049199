import { useState, useEffect } from 'react';

// Styles
import { StyledSearchBar } from '@/styles/shared.styles';

export default function SearchBar({
	unfilteredValues,
	setFilteredValues = (values) => {},
	keysToSearch = [],
	placeholder = 'Search...',
}) {
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		setFilteredValues(
			unfilteredValues.filter((value) => {
				return keysToSearch.some((key) => {
					if (value[key] == null) return false;
					return `${value[key]}`.toLowerCase().includes(searchValue.toLowerCase());
				});
			})
		);
	}, [searchValue]);

	return (
		<StyledSearchBar
			style={{
				width: '100%',
				marginBottom: '10px',
			}}
			type='text'
			value={searchValue}
			onChange={(e) => setSearchValue(e.target.value)}
			placeholder={placeholder}
		/>
	);
}
