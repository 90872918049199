// Components
import SVG from '@/components/svg.component';

// Styles
import { FlexRowAlignCenter, StyledButtonNoStyle } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

// Assets
import { actions } from '@/assets/icons';

export default function RowCloseButton({ onClose, iconFill = theme.colors.base.white, style = {} }) {
	return (
		<FlexRowAlignCenter
			style={{
				justifyContent: 'flex-end',
				width: '100%',
				...style,
			}}>
			<StyledButtonNoStyle
				type='button'
				onClick={() => {
					onClose();
				}}>
				<SVG
					d={actions.closeD}
					width={25}
					height={25}
					fill={iconFill}
				/>
			</StyledButtonNoStyle>
		</FlexRowAlignCenter>
	);
}
