// Third-party
import styled from "styled-components";
import * as R from "ramda";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flipper, Flipped } from "react-flip-toolkit";

// Styles
import {
  FlexCol,
  FlexRow,
  FlexRowAlignCenter,
  StyledButtonNoStyle,
  StyledHRFullWidth,
  StyledButtonGeneric,
  StyledSpanBold,
} from "@/styles/shared.styles";
import theme from "@/styles/theme.styles";

// Assets
import { groupIcons, navigation, actions } from "@/assets/icons";

// Components
import SVG from "@/components/svg.component";
import InputText from "@/components/input-text.component";
import InputTextArea from "@/components/input-text-area.component";
import InputErrorMessage from "@/components/input-error-message.component";
import PopupGroupDetail from "@/components/popup-group-detail.component";
import PopupCancelConfirm from "@/components/popup-cancel-confirm.component";
import PopupGroupManageMembers from "@/components/popup-group-manage-members.component";
import PopupError from "@/components/popup-error.component";
import PopupSuccess from "@/components/popup-success.component";
import GroupMiniCard from "@/components/group-mini-card.component";
import {
  ButtonColor,
  ButtonIcon,
  ButtonSubmit,
  ButtonCancel,
  ButtonBack,
} from "@/components/buttons.component";

// Libs
import { colors } from "@/libs/groups.lib";
import { get, deleteById } from "@/libs/requests.lib";
import { useCustomNavigate } from "@/libs/hooks.lib";
import { useEditViewGenericProps } from "@/libs/hooks.lib";

// State
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

const StyledGroupsMainViewContainer = styled.div`
  position: relative;
  grid-area: content;
  max-width: 100%;
  max-height: 100%;
  margin: 40px 30px;
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

const StyledGroupsCreateViewWrapper = styled.div`
  width: ${(props) => (props.isCreateView ? "65%" : "100%")};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.isCreateView ? "stretch" : "center")};
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 18px 0 0 18px;
`;

const StyledGroupsCreateView = styled.form`
	width: ${(props) => (props.isCreateView ? "100%" : "60%")}};
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	padding: 0px 36px 18px 18px;
`;

const StyledGroupsListView = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  background-color: ${(props) => props.theme.colors.base.white};
  box-shadow: 1px 5px 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 20px;

  .flipper {
    width: 100%;
  }
`;

const StyledGroupList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const StyledFormSubtitle = styled(StyledSpanBold)`
  font-weight: bold;
`;

const StyledFormItem = styled(FlexCol)`
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
`;

const StyledGridLayout = styled(FlexRow)`
  gap: 15px;
  flex-wrap: wrap;
`;

export default function GroupsMainView({}) {
  const { navigateTo } = useCustomNavigate();
  const navigate = useNavigate();
  const { userAuthState } = useContext(UserAuthContext);

  const [popupSuccessMessage, setPopupSuccessMessage] = useState("");
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState("");
  const [showPopupError, setShowPopupError] = useState(false);

  // State
  const [formState, setFormState] = useState("POST");
  const [currentGroups, setCurrentGroups] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [popupGroupDetailIsVisible, setPopupGroupDetailIsVisible] =
    useState(false);
  const [popupGroupDetailPos, setPopupGroupDetailPos] = useState({
    x: 0,
    y: 0,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [popupDeleteGroupIsVisible, setPopupDeleteGroupIsVisible] =
    useState(false);
  const [popupManageMembersIsVisible, setPopupManageMembersIsVisible] =
    useState(false);
  const [usersInGroupUpdated, setUsersInGroupUpdated] = useState(false);
  const [genericPropsRandomKey, setGenericPropsRandomKey] = useState(
    Math.random()
  );

  // Computed state
  const colorAndIconHaveBeenSelected = selectedColor && selectedIcon;

  // Methods
  const getCurrentGroups = async () => {
    try {
      const data = await get({ endpoint: "groups", navigate });
      setCurrentGroups(data);
    } catch (error) {
      setPopupErrorMessage(error);
      setShowPopupError(true);
    }
  };

  const deleteGroup = async (idGroup) => {
    try {
      await deleteById({
        endpoint: "groups",
        id: idGroup,
        idUser: userAuthState.user.id,
        navigate,
      });

      setPopupSuccessMessage("Group deleted successfully!");
      setShowPopupSuccess(true);
    } catch (error) {
      setPopupErrorMessage(error);
      setShowPopupError(true);
    }
  };

  // Effects
  useEffect(() => {
    getCurrentGroups();
  }, []);

  const {
    handleSubmit,
    isDirty,
    errors,
    control,
    setValue,
    onSubmit,
    RequiredAsterisk,
    dispatchExtraData,
    getData,
    reset,
    getValues,
  } = useEditViewGenericProps({
    tableName: "groups",
    requestType: formState,
    contentType: "application/json",
    currentlySelectedId: selectedGroup === null ? null : selectedGroup.idGroup,
    onSubmitSuccess: () => {
      if (formState === "POST") {
        setPopupSuccessMessage("Group created successfully!");
      } else {
        setPopupSuccessMessage("Group updated successfully!");
      }
      setShowPopupSuccess(true);
      reset();
    },
    onSubmitError: (error) => {
      setPopupErrorMessage(error);
      setShowPopupError(true);
    },
    defaultExtraData: { users: [] },
  });

  const switchToPatchState = () => {
    setFormState("PATCH");
    setSelectedColor(selectedGroup.color);
    setSelectedIcon(selectedGroup.iconName);
    setUsersInGroupUpdated(false);
    getData();
  };

  const switchToPostState = () => {
    setFormState("POST");
    resetFormData();
    setUsersInGroupUpdated(false);
    reset();
  };

  const resetFormData = () => {
    setSelectedColor(null);
    setSelectedIcon(null);
    setSelectedGroup(null);
    setGenericPropsRandomKey(Math.random());
  };

  return (
    <>
      {popupGroupDetailIsVisible && (
        <PopupGroupDetail
          x={popupGroupDetailPos.x}
          y={popupGroupDetailPos.y}
          onClickOutside={() => {
            setSelectedGroup(null);
            setPopupGroupDetailIsVisible(false);
          }}
          onClickEdit={() => {
            setPopupGroupDetailIsVisible(false);
            switchToPatchState();
          }}
          groupData={selectedGroup}
          buttonEditIsVisible={
            userAuthState.user.permissions.groups.update !== false
          }
        />
      )}
      <StyledGroupsMainViewContainer key={formState + genericPropsRandomKey}>
        <>
          {showPopupSuccess && (
            <PopupSuccess
              message={popupSuccessMessage}
              onClose={() => {
                if (formState === "PATCH") {
                  switchToPostState();
                } else {
                  resetFormData();
                }

                getCurrentGroups();
                setShowPopupSuccess(false);
              }}
            />
          )}
          {showPopupError && (
            <PopupError
              error={popupErrorMessage}
              onClose={() => {
                setShowPopupError(false);
              }}
            />
          )}
          {popupDeleteGroupIsVisible && (
            <PopupCancelConfirm
              message="Are you sure you want to delete this group?"
              onCancel={() => setPopupDeleteGroupIsVisible(false)}
              onConfirm={() => {
                setSelectedGroup(null);
                deleteGroup(selectedGroup.idGroup);
                setPopupDeleteGroupIsVisible(false);
              }}
            />
          )}
          {userAuthState.user.permissions.groups.create !== false && (
            <StyledGroupsCreateViewWrapper isCreateView={formState === "POST"}>
              {popupManageMembersIsVisible && (
                <PopupGroupManageMembers
                  currentMembers={selectedGroup.users}
                  onManagingMembersDone={(data) => {
                    setPopupManageMembersIsVisible(false);
                    dispatchExtraData({ users: data });
                    setSelectedGroup({
                      ...selectedGroup,
                      users: data,
                    });
                    setUsersInGroupUpdated(true);
                  }}
                />
              )}
              <ButtonBack
                onClick={() => {
                  if (formState === "POST")
                    navigateTo({
                      mainPath: "users",
                      subPath: "table",
                    });
                  else switchToPostState();
                }}
                text={formState === "POST" ? "Back to Users" : "Back to Groups"}
              />
              <StyledGroupsCreateView
                onSubmit={handleSubmit(onSubmit)}
                isCreateView={formState === "POST"}
              >
                {formState === "POST" && (
                  <FlexRowAlignCenter
                    style={{
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <SVG d={navigation.groupsD} height="50" width="50" />
                    <StyledSpanBold
                      style={{
                        fontSize: "2rem",
                      }}
                    >
                      {"Groups"}
                    </StyledSpanBold>
                  </FlexRowAlignCenter>
                )}
                {formState === "PATCH" && (
                  <FlexRowAlignCenter
                    style={{
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <FlexRowAlignCenter
                      style={{
                        gap: "10px",
                      }}
                    >
                      <StyledSpanBold
                        style={{
                          fontSize: "2rem",
                          marginRight: "10px",
                        }}
                      >
                        Edit
                      </StyledSpanBold>
                      <SVG
                        d={selectedGroup.iconSVGPath}
                        height="35"
                        width="35"
                      />
                      <StyledSpanBold
                        style={{
                          fontSize: "1.5rem",
                        }}
                      >
                        {selectedGroup.name}
                      </StyledSpanBold>
                    </FlexRowAlignCenter>
                  </FlexRowAlignCenter>
                )}
                <FlexRowAlignCenter
                  style={{
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <StyledSpanBold
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    {formState === "POST" ? "Create new group" : "Edit group"}
                  </StyledSpanBold>
                  {formState === "PATCH" && (
                    <StyledButtonGeneric
                      hasShadow={true}
                      type="button"
                      onClick={() => {
                        setPopupManageMembersIsVisible(true);
                      }}
                      backgroundColor={theme.colors.blues.midPastel}
                    >
                      <StyledSpanBold>Manage Members</StyledSpanBold>
                    </StyledButtonGeneric>
                  )}
                </FlexRowAlignCenter>
                <StyledHRFullWidth
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                />
                <StyledFormItem>
                  <FlexRowAlignCenter>
                    <StyledFormSubtitle>NAME</StyledFormSubtitle>
                    <RequiredAsterisk />
                  </FlexRowAlignCenter>
                  <InputText
                    style={{
                      width: "400px",
                    }}
                    accessor="name"
                    control={control}
                    placeholder="Enter a name"
                    validation={{
                      required: true,
                      minLength: {
                        value: 3,
                        message: "3",
                      },
                      maxLength: {
                        value: 45,
                        message: "45",
                      },
                    }}
                  />
                  <InputErrorMessage
                    formErrors={errors}
                    accessor="name"
                    style={{
                      marginBottom: "10px",
                    }}
                  />
                </StyledFormItem>
                <StyledFormItem>
                  <StyledFormSubtitle>COLOR</StyledFormSubtitle>
                  <StyledGridLayout>
                    {colors.map((color) => (
                      <ButtonColor
                        key={color}
                        color={color}
                        width="40px"
                        height="40px"
                        selected={selectedColor === color}
                        onClick={() => {
                          setValue("color", color);
                          setSelectedColor(color);
                        }}
                      />
                    ))}
                  </StyledGridLayout>
                </StyledFormItem>
                <StyledFormItem>
                  <StyledFormSubtitle>ICON</StyledFormSubtitle>
                  <StyledGridLayout>
                    {R.pipe(
                      R.toPairs,
                      R.map(([key, value]) => {
                        return (
                          <ButtonIcon
                            key={key}
                            d={value}
                            width="40px"
                            height="40px"
                            selected={selectedIcon === key}
                            onClick={() => {
                              setValue("iconName", key);
                              setValue("iconSVGPath", value);
                              setSelectedIcon(key);
                            }}
                          />
                        );
                      })
                    )(groupIcons)}
                  </StyledGridLayout>
                </StyledFormItem>
                <StyledFormItem
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <FlexRowAlignCenter>
                    <StyledFormSubtitle>DESCRIPTION</StyledFormSubtitle>
                    <RequiredAsterisk />
                  </FlexRowAlignCenter>
                  <InputTextArea
                    placeholder={"Enter a small description of your group"}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    accessor="description"
                    control={control}
                    cols={null}
                    validation={{
                      required: true,
                      minLength: {
                        value: 3,
                        message: "3",
                      },
                      maxLength: {
                        value: 255,
                        message: "255",
                      },
                    }}
                  />
                  <InputErrorMessage
                    formErrors={errors}
                    accessor="description"
                    style={{
                      marginBottom: "10px",
                    }}
                  />
                </StyledFormItem>
                <FlexRow
                  style={{
                    justifyContent:
                      formState == "POST" ? "flex-end" : "space-between",
                  }}
                >
                  {formState === "PATCH" && (
                    <ButtonCancel
                      onClick={() => {
                        switchToPostState();
                      }}
                    />
                  )}
                  <ButtonSubmit
                    style={{
                      fontSize: "1.2rem",
                    }}
                    disabled={
                      !isDirty &&
                      !colorAndIconHaveBeenSelected &&
                      !usersInGroupUpdated
                    }
                    text={formState === "POST" ? "Create Group" : "Save"}
                    backgroundColor={
                      formState === "POST"
                        ? null
                        : theme.colors.greens.midStrong
                    }
                  />
                </FlexRow>
              </StyledGroupsCreateView>
            </StyledGroupsCreateViewWrapper>
          )}

          {formState === "POST" && (
            <StyledGroupsListView>
              <StyledSpanBold
                style={{
                  fontSize: "1.8rem",
                }}
              >{`Groups (${currentGroups.length})`}</StyledSpanBold>
              <Flipper flipKey={currentGroups.length} className="flipper">
                <StyledGroupList>
                  {currentGroups.map((group, index) => (
                    <Flipped flipId={group.name} key={index}>
                      <FlexRowAlignCenter
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <StyledButtonNoStyle
                          onClick={(e) => {
                            e.preventDefault();
                            setPopupGroupDetailPos({
                              x: e.clientX,
                              y: e.clientY,
                            });
                            setSelectedGroup(group);
                            setPopupGroupDetailIsVisible(true);
                          }}
                        >
                          <GroupMiniCard
                            name={group.name}
                            color={group.color}
                            iconSVGPath={group.iconSVGPath}
                          />
                        </StyledButtonNoStyle>
                        <StyledButtonNoStyle
                          onClick={(e) => {
                            e.preventDefault();
                            setPopupDeleteGroupIsVisible(true);
                            setSelectedGroup(group);
                          }}
                        >
                          <SVG
                            d={actions.deleteFillD}
                            height="30"
                            width="30"
                            fill={theme.colors.reds.midStrong}
                          />
                        </StyledButtonNoStyle>
                      </FlexRowAlignCenter>
                    </Flipped>
                  ))}
                </StyledGroupList>
              </Flipper>
            </StyledGroupsListView>
          )}
        </>
      </StyledGroupsMainViewContainer>
    </>
  );
}
