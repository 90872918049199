// Components
import PopupGeneric from './popup-generic.component';
import { ButtonAccept } from '@/components/buttons.component';
import styled from 'styled-components';

// Styles
import { FlexCol, StyledSpan, StyledSpanBold } from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';

const StyledPopupSuccess = styled(FlexCol)`
	width: 100%;
	height: 100%;
	gap: 10px;
	padding: 20px;
	align-items: center;
	border: 2px solid ${theme.colors.greens.midStrong};
	border-radius: 5px;
`;

export default function PopupSuccess({ message, onClose }) {
	return (
		<PopupGeneric
			onClickOutside={onClose}
			zIndex={30}>
			<StyledPopupSuccess>
				<StyledSpanBold
					style={{
						fontSize: '1.5rem',
					}}>
					{'Success!'}
				</StyledSpanBold>
				<StyledSpan>{message}</StyledSpan>
				<ButtonAccept
					style={{
						width: '100px',
						marginTop: '20px',
					}}
					onClick={onClose}
					text={'Ok'}
					backgroundColor={theme.colors.greens.midStrong}
				/>
			</StyledPopupSuccess>
		</PopupGeneric>
	);
}
