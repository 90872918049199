// Component
import GenericMiniCard from '@/components/generic-mini-card.component';
import { TooltipActivatorWarning } from '@/components/tooltip-activators.component';

// Styles
import { FlexRowAlignCenter } from '@/styles/shared.styles';

export default function UserMiniCard({
	username,
	email,
	avatar = null,
	active,
	hoverable = false,
	imageSize = '50px',
}) {
	return (
		<FlexRowAlignCenter
			style={{
				justifyContent: 'flex-start',
				width: '100%',
				height: '100%',
				gap: '20px',
			}}>
			<GenericMiniCard
				image={avatar}
				topText={username}
				bottomText={email}
				active={active}
				hoverable={hoverable}
				imageSize={imageSize}
			/>
			{!active && (
				<TooltipActivatorWarning
					id={'user-not-active-tooltip'}
					content={'User is not active'}
					size={20}
				/>
			)}
		</FlexRowAlignCenter>
	);
}
