import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';
import * as R from 'ramda';

// Components
import InputText from '@/components/input-text.component';
import InputTextArea from '@/components/input-text-area.component';
import InputAsyncSelectGameAndVersion from '@/components/input-async-select-game-and-version.component';
import InputErrorMessage from '@/components/input-error-message.component';
import SVG from '@/components/svg.component';
import {
    CancelSaveRow,
    ButtonBack,
    ButtonIcon,
} from '@/components/buttons.component';
import PopupError from '@/components/popup-error.component';
import PopupSuccess from '@/components/popup-success.component';
import GroupMiniCard from '@/components/group-mini-card.component';
import PopupAddGroup from '@/components/popup-add-group.component';
import RequiredAsterisk from '@/components/required-asterisk.component';
import LoadingIcon from '@/components/loading-icon.component';

// Assets
import { navigation, actions, alerts } from '@/assets/icons';

// Styles
import {
    StyledContent,
    FlexRowAlignCenter,
    StyledSpanBold,
    StyledSpan,
    StyledButtonGeneric,
    StyledButtonNoStyle,
} from '@/styles/shared.styles';
import theme from '@/styles/theme.styles';
import StyledGridForm from '@/styles/styled-grid-form.styles';

// Requests
import { getById, getOneToManyById } from '@/libs/requests.lib';

// State
import { CheatDefinitionsContext } from '@/contexts/cheat-definitions.context';
import { UserAuthContext } from '@/contexts/user-auth.context';
import { useContext } from 'react';

// Libs
import {
    getFormattedMethodsData,
    getFormattedVariablesData,
} from '@/libs/cheat-definitions.lib';
import {
    postApplicationJson,
    patchApplicationJsonById,
} from '@/libs/requests.lib';
import { useCustomNavigate } from '@/libs/hooks.lib';
import { get } from '@/libs/requests.lib';

function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function CheatDefinitionsEditView() {
    const { navigateTo } = useCustomNavigate();
    const navigate = useNavigate();

	const { userAuthState } = useContext(UserAuthContext);
    const {
        cheatDefinitionsState,
        setCheatDefinitionsState,
        resetCheatDefinitionsState,
    } = useContext(CheatDefinitionsContext);

    const [loading, setLoading] = useState(true);
    const [showPopupAddGroup, setShowPopupAddGroup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPopupError, setShowPopupError] = useState(false);
    const [showPopupSuccess, setShowPopupSuccess] = useState(false);
    const [hasValidCode, setHasValidCode] = useState(true);

    const {
        handleSubmit,
        formState: { errors, isDirty },
        control,
        setValue,
        watch,
        getValues,
        reset,
    } = useForm({
        active: true,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'groups',
    });

    const [nonSelectedGroups, setNonSelectedGroups] = useState([]);

    const getGroups = async () => {
        const response = await get({ endpoint: 'groups', navigate });
        if (response) {
            const processedGroups = R.pipe(
                R.map((group) => {
                    return R.dissoc('users', group);
                }),
                R.filter(
                    (group) =>
                        !R.find(R.propEq('idGroup', group.idGroup))(fields)
                )
            )(response);
            setNonSelectedGroups(processedGroups);
        }
    };

    const idGameDefinition = watch('idGameDefinition', cheatDefinitionsState.currentlySelectedIdGameDefinition);

    useEffect(() => {
        async function setupData() {
            setLoading(true);
            try {
                let cheatDefinitionsState_ = cheatDefinitionsState;
                // If we're on this view and requestType is null
                if (!cheatDefinitionsState || cheatDefinitionsState_.requestType == null) {
                    navigateTo({
                        mainPath: 'cheatDefinitions',
                        subPath: 'table',
                    })
                }
                // The first time the component is mounted, this condition will be true
                if (!cheatDefinitionsState_.isEditing) {
                    // This gets called in case we are editing a cheat definition
                    if (cheatDefinitionsState_.currentlySelectedId != null) {
                        let cheatDefinition = await getById({
                            endpoint: 'cheatDefinitions',
                            id: cheatDefinitionsState_.currentlySelectedId,
                            navigate,
                        });

                        let cheatKeys = await getOneToManyById({
                            endpointOwner: 'cheatDefinitions',
                            idOwner: cheatDefinitionsState_.currentlySelectedId,
                            endpointOwned: 'cheatKeys',
                            navigate,
                        });

                        
                        cheatKeys = R.map((cheatKey) => {
                            return {
                                ...cheatKey,
                                overrideMethodParams: cheatKey.overrideMethodParams === null ? [] : JSON.parse('[' + 
                                cheatKey.overrideMethodParams + ']'
                                ),
                                overrideVariables: cheatKey.overrideVariables === null ? [] : JSON.parse('[' + 
                                cheatKey.overrideVariables + ']'
                                ),
                                overrideMethods: cheatKey.overrideMethods === null ? [] : JSON.parse('[' + cheatKey.overrideMethods + ']'),
                            }
                        }, cheatKeys);
                        
                        const variables = await getOneToManyById({
                            endpointOwner: 'cheatDefinitions',
                            idOwner: cheatDefinitionsState_.currentlySelectedId,
                            endpointOwned: 'variableDescriptions',
                            navigate,
                        });
                        const formattedVariables =
                            getFormattedVariablesData(variables);

                        const methods = await getOneToManyById({
                            endpointOwner: 'cheatDefinitions',
                            idOwner: cheatDefinitionsState_.currentlySelectedId,
                            endpointOwned: 'methodDescriptions',
                            navigate,
                        });
                        const formattedMethods =
                            getFormattedMethodsData(methods);

                        reset(cheatDefinition[0]);
                        setCheatDefinitionsState({
                            ...cheatDefinitionsState_,
                            cheatDefinition: cheatDefinition[0],
                            cheatKeys,
                            variables: formattedVariables,
                            methods: formattedMethods,
                            isEditing: true,
                        });

                        const hasValidCode = R.any((cheatKey) => {
                            return cheatKey.currentUsageCount < cheatKey.maxUsageAmount;
                        })(cheatKeys);
                        setHasValidCode(hasValidCode);

                        setLoading(false);
                    } else {
                        // This gets called in case we are creating a new cheat definition
                        await delay(500);

                        const filteredGroups = R.filter((group) => {
                            return group.name != 'Hyperbeard';
                        })(userAuthState.user.groups);

                        setCheatDefinitionsState({
                            ...cheatDefinitionsState_,
                            cheatDefinition: { groups: filteredGroups },
                            isEditing: true,
                        });

                        setHasValidCode(false);

                        reset({ groups: filteredGroups });

                        setLoading(false);
                    }
                } else {
                    // This gets called in case we are coming back from the definition editor or the cheat keys editor
                    await delay(500);

                    reset(cheatDefinitionsState_.cheatDefinition);

                    const hasValidCode = R.any((cheatKey) => {
                        return cheatKey.currentUsageCount < cheatKey.maxUsageAmount;
                    })(cheatDefinitionsState_.cheatKeys);
                    setHasValidCode(hasValidCode);

                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setErrorMessage(error);
                setShowPopupError(true);
            }
        }

        setupData();
    }, []);

    const onSubmit = async (data) => {
        if (data.groups.length == 0) {
            setErrorMessage(
                'Cheat needs to have at least one group. If you do not have any group available, please contact your leader.'
            );
            setShowPopupError(true);
            return;
        }

        setLoading(true);
        const finalData = {
            ...data,
            cheatKeys: cheatDefinitionsState.cheatKeys,
            variables: cheatDefinitionsState.variables,
            methods: cheatDefinitionsState.methods,
        };

        const requestFunction =
            cheatDefinitionsState.requestType == 'POST'
                ? postApplicationJson({
                      endpoint: 'cheatDefinitions',
                      body: finalData,
                      idUser: userAuthState.user.id,
                      navigate,
                  })
                : patchApplicationJsonById({
                      endpoint: 'cheatDefinitions',
                      id: cheatDefinitionsState.currentlySelectedId,
                      body: finalData,
                      idUser: userAuthState.user.id,
                      navigate,
                  });

        try {
            await requestFunction;

            setShowPopupSuccess(true);
            setLoading(false);
        } catch (error) {
            setErrorMessage(error);
            setShowPopupError(true);
            setLoading(false);
        }
    };
    
    return (
        <StyledContent
            style={{
                justifyContent: 'flex-start',
            }}>
            {showPopupError && (
                <PopupError
                    error={errorMessage}
                    onClose={() => {
                        setShowPopupError(false);
                    }}
                />
            )}
            {showPopupSuccess && (
                <PopupSuccess
                    message={
                        cheatDefinitionsState.requestType == 'POST'
                            ? 'Cheat created successfully!'
                            : 'Cheat updated successfully!'
                    }
                    onClose={() => {
                        if (cheatDefinitionsState.isPartialSnapshot) {
                            resetCheatDefinitionsState();
                            navigateTo({
                                mainPath: 'partialSnapshots',
                                subPath: 'patch',
                            });
                        } else {
                            if (cheatDefinitionsState.requestType == 'PATCH') {
                                resetCheatDefinitionsState();
                                navigateTo({
                                    mainPath: 'cheatDefinitions',
                                    subPath: 'table',
                                });
                            } else {
                                setCheatDefinitionsState({
                                    ...cheatDefinitionsState,
                                    requestType: 'PATCH',
                                });

                                setShowPopupSuccess(false);
                            }
                        }
                    }}
                />
            )}
            {showPopupAddGroup && (
                <PopupAddGroup
                    nonSelectedGroups={nonSelectedGroups}
                    onClose={() => {
                        setShowPopupAddGroup(false);
                    }}
                    onGroupSelected={(group) => {
                        append(group);
                        setShowPopupAddGroup(false);
                    }}
                />
            )}
            <ButtonBack
                text={
                    cheatDefinitionsState && cheatDefinitionsState.isPartialSnapshot
                        ? 'Back to Partial Snapshot'
                        : 'Back to Code Batches'
                }
                onClick={() => {
                    const isPartialSnapshot = cheatDefinitionsState.isPartialSnapshot;
                    resetCheatDefinitionsState();
                    if (isPartialSnapshot) {
                        navigateTo({
                            mainPath: 'partialSnapshots',
                            subPath: 'patch',
                        });
                    } else {
                        navigateTo({
                            mainPath: 'cheatDefinitions',
                            subPath: 'table',
                        });
                    }
                }}
            />
            {idGameDefinition !== null && idGameDefinition !== undefined && !hasValidCode && (
                <FlexRowAlignCenter
                    style={{
                        marginTop: '20px',
                        border: `1px solid ${theme.colors.reds.midStrong}`,
                        padding: '10px 20px',
                        borderRadius: '5px',
                        maxWidth: '600px',
                        gap: '10px',
                    }}
                >
                    <SVG 
                        d={alerts.warningFillD}
                        width={50}
                        height={50}
                        fill={theme.colors.reds.midStrong}
                    />
                    <StyledSpan
                        style={{
                            fontSize: '15px',
                            color: theme.colors.reds.midStrong,
                            
                        }}
                    >There are no valid codes for this code batch! To use this code batch you need at least 1 code with "Max Usage Amount" greater than "Current Usage Count".</StyledSpan>
                </FlexRowAlignCenter>
            )}
            <FlexRowAlignCenter
                style={{
                    gap: '10px',
                    marginTop: '20px',
                    marginBottom: '50px',
                }}>
                <SVG
                    d={navigation.cheatDefinitionsD}
                    width={50}
                    height={50}
                    fill={theme.colors.greens.midStrong}
                />
                <StyledSpanBold
                    style={{
                        fontSize: '30px',
                    }}>{`${
                    cheatDefinitionsState && cheatDefinitionsState.requestType === 'PATCH'
                        ? 'Edit'
                        : 'Create'
                } Code Batch`}</StyledSpanBold>
            </FlexRowAlignCenter>
            {loading && <LoadingIcon />}
            {!loading && (
                <StyledGridForm onSubmit={handleSubmit(onSubmit)}>
                    <StyledGridForm.Label>ID</StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <StyledSpan>
                            {getValues('idCheatDefinition')
                                ? getValues('idCheatDefinition')
                                : 'N/A'}
                        </StyledSpan>
                    </StyledGridForm.Input>
                    <StyledGridForm.Gap />
                    <InputAsyncSelectGameAndVersion 
                        control={control}
                        getValues={getValues}
                        requestType={cheatDefinitionsState.requestType}
                        setValue={setValue}
                        errors={errors}
                        watch={watch}
                        onIdGameChanged={() => {
                            setValue('cheatDefinition', '');
                            setValue('arguments', []);
                        }}
                        onIdGameDefinitionChanged={(idGameDefinition) => {
                            setCheatDefinitionsState({
                                ...cheatDefinitionsState,
                                currentlySelectedIdGameDefinition: idGameDefinition,
                            });
                        }}
                        onError={(error) => {
                            setErrorMessage(error);
                            setShowPopupError(true);
                        }}
                        filterByGroups={true}
                        idGroupsToAllow={userAuthState.user.groups.map(group => group.idGroup)}
                    />
                    <StyledGridForm.Gap />
                    <StyledGridForm.Label>
                        <FlexRowAlignCenter>
                            <StyledSpanBold>Name</StyledSpanBold>
                            <RequiredAsterisk />
                        </FlexRowAlignCenter>
                    </StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <InputText
                            placeholder={'Enter a Name'}
                            accessor={'name'}
                            control={control}
                            validation={{ required: true }}
                        />
                    </StyledGridForm.Input>
                    <StyledGridForm.InputError>
                        <InputErrorMessage
                            formErrors={errors}
                            accessor={'name'}
                        />
                    </StyledGridForm.InputError>
                    <StyledGridForm.Gap />
                    <StyledGridForm.Label>
                        <FlexRowAlignCenter>
                            <StyledSpanBold>Description</StyledSpanBold>
                            <RequiredAsterisk />
                        </FlexRowAlignCenter>
                    </StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <InputTextArea
                            placeholder={'Enter a Description'}
                            accessor={'description'}
                            control={control}
                            validation={{ required: true }}
                            cols={null}
                            style={{
                                width: '100%',
                            }}
                        />
                    </StyledGridForm.Input>
                    <StyledGridForm.InputError>
                        <InputErrorMessage
                            formErrors={errors}
                            accessor={'description'}
                        />
                    </StyledGridForm.InputError>
                    <StyledGridForm.Gap />
                    <StyledGridForm.Label>Groups</StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <Flipper
                            flipKey={fields
                                .map((item) => item.idGroup)
                                .join('')}>
                            <FlexRowAlignCenter
                                style={{
                                    gap: '10px',
                                    width: '100%',
                                }}>
                                <FlexRowAlignCenter
                                    style={{
                                        width: '350px',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        flexGrow: 1,
                                        height: '100px',
                                        overflowY: 'auto',
                                        border: `1px solid ${theme.colors.grays.light}`,
                                        borderRadius: '5px',
                                        padding: '5px 10px',
                                    }}>
                                    {fields.map((item, index) => (
                                        <Flipped
                                            key={item.idGroup}
                                            flipId={item.idGroup}>
                                            <FlexRowAlignCenter
                                                style={{
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    backgroundColor:
                                                        theme.colors.grays
                                                            .light,
                                                    borderRadius: '5px',
                                                    padding: '5px 10px',
                                                }}>
                                                <GroupMiniCard
                                                    name={item.name}
                                                    iconSVGPath={
                                                        item.iconSVGPath
                                                    }
                                                    color={item.color}
                                                />
                                                <StyledButtonNoStyle
                                                    type={'button'}
                                                    onClick={() => {
                                                        remove(index);
                                                    }}>
                                                    <SVG
                                                        d={actions.closeFillD}
                                                        width={15}
                                                        height={15}
                                                        fill={
                                                            theme.colors.reds
                                                                .midStrong
                                                        }
                                                    />
                                                </StyledButtonNoStyle>
                                            </FlexRowAlignCenter>
                                        </Flipped>
                                    ))}
                                </FlexRowAlignCenter>
                                <ButtonIcon
                                    hoverable={true}
                                    d={actions.addCircleD}
                                    fill={theme.colors.blues.midPastel}
                                    hoverFill={theme.colors.blues.midStrong}
                                    onClick={() => {
                                        getGroups();
                                        setShowPopupAddGroup(true);
                                    }}
                                    width={'35px'}
                                    height={'35px'}
                                />
                            </FlexRowAlignCenter>
                        </Flipper>
                    </StyledGridForm.Input>
                    <StyledGridForm.Gap />
                    <StyledGridForm.Label>Definition</StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <StyledButtonGeneric
                            type={'button'}
                            hasShadow={true}
                            backgroundColor={theme.colors.greens.midStrong}
                            onClick={() => {
                                setCheatDefinitionsState({
                                    ...cheatDefinitionsState,
                                    cheatDefinition: getValues(),
                                });
                                navigateTo({
                                    mainPath: 'cheatDefinitions',
                                    subPath: 'definitionEditor',
                                });
                            }}
                            style={{
                                width: '100%',
                            }}
                            disabled={idGameDefinition === null || idGameDefinition === undefined}>
                            Edit
                        </StyledButtonGeneric>
                    </StyledGridForm.Input>
                    <StyledGridForm.Gap />
                    <StyledGridForm.Label>Codes</StyledGridForm.Label>
                    <StyledGridForm.Input>
                        <StyledButtonGeneric
                            type={'button'}
                            hasShadow={true}
                            backgroundColor={theme.colors.greens.midStrong}
                            onClick={() => {
                                setCheatDefinitionsState({
                                    ...cheatDefinitionsState,
                                    cheatDefinition: getValues(),
                                });
                                navigateTo({
                                    mainPath: 'cheatDefinitions',
                                    subPath: 'cheatKeys',
                                });
                            }}
                            style={{
                                width: '100%',
                            }}
                            disabled={idGameDefinition === null || idGameDefinition === undefined}>
                            Edit
                        </StyledButtonGeneric>
                    </StyledGridForm.Input>
                    <StyledGridForm.Gap />
                    <StyledGridForm.Gap />
                    <StyledGridForm.Gap />
                    <FlexRowAlignCenter
                        style={{
                            gridColumn: '1 / 3',
                            justifyContent: 'center',
                            width: '100%',
                        }}>
                        <CancelSaveRow
                            onCancel={() => {
                                const isPartialSnapshot = cheatDefinitionsState.isPartialSnapshot;
                                resetCheatDefinitionsState();
                                if (isPartialSnapshot) {
                                    navigateTo({
                                        mainPath: 'partialSnapshots',
                                        subPath: 'patch',
                                    });
                                } else {
                                    navigateTo({
                                        mainPath: 'cheatDefinitions',
                                        subPath: 'table',
                                    });
                                }
                            }}
                            saveDisabled={
                                !isDirty &&
                                cheatDefinitionsState &&
                                !cheatDefinitionsState.variablesOrMethodsUpdated &&
                                !cheatDefinitionsState.cheatKeysUpdated
                            }
                        />
                    </FlexRowAlignCenter>
                </StyledGridForm>
            )}
        </StyledContent>
    );
}
