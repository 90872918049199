import styled from 'styled-components';
import { StyledButtonNoStyle } from '@/styles/shared.styles';

const StyledSelect = styled.div`
	position: relative;
	width: ${(props) => props.width || '160px'};
`;

const StyledSelectHeader = styled.button`
	width: 100%;
	height: 42px;
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 7px 0 18px;
	cursor: pointer;
	font-size: 1.1rem;
	color: #4e4e4c;
`;

const StyledSelectList = styled.div`
	position: absolute;
	width: ${(props) => props.width || '100%'};
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	z-index: 1;
	padding: 8px 5px;
	cursor: pointer;
	overflow-y: auto;
	max-height: ${(props) => props.maxHeight || '100%'}};

	input {
		cursor: pointer;
	}

	label {
		cursor: pointer;
	}
`;

const StyledSelectOption = styled(StyledButtonNoStyle)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 10px;
	font-size: 0.9rem;
	font-weight: 550;
	margin: 5px 0;
	width: 100%;

	&:hover {
		background-color: ${(props) => props.theme.colors.purples.light}};
	}
`;

StyledSelect.Header = StyledSelectHeader;
StyledSelect.List = StyledSelectList;
StyledSelect.Option = StyledSelectOption;

export default StyledSelect;
